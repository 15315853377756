import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu as AntMenu } from 'antd'

import { Module } from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core/dist'
import { Account } from '@vms/vmspro3-core/dist/types'

import useAuthz from '../../../hooks/useAuthz'
import config from '../../../config.json'
import { useCanUpdateSubscription } from '../../../redux/hooks'

interface MenuProps {
  account?: Account
  menuOpen: boolean
  closeMenu: VoidFunction
}

type MenuItem = {
  key: string
  label: string
  children?: MenuItem[]
}

/**
 * Renders application menu. Menu is shown when state.app.menuOpen is true, nothing
 * when false. Component memoization is added on export to preserve component name
 * in the React devtools component explorer.
 */
export function Menu({ account, menuOpen, closeMenu }: MenuProps) {
  const location = useLocation()
  const canUpdateSubscription = useCanUpdateSubscription()

  const helpdesk = config.instance?.helpdesk
  const showHelpdesk = !!helpdesk

  const navigate = useNavigate()
  const onMenuClick = useCallback(
    ({ key }) => {
      if (key === helpdesk.url) window.open(helpdesk.url, '_blank')?.focus()
      else navigate(key)
      closeMenu()
    },
    [helpdesk.url, navigate, closeMenu]
  )

  const authz = useAuthz()
  const showRiskManagement = useMemo(
    () => authz(actions.feature.useModule({}, { moduleKey: Module.PORTFOLIO_RISK })),
    [authz]
  )

  const menuItems = useMemo(() => {
    const menuItems: MenuItem[] = []
    if (account) {
      menuItems.push({ key: `/${account.commonId}`, label: 'Home' })
      if (showRiskManagement) menuItems.push({ key: `/${account.commonId}/port`, label: 'Risk Management' })
      if (!!account.quotas.decisions) {
        menuItems.push({ key: `/${account.commonId}/decision`, label: 'Decision Support' })
      }
      const adminMenuItems = [
        { key: `/${account.commonId}/users`, label: 'User Management' },
        { key: `/${account.commonId}/roles`, label: 'Roles' },
      ]
      if (canUpdateSubscription) {
        adminMenuItems.push({ key: `/${account.commonId}/subscription`, label: 'Subscription' })
      }
      menuItems.push({
        key: 'admin',
        label: 'Admin',
        children: adminMenuItems,
      })
    }
    if (showHelpdesk) {
      menuItems.push({ key: helpdesk.url, label: helpdesk.title })
    }
    return menuItems
  }, [account, canUpdateSubscription, helpdesk.title, helpdesk.url, showHelpdesk, showRiskManagement])

  return (
    <>
      <div style={style.container(menuOpen)}>
        <AntMenu
          mode="inline"
          onClick={onMenuClick}
          selectedKeys={[location.pathname]}
          theme="dark"
          items={menuItems}
        />
        <div style={style.footer}>
          Version {process.env.REACT_APP_VMSPRO_VERSION} (build {process.env.REACT_APP_GIT_HEAD_COMMIT_ID})
          <br />
          &copy; {new Date().getFullYear()} OptionLab Inc.
        </div>
      </div>
      <div onClick={closeMenu} style={menuOpen ? style.overlayBlock : style.overlayHidden} />
    </>
  )
}

const antDesignMenuDarkBackground = '#001529'

const style = {
  container: (menuOpen: boolean) =>
    ({
      '--menu-open-width': '300px',
      width: menuOpen ? 'var(--menu-open-width)' : '0',
      height: '100%',
      zIndex: 10001,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: antDesignMenuDarkBackground,
      color: 'rgba(255, 255, 255, 0.65)',
      fontWeight: 300,
      boxShadow: '4px 0px 13px -2px rgba(0, 0, 0, .3)',
      transition: 'width 120ms ease-in-out',
      overflowX: 'hidden',
      position: 'absolute',
    } as React.CSSProperties),
  footer: {
    marginBottom: '20px',
    textAlign: 'center',
    width: 'var(--menu-open-width)',
  } as React.CSSProperties,
  overlayHidden: {
    display: 'none',
  },
  overlayBlock: {
    display: 'block',
    zIndex: 10000,
    height: '100vh',
    width: '100vw',
    position: 'fixed',
  },
  sider: {
    fontWeight: '300',
    boxShadow: '4px 0px 13px -2px rgba(0, 0, 0, .3)',
    position: 'fixed',
    height: 'calc(100vh - 52px)',
    zIndex: 10001,
  },
}
