import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'

function getEntityTypeFromPathnameType(type) {
  switch (type) {
    case 'port':
      return EntityType.PORTFOLIO
    case 'prog':
      return EntityType.PROGRAM
    case 'proj':
      return EntityType.PROJECT
    case 'risk':
      return EntityType.RISK
    default:
      throw new Error(`Unrecognized entityType in path params "${type}"`)
  }
}

function getPathnameTypeFromEntityType(type) {
  switch (type) {
    case EntityType.PORTFOLIO:
      return 'port'
    case EntityType.PROGRAM:
      return 'prog'
    case EntityType.PROJECT:
      return 'proj'
    case EntityType.RISK:
      return 'risk'
    default:
      throw new Error(`Unrecognized entityType "${type}"`)
  }
}

export { getEntityTypeFromPathnameType, getPathnameTypeFromEntityType }
