import './polyfills'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Provider as ReduxProvider } from 'react-redux'
import { ConfigProvider as AntDesignConfigProvider } from 'antd'

import { stage } from './utils/env'
import config from './config.json'

import { App } from './components/App'

import { apolloClient } from './graphql'
import { store } from './redux'

import 'antd/dist/antd.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress?: any
    store?: typeof store
  }
}
if (window.Cypress) {
  window.store = store
}

document.title = config.instance.appName || 'OptionLab'

const enableSentry =
  stage.startsWith('prod') ||
  stage === 'staging' ||
  stage === 'risk' ||
  stage.startsWith('qa') ||
  process.env.REACT_APP_ENABLE_SENTRY === '1'

if (enableSentry) {
  console.log('[initializing Sentry client]')
  Sentry.init({
    dsn: 'https://d778f42f4a3940759a35a2eba973d61e@o138263.ingest.sentry.io/1365392',
    environment: (config.instance && config.instance.awsAccount) + ':' + stage,
    release: 'vmspro3:' + (process.env.REACT_APP_GIT_HEAD_COMMIT_ID || 'UNKNOWN_RELEASE'),
    beforeSend(event) {
      if (event.user && event.user.sessionStarted) {
        const duration = moment.duration(Date.now() - event.user.sessionStarted)
        event.user.sessionAge =
          duration.asMinutes() < 1
            ? `${duration.asSeconds()} second(s)`
            : `${duration.hours()} hour(s), ${duration.minutes()} minute(s)`
      }
      return event
    },
    // attachStacktrace: appends a stackTrace for the error and will show the exact line the error was triggered.
    attachStacktrace: true,
  })
}

const formConfig: React.ComponentProps<typeof AntDesignConfigProvider>['form'] = {
  validateMessages: {
    /* eslint-disable no-template-curly-in-string */
    required: '${label} is required',
    whitespace: '${label} cannot be empty',
    /* eslint-enable no-template-curly-in-string */
  },
}

ReactDOM.render(
  <AntDesignConfigProvider form={formConfig}>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </ReduxProvider>
    </BrowserRouter>
  </AntDesignConfigProvider>,
  document.getElementById('root')
)
