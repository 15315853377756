import { Navigate, Route } from 'react-router-dom'

import RiskPortfolio from './components/RiskPortfolio'
import RiskEntityConfig from './components/RiskEntityConfig'
import RiskDetail from './components/Risk'

export function riskRoutes() {
  return (
    <>
      <Route path="port">
        <Route index element={<RiskPortfolio entityType="PORTFOLIO" />} />
        <Route path="config" element={<RiskEntityConfig />} />
        <Route path=":entityId" element={<RiskPortfolio entityType="PORTFOLIO" />} />
        <Route path=":entityId/config" element={<RiskEntityConfig />} />
      </Route>
      <Route path="prog">
        <Route index element={<Navigate to="/port" />} />
        <Route path=":entityId" element={<RiskPortfolio entityType="PROGRAM" />} />
        <Route path=":entityId/config" element={<RiskEntityConfig />} />
      </Route>
      <Route path="proj">
        <Route index element={<Navigate to="/port" />} />
        <Route path=":entityId" element={<RiskPortfolio entityType="PROJECT" />} />
        <Route path=":entityId/config" element={<RiskEntityConfig />} />
        <Route path=":projectId/risk/:riskId" element={<RiskDetail />} />
      </Route>
    </>
  )
}
