import { proxy, useSnapshot } from 'valtio'
import _mapValues from 'lodash/mapValues'
import { useCallback, useMemo } from 'react'
import { traverseDFS } from '../../utils/traverseDFS'
import { Checkbox } from 'antd'

// see https://github.com/pmndrs/valtio/issues/327
declare module 'valtio' {
  function useSnapshot<T extends object>(p: T): T
}

interface ItemProps<T extends string> {
  k: T
  itemsByKey: Record<T, CheckboxTreeItem<T>>
  onChange: (key: T, value: boolean) => void
}
function Item<T extends string>(props: ItemProps<T>) {
  const { k, itemsByKey, onChange } = props
  const item = useSnapshot(itemsByKey)[k]
  return (
    <label>
      <Checkbox
        type="checkbox"
        disabled={item.disabled}
        checked={!!item.value}
        onChange={e => onChange(item.key, e.target.checked)}
      >
        {item.label}
      </Checkbox>
    </label>
  )
}

interface CheckboxTreeProps<T extends string> {
  keys: T[]
  itemsByKey: Record<T, CheckboxTreeItem<T>>
  depthByKey: Record<T, number>
  onChange: (key: T, value: boolean) => void
}
export function CheckboxTree<T extends string>(props: CheckboxTreeProps<T>) {
  const { keys, itemsByKey, depthByKey, onChange } = props
  return (
    <>
      {keys.map(key => (
        <div key={key} style={{ marginLeft: (depthByKey[key] ?? 0) * 1.2 + 'em' }}>
          <Item<T> key={key} k={key} itemsByKey={itemsByKey} onChange={onChange} />
        </div>
      ))}
    </>
  )
}

export type CheckboxTreeItem<T extends string> = {
  key: T
  label: string
  value?: boolean
  disabled?: boolean
  children?: CheckboxTreeItem<T>[]
}

/**
 * TODO: I like this pattern...it has a lot of advantages...but I have to ignore the rules
 * of hooks in here to make it work, making me feel like I'm doing something terribly wrong.
 * So...maybe look at that?  Possibly using refs.
 */
export function useCheckboxTree<T extends string>(
  items: CheckboxTreeItem<T>[],
  onChange: (
    key: T,
    value: boolean,
    keys: T[],
    itemsByKey: Record<T, CheckboxTreeItem<T>>,
    values: Record<T, boolean>
  ) => void
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keys: T[] = []
  const depthByKey = {} as Record<T, number>
  const itemsByKey = proxy({} as Record<T, CheckboxTreeItem<T>>)
  items.forEach(item =>
    traverseDFS(
      item,
      item => item.children,
      (item, path) => {
        keys.push(item.key)
        depthByKey[item.key] = path.length
        itemsByKey[item.key] = item
      }
    )
  )
  const getValues = useCallback(() => _mapValues(itemsByKey, item => !!item.value), [itemsByKey])
  const _onChange = useCallback(
    (key: T, value: boolean) => {
      itemsByKey[key].value = value
      onChange(key, value, keys, itemsByKey, getValues())
    },
    [getValues, itemsByKey, keys, onChange]
  )
  // this is violating the rule of hooks :(  but it does accomplish what I want...
  return useMemo(
    () => ({
      getValues,
      CheckboxTree: () => (
        <CheckboxTree keys={keys} itemsByKey={itemsByKey} depthByKey={depthByKey} onChange={_onChange} />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
