import { useCallback, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'

import { Account } from '@vms/vmspro3-core/dist/types'
import { actions } from '@vms/vmspro3-core/dist'
import { Module } from '@vms/vmspro3-core/dist/systemConsts'

import { Layout } from '../common/Layout'
import { UserMenu } from '../common/UserMenu'
import {
  AccountHomePage,
  AccountSelectionPage,
  AccountUserPage,
  AccountUsersPage,
  ParticipationPage,
  AdHocParticipationPage,
  DecisionFolderPage,
  DecisionPage,
  InvoicesPage,
  HomePage,
  RolesPage,
  SignInPage,
  SignUpPage,
  SubscriptionPage,
} from '../pages'

import { riskRoutes } from '../risk/riskRoutes'
import { useAuth, useAccount, AccountProvider } from '../../context'

import useAuthz from '../../hooks/useAuthz'
import { useAppSelector } from '../../redux'
import { Menu, MenuToggle } from '../common/Menu'
import { LogoNav } from '../common/Layout/LogoNav'
import { useCanUpdateSubscription } from '../../redux/hooks'
import { PrivacyPolicy } from '../pages/PrivacyPolicy'

function useMenu(account?: Account) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const closeMenu = useCallback(() => setMenuOpen(false), [])
  const toggleMenu = useCallback(() => setMenuOpen(open => !open), [])

  const menu = <Menu account={account} menuOpen={menuOpen} closeMenu={closeMenu} />
  const menuToggle = <MenuToggle menuOpen={menuOpen} toggleMenuOpen={toggleMenu} />

  return [menu, menuToggle]
}

function AccountRoutes() {
  const { authUserId } = useAuth()
  const { accountCommonId } = useParams<'accountCommonId'>()
  if (!accountCommonId) {
    throw new Error('AccountRoutes must only be rendered within a route beginning in "/:accountCommonId"')
  }

  const account = useAppSelector(state => state.account.account)

  const [menu, menuToggle] = useMenu(account)

  return (
    <Layout
      headerLeft={
        <>
          {menuToggle}
          <LogoNav linkTo={`/${accountCommonId}`} />
          {account?.name}
        </>
      }
      headerRight={
        <>
          <div id="helpButtonContainer" style={{ marginRight: '20px' }} />
          <UserMenu accountCommonId={accountCommonId} />
        </>
      }
      content={
        <AccountProvider accountCommonId={accountCommonId} authUserId={authUserId}>
          <Outlet />
        </AccountProvider>
      }
      menu={menu}
    />
  )
}

function DecisionRoutes() {
  const { account } = useAccount()

  if (account.quotas.decisions.allowed > 0) {
    return <Outlet />
  }

  return <Navigate to="/" replace />
}

export function AuthenticatedRoutes() {
  const authz = useAuthz()
  const [menu, menuToggle] = useMenu()

  const showRiskRoutes = authz(actions.feature.useModule({}, { moduleKey: Module.PORTFOLIO_RISK }))
  const showInvoicesRoute = authz(actions.account.readInvoices())
  const showSubscriptionRoute = useCanUpdateSubscription()

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            headerLeft={
              <>
                {menuToggle}
                <LogoNav linkTo="/" />
              </>
            }
            headerRight={<UserMenu />}
            content={<AccountSelectionPage />}
            menu={menu}
          />
        }
      />
      <Route path="/:accountCommonId" element={<AccountRoutes />}>
        <Route index element={<AccountHomePage />} />
        <Route path="user/:userId" element={<AccountUserPage />} />
        <Route path="users" element={<AccountUsersPage />} />
        <Route path="roles" element={<RolesPage />} />

        {showSubscriptionRoute && <Route path="subscription" element={<SubscriptionPage />} />}
        {showInvoicesRoute && <Route path="invoices" element={<InvoicesPage />} />}
        {showRiskRoutes && riskRoutes()}
        <Route path="*" element={<Navigate to="/" />} />

        <Route path="decision" element={<DecisionRoutes />}>
          <Route index element={<DecisionFolderPage />} />
          <Route path=":decisionId" element={<DecisionPage />} />
        </Route>
        <Route
          path="/:accountCommonId/decision/:decisionId/participation/:participationSessionId"
          element={<ParticipationPage />}
        />
      </Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  )
}

export function UnauthenticatedRoutes() {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            headerLeft={<LogoNav linkTo="/" />}
            headerRight={
              <>
                <Button type="primary" onClick={() => navigate('/signin')}>
                  Sign In
                </Button>
              </>
            }
            content={<HomePage />}
          />
        }
      />
      <Route element={<Layout headerLeft={<LogoNav linkTo="/" />} content={<Outlet />} />}>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/:accountCommonId/decision/:decisionId/participation/:participationSessionId"
          element={<AdHocParticipationPage />}
        />
        <Route path="*" element={<Navigate to="/signin" state={{ from: location }} />} />
      </Route>
    </Routes>
  )
}
