import { ReactElement, useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql } from 'graphql-tag'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import _pick from 'lodash/pick'
import _keyBy from 'lodash/keyBy'
import { Form, FormInstance, FormItemProps, Input, Modal, Select } from 'antd'

import { SystemPolicyId } from '@vms/vmspro3-core/dist/systemConsts'
import { createAccount } from '@vms/vmspro3-core/dist/actions/account/createAccount'
import { updateAccountSubscription } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'
import { CompanyContact, Product, ProductType } from '@vms/vmspro3-core/dist/types'

import Server from '../../../server/VMSProServerAdapter'
import config from '../../../config.json'
import { useAugmentAction } from '../../../hooks/useAugmentAction'
import { useProductsForAccountCreation } from '../../../hooks/products'
import { useAppDispatch } from '../../../redux'
import { useAuth, useUserAccounts } from '../../../context'

const IS_COMMON_ID_UNIQUE_QUERY = gql`
  query IsCommonIdUnique($commonId: String!) {
    accountIsCommonIdUnique(commonId: $commonId) {
      isUnique
    }
  }
`

interface AccountFormValues {
  account: {
    name: string
    commonId: string
    contacts: {
      billing: CompanyContact
    }
  }
  productId: string
}

const requiredFieldRule = [
  {
    required: true,
    whitespace: true,
  },
]

const setFormFieldError = (
  formInstance: FormInstance<AccountFormValues>,
  name: NonNullable<FormItemProps['name']>,
  errorMessage?: string
) =>
  formInstance.setFields([
    {
      name,
      errors: errorMessage ? [errorMessage] : undefined,
    },
  ])

interface AccountCreateFormProps {
  formInstance: FormInstance<AccountFormValues>
  closeModal: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
}
function AccountCreateForm({ formInstance, closeModal, setLoading }: AccountCreateFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const augmentAction = useAugmentAction()
  const navigate = useNavigate()

  const { authUser } = useAuth()
  const { refetchUserAccounts } = useUserAccounts()

  const { products, productSelectOptions } = useProductsForAccountCreation()

  // casting as `Product` because the JSON import product.type is inferred as
  // `string` rather than the correct `ProductType` string union.
  const productsById = useMemo(() => _keyBy(products, 'id') as Record<string, Product>, [products])

  const [selectedProductType, setSelectedProductType] = useState<ProductType | null>(null)

  const onFinish = useCallback(
    async (fieldValues: AccountFormValues) => {
      if (!stripe || !elements) return

      setLoading(true)
      const { productId, account: accountFields } = fieldValues
      const product = productsById[productId]

      let paymentMethodId = null
      const stripeCardElement = elements.getElement(CardElement)
      if (product.type !== 'Internal' && stripeCardElement) {
        const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
          type: 'card',
          card: stripeCardElement,
        })
        if (stripeError) {
          setFormFieldError(formInstance, 'stripeCardElement', stripeError.message)
          setLoading(false)
          return
        }
        if (paymentMethod) {
          paymentMethodId = paymentMethod.id
        }
      }

      const partialAccountUser = {
        // has everything but accountId (which isn't known yet)
        ..._pick(authUser, ['email', 'fullName', 'shortName', 'initials', 'phone', 'createdAt', 'updatedAt']),
        userId: authUser.id,
        // as the owner of the account, they get all the "admin" roles
        policyIds: [
          SystemPolicyId.RISK_ADMINISTRATOR,
          SystemPolicyId.DECISION_ADMIN,
          SystemPolicyId.BILLING_ADMIN,
        ],
      }

      // create account
      const billingContact = accountFields.contacts.billing
      // this fixes a subtle issue; if the user doesn't enter title or phone, the values
      // passed to createAccount will be undefined.  however, if they enter something and
      // then delete it, the values passed to createAccount will be an empty string, which
      // causes a validation failure (if missing, they should currently be null)
      if (billingContact.attn.title?.trim() === '') billingContact.attn.title = null
      if (billingContact.attn.phone?.trim() === '') billingContact.attn.phone = null
      const createAccountAction = createAccount({
        name: accountFields.name,
        commonId: accountFields.commonId,
        billingContact,
        user: {
          ...partialAccountUser,
          eulas: {},
        },
      })
      const account = createAccountAction.payload
      // subscribe to plan
      const updateAccountSubscriptionAction = updateAccountSubscription(account, product, paymentMethodId)

      try {
        await Server.tryAction(augmentAction(createAccountAction))
        await Server.tryAction(augmentAction(updateAccountSubscriptionAction))

        // note for each of the actions below, we set "ephemeral" to true: they've already been handled
        // (by Server.tryAction), so we don't want isomorphic redux sending it to the server again!
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(createAccountAction.meta as any).ephemeral = true
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(updateAccountSubscriptionAction.meta as any).ephemeral = true

        // we can now dispatch these already-processed (and therefore now ephemeral) actions
        dispatch(createAccountAction)
        dispatch(updateAccountSubscriptionAction)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        if (err?.response?.data?.code === 'incorrect_cvc') {
          // this will only apply to the updateAccountSubscription action
          setFormFieldError(formInstance, 'stripeCardElement', err.response.data.message)
          setLoading(false)
          return
        }
        // in the future we can get more nuanced about error handling here since we've decomposed
        // these actions, but for now any error in the three actions will show up
        setFormFieldError(formInstance, ['account', 'name'], 'Uknown error: please contact support.')
        setLoading(false)
        return
      }
      setLoading(false)

      await refetchUserAccounts()
      navigate(`/${account.commonId}`)

      closeModal()
    },
    [
      augmentAction,
      authUser,
      closeModal,
      dispatch,
      elements,
      formInstance,
      navigate,
      productsById,
      refetchUserAccounts,
      setLoading,
      stripe,
    ]
  )

  const onValuesChange = useCallback(
    changedValues => {
      if (!changedValues.productId) return
      const product = productsById[changedValues.productId]
      setSelectedProductType(product.type)
    },
    [productsById]
  )

  const handleStripeCardElementChange = useCallback(
    ({ error }) => {
      setFormFieldError(formInstance, 'stripeCardElement', error?.message)
    },
    [formInstance]
  )

  /**
   * Asynchronous Ant Design form field validator function to confirm
   * valid syntax and uniqueness of the account.commonId field. account.commonId
   * should conform to the RFC spec for domain labels, which can be represented
   * by the regular expression /^[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?$/i, though
   * this function will give users more specific hints about why an invalid
   * input value is failing validation.
   *
   * see: https://www.rfc-editor.org/rfc/rfc1035#section-2.3.1
   *
   * Note that Lodash#debounce will not return a Promise so we are on our own
   * there.
   */
  const debouncedCommonIdValidator = useMemo(() => {
    let timeoutId: number | undefined = undefined

    return (_: unknown, commonId: string) => {
      if (timeoutId) clearTimeout(timeoutId)

      return new Promise<void>((resolve, reject) => {
        if (!/\S/.test(commonId)) {
          return reject(new Error('Account ID is required'))
        } else if (commonId.length > 63) {
          return reject(new Error('Account ID must be 63 characters or less'))
        } else if (!/^[a-z0-9-]*$/i.test(commonId)) {
          return reject(new Error('Account ID must contain only letters, numbers, or hyphens'))
        } else if (!/^[a-z]/i.test(commonId)) {
          return reject(new Error('Account ID must begin with a letter'))
        }

        const thenableTimeout = new Promise<void>(endTimeout => {
          timeoutId = window.setTimeout(endTimeout, 800)
        })

        thenableTimeout.then(() => {
          if (!/[a-z0-9]$/i.test(commonId)) {
            // this rule should only be checked after the timeout, otherwise
            // any time a hyphen is typed as a trailing inline character
            // during normal typing it would show a validation error
            // immediately, even though it is a valid internal character.
            return reject(new Error('Account ID must end with a letter or number'))
          }

          return Server.graphql({
            query: IS_COMMON_ID_UNIQUE_QUERY,
            variables: { commonId },
          }).then(response => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(response as any).data.accountIsCommonIdUnique.isUnique) {
              return reject(new Error(`Account ID ${commonId} already in use`))
            }
            return resolve()
          })
        })
      })
    }
  }, [])

  return (
    <Form<AccountFormValues>
      layout="vertical"
      form={formInstance}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <h3>Account Details:</h3>
      <Form.Item<AccountFormValues>
        label="Account Name"
        name={['account', 'name']}
        rules={requiredFieldRule}
        extra={<i>Can be same as company name</i>}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="Company Name"
        name={['account', 'contacts', 'billing', 'company']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="Account ID"
        name={['account', 'commonId']}
        normalize={value => value.replace(/[^a-z0-9-]/gi, '')}
        rules={[{ validator: debouncedCommonIdValidator }]}
        hasFeedback
        validateFirst
        extra={
          <i>
            Account ID must begin with a letter, end with a letter or number, and contain only letters, numbers,
            and hyphens
          </i>
        }
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="Address 1"
        name={['account', 'contacts', 'billing', 'address1']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues> label="Address 2" name={['account', 'contacts', 'billing', 'address2']}>
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="City"
        name={['account', 'contacts', 'billing', 'city']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="State"
        name={['account', 'contacts', 'billing', 'state']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="Zip Code"
        name={['account', 'contacts', 'billing', 'zip']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <h3>Account Billing Contact:</h3>
      <Form.Item<AccountFormValues>
        label="Name"
        name={['account', 'contacts', 'billing', 'attn', 'name']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues> label="Title" name={['account', 'contacts', 'billing', 'attn', 'title']}>
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues>
        label="Email"
        name={['account', 'contacts', 'billing', 'attn', 'email']}
        rules={requiredFieldRule}
      >
        <Input />
      </Form.Item>
      <Form.Item<AccountFormValues> label="Phone" name={['account', 'contacts', 'billing', 'attn', 'phone']}>
        <Input />
      </Form.Item>
      <h3>Subscription:</h3>
      <Form.Item label="Plan" name="productId" rules={[{ required: true }]}>
        <Select options={productSelectOptions} />
      </Form.Item>
      {selectedProductType && selectedProductType !== 'Internal' && (
        <Form.Item label="Payment Information" name="stripeCardElement">
          <CardElement onChange={handleStripeCardElementChange} />
        </Form.Item>
      )}
    </Form>
  )
}

const stripePromise = loadStripe(config.instance.stripe.publishableKeys.test)

interface AccountCreateModalProps {
  visible: boolean
  hideModal: () => void
}
export function AccountCreateModal({ visible, hideModal }: AccountCreateModalProps): ReactElement {
  const [loading, setLoading] = useState(false)
  const [formInstance] = Form.useForm<AccountFormValues>()

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      onCancel={hideModal}
      onOk={formInstance.submit}
      maskClosable={false}
      destroyOnClose
    >
      <Elements stripe={stripePromise}>
        <AccountCreateForm formInstance={formInstance} closeModal={hideModal} setLoading={setLoading} />
      </Elements>
    </Modal>
  )
}
