import React from 'react'
/** @jsxImportSource @emotion/react */
// import { effectiveRiskContextFromEntityId } from '../../../../../selectors'
import { COLOR } from '../../_riskConfig/color'

const Traits = ({ article }) => (
  // NOTE: this is the desired pattern, but there is a deeper change needed to accomplish this parsing
  // for now we are just assigning the label to 'article.category', this is not ideal, but will need more
  // development to reach the end goal of not changing these keys.
  // const effectiveRiskContext = useSelector(useCallback(effectiveRiskContextFromEntityId(entityId), [entityId]))
  // const articleCategory = effectiveRiskContext.types.category.values
  //   .find(v => v.value === article.category)
  // const articlePhase = effectiveRiskContext.types.phase.values
  //   .find(v => v.value === article.phase)
  <p
    css={{
      marginBottom: 0,
      gridArea: 'traits',
      height: '24px',
      marginLeft: '5px',
    }}
  >
    {article.category && (
      <span
        css={{
          height: '20px',
          marginLeft: '5px',
          padding: '2px 5px 4px',
          background: COLOR.GREY_1D,
          color: 'black',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        {article.category}
      </span>
    )}
    {article.phase && (
      <span
        css={{
          height: '20px',
          marginLeft: '5px',
          padding: '2px 5px 4px',
          background: COLOR.GREY_1D,
          color: 'black',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        {article.phase}
      </span>
    )}
  </p>
)

export default Traits
