import { ReactElement, useCallback, useState } from 'react'

export function useModalState(defaultModal: ReactElement | null = null) {
  const [modal, setModal] = useState<ReactElement | null>(defaultModal)
  const showModal = useCallback((modal: ReactElement) => setModal(modal), [])
  const hideModal = useCallback(() => setModal(null), [])

  return {
    modal,
    showModal,
    hideModal,
  }
}
