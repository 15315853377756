import React from 'react'
/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom'

import { COLOR } from '../../_riskConfig/color'

export default function Title({ accountCommonId, article, entityId }) {
  const navigate = useNavigate()
  return (
    <h3
      css={{
        gridArea: 'title',
        alignSelf: 'start',
        lineHeight: '1.2',
        overflow: 'hidden',
        margin: '10px 0 0 10px',
        textOverflow: 'ellipsis',
        fontWeight: '700',
        fontSize: '20px',
        cursor: 'pointer',
        transformOrigin: 'left',
        transition: 'background-color color transform',
        transitionDuration: '.1s',
        transitionTimingFunction: 'ease-out',
        '&:hover,&:focus': {
          background: COLOR.GREY_1D,
          color: COLOR.GREY_3,
        },
        '&:active': {
          background: COLOR.GREY_2,
          transform: 'scale(0.98)',
          color: 'black',
        },
      }}
      onClick={() => navigate(`/${accountCommonId}/proj/${entityId}/risk/${article.id}`)}
    >
      <span>{article.name}</span>
      <span
        css={{
          display: 'inline-block',
          padding: '0 3px',
          margin: '0 3px',
          fontWeight: 400,
        }}
      >
        →
      </span>
    </h3>
  )
}
