import { useMemo } from 'react'
import { UserAddOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { actions } from '@vms/vmspro3-core'
import { InviteUserModal } from './InviteUserModal'
import { PendingInvitations } from './PendingInvitations'
import { UsersTable } from './UsersTable'
import { Page } from '../../common/Page'

import useAuthz from '../../../hooks/useAuthz'
import useEnforceUsersQuota from '../../../hooks/useEnforceUsersQuota'
import { useModalState } from '../../../hooks/useModalState'
import {
  useFetchPendingInvitationsForAccount,
  useFetchUsers,
  usePendingInvitationsForAccount,
  useUsers,
} from '../../../redux/hooks'

export function AccountUsersPage() {
  useFetchUsers()
  useFetchPendingInvitationsForAccount()

  const authz = useAuthz()
  const canInviteUser = useMemo(
    () => authz({ module: 'System', type: actions.account.inviteUser.toString() }),
    [authz]
  )

  // TODO: type properly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [enforceUsersQuota, loadingEnforceUsersQuota] = useEnforceUsersQuota() as [any, boolean]
  const { modal, showModal, hideModal } = useModalState()
  const showInviteUserModal = useMemo(
    () => enforceUsersQuota(() => showModal(<InviteUserModal hideModal={hideModal} />)),
    [enforceUsersQuota, showModal, hideModal]
  )

  const [users, loadingUsers] = useUsers()
  const [pendingInvitations, loadingInvitations, authorizedToReadInvitations] = usePendingInvitationsForAccount()

  return (
    <Page title="User Management" loading={loadingUsers || (authorizedToReadInvitations && loadingInvitations)}>
      {modal}
      <div>
        <div style={style.tableHeadingBar}>
          <h2>Account Users</h2>
          <div>
            {canInviteUser && (
              <Button
                type="link"
                disabled={loadingEnforceUsersQuota}
                onClick={showInviteUserModal}
                icon={<UserAddOutlined />}
              >
                Invite User
              </Button>
            )}
          </div>
        </div>
        <UsersTable users={users} />
      </div>
      <br />
      {authorizedToReadInvitations && pendingInvitations && pendingInvitations.length > 0 && (
        <PendingInvitations invitations={pendingInvitations} />
      )}
    </Page>
  )
}

const style = {
  tableHeadingBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}
