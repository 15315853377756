import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { CaretUpOutlined, CaretDownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Slider, SliderSingleProps, Tooltip } from 'antd'

import { Criterion, RatingScaleConfig } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { updateCriterion } from '@vms/vmspro3-core/dist/actions/decision'

import { RatingNodeLayout } from '../../../common/Rating/RatingNodeLayout'
import { RatingScaleOverlay } from '../../../common/Rating/RatingScaleOverlay'
import { NumberInput } from '../../../common/NumberInput'

import { HexColorPicker } from 'react-colorful'

import style from './RatingScaleEditor.module.css'
import { useAppDispatch } from '../../../../redux'
import ReactRouterPrompt from 'react-router-prompt'
type ReactRouterPromptArgs = {
  isActive: boolean
  onCancel: (value: unknown) => void
  onConfirm: (value: unknown) => void
}

interface SliderWithNumberInputSharedProps {
  value?: number
  min?: number
  max?: number
  onChange?: (value: number | null) => void
  disabled?: boolean
  showSlider?: boolean
}
interface SliderWithNumberInputProps extends SliderWithNumberInputSharedProps {
  sliderProps?: Omit<SliderSingleProps, keyof SliderWithNumberInputSharedProps>
  inputNumberProps?: Omit<React.ComponentProps<typeof NumberInput>, keyof SliderWithNumberInputSharedProps>
}
const SliderWithNumberInput = React.forwardRef<HTMLDivElement, SliderWithNumberInputProps>(
  ({ min, max, value, onChange, disabled, showSlider, sliderProps, inputNumberProps }, ref) => (
    <Input.Group>
      <div className={style.sliderWithNumberInput}>
        {showSlider && (
          <Slider
            {...sliderProps}
            className={style.sliderWithNumberInputSlider}
            ref={ref}
            min={min}
            max={max}
            value={value}
            disabled={disabled}
            onChange={onChange}
          />
        )}
        <NumberInput
          {...inputNumberProps}
          className={style.sliderWithNumberInputNumberInput}
          allowNull={false}
          min={min}
          max={max}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </Input.Group>
  )
)

interface RatingScaleFormProps {
  decisionId: string
  criterion: Criterion
  /** use criterionId as the key to force remounting and reset component state */
  key: string
}
export function RatingScaleEditor({ decisionId, criterion }: RatingScaleFormProps): ReactElement {
  const [formInstance] = Form.useForm<RatingScaleConfig>()
  const [isDirty, setIsDirty] = useState(false)

  const [bgColor, _setBgColor] = useState<string>(criterion.optionRatingScaleConfig.bgColor || '#fff')
  const [fgColor, _setFgColor] = useState<string>(criterion.optionRatingScaleConfig.fgColor || '#d3d3d3')

  const setBgColor = useCallback(
    (color: string) => {
      _setBgColor(color)
      setIsDirty(true)
    },
    [_setBgColor, setIsDirty]
  )
  const setFgColor = useCallback(
    (color: string) => {
      _setFgColor(color)
      setIsDirty(true)
    },
    [_setFgColor, setIsDirty]
  )

  const [editing, setEditing] = useState<boolean>(false)
  useEffect(() => {
    if (!editing) {
      formInstance.resetFields()
      setIsDirty(false)
    }
  }, [formInstance, editing])

  const showEditMode = useCallback(() => {
    if (criterion.isInternal) {
      Modal.confirm({
        title: `Editing ${criterion.name}`,
        content:
          `This criterion (${criterion.name}) has children; because of this, ` +
          "participants won't rate against this criterion directly. Instead, they will " +
          'rate against children (or descendants, if the children have children). You ' +
          "may still edit the scale, but unless children are removed, participants won't " +
          'ever see the scale.',
        onOk: () => setEditing(true),
        okText: 'Continue',
      })
    } else {
      setEditing(true)
    }
  }, [criterion])

  /**
   * Adds a new rating scale item to form state and redistributes the scale
   * item "maxValue" maximum rating values across the scale span. Note that
   * Form.List does offer an "add" operation that accepts an initial value
   * for the new fields, however it does not allow us to set form state values
   * for any of the other scale item fields.
   */
  const addRatingScaleItem = useCallback(() => {
    const formValues = formInstance.getFieldsValue()

    const scaleSpan = criterion.optionRatingScaleConfig.maxRating - criterion.optionRatingScaleConfig.minRating
    const scaleItemCount = (formValues.ratingScale?.length ?? 0) + 1
    const scaleItemSpan = scaleSpan / scaleItemCount

    const ratingScale = (formValues.ratingScale ?? [])
      .map((item, i) => ({
        label: item.label,
        maxValue: criterion.optionRatingScaleConfig.maxRating - scaleItemSpan * i,
      }))
      .concat({
        label: 'New Label ' + scaleItemCount,
        maxValue: scaleItemSpan,
      })

    formInstance.setFieldsValue({ ratingScale })
  }, [formInstance, criterion])

  /**
   * Removes an existing rating scale item from form state by the item's index
   * in the scale array. The first item in the scale will always have a maxValue
   * equal to the rating scale config maxRating value. Note that Form.List does
   * offer a "remove" operation as well but it does not allow us to set form
   * state values for any of the other scale item fields.
   */
  const removeRatingScaleItem = useCallback(
    (indexToRemove: number) => {
      const formValues = formInstance.getFieldsValue()

      const ratingScale = formValues.ratingScale?.filter((item, i) => i !== indexToRemove) ?? []

      if (ratingScale[0] && ratingScale[0].maxValue !== criterion.optionRatingScaleConfig.maxRating) {
        ratingScale[0] = {
          ...ratingScale[0],
          maxValue: criterion.optionRatingScaleConfig.maxRating,
        }
      }

      formInstance.setFieldsValue({ ratingScale })
    },
    [formInstance, criterion]
  )

  const dispatch = useAppDispatch()
  const saveRatingScale = useCallback(
    (optionRatingScaleConfig: Omit<RatingScaleConfig, 'minRating' | 'maxRating'>) => {
      const action = updateCriterion(decisionId, criterion.id, {
        optionRatingScaleConfig: {
          ...optionRatingScaleConfig,
          fgColor,
          bgColor,
          maxRating: criterion.optionRatingScaleConfig.maxRating,
          minRating: criterion.optionRatingScaleConfig.minRating,
        },
      })
      dispatch(action)
      setEditing(false)
    },
    [dispatch, decisionId, criterion, fgColor, bgColor]
  )

  return (
    <>
      <ReactRouterPrompt when={isDirty}>
        {({ isActive, onConfirm, onCancel }: ReactRouterPromptArgs) => {
          if (isActive) {
            Modal.confirm({
              title: 'You Have Unsaved Changes',
              content: 'You have unsaved changes; are you sure you want to leave?',
              okText: 'Yes',
              cancelText: 'No',
              onOk: () => onConfirm(null),
              onCancel: () => onCancel(null),
            })
          }
          return undefined
        }}
      </ReactRouterPrompt>
      <Form
        className={style.ratingScaleEditor}
        form={formInstance}
        initialValues={criterion.optionRatingScaleConfig}
        layout="vertical"
        onFinish={saveRatingScale}
        onValuesChange={() => setIsDirty(true)}
      >
        <Form.Item<RatingScaleConfig> shouldUpdate noStyle>
          {form => {
            const formValues = form.getFieldsValue()
            return (
              <div className={style.ratingNodeLayout}>
                <RatingNodeLayout
                  maxRatingLabel={formValues.maxRatingLabel}
                  minRatingLabel={formValues.minRatingLabel}
                  abstainLabel={formValues.abstainLabel}
                  abstainBgColor={bgColor}
                  ratingNode={
                    <RatingScaleOverlay
                      maxRating={criterion.optionRatingScaleConfig.maxRating}
                      minRating={criterion.optionRatingScaleConfig.minRating}
                      ratingScale={formValues.ratingScale}
                      fgColor={fgColor}
                      bgColor={bgColor}
                    />
                  }
                />
              </div>
            )
          }}
        </Form.Item>
        <div className={style.ratingScaleFormColumn}>
          <div className={style.ratingScaleFormSection}>
            <h2>{criterion.name} Rating Scale</h2>
            {!editing && (
              <>
                <p>
                  This is an example of the rating canvas that participants will see for this criterion. You can
                  edit the text and max rating value for each of the scale items.
                </p>
                <Button className={style.ratingScaleFormButton} onClick={showEditMode}>
                  Edit Scale
                </Button>
              </>
            )}
          </div>
          <div>
            <Form.Item hidden={!editing} name="maxRatingLabel" label="Top Y-axis Label">
              <Input showCount maxLength={20} />
            </Form.Item>
            <Form.Item hidden={!editing} name="minRatingLabel" label="Bottom Y-axis Label">
              <Input showCount maxLength={20} />
            </Form.Item>
            <Form.Item hidden={!editing} name="abstainLabel" label="Abstain Label">
              <Input showCount maxLength={10} />
            </Form.Item>
          </div>
          <div className={style.ratingScaleFormSection}>
            <Form.List name="ratingScale">
              {ratingScaleFields => (
                <>
                  {ratingScaleFields.map(({ key, name, ...fieldProps }, fieldIndex) => (
                    <div className={style.ratingScaleFieldGroup} key={key}>
                      <Form.Item
                        {...fieldProps}
                        name={[name, 'label']}
                        label={fieldIndex === 0 ? 'Scale Label' : undefined}
                        className={style.ratingScaleLabelField}
                        hidden={!editing}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item<RatingScaleConfig>
                        {...fieldProps}
                        noStyle
                        hidden={!editing}
                        shouldUpdate={(prevValues, nextValues) =>
                          prevValues.ratingScale !== nextValues.ratingScale
                        }
                      >
                        {form => {
                          const fieldValues = form.getFieldsValue()
                          const lowestMaxValue = criterion.optionRatingScaleConfig.minRating + 1

                          const max =
                            fieldValues?.ratingScale && fieldIndex > 0
                              ? fieldValues.ratingScale[fieldIndex - 1].maxValue - 1
                              : criterion.optionRatingScaleConfig.maxRating

                          const min =
                            fieldValues?.ratingScale && fieldIndex < fieldValues?.ratingScale.length - 1
                              ? fieldValues.ratingScale[fieldIndex + 1].maxValue + 1
                              : lowestMaxValue

                          const marks = {
                            [max]: <CaretUpOutlined />,
                          }
                          if (fieldIndex > 0 && min > lowestMaxValue) {
                            marks[min] = <CaretDownOutlined />
                          }

                          return (
                            <Form.Item<RatingScaleConfig>
                              {...fieldProps}
                              name={[name, 'maxValue']}
                              label={fieldIndex === 0 ? 'Max Value' : undefined}
                              className={style.ratingScaleMaxValueField}
                              normalize={value => {
                                if (value > max) return max
                                if (value < min) return min
                                return value
                              }}
                            >
                              <SliderWithNumberInput
                                min={lowestMaxValue}
                                max={criterion.optionRatingScaleConfig.maxRating}
                                disabled={fieldIndex === 0}
                                showSlider={fieldIndex > 0}
                                sliderProps={{
                                  step: 0.001,
                                  tooltip: {
                                    formatter: value => value?.toFixed(1),
                                  },
                                  marks,
                                  included: false,
                                }}
                                inputNumberProps={{
                                  decimalPlaces: 1,
                                  readOnly: fieldIndex === 0,
                                }}
                              />
                            </Form.Item>
                          )
                        }}
                      </Form.Item>
                      <Form.Item
                        /* non-empty form item label maintains scale item group alignment */
                        label={fieldIndex === 0 ? ' ' : undefined}
                        hidden={!editing}
                      >
                        <Tooltip title="Delete Scale Label">
                          <Button
                            type="default"
                            onClick={() => removeRatingScaleItem(fieldIndex)}
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Form.Item>
                    </div>
                  ))}
                  <Form.Item<RatingScaleConfig> noStyle shouldUpdate>
                    {form => {
                      const formValues = form.getFieldsValue()
                      return (
                        <Button
                          className={style.ratingScaleFormButton}
                          icon={<PlusOutlined />}
                          onClick={addRatingScaleItem}
                          hidden={!editing}
                          disabled={(formValues.ratingScale?.length ?? 0) >= 10}
                        >
                          Add New Label
                        </Button>
                      )
                    }}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
          {editing && (
            <>
              <div className="small" style={{ display: 'flex', gap: '3em' }}>
                <div>
                  <label>Foreground Color</label>
                  <HexColorPicker color={fgColor} onChange={setFgColor} />
                </div>
                <div>
                  <label>Background Color</label>
                  <HexColorPicker color={bgColor} onChange={setBgColor} />
                </div>
              </div>
              <div className={style.formControlGroup}>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
                <Button onClick={() => setEditing(false)}>Cancel</Button>
              </div>
            </>
          )}
        </div>
      </Form>
    </>
  )
}
