import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import {
  ClientRect,
  DndContextProps,
  KeyboardSensor,
  Modifier,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import { RatingVector } from '@vms/vmspro3-core/dist/types'

export function positionToRatingVector(
  range: { min: number; max: number },
  activeRect: ClientRect | null | undefined,
  overRect: ClientRect | null | undefined
): RatingVector {
  if (!activeRect || !overRect) {
    return null
  }

  const span = {
    x: overRect.width - activeRect.width,
    y: overRect.height - activeRect.height,
  }
  const position = {
    left: activeRect.left - overRect.left,
    top: activeRect.top - overRect.top,
  }
  const percent = {
    x: Math.min(1, Math.max(0, position.left / span.x)),
    y: Math.min(1, Math.max(0, 1 - position.top / span.y)),
  }
  const percentToRating = (percentage: number) => percentage * (range.max - range.min) + range.min

  return [percentToRating(percent.y), percentToRating(percent.x)]
}

const snapThreshold = 50
export const snapWithinRatingNode: Modifier = ({ transform, activeNodeRect, over }) => {
  if (!activeNodeRect || !over) {
    return transform
  }

  const value = {
    ...transform,
  }

  const transformedRect = {
    top: activeNodeRect.top + transform.y,
    bottom: activeNodeRect.bottom + transform.y,
    left: activeNodeRect.left + transform.x,
    right: activeNodeRect.right + transform.x,
  }

  if (transformedRect.top < over.rect.top && transformedRect.top >= over.rect.top - snapThreshold) {
    value.y = Math.floor(over.rect.top - activeNodeRect.top)
  } else if (
    transformedRect.bottom > over.rect.bottom &&
    transformedRect.bottom <= over.rect.bottom + snapThreshold
  ) {
    value.y = Math.ceil(over.rect.bottom - activeNodeRect.bottom)
  }

  if (transformedRect.left < over.rect.left && transformedRect.left >= over.rect.left - snapThreshold) {
    value.x = Math.floor(over.rect.left - activeNodeRect.left)
  } else if (transformedRect.right > over.rect.right && transformedRect.right <= over.rect.right + snapThreshold) {
    value.x = Math.floor(over.rect.right - activeNodeRect.right)
  }

  return value
}

/**
 * Hook to manage DndContext props, applies reasonable defaults for sensors and modifiers.
 */
export function useRatingDndContextConfig(props: Omit<DndContextProps, 'children'> = {}) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor), useSensor(KeyboardSensor))
  const modifiers = [restrictToWindowEdges, snapWithinRatingNode]

  return {
    sensors,
    modifiers,
    ...props,
  }
}
