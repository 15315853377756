import { useEffect } from 'react'

import { Invitation } from '@vms/vmspro3-core/dist/types'

import { fetchUserInvitations } from '../actions'
import { useAppDispatch, useAppSelector } from '../store'

export function useUserInvitations(): [userInvitations: Invitation[] | undefined, loading: boolean] {
  return [
    useAppSelector(state => state.user.invitations),
    useAppSelector(state => state.user.invitationsLoadingStatus !== 'Loaded'),
  ]
}

export function useFetchUserInvitations(): void {
  const loadingStatus = useAppSelector(state => state.user.invitationsLoadingStatus)
  const email = useAppSelector(state => state.user.currentUser?.email)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (email && loadingStatus === 'NotLoaded') {
      dispatch(fetchUserInvitations(email))
    }
  }, [dispatch, email, loadingStatus])
}
