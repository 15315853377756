import { ReactElement, useCallback, useMemo, useState } from 'react'
import _partition from 'lodash/partition'
import { DndContext, DragStartEvent, UniqueIdentifier, DragEndEvent } from '@dnd-kit/core'

import { OptionData, OptionOutcomeData } from '@vms/vmspro3-core/dist/nextgen/options'
import { CriterionData, RatingScaleConfig } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Rating as IRating, RatingVector } from '@vms/vmspro3-core/dist/types'

import { RatingNode } from './RatingNode'
import { RatingNodeLayout } from './RatingNodeLayout'
import { RatingScaleOverlay } from './RatingScaleOverlay'
import { RatingSubject } from './RatingSubject'

import { positionToRatingVector, useRatingDndContextConfig } from './utils'
import style from './Rating.module.css'

export type UpdateRatingArgs = {
  subjectId: string
  subjectType: 'Criterion' | 'Option' | 'OptionOutcome'
  ratingVector: RatingVector
  abstain?: boolean
}

function textWidth(s: string) {
  const byLetter = s
    .toUpperCase()
    .split('')
    .reduce<Record<string, number>>(
      (byLetter, letter) => Object.assign(byLetter, { [letter]: (byLetter[letter] ?? 0) + 1 }),
      {}
    )
  return (
    s.length +
    (byLetter.W ?? 0) * 0.5 +
    (byLetter.M ?? 0) * 0.5 -
    (byLetter.I ?? 0) * 0.5 -
    (byLetter.J ?? 0) * 0.25
  )
}

export type RatingProps = {
  subjects: (CriterionData | OptionData | OptionOutcomeData)[]
  ratingBySubjectId: Record<string, IRating>
  ratingScaleConfig: RatingScaleConfig
  hideRating: boolean
  onUpdateRating: (args: UpdateRatingArgs) => void
  ratingDetailPanel?: ReactElement
  extra?: ReactElement
}
export const Rating = ({
  subjects,
  ratingBySubjectId,
  ratingScaleConfig,
  hideRating,
  onUpdateRating,
  ratingDetailPanel,
  extra,
}: RatingProps): ReactElement => {
  const subjectFontSize = useMemo(() => {
    const abbrevWidths = subjects.map(subject => textWidth(subject.abbrev))
    const maxWidth = Math.max(...abbrevWidths)

    if (maxWidth >= 8) return '11px'
    if (maxWidth >= 6) return '14px'
    return '16px'
  }, [subjects])

  const [zIndexOrder, setZIndexOrder] = useState<UniqueIdentifier[]>(() => [])
  const renderRatingSubject = useCallback(
    (subject: CriterionData | OptionData | OptionOutcomeData) => (
      <RatingSubject
        key={subject.id}
        zIndex={zIndexOrder.indexOf(subject.id) + 1}
        subject={subject}
        rating={ratingBySubjectId[subject.id]}
        fontSize={subjectFontSize}
        maxRating={ratingScaleConfig.maxRating}
        minRating={ratingScaleConfig.minRating}
        hideRating={hideRating}
      />
    ),
    [
      zIndexOrder,
      ratingBySubjectId,
      ratingScaleConfig.maxRating,
      ratingScaleConfig.minRating,
      hideRating,
      subjectFontSize,
    ]
  )

  const ratables = useMemo(() => {
    const [withRating, unrated] = _partition(subjects, subject => ratingBySubjectId[subject.id]?.ratingVector)
    const [abstained, rated] = _partition(withRating, subject => ratingBySubjectId[subject.id].abstain)

    return {
      unrated,
      abstained,
      rated,
    }
  }, [subjects, ratingBySubjectId])

  const ratingDndConfig = useRatingDndContextConfig({
    onDragStart: useCallback((event: DragStartEvent) => {
      setZIndexOrder(state => {
        const oldIdx = state.indexOf(event.active.id)
        if (oldIdx > -1) state.splice(oldIdx, 1)
        return state.concat(event.active.id)
      })
    }, []),
    onDragEnd: useCallback(
      (event: DragEndEvent) => {
        if (typeof event.active.id === 'string' && event.active.data.current) {
          const range = { min: ratingScaleConfig.minRating, max: ratingScaleConfig.maxRating }

          const activeRect = event.active.rect.current.translated
          const overRect = event.over?.rect
          const ratingVector = positionToRatingVector(range, activeRect, overRect)

          const abstain = event.over?.id === 'abstainCanvas'
          onUpdateRating({
            subjectId: event.active.id,
            subjectType: event.active.data.current.subject.__typename,
            ratingVector,
            abstain,
          })
        }
      },
      [onUpdateRating, ratingScaleConfig.minRating, ratingScaleConfig.maxRating]
    ),
  })

  return (
    <div className={style.rating}>
      <DndContext {...ratingDndConfig}>
        <div className={style.ratingCanvasColumn}>
          <RatingNodeLayout
            abstainLabel={ratingScaleConfig.abstainLabel}
            maxRatingLabel={ratingScaleConfig.maxRatingLabel}
            minRatingLabel={ratingScaleConfig.minRatingLabel}
            abstainNode={
              <RatingNode nodeId="abstainCanvas">{ratables.abstained.map(renderRatingSubject)}</RatingNode>
            }
            abstainBgColor={ratingScaleConfig.bgColor}
            ratingNode={
              <RatingNode nodeId="ratingCanvas">
                {ratables.rated.map(renderRatingSubject)}
                <RatingScaleOverlay
                  maxRating={ratingScaleConfig.maxRating}
                  minRating={ratingScaleConfig.minRating}
                  ratingScale={ratingScaleConfig.ratingScale}
                  bgColor={ratingScaleConfig.bgColor}
                  fgColor={ratingScaleConfig.fgColor}
                />
              </RatingNode>
            }
          />
        </div>
        <div className={style.unratedPoolColumn}>
          <div className={style.ratingDetailPanelWrapper}>
            <div className={style.ratingDetailPanelInner}>{ratingDetailPanel}</div>
          </div>
          <div className={style.unratedPool}>{ratables.unrated.map(renderRatingSubject)}</div>
          <div className={style.extra}>{extra}</div>
        </div>
      </DndContext>
    </div>
  )
}
