import { Html } from '@vms/vmspro3-core/dist/types'

type RenderHtmlObjectProps = {
  htmlObject?: Html
}

export function RenderHtmlObject({ htmlObject }: RenderHtmlObjectProps): JSX.Element | null {
  if (!htmlObject?.value) return null

  // eslint-disable-next-line react/no-danger
  return <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: htmlObject.value }} />
}
