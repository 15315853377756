import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { DecisionFolderPolicyKey, decisionPolicyFolderKeys } from '@vms/vmspro3-core/dist/types'
import { Select, Spin } from 'antd'

import style from './Table/Table.module.css'
import { useDecisionFolder, useUsers } from '../../redux/hooks'
import { useCallback, useMemo } from 'react'
import { SystemPolicyId } from '@vms/vmspro3-core/dist/systemConsts'
import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'
import { useAppDispatch } from '../../redux'
import { UpdateDecisionFolderPolicyAction } from '@vms/vmspro3-core/dist/actions/decision'

function useFolderPolicyTable(folderId: string) {
  const [usersById, loadingUsers] = useUsers()
  const folder = useDecisionFolder(folderId)
  const folderPath = folder.ancestry === '/' ? '/' + folder.id : folder.ancestry + '/' + folder.id
  const dispatch = useAppDispatch()
  const onPolicyChange = useCallback(
    (args: { userId: string; policyKey: DecisionFolderPolicyKey }) => {
      const { userId, policyKey } = args
      const action = {
        module: 'Decision',
        type: 'Update Decision Folder Policy',
        payload: {
          userIds: [userId],
          folderPath,
          policyKey,
        },
      } as UpdateDecisionFolderPolicyAction
      console.log('dispatching:', action)
      dispatch(action)
    },
    [dispatch, folderPath]
  )
  const columns = [
    colHelper.accessor(row => row.fullName, { header: 'Name' }),
    colHelper.accessor(row => row.email, { header: 'Email' }),
    colHelper.accessor(row => row.policyKey, {
      header: 'Access',
      cell: ({
        row: {
          original: { policyKey, userId },
        },
      }) => (
        <Select
          value={policyKey}
          onChange={policyKey => onPolicyChange({ userId, policyKey })}
          options={decisionPolicyFolderKeys.map(value => ({ value, label: value }))}
          style={{ width: 200 }}
        />
      ),
    }),
  ]

  const policyKeyByUserId = useMemo<Record<string, DecisionFolderPolicyKey>>(() => {
    if (!folder.policies) return {}
    return _mapValues(_keyBy(folder?.policies || {}, 'userId'), p => p.policyKey)
  }, [folder.policies])
  const users = useMemo(
    () => usersById && Object.values(usersById).filter(u => u.policyIds.includes(SystemPolicyId.DECISION_USER)),
    [usersById]
  )
  const data: FolderPolicyTableRow[] = useMemo(
    () =>
      users.map(({ fullName, email, id: userId }) => ({
        userId,
        fullName,
        email,
        policyKey: policyKeyByUserId[userId] || 'Inherit',
      })),
    [users, policyKeyByUserId]
  )
  return { columns, data, loading: loadingUsers }
}

type FolderPolicyTableRow = {
  userId: string
  fullName: string
  email: string
  policyKey: DecisionFolderPolicyKey
}
const colHelper = createColumnHelper<FolderPolicyTableRow>()
type FolderPolicyTableProps = {
  folderId: string
}
/**
 * For a given decision folder, list all users with the "Decision User" policy, and list
 * their access for this folder.  Allows changing access for each user.
 */
export default function FolderPolicyTable(props: FolderPolicyTableProps) {
  const { data, columns, loading } = useFolderPolicyTable(props.folderId)
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  if (loading) return <Spin />
  return (
    <div className={`${style.table} stripe`}>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={header.id === 'Access' ? { width: 260 } : undefined}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-stripe>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
