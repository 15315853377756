import { useCallback, useState } from 'react'
import _groupBy from 'lodash/groupBy'
import { Button, Col, Modal, Row, Space } from 'antd'

import { valueExpressionToString } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'

/**
 * Renders a standard mathematical representation of a value formula based on value
 * function parameters.  For example, V = P / (C+ T).  Not very fancy or sophisticated
 * right now.
 */
function ValueFormula({ expr }) {
  return valueExpressionToString(expr)
}

/**
 * A form for configuring a value formula, allowing the user to apply whatever coefficients
 * to perf, cost, and time, and whether they go in the numerator or denominator.  This is a
 * pretty messy first take.
 */
function ValueFormulaConfigForm({ initialValueExpr, onChange, groupCostPri, groupTimePri }) {
  const [state, setState] = useState(() => {
    const initialState = {}

    function exprToFlatState(expr, position = 'numerator') {
      // term without a coefficient -> coefficient = 1
      if (typeof expr === 'string') {
        const key = expr.replace(/'$/, '') // here we don't distinguish between a parameter and its complement
        return (initialState[key] = { key, value: 1, position })
      }
      if (typeof expr === 'number') return // constant numerator; nothing to do
      switch (expr[0]) {
        case 'Multiply': {
          const key = expr[2].replace(/'$/, '') // here we don't distinguish between a parameter and its complement
          initialState[key] = { key, value: expr[1], position }
          break
        }
        case 'Add': {
          expr.slice(1).forEach(arg => exprToFlatState(arg, position))
          break
        }
        case 'Divide': {
          exprToFlatState(expr[1], position)
          exprToFlatState(expr[2], 'denominator')
          break
        }
        default:
          throw new Error('unrecognized operation: ' + expr[0])
      }
    }

    if (initialValueExpr) exprToFlatState(initialValueExpr)

    if (!initialState.perf) initialState.perf = { value: 0, position: 'unused' }
    if (!initialState.cost) initialState.cost = { value: 0, position: 'unused' }
    if (!initialState.time) initialState.time = { value: 0, position: 'unused' }

    return initialState
  })

  // ugh...because the user can set parameters to "unused", which makes them disappear
  // from the value function inputs, we can't just use the ValueFunctionInputs type
  // from valuemetrics; instead we need our own internal representation, and this got
  // messy real fast...would like to rethink this whole component
  const stateToExpr = useCallback(state => {
    const { numerator = [], denominator = [] } = _groupBy(Object.values(state), 'position')
    if (denominator.length) {
      const numeratorTerms =
        numerator.length === 0 ? [1] : numerator.map(({ key, value }) => ['Multiply', value, key])
      const denominatorTerms = denominator.map(({ key, value }) => ['Multiply', value, key + "'"])
      const expr = [
        'Divide',
        numeratorTerms.length === 1 ? numeratorTerms[0] : ['Add', ...numeratorTerms],
        denominatorTerms.length === 1 ? denominatorTerms[0] : ['Add', ...denominatorTerms],
      ]
      return expr
    }
    const numeratorTerms =
      numerator.length === 0
        ? [0] // this is an edge case when they change everything to "unused"...zero value is as good as anything
        : numerator.map(({ key, value }) => ['Multiply', value, key])
    const expr = numeratorTerms.length === 1 ? numeratorTerms[0] : ['Add', ...numeratorTerms]
    return expr
  }, [])

  const useGroupAverages = useCallback(() => {
    // round priorities to 3 decimal places
    const cost = typeof groupCostPri === 'number' ? Math.round(groupCostPri * 1000) / 1000 : undefined
    const time = typeof groupTimePri === 'number' ? Math.round(groupTimePri * 1000) / 1000 : undefined
    const newState = {
      ...state,
      cost: { key: 'cost', value: cost, position: state.cost.position },
      time: { key: 'time', value: time, position: state.time.position },
    }
    setState(newState)
    onChange(stateToExpr(newState))
  }, [groupCostPri, groupTimePri, onChange, state, stateToExpr])

  const onChangeCoeff = key => e => {
    const newState = {
      ...state,
      [key]: { key, value: Number(e.target.value), position: state[key].position },
    }
    setState(newState)
    onChange(stateToExpr(newState))
  }

  const onChangePos = key => e => {
    const newState = {
      ...state,
      [key]: { key, value: state[key].value, position: e.target.value },
    }
    setState(newState)
    onChange(stateToExpr(newState))
  }

  const { numeratorSum, denominatorSum } = Object.values(state).reduce(
    (sums, v) => {
      if (v.position === 'numerator') sums.numeratorSum += v.value
      if (v.position === 'denominator') sums.denominatorSum += v.value
      return sums
    },
    { numeratorSum: 0, denominatorSum: 0 }
  )

  return (
    <>
      <h3>Coeffients:</h3>
      <Space>
        <span style={{ textAlign: 'right', display: 'inline-block', width: 100 }}>Performance:</span>
        <input
          type="number"
          step={0.1}
          style={{ width: 80 }}
          value={state.perf.value}
          onChange={onChangeCoeff('perf')}
        />
        <select style={{ width: 120 }} value={state.perf.position} onChange={onChangePos('perf')}>
          <option value="numerator">Numerator</option>
          <option value="denominator">Denominator</option>
          <option value="unused">(Unused)</option>
        </select>
      </Space>
      <Space style={{ paddingTop: '12px' }}>
        <span style={{ textAlign: 'right', display: 'inline-block', width: 100 }}>Cost:</span>
        <input
          type="number"
          step={0.1}
          style={{ width: 80 }}
          value={state.cost.value}
          onChange={onChangeCoeff('cost')}
        />
        <select style={{ width: 120 }} value={state.cost.position} onChange={onChangePos('cost')}>
          <option value="numerator">Numerator</option>
          <option value="denominator">Denominator</option>
          <option value="unused">(Unused)</option>
        </select>
        <span>Group average: {groupCostPri?.toFixed(3) || 'N/A'}</span>
      </Space>
      <Space style={{ paddingTop: '12px' }}>
        <span style={{ textAlign: 'right', display: 'inline-block', width: 100 }}>Time:</span>
        <input
          type="number"
          step={0.1}
          style={{ width: 80 }}
          value={state.time.value}
          onChange={onChangeCoeff('time')}
        />
        <select style={{ width: 120 }} value={state.time.position} onChange={onChangePos('time')}>
          <option value="numerator">Numerator</option>
          <option value="denominator">Denominator</option>
          <option value="unused">(Unused)</option>
        </select>
        <span style={{ width: 155 }}>Group average: {groupTimePri?.toFixed(3) || 'N/A'}</span>
      </Space>
      <div style={{ paddingTop: '12px', width: '100%', textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={useGroupAverages}
          disabled={typeof groupCostPri !== 'number' || typeof groupTimePri !== 'number'}
        >
          Use Group Averages
        </Button>
      </div>
      <Row style={{ paddingTop: '24px' }}>
        <Col xs={12}>
          <h3>Coefficient sums:</h3>
          <div>
            <Space>
              <span style={{ textAlign: 'right', display: 'inline-block', width: 100 }}>Numerator:</span>
              <span style={{ textAlign: 'right', display: 'inline-block', width: 40 }}>
                {numeratorSum?.toFixed(3)}
              </span>
            </Space>
          </div>
          <div>
            <Space style={{ paddingTop: '12px' }}>
              <span style={{ textAlign: 'right', display: 'inline-block', width: 100 }}>Denominator:</span>
              <span style={{ textAlign: 'right', display: 'inline-block', width: 40 }}>
                {denominatorSum?.toFixed(3)}
              </span>
            </Space>
          </div>
        </Col>
        <Col xs={12}>
          <h3>Value Formula:</h3>
          <ValueFormula expr={stateToExpr(state)} />
        </Col>
      </Row>
    </>
  )
}

export const ValueFormulaConfigModalId = 'ValueFormulaConfigModal'
export function ValueFormulaConfigModal({ onOk, initialValueExpr, groupCostPri, groupTimePri, hideModal }) {
  const [valueExpr, setValueExpr] = useState(initialValueExpr)

  return (
    <Modal
      open
      onOk={() => {
        onOk(valueExpr)
        hideModal()
      }}
      onCancel={hideModal}
    >
      <ValueFormulaConfigForm
        initialValueExpr={valueExpr}
        onChange={setValueExpr}
        groupCostPri={groupCostPri}
        groupTimePri={groupTimePri}
      />
    </Modal>
  )
}
ValueFormulaConfigModal.id = ValueFormulaConfigModalId
