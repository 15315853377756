import { useCallback } from 'react'
import asSet from 'arraysetjs'
import { AnyAction } from 'redux'

import { authz as coreAuthz } from '@vms/vmspro3-core/dist/security'
import { resolveResourcePolicies } from '@vms/vmspro3-core/dist/security/getResourcePolicies'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { RolePolicy_App } from '@vms/vmspro3-core/dist/types'
import { actions } from '@vms/vmspro3-core/dist'

import { useAppSelector } from '../redux'
import { AssumeAuthzType } from '../utils/authzUtils'
import { useUserPolicies } from '../redux/hooks'
import {
  selectSystemPolicies,
  selectAuthUserId,
  selectAssumedRoleIds,
  selectAssumedRolePolicies,
} from '../redux/selectors'

/**
 * Custom hook that returns a callback to authorize an action to be performed
 * by the authenticated user.
 */
export default function useAuthz() {
  const authUserId = useAppSelector(selectAuthUserId)

  const [userPolicies, userPoliciesLoading] = useUserPolicies()

  const assumedRoleIds = useAppSelector(selectAssumedRoleIds)
  const assumedRolePolicies = useAppSelector(selectAssumedRolePolicies)

  const systemPolicies = useAppSelector(selectSystemPolicies)
  const policiesByResourceId = useAppSelector(state => state.policies.byResourceId)

  const authz = useCallback(
    (action: AnyAction, options?: { explain: boolean }) => {
      if (!authUserId || userPoliciesLoading) return false

      const authzResourceIds = action.meta?.authz?.resources ?? []
      const ancestorIds = splitAncestry(action?.meta?.ancestry ?? '')
      const resourceIds = asSet(authzResourceIds).union(ancestorIds) as string[]

      const authUserResourcePolicies = resourceIds.reduce<RolePolicy_App[]>(
        (authUserResourcePolicies, resourceId) => {
          const principal = 'user:' + authUserId
          const resourcePolicies = policiesByResourceId[resourceId]
          const resolvedPolicies = resolveResourcePolicies(
            principal,
            resourcePolicies,
            systemPolicies
          ) as RolePolicy_App[]
          return authUserResourcePolicies.concat(resolvedPolicies)
        },
        []
      )

      if (assumedRoleIds) {
        return (
          assumedRoleIds.every(policyId =>
            coreAuthz(
              actions.user.assumeAuthz({ policyId }, { type: AssumeAuthzType.ASSUME_ROLE, authUserId }),
              userPolicies,
              undefined,
              options
            )
          ) && coreAuthz(action, assumedRolePolicies?.concat(authUserResourcePolicies) ?? [], undefined, options)
        )
      }
      return coreAuthz(action, userPolicies.concat(authUserResourcePolicies), undefined, options)
    },
    [
      authUserId,
      systemPolicies,
      policiesByResourceId,
      userPolicies,
      assumedRoleIds,
      assumedRolePolicies,
      userPoliciesLoading,
    ]
  )

  return authz
}
