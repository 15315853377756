import { GlobalPolicy, GlobalPolicy_App } from '@vms/vmspro3-core/dist/types'
import Server from '../server/VMSProServerAdapter'

export async function getPolicies(): Promise<GlobalPolicy_App[]> {
  const { policies } = (await Server.getGlobalPolicies()) as { policies: GlobalPolicy[] }
  // TODO: resolve "id" vs "policyId" issues
  return policies.map(({ policyId, ...restPolicy }) => ({
    ...restPolicy,
    id: policyId,
  }))
}
