import { HttpLink, ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import config from '../config.json'
import { getIdToken } from '../server/AmplifyProxy'

const httpLink = new HttpLink({
  uri: operation => {
    if (operation.getContext().headers?.['x-optionlab-idtoken']) {
      return config.amplify.API.graphql_endpoint
    }
    return config.amplify.API.graphql_endpoint.replace(/\/graphql$/, '/unauth/graphql')
  },
})

const authMiddleware = setContext(async (_, { headers = {} }) => {
  const context = { headers: { ...headers } }

  try {
    context.headers['x-optionlab-idtoken'] = await getIdToken()
  } catch {
    // optional catch binding and eslint/keyword-spacing https://github.com/eslint/eslint/issues/13278
    // no current user
  }

  return context
})

export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Rating: {
        keyFields: ['participationSessionId', 'participantId', 'contextId', 'subjectId'],
      },
      RatingNotes: {
        keyFields: ['participationSessionId', 'participantId', 'contextId', 'subjectType'],
      },
      AccountUser: {
        keyFields: ['id', 'accountId'],
      },
    },
  }),
})
