/* eslint-disable @typescript-eslint/no-explicit-any */
import { Bar } from '@ant-design/plots'
import { getCriteriaSorter } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { RatingNotes } from '@vms/vmspro3-core/dist/types'
import { Col, Row } from 'antd'
import { useMemo, useState } from 'react'
import { Column } from 'react-table'
import { useQuerystringValue } from '../../../../hooks/useQuerystringValue'
import { useTour } from '../../../../hooks/useTour'
import { useDecision } from '../../../../redux/hooks'
import { Table } from '../../../common/Table'
import { ContextRatingNotes } from '../CriteriaRatings/ContextRatingNotes'
import { ContextPriorityTable } from './ContextPriorityTable'
import { CriteriaNavigator } from './CriteriaNavigator'
import { CriterionEditor } from './CriterionEditor'
import { CriterionOverview } from './CriterionOverview'
import { ParticipationSessionControl } from './ParticipationSessionControl'

const criteriaSorter = getCriteriaSorter('pri')

interface ChildCriteriaPrioritiesChartProps {
  decisionId: string
  criterionId: string
}
export function ChildCriteriaPrioritiesChart(props: ChildCriteriaPrioritiesChartProps) {
  const { decisionId, criterionId } = props
  const decision = useDecision(decisionId)
  const criterion = decision.criteria.byId[criterionId]
  if (!criterion) throw new Error(`criterion ${criterionId} not found`)
  const items = useMemo(
    () =>
      criterion.children.sort(criteriaSorter).map(c => ({
        id: c.id,
        name: c.name,
        abbrev: c.abbrev,
        priority: c.pri.local,
        color: c.color,
      })),
    [criterion.children]
  )
  const priFormatter = useMemo(
    () =>
      Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'percent',
      }),
    []
  )
  return (
    <div>
      <h2>Sub-criteria Priorities</h2>
      <p>This chart shows the relative importance of the sub-criteria of "{criterion.name}".</p>
      <div style={{ height: 200, maxHeight: 200, marginBottom: 18 }}>
        <Bar
          data={items}
          color={({ id }) => decision.criteria.byId[id]?.color}
          xField="priority"
          yField="id"
          seriesField="id"
          legend={false}
          animation={false}
          tooltip={
            {
              formatter: (datum: any) => ({
                name: 'Local priority',
                value: priFormatter.format(datum.priority),
              }),
            } as any
          }
          meta={{
            id: {
              formatter: id => decision.criteria.byId[id]?.name ?? id,
            },
            priority: {
              formatter: pri => (pri === null ? '' : priFormatter.format(pri)),
            },
          }}
        />
      </div>
    </div>
  )
}

interface ContextNotesTableProps {
  decision: Decision
  criterion: Criterion
}
function ContextNotesTable(props: ContextNotesTableProps) {
  const { decision, criterion } = props
  const cols: Column<RatingNotes>[] = [
    {
      Header: 'Participant',
      Cell: ({ row }: any) => <span>{decision.participantsById[row.original.participantId].fullName}</span>,
    },
    {
      Header: 'Notes',
      Cell: ({ row }: any) => <div style={{ whiteSpace: 'pre-wrap' }}>{row.original.notes.value ?? ''}</div>,
    },
  ]
  const notes = useMemo(
    () =>
      criterion.ratingContextNotes.filter(
        n => n.participantId !== '*' && decision.participantsById[n.participantId]
      ),
    [criterion, decision.participantsById]
  )
  return (
    <div style={{ marginTop: '24px' }}>
      <Table columns={cols} data={notes} />
    </div>
  )
}

interface PrioritizationParticipationBreakdownProps {
  decision: Decision
  criterion: Criterion
}
export function PrioritizationParticipationBreakdown(props: PrioritizationParticipationBreakdownProps) {
  const { decision, criterion } = props
  return (
    <div>
      <h2>Participation Breakdown</h2>
      <p>
        This table shows how each participant assessed the relative importance of the sub-criteria of "
        {criterion.name}". You can mouse over a participant's initials to see their full name.
      </p>
      <ContextPriorityTable decision={decision} contextCriterionId={criterion.id} />
      <h2>Participation Notes</h2>
      <ContextRatingNotes
        decisionId={decision.id}
        participationSessionId={decision.getParticipationSession('CriteriaPrioritization').id}
        participantId="*"
        contextId={criterion.id}
        contextLabel="Summary notes"
        subjectType="Criterion"
      />
      <ContextNotesTable decision={decision} criterion={criterion} />
    </div>
  )
}

interface CriteriaPageWithSelectionProps {
  decision: Decision
  selectedCriterion: Criterion
  onSelectCriterionId: (criterionId?: string) => void
  setEditing: (isEditing: boolean) => void
}
function CriteriaPageWithSelection(props: CriteriaPageWithSelectionProps) {
  const { decision, selectedCriterion, onSelectCriterionId, setEditing } = props
  const { tour } = useTour('Criteria Page', [
    {
      target: "[data-tour-id='Criteria Navigator']",
      placement: 'right',
      content: (
        <>
          <p>
            This is where you can navigate your criteria. Subjective criteria are what you will evaluate yourself,
            and objective criteria are attributes of the options you're deciding on that are already known and
            measurable, like cost and time.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Add Criterion']",
      placement: 'right',
      content: (
        <>
          <p>Creating some subjective criteria is a pretty good place to start!</p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Participation Session Control']",
      placement: 'right',
      content: (
        <>
          <p>
            This is where you control participation in this decision. When you're ready to invite other people to
            participate in the decision (or if you just want to prioritize the criteria yourself), you can start
            the prioritization session.
          </p>
          {decision.type !== 'Regular' && (
            <p>
              If you choose "Include objective criteria", participants will be asked to prioritize cost &amp; time
              as well.
            </p>
          )}
          <p>
            Once the session is started, you can copy the link to share with users, prioritize criteria yourself,
            or show a presentation screen with all of the relevant information so people can join the session. You
            can also stop the session to prevent further participation.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Criterion Overview']",
      placement: 'top',
      content: (
        <>
          <p>
            You'll see details about the selected criterion here. If the criterion has children, you'll see
            prioritization data for it (assuming at least one person has prioritized the criteria).
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Edit Criterion Button']",
      placement: 'top',
      content: (
        <>
          <p>
            Click here to edit details about the criterion, including its name, description, and the way the rating
            scale will appear during option rating.
          </p>
        </>
      ),
    },
  ])
  return (
    <>
      {tour}
      <Row gutter={36}>
        <Col xs={24} lg={6}>
          <CriteriaNavigator
            decision={decision}
            onSelectCriterion={onSelectCriterionId}
            selectedCriterionId={selectedCriterion.id}
          />
          <ParticipationSessionControl decision={decision} type="CriteriaPrioritization" />
        </Col>
        <Col xs={24} lg={18}>
          <CriterionOverview criterion={selectedCriterion} onEdit={() => setEditing(true)} />
          {selectedCriterion.children.length > 1 && selectedCriterion.children.some(c => c.pri.local !== null) && (
            <ChildCriteriaPrioritiesChart decisionId={decision.id} criterionId={selectedCriterion.id} />
          )}
          {selectedCriterion.children.length > 1 &&
            selectedCriterion.children.some(c => Object.keys(c.localPriByParticipantId).length > 0) && (
              <PrioritizationParticipationBreakdown decision={decision} criterion={selectedCriterion} />
            )}
        </Col>
      </Row>
    </>
  )
}

interface CriteriaPageProps {
  decisionId: string
}
export function CriteriaPage(props: CriteriaPageProps) {
  const decision = useDecision(props.decisionId)
  const [selectedCriterionId, setSelectedCriterionId] = useQuerystringValue<string, string | undefined>(
    'criterionId',
    decision.criteria.perfRoot.id
  )
  const selectedCriterion = useMemo(
    () => (selectedCriterionId ? decision.criteria.byId[selectedCriterionId] : undefined),
    [decision.criteria.byId, selectedCriterionId]
  )
  const [editing, setEditing] = useState(false)
  if (!selectedCriterion) return <h3>Error: criterion with ID {selectedCriterionId} not found.</h3>
  if (editing && selectedCriterion) {
    return (
      <CriterionEditor decisionId={decision.id} criterion={selectedCriterion} onExit={() => setEditing(false)} />
    )
  }
  return (
    <CriteriaPageWithSelection
      decision={decision}
      selectedCriterion={selectedCriterion}
      onSelectCriterionId={setSelectedCriterionId}
      setEditing={setEditing}
    />
  )
}
