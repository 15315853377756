/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { Participant } from '@vms/vmspro3-core/dist/types'
import { Link } from 'react-router-dom'
import { Column, CellProps, TableInstance } from 'react-table'
import { TeamOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Checkbox, Modal, Popover } from 'antd'

import { Table, TableCellRenderer } from '../../../common/Table'
import { ParticipantsMergeModal } from './ParticipantsMergeModal'

import { useModalState } from '../../../../hooks/useModalState'
import { useAccount } from '../../../../context'
import { deleteParticipantData } from '@vms/vmspro3-core/dist/actions/decision'
import { useAppDispatch } from '../../../../redux'
import { useDecision } from '../../../../redux/hooks'

interface DeleteParticipantDataModalProps {
  decisionId: string
  participantIds: string[]
  hideModal: VoidFunction
}
export function DeleteParticipantDataModal(props: DeleteParticipantDataModalProps) {
  const { decisionId, participantIds, hideModal } = props

  const [allData, _setAllData] = useState(true)
  const [childrenDisabled, setChildrenDisabled] = useState(true)
  const [criPriData, setCriPriData] = useState(true)
  const [opRatingData, setOpRatingData] = useState(true)
  const [outcomeProbData, setOutcomeProbData] = useState(true)

  const setAllData = useCallback((checked: boolean) => {
    if (checked) {
      _setAllData(true)
      setCriPriData(true)
      setOpRatingData(true)
      setOutcomeProbData(true)
      setChildrenDisabled(true)
    } else {
      _setAllData(false)
      setChildrenDisabled(false)
    }
  }, [])

  const dispatch = useAppDispatch()

  const onOk = useCallback(() => {
    const action = deleteParticipantData(
      decisionId,
      participantIds,
      allData,
      criPriData,
      opRatingData,
      outcomeProbData
    )
    dispatch(action)
    hideModal()
  }, [allData, criPriData, decisionId, dispatch, hideModal, opRatingData, outcomeProbData, participantIds])

  return (
    <Modal open onOk={onOk} onCancel={hideModal} okButtonProps={{ icon: <DeleteOutlined />, danger: true }}>
      <p>
        Deleting participant data for {participantIds.length} participant(s). For each of these participants,
        indicate what participant data you want to delete:
      </p>
      <div>
        <Checkbox checked={allData} onChange={e => setAllData(e.target.checked)}>
          Participant and all data
        </Checkbox>
        <div style={{ marginLeft: '2em' }}>
          <div>
            <Checkbox
              checked={criPriData}
              onChange={e => setCriPriData(e.target.checked)}
              disabled={childrenDisabled}
            >
              Criteria prioritization data (if any)
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={opRatingData}
              onChange={e => setOpRatingData(e.target.checked)}
              disabled={childrenDisabled}
            >
              Option rating data (if any)
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={outcomeProbData}
              onChange={e => setOutcomeProbData(e.target.checked)}
              disabled={childrenDisabled}
            >
              Outcome probability data (if any)
            </Checkbox>
          </div>
        </div>
      </div>
    </Modal>
  )
}

type ParticipantsProps = {
  decisionId: string
}
export function Participants({ decisionId }: ParticipantsProps): ReactElement {
  const { accountId, accountCommonId } = useAccount()

  const decision = useDecision(decisionId)

  const columns = useMemo<Column<Participant>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Associated User',
        accessor: row => row.associatedUser?.fullName,
        Cell: ({ value, row }: CellProps<Participant, string | undefined>) =>
          value ? <Link to={`/${accountCommonId}/user/${row.original.userId}`}>{value}</Link> : null,
      },
      {
        Header: 'Last Participation',
        accessor: row => row.updated.timestamp,
        Cell: TableCellRenderer.Timestamp as any,
        sortType: 'numeric',
      },
    ],
    [accountCommonId]
  )

  const mergeModal = useModalState()
  const deleteModal = useModalState()

  const onMergeParticipants = useCallback(
    (instance: TableInstance<Participant>) => {
      const participants = instance.selectedFlatRows.map(row => row.original)

      if (participants.filter(participant => participant.userId).length > 1) {
        return Modal.warning({
          title: 'Merge Participants',
          content:
            'You selected two or more participants with an associated user. Merging user ' +
            'data is not supported. Please contact support if you need assistance.',
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
        })
      }

      mergeModal.showModal(
        <ParticipantsMergeModal
          accountId={accountId}
          decisionId={decisionId}
          participants={instance.selectedFlatRows.map(row => row.original)}
          hideModal={mergeModal.hideModal}
        />
      )
    },
    [mergeModal, accountId, decisionId]
  )

  const onDeleteParticipants = useCallback(
    (instance: TableInstance<Participant>) => {
      const participantIds = instance.selectedFlatRows.map(row => row.original.id)
      deleteModal.showModal(
        <DeleteParticipantDataModal
          decisionId={decisionId}
          participantIds={participantIds}
          hideModal={deleteModal.hideModal}
        />
      )
    },
    [decisionId, deleteModal]
  )

  return (
    <>
      {mergeModal.modal}
      {deleteModal.modal}
      <Table
        data={decision.participants}
        columns={columns}
        rowSelectPosition="first"
        toolbarRight={instance => {
          const selectedIds = Object.keys(instance.state.selectedRowIds)

          const mergeParticipantsButton =
            selectedIds.length < 2 ? (
              <Popover
                title="Merge Participants"
                content="Select two or more participants to merge"
                placement="bottomLeft"
              >
                <Button disabled>Merge Participants</Button>
              </Popover>
            ) : (
              <Button icon={<TeamOutlined />} onClick={() => onMergeParticipants(instance)}>
                Merge Participants
              </Button>
            )

          return (
            <>
              <Button
                disabled={selectedIds.length === 0}
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteParticipants(instance)}
              >
                Delete Participant Data
              </Button>
              {mergeParticipantsButton}
            </>
          )
        }}
      />
    </>
  )
}
