import React from 'react'
import './risk-scale-table.css'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import { TableInput } from '../controls'
import { testId } from '../../../test-automation'

const { Color } = systemConsts

// TODO: see https://vms.atlassian.net/browse/VP3-1817 for refactor notes

/**
 * Table display (with editing) of risk scale from risk context configuration.
 *
 * In the new risk product, this takes the place of ImpactTable from VMSPro3
 * (which was misnamed, since it was also used for the probability scale).
 *
 * @param {boolean} props.includeMedian - If true, included the median of the scale (read-only) in the table.
 * @param {boolean} props.includeSubheader - If true, include the subheader listening "M", "ML", and "H"
 *   (meaning "min", "median", and "max").
 * @param {function} props.onChange - Callback function when a value is changed.  This is an object that
 *   includes key (the key of the value being changed), param (either "min" or "max") and val (the new
 *   value).
 * @param {boolean} props.readOnly - If true, table is displayed read-only.
 * @param {object[]} props.riskScale - The risk scale to display as a table.
 * @param {boolean} props.showHeaderCol - If true, show the header col that contains the short labels
 *   of the scale values.
 * @param {string} props.title - Title appears in row 1, col 1.
 * @param {string} props.subtitle - Subtitle appears in row 1, col 2-N (N is 2 or 3, depending on
 *   includeMedian)
 * @param {string} props.titleBgColor - Background color for title cell.
 * @param {string} [props.format = "percentage"] - Format to use for cell values.  Use "percentage" for
 *   a percentage scale, and "cost", "duration", or "number" for value scales.
 * @param {number|null} [props.value] - If specified, this represents the overall value for the risk
 *   context (that is the context cost, duration or 1 for performance).  If this is provided, this will
 *   be a value (as opposed to percentage) table, and all values displayed will be multiplied by this value,
 *   and all calls to onChange will be divided by this number.
 */
const RiskScale = ({
  tableId,
  includeMedian,
  includeSubheader,
  onChange,
  readOnly,
  riskScale,
  showHeaderCol,
  title,
  subtitle,
  titleBgColor,
  headerHeight = '60px',
  format = 'percentage',
  value,
}) => (
  <table className="risk-scale-table" id={tableId} {...testId(tableId)}>
    <thead>
      <tr>
        {showHeaderCol && <th style={style.title(titleBgColor)}>{title}</th>}
        <th colSpan={includeMedian ? 3 : 2} style={{ height: headerHeight }}>
          {subtitle}
        </th>
      </tr>
      {includeSubheader && (
        <tr>
          <th style={style.header} />
          <th className="text-center" style={style.cell(includeMedian, Color.GREY_LIGHT)}>
            L
          </th>
          {includeMedian && (
            <th className="text-center" style={style.cell(includeMedian, Color.GREY_LIGHT)}>
              ML
            </th>
          )}
          <th className="text-center " style={style.cell(includeMedian, Color.GREY_LIGHT)}>
            H
          </th>
        </tr>
      )}
    </thead>
    <tbody>
      {riskScale.map(({ key, label, quantRange }) => {
        const [min, max] = Number.isFinite(value) ? quantRange.map(x => x * value) : quantRange
        const _onChange = ({ key, param, val }) =>
          Number.isFinite(value) ? onChange({ key, param, val: val / value }) : onChange({ key, param, val })
        return (
          <tr key={key}>
            {showHeaderCol && <th style={style.header}>{label.short}</th>}
            <td style={style.cell(includeMedian)}>
              <TableInput
                id={`${tableId}-${label.short}-L-input`}
                align="center"
                format={format}
                onChange={val => _onChange({ key, param: 'min', val })}
                isReadOnly={readOnly}
                value={min}
              />
            </td>
            {includeMedian && (
              <td style={style.cell(includeMedian)}>
                <TableInput
                  id={`${tableId}-${label.short}-ML-input`}
                  align="center"
                  format={format}
                  isReadOnly
                  value={(min + max) / 2}
                />
              </td>
            )}
            <td style={style.cell(includeMedian)}>
              <TableInput
                id={`${tableId}-${label.short}-H-input`}
                align="center"
                format={format}
                onChange={val => _onChange({ key, param: 'max', val })}
                isReadOnly={readOnly}
                value={max}
              />
            </td>
          </tr>
        )
      })}
    </tbody>
  </table>
)
RiskScale.defaultProps = {
  readOnly: false,
  showHeaderCol: true,
  includeMedian: false,
  includeSubheader: false,
}

const style = {
  header: {
    width: '12ch',
  },
  title: bgColor => ({
    width: '12ch',
    backgroundColor: bgColor,
  }),
  cell: (includeMedian, color) => ({
    width: includeMedian ? '33%' : '50%',
    backgroundColor: color,
  }),
}

export default RiskScale
