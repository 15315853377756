import _sumBy from 'lodash/sumBy'

export const BaseQuantity = {
  Duration: 'Duration',
  Cost: 'Cost',
  Dimensionless: 'Dimensionless',
} as const

export class Quantity {
  base: keyof typeof BaseQuantity
  unit: string
  value: number | undefined
  constructor(base: keyof typeof BaseQuantity, unit: string, value?: number) {
    this.base = base
    this.unit = unit
    this.value = value
  }
  static isQuantity(obj: any) {  // eslint-disable-line @typescript-eslint/no-explicit-any
    if(!obj || typeof obj !== 'object') return false
    if(!Object.values(BaseQuantity).includes(obj.base)) return false
    if(typeof obj.unit !== 'string') return false
    if('value' in obj && obj.value !== undefined && typeof obj.value !== 'number') return false
    return true
  }
}

export const CostUnits = [
  'AUD',
  'BRL',
  'BTC',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PLN',
  'SGD',
  'SEK',
  'TWD',
  'THB',
  'TRY',
  'USD',
  'BTC',
] as const

export type CostUnit = typeof CostUnits[number]

/**
 * This allows const-type access to duration units when type safety
 * is not available.  I.e., you can use `CostUnit.USD` instead of `'USD'`.
 */
export const CostUnit = Object.fromEntries(CostUnits.map(u => [u, u]))

interface CostUnitMetadata {
  key: CostUnit
  label: string
  quickSelectIndex?: number
  symbol?: string
}

export const CostUnitMetadata: Record<CostUnit, CostUnitMetadata> = {
  AUD: { key: 'AUD', label: 'Australian Dollar' },
  BRL: { key: 'BRL', label: 'Brazilian Real' },
  CAD: { key: 'CAD', label: 'Canadian Dollar', quickSelectIndex: 1 },
  CHF: { key: 'CHF', label: 'Swiss Franc' },
  CNY: { key: 'CNY', label: 'Chinese Yuan Renminbi', symbol: '¥' },
  CZK: { key: 'CZK', label: 'Czech Koruna' },
  DKK: { key: 'DKK', label: 'Danish Krone' },
  EUR: { key: 'EUR', label: 'Euro', symbol: '€' },
  GBP: { key: 'GBP', label: 'Pound Sterling', symbol: '£' },
  HKD: { key: 'HKD', label: 'Hong Kong Dollar' },
  HUF: { key: 'HUF', label: 'Hungarian Forint' },
  ILS: { key: 'ILS', label: 'Israeli New Sheqel' },
  JPY: { key: 'JPY', label: 'Japanese Yen', symbol: '¥' },
  MXN: { key: 'MXN', label: 'Mexican Peso', symbol: '$' },
  MYR: { key: 'MYR', label: 'Malaysian Ringgit' },
  NOK: { key: 'NOK', label: 'Norwegian Krone' },
  NZD: { key: 'NZD', label: 'New Zealand Dollar' },
  PHP: { key: 'PHP', label: 'Philippine Peso', symbol: '₱' },
  PLN: { key: 'PLN', label: 'Polish Zloty' },
  SGD: { key: 'SGD', label: 'Singapore Dollar' },
  SEK: { key: 'SEK', label: 'Swedish Krona' },
  TWD: { key: 'TWD', label: 'Taiwan New Dollar' },
  THB: { key: 'THB', label: 'Thai Baht', symbol: '฿' },
  TRY: { key: 'TRY', label: 'Turkish Lira' },
  USD: { key: 'USD', label: 'U.S. Dollar', symbol: '$', quickSelectIndex: 0 },
  BTC: { key: 'BTC', label: 'Bitcoin', symbol: '₿' },
}

export class Cost extends Quantity {
  base: 'Cost' = 'Cost'
  unit: CostUnit
  updated: number
  constructor(unit: CostUnit, value?: number, updated = Date.now()) {
    super(BaseQuantity.Cost, unit, value)
    this.unit = unit
    this.updated = updated
  }
  static isCost(obj: any) {   // eslint-disable-line @typescript-eslint/no-explicit-any
    return Quantity.isQuantity(obj) && obj.base === BaseQuantity.Cost
  }
  static USD(value?: number, updated = Date.now()) {
    return new Cost('USD', value, updated)
  }
  static sum(costs: Cost[], unit: CostUnit) {
    if(!costs.length) return new Cost(unit, 0)
    if(costs.some(c => c.unit !== unit)) throw new Error('cannot sum costs with nonhomogenous units')
    return new Cost(unit, _sumBy(costs, 'value'))
  }
}

export const DurationUnits = [
  'Seconds',
  'Minutes',
  'Hours',
  'Days',
  'Weeks',
  'Months',
  'Quarters',
  'Years',
] as const

export type DurationUnit = typeof DurationUnits[number]

/**
 * This allows const-type access to duration units when type safety
 * is not available.  I.e., you can use `DurationUnit.Months` instead of `'Months'`.
 */
export const DurationUnit = Object.fromEntries(DurationUnits.map(u => [u, u]))

interface DurationMetadata {
  key: DurationUnit
  label: string
  abbrev: string
}

export const DurationUnitMetadata: Record<DurationUnit, DurationMetadata> = {
  Years: { key: 'Years', label: 'Years', abbrev: 'yr' },
  Quarters: { key: 'Quarters', label: 'Quarters', abbrev: 'q' },
  Months: { key: 'Months', label: 'Months', abbrev: 'mo' },
  Weeks: { key: 'Weeks', label: 'Weeks', abbrev: 'wk' },
  Days: { key: 'Days', label: 'Days', abbrev: 'days' },
  Hours: { key: 'Hours', label: 'Hours', abbrev: 'hr' },
  Minutes: { key: 'Minutes', label: 'Minutes', abbrev: 'min' },
  Seconds: { key: 'Seconds', label: 'Seconds', abbrev: 'sec' },
}

export class Duration extends Quantity {
  base: 'Duration' = 'Duration'
  unit: DurationUnit
  constructor(unit: DurationUnit, value?: number) {
    super(BaseQuantity.Duration, unit, value)
    this.unit = unit
  }
  static isDuration(obj: any) {   // eslint-disable-line @typescript-eslint/no-explicit-any
    return Quantity.isQuantity(obj) && obj.base === BaseQuantity.Duration
  }
  static convert(duration: Duration, unit: DurationUnit) {
    const chain: { unit: DurationUnit, down: number }[] = [
      { unit: 'Years', down: 4 },
      { unit: 'Quarters', down: 3 },
      { unit: 'Months', down: 52 / 12 },
      { unit: 'Weeks', down: 7 },
      { unit: 'Days', down: 24 },
      { unit: 'Hours', down: 60 },
      { unit: 'Minutes', down: 60 },
      { unit: 'Seconds', down: NaN },
    ]
    const fromIdx = chain.findIndex(c => c.unit === duration.unit)
    const toIdx = chain.findIndex(c => c.unit === unit)
    let { value } = duration
    if(value === undefined) return new Duration(unit, value)
    if(fromIdx < toIdx) {
      for(let i = fromIdx; i < toIdx; i++) value *= chain[i].down
    } else {
      for(let i = fromIdx; i > toIdx; i--) value /= chain[i - 1].down
    }
    return new Duration(unit, value)
  }
  /**
   * Compares two durations a and b:
   *
   *   returns negative number if b > a
   *   returns positive number if a < b
   *   returns  0 if a === b
   *
   * This function is suitable for use with Array#sort.
   *
   * You may also specify an optional unit as the basis for comparison; in theory,
   * it doesn't make a difference, but there could be precision issues if you are
   * comparing durations in large units by converting to small units or vice versa.
   */
  static compare(a: Duration, b: Duration, unit: DurationUnit = 'Months') {
    const aValue = Duration.convert(a, unit).value
    const bValue = Duration.convert(b, unit).value
    if(aValue === undefined) return bValue === undefined ? 0 : 1
    if(bValue === undefined) return aValue === undefined ? 0 : -1
    return aValue - bValue
  }

  static Years(v?: number) { return new Duration('Years', v) }
  static Quarters(v?: number) { return new Duration('Quarters', v) }
  static Months(v?: number) { return new Duration('Months', v) }
  static Weeks(v?: number) { return new Duration('Weeks', v) }
  static Days(v?: number) { return new Duration('Days', v) }
  static Hours(v?: number) { return new Duration('Hours', v) }
  static Minutes(v?: number) { return new Duration('Minutes', v) }
  static Seconds(v?: number) { return new Duration('Seconds', v) }
}


export class Dimensionless extends Quantity {
  base: 'Dimensionless' = 'Dimensionless'
  unit: 'Dimensionless' = 'Dimensionless'
  constructor(value?: number) {
    super(BaseQuantity.Dimensionless, BaseQuantity.Dimensionless, value)
  }
  static isDimensionless(obj: any) {  // eslint-disable-line @typescript-eslint/no-explicit-any
    return Quantity.isQuantity(obj) && obj.base === BaseQuantity.Dimensionless
  }
}
