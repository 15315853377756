import { ReactElement, useEffect } from 'react'
import { ROOT_DECISION_NODE_ID, SystemPolicyId } from '@vms/vmspro3-core/dist/systemConsts'
import { Button, Divider, Spin } from 'antd'

import { Page } from '../../common/Page'
import { DecisionFolderChildren } from './DecisionFolderChildren'

import { useQuerystringValue } from '../../../hooks/useQuerystringValue'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { fetchDecisionEntity } from '../../../redux/actions'
import { useAccount } from '../../../context'
import {
  useDecisionFolder,
  useDecisionFolderBreadcrumbRoutes,
  useFetchUsers,
  useLoadDecisionFolderChildren,
} from '../../../redux/hooks'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import CheckOutlined from '@ant-design/icons/CheckOutlined'
import { NavLink } from 'react-router-dom'
import FolderPolicyTable from '../../common/FolderPolicyTable'

interface DecisionExplorerProps {
  accountCommonId: string
  accountId: string
  decisionFolderId: string
}
function DecisionExplorer({ accountCommonId, accountId, decisionFolderId }: DecisionExplorerProps): ReactElement {
  const folder = useDecisionFolder(decisionFolderId)
  const childrenLoading = useLoadDecisionFolderChildren(accountId, decisionFolderId)
  const breadcrumbRoutes = useDecisionFolderBreadcrumbRoutes(accountCommonId, decisionFolderId)
  const { accountUserRecord } = useAccount()

  const [view, setView] = useQuerystringValue('view', '', false)

  useEffect(() => {
    if (folder) console.log(`folder path: ${joinAncestry(folder.ancestry, folder.id)}`)
  }, [folder])

  const extra =
    view === 'config' ? (
      <Button icon={<CheckOutlined />} type="primary" onClick={() => setView('')}>
        Done
      </Button>
    ) : accountUserRecord.policyIds.includes(SystemPolicyId.DECISION_ADMIN) ? (
      <Button icon={<SettingOutlined />} onClick={() => setView('config')}>
        Configuration
      </Button>
    ) : undefined

  return view === 'config' ? (
    <Page loading={childrenLoading} title={folder.name} breadcrumbRoutes={breadcrumbRoutes} extra={extra}>
      <DecisionFolderConfig decisionFolderId={decisionFolderId} />
    </Page>
  ) : (
    <Page loading={childrenLoading} title={folder.name} breadcrumbRoutes={breadcrumbRoutes} extra={extra}>
      <DecisionFolderChildren accountCommonId={accountCommonId} decisionFolderId={decisionFolderId} />
    </Page>
  )
}

export function DecisionFolderPage(): ReactElement {
  const [decisionFolderId] = useQuerystringValue('folder', ROOT_DECISION_NODE_ID)
  const { accountId, accountCommonId } = useAccount()

  const decisionFolderStatus = useAppSelector(state => state.decisionFolders[decisionFolderId]?.status ?? 'Idle')

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (decisionFolderStatus === 'Idle') {
      dispatch(fetchDecisionEntity(accountId, decisionFolderId, 'DecisionFolder'))
    }
  }, [dispatch, accountId, decisionFolderId, decisionFolderStatus])

  if (decisionFolderStatus !== 'Success') {
    return <Spin />
  }

  return (
    <DecisionExplorer
      accountCommonId={accountCommonId}
      accountId={accountId}
      decisionFolderId={decisionFolderId}
    />
  )
}

type DecisionFolderConfigProps = {
  decisionFolderId: string
}
function DecisionFolderConfig(props: DecisionFolderConfigProps) {
  useFetchUsers()
  const { accountCommonId } = useAccount()
  return (
    <div>
      <h2>Decision Users</h2>
      <p>
        Only users with the role "Decision Users" are listed here; go to{' '}
        <NavLink to={`/${accountCommonId}/roles`}>Admin &gt; Roles</NavLink> to add users to this role.
      </p>
      <p>
        Access specified here will override access from ancestor folders, and applies to descendant folders (but
        can be overridden by descendant folders). Set access to "Inherit" to inherit access level from nearest
        ancestor folder.
      </p>
      <Divider orientationMargin={16} />
      <FolderPolicyTable folderId={props.decisionFolderId} />
    </div>
  )
}
