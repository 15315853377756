import { useMemo } from 'react'
import { getRiskCategoryLabelFactory } from '@vms/vmspro3-core/dist/utils/risk'

/**
 * Given a risk category value (usually a UUID), and a list of all values in the effective
 * risk context, will render a label that unambiguously describes the category by prepending
 * its ancestry.  For exmaple, the rendered label might be "Env:Soil" (the "Soil" category within
 * the "Environmental" category).  By default, the short labels are used since the ancestor
 * concatenation can add a lot of length.
 *
 * @param {string} options.category - Category value to render (or null/undefined, which will render
 *   an empty string).
 * @param {object[]} options.categoryValues - Category values from effecive risk context
 *   (effectiveRiskContext.types.category.values).
 * @param {boolean} [options.short = true] - Set to false to render long names.  Defaults to short names.
 * @param {string} [options.sep] - Set to use a different separator (such as "/").
 */
const RiskCategoryLabel = ({ category, categoryValues, short = true, sep }) => {
  const getRiskCategoryLabel = useMemo(() => getRiskCategoryLabelFactory(categoryValues), [categoryValues])
  if (!category) return ''
  return getRiskCategoryLabel(category, short ? 'short' : 'long', sep)
}

export default RiskCategoryLabel
