import { Html, Participant, Rating, RatingNotes, ValidRating } from '../types'
import { extractCoefficients, ValueFunctionLibrary } from '../valuemetrics/valuemetrics'
import { Criteria, defaultCriteriaPrioritizationScaleConfig, defaultOptionProbabilityScaleConfig } from './criteria'
import { CriterionData } from './Criterion'
import { OptionData, Options } from './options'
import { ParticipationSession, ParticipationSessionData, ParticipationSessionType } from './participationSession'
import { RatingToPrioritizationAlgorithm } from './prioritization/quickPrioritization'
import { RatingContext } from './RatingContext'

import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _keyBy from 'lodash/keyBy'
import { Expression } from '../valuemetrics/valueFormula'
import { updateCoefficients } from '../valuemetrics/util'

export type DecisionType =
  | 'Regular'
  | 'VM'

export type DecisionVersion =
  | '2.0.0'
  | '3.0.0'

/**
 * Serializable metadata about a decision.  A decision consists of many parts
 * (participants, criteria, options, participation sessions, ratings, rating notes),
 * all of which are stored separately.  This is the basic decision metadata that's
 * stored in the database.
 */
export interface DecisionData {
  id: string
  /**
   * Type of decision.  If not set, it is assumed to be VM.
   */
  type?: DecisionType
  /**
   * Decision version.  Hooray, another prop!  If not set, it is assumed to be '2.0.0'.
   */
  version?: DecisionVersion
  /**
   * Ancestry of this decision, not including its own ID.  This places the
   * decision in the folder hierarchy.  Eventually, this metadata will move
   * out of DecisionData proper, as it's not intrinsic to the decision itself.
   */
  ancestry: string
  name: string
  /**
   * Not yet defined.  See https://vms.atlassian.net/browse/VP3-2710
   */
  status?: string
  /**
   * Decision owner; convenience info that we're not currently making much use of.
   */
  owner: {
    userName: string
    userId: string
  }
  /**
   * The objective of this decision; this should be clear and concise.
   */
  objective: Html
  /**
   * The description of this decision; this may be more detailed, and include extra
   * background information about the decision.
   */
  description: Html
  /**
   * JSON representation of the value function.  See Expression in core/src/valuemetrics/valueFormula.ts
   */
  valueFunctionJson?: string
  ratingsToPrioritizationAlgorithm?: RatingToPrioritizationAlgorithm
  /**
   * Baseline is simply an option that serves as a reference for computing % changed.  The way
   * this works is probably going to change at some point.
   */
  baselineOptionId: string | null
}

/**
 * A comprehensive decision view.  This class pulls together all the parts of a decision
 * (participants, criteria, options, participation sessions, ratings, and rating notes)
 * to provide a deep & fluent view into a decision.
 *
 * Note that there are challenges with mutable decision; the patterns and API surrounding
 * synchronizing an in-memory Decision class instance and changes made to its constituent
 * components is not well established yet.  The safe (but expensive) option is to simply
 * re-instantiate the Decision instance if any of its descendant data changes.
 */
export class Decision {

  readonly criteria: Criteria
  readonly options: Options
  readonly participationSessions: ParticipationSession[]
  readonly valueFunctionExpr: Expression
  readonly participantsById: Record<string, Participant>

  constructor(
    readonly data: DecisionData,
    readonly participants: Participant[],
    readonly criteriaData: CriterionData[],
    readonly optionData: OptionData[],
    readonly participationSessionData: ParticipationSessionData[],
    readonly ratings: Rating[],
    readonly ratingNotes: RatingNotes[],
  ) {
    this.participationSessions = participationSessionData.map(data =>
      new ParticipationSession(
        data,
        participants,
        ratings.filter(r => r && r.participationSessionId === data.id),
        ratingNotes.filter(r => r && r.participationSessionId === data.id),
      )
    )

    this.criteria = new Criteria(criteriaData)
    this.criteria.useParticipationSession(
      this.getParticipationSession('CriteriaPrioritization'),
      data.ratingsToPrioritizationAlgorithm
    )

    this.valueFunctionExpr = data.valueFunctionJson
      ? JSON.parse(data.valueFunctionJson)
      : ValueFunctionLibrary.Standard
    this.options = new Options(
      optionData,
      this.criteria,
      this.getParticipationSession('OptionRating'),
      this.valueFunctionExpr
    )
    try {
      this.options.useOutcomeProbPs(this.getParticipationSession('OutcomeProbability'))
    } catch (err) {
      // do nothing; not necessary to have an outcome prob session (currently)
    }
    if (data.baselineOptionId) {
      this.options.baseline = this.options.all.find(option => option.id === data.baselineOptionId)
    }
    this.participantsById = _keyBy(this.participants, 'id')
  }

  get id() { return this.data.id }

  get name() { return this.data.name }

  get objective() { return this.data.objective }

  get type(): DecisionType { return this.data.type || 'VM' }

  get version(): DecisionVersion { return this.data.version || '2.0.0' }

  /**
   * Extract the cost/time prioritization from intrinsic criteria and update the value
   * formula to use them (if they've been prioritized).
   *
   * TODO: increasingly this is becoming a hot fucking mess...the way we identify cost/time,
   * and what to do if either or both aren't used....
   */
  updateValueFormulaFromIntrinsicPri() {
    const cost = this.criteria.all.find(c => c.name === 'Cost')
    const time = this.criteria.all.find(c => c.name === 'Time')
    if (cost && time && cost.pri.local !== null && time.pri.local !== null) {
      let valueFormula = this.data.valueFunctionJson
        ? JSON.parse(this.data.valueFunctionJson)
        : ValueFunctionLibrary.Standard
      // note that we have to check to see if the value formula even USES both...only make adjustments if it does
      const coefficients = extractCoefficients(valueFormula)
      if (typeof coefficients.cost === 'number' && typeof coefficients.time === 'number') {
        valueFormula = updateCoefficients(valueFormula, {
          cost: cost.pri.local,
          time: time.pri.local,
        })
        this.data.valueFunctionJson = JSON.stringify(valueFormula)
        this.options.setValueFormula(valueFormula)
      }
    }
    return this
  }

  /**
   * Creates a clone of this decision, with two changes:
   *
   *  - All criteria prioritization ratings replaced with aggregated ratings (with participant ID '*');
   *    as if a single participant had done criteria prioritization.
   *  - No rating notes.
   */
  cloneForSensitivityAnalysis() {
    const ratings = [
      ...this.participationSessions.filter(ps => ps.type !== 'CriteriaPrioritization').flatMap(ps => ps.ratings),
      ...this.criteria.contextCriteria.flatMap(cc => cc.getAggregateChildRatings()),
    ]
    const d = new Decision(
      this.data,
      this.participants,
      this.criteriaData,
      this.optionData,
      this.participationSessionData,
      ratings,
      [],
    ).updateValueFormulaFromIntrinsicPri()
    return d
  }

  /**
   * Creates a clone of this decision, replacing criteria ratings (as in sensitivity analysis).
   *
   * Note that the newly provided ratings must match the existing criteria prioritization participation
   * session.
   *
   * This will also update the cost/time prioritization in the value formula if cost/time
   * were prioritized in the intrinsic criteria.
   */
  cloneWithNewCriteriaRatings(criteriaRatings: ValidRating[]) {
    const cPs = this.getParticipationSession('CriteriaPrioritization')
    if (criteriaRatings.some(r => r.participationSessionId !== cPs.id)) {
      throw new Error(`provided criteria ratings must be associated with participation session ${cPs.id}`)
    }
    const ratings = [
      ...this.participationSessions.filter(ps => ps.type !== 'CriteriaPrioritization').flatMap(ps => ps.ratings),
      ...criteriaRatings,
    ]
    return new Decision(
      this.data,
      this.participants,
      this.criteriaData,
      this.optionData,
      this.participationSessionData,
      ratings,
      [],
    ).updateValueFormulaFromIntrinsicPri()
  }

  /**
   * Convenience function to get participation session of a given type.  This anticipates
   * a future where we may have multiple participation sessions; we can track down anything
   * that uses this function and modify it accordingly.
   */
  getParticipationSession(type: ParticipationSessionType) {
    const sessions = this.participationSessions.filter(ps => ps.type === type)
    if (sessions.length === 0) throw new Error(`missing prioritization session: "${type}"`)
    if (sessions.length > 1) throw new Error(`multiple prioritization sessions found of type "${type}"`)
    return sessions[0]
  }

  /**
   * For a given participation session type and participant, get all the relevant rating
   * contexts.  The rating context includes all necessary information to render the rating
   * interface for the participant.
   */
  getRatingContexts(type: ParticipationSessionType, participantId: string): RatingContext[] {
    switch (type) {
      case 'CriteriaPrioritization': {
        const ps = this.getParticipationSession('CriteriaPrioritization')
        return this.criteria.contextCriteria
          // if "includeIntrinsic" is false, we filter out intrinsic (quantitative) context criteria
          .filter(cc => ps.includeIntrinsic || cc.type !== 'IntrinsicRoot')
          // rating criteria in a context requires at least 2 child criteria; if you only have one,
          // no matter where you place it, it will have a priority of 1
          .filter(cc => cc.children.length > 1)
          .map(cc => {
            // normally we could get ratings from cc.ratings, but that only
            // yields readonly valid ratings; we may want to rethink when and
            // how we provide rating data, but for now we just get them from
            // the participation session
            const subjects = cc.children.map(c => c.data)
            const ratingBySubjectId = Object.fromEntries(
              subjects.map(s => {
                const ratings = _filter(ps.ratings, { contextId: cc.id, subjectId: s.id, participantId })
                if (ratings.length > 1) {
                  throw new Error(`found multiple "${type}" ratings for participant ${participantId}, ` +
                    `context ${cc.id}, and subject ${s.id}`)
                }
                return [s.id, ratings[0]]
              }).filter(([, r]) => !!r)
            )

            return {
              contextCriterion: cc.data,
              subjects,
              ratingBySubjectId,
              ratingNotes: _find(ps.ratingNotes, { contextId: cc.id, subjectType: 'Criterion', participantId }),
              ratingScaleConfig: defaultCriteriaPrioritizationScaleConfig,
              remainingCount: subjects.filter(subject => !ratingBySubjectId[subject.id]?.ratingVector).length,
            }
          })
      }
      case 'OptionRating': {
        const ps = this.getParticipationSession('OptionRating')
        return this.criteria.leafCriteria
          // options aren't rated against intrinsic attributes (that's the whole point!)
          .filter(cc => cc.type !== 'Intrinsic')
          .map(cc => {
            const subjects = this.options.all
              .map(o => o.outcomes.all.length > 0
                ? o.outcomes.all.map(oo => ({
                  ...oo.outcomeData,
                  // this is a cheesy re-mapping of outcome names/abbrevs to include the option name...
                  // we need a better system!
                  name: `${o.name} > ${oo.name}`,
                  abbrev: `${o.abbrev}\n(${oo.abbrev})`,
                  __typename: 'OptionOutcome',
                }))
                : {
                  ...o.data,
                  __typename: 'Option',
                }
              )
              .flat()
            const ratingBySubjectId = Object.fromEntries( // currently no other options
              subjects.map(s => {
                const ratings = _filter(ps.ratings, { contextId: cc.id, subjectId: s.id, participantId })
                if (ratings.length > 1) {
                  throw new Error(`found multiple "${type}" ratings for participant ${participantId}, ` +
                    `context ${cc.id}, and subject ${s.id}`)
                }
                return [s.id, ratings[0]]
              }).filter(([, r]) => !!r)
            )

            return {
              contextCriterion: cc.data,
              subjects,
              ratingBySubjectId,
              ratingNotes: _find(ps.ratingNotes, { contextId: cc.id, subjectType: 'Option', participantId }),
              ratingScaleConfig: cc.optionRatingScaleConfig,
              remainingCount: subjects.filter(subject => !ratingBySubjectId[subject.id]?.ratingVector).length,
            }
          })
      }
      case 'OutcomeProbability': {
        const ps = this.getParticipationSession('OutcomeProbability')
        return this.options.all.filter(o => o.outcomes.all.length > 1).map(o => {
          const subjects = o.outcomes.all.map(oo => oo.outcomeData)
          const ratingBySubjectId = Object.fromEntries( // currently no other options
            subjects.map(s => {
              const ratings = _filter(ps.ratings, { contextId: o.id, subjectId: s.id, participantId })
              if (ratings.length > 1) {
                throw new Error(`found multiple "${type}" ratings for participant ${participantId}, ` +
                  `context ${o.id}, and subject ${s.id}`)
              }
              return [s.id, ratings[0]]
            }).filter(([, r]) => !!r)
          )

          return {
            contextCriterion: o.data,
            subjects,
            ratingBySubjectId,
            ratingNotes: _find(ps.ratingNotes, { contextId: o.id, subjectType: 'OptionOutcome', participantId }),
            ratingScaleConfig: defaultOptionProbabilityScaleConfig,
            remainingCount: subjects.filter(subject => !ratingBySubjectId[subject.id]?.ratingVector).length,
          }
        })
      }
    }
  }

}
