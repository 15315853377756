import _cloneDeep from 'lodash/cloneDeep'
import _upperFirst from 'lodash/upperFirst'
import { Tag } from 'antd'

// Recursive function to filter out the non-checked columns for configurable tables
const filterTableColumns = (_columns, _config) =>
  _columns.reduce((_filteredColumns, column) => {
    const _column = _cloneDeep(column)
    const { children, fixed, key } = _column
    if (children) {
      const filteredChildren = filterTableColumns(children, _config)
      if (filteredChildren.length) _column.children = filteredChildren
      else delete _column.children
    }
    if (fixed || _config.checkedColumns?.includes(key) || _column.children) {
      _filteredColumns.push(_column)
    }
    return _filteredColumns
  }, [])

/**
 * Take a dataIndex for a table column and return a space separated and upperFirst mapping.
 * EX: impact.cost.qual -> Impact Cost Qual
 *
 * @param {String} k - the key to process
 *
 * @returns new key.
 */
const propertyPathToTitle = k => k.split('.').map(_upperFirst).join(' ')

// TODO: see https://vms.atlassian.net/browse/VP3-1947
/**
 * Build the tag for the active filters in the table.
 *
 * @param {String} k - Key for the tag
 * @param {Object} v - Value for the tag as an Array
 */
const buildFilterTag = (k, v, { closeable = true, onClose = () => {}, color }) => {
  const display = `(${v.join(', ')}) ${propertyPathToTitle(k.replace(/^managed\./, ''))}`
  return (
    <Tag closable={closeable} onClose={() => onClose(k)} color={color} key={k}>
      {display}
    </Tag>
  )
}

export { buildFilterTag, filterTableColumns, propertyPathToTitle }
