import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Html } from '@vms/vmspro3-core/dist/types/html'
import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { match } from 'ts-pattern'

interface DynamicHtmlProps {
  value: Html
}
function DynamicHtml(props: DynamicHtmlProps) {
  const html = props.value
  if (!html.value) return <div></div>
  const __html = html.value.replace(/\n/g, '<br />') // cheap since we don't have WYSIWYG editors set up :(
  return <div dangerouslySetInnerHTML={{ __html }}></div>
}

function NoCriterionSelected() {
  return (
    <div>
      <h2>No Criterion Selected</h2>
      <p>Please select a criterion.</p>
    </div>
  )
}

function EmptyDescription() {
  return <>This criterion doesn't have a description; click "Edit" above to add one!</>
}

function isHtmlEmpty(html: Html) {
  if (!html.value) return true
  if (html.value.replace(/<.*?>/g, '').trim() === '') return true
  return false
}

interface CriterionOverviewProps {
  criterion?: Criterion
  onEdit: () => void
}
export function CriterionOverview(props: CriterionOverviewProps) {
  const { criterion, onEdit } = props
  if (!criterion) return <NoCriterionSelected />
  const name = match(criterion.type)
    .with('Value', () => 'All Criteria') // this shouldn't actually happen, but just in case
    .with('Performance', () => criterion.name) // no abbrev with subjective root
    .with('IntrinsicRoot', () => criterion.name) // or objective root
    .otherwise(() => criterion.nameWithAbbrev)
  return (
    <div data-tour-id="Criterion Overview">
      <Row justify="space-between">
        <Col>
          <h2>{name}</h2>
        </Col>
        <Col>
          <Button data-tour-id="Edit Criterion Button" onClick={onEdit} icon={<EditOutlined />}>
            Edit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {isHtmlEmpty(criterion.description) ? (
            <EmptyDescription />
          ) : (
            <DynamicHtml value={criterion.description} />
          )}
        </Col>
      </Row>
    </div>
  )
}
