import React, { useCallback } from 'react'
import { Button, Col, Form, Modal, Row } from 'antd'

import { useHideModal } from '../RiskModalContext'

/**
 * FormModal
 *
 * This component has been changed very slightly to better support
 * CTRL+Enter functionality. Ant Design's Form onFinish method that runs when
 * a form is submitted only passes the values of the fields, and unfortunately
 * does not pass the event to the handler, so we will have to continue to look
 * for the keydown event with a custom event handler. When a <form> component
 * is rendered, the form "submit" event is caught by both <form> and our custom
 * onKeyDown handler. This causes required fields to fail validation after our
 * onKeyDown handler resets the form fields, and triggers the onFinishFailed
 * event handler. Instead of rendering the default <form> component, it now
 * renders a <div> component which allows us to attach our custom handler and
 * catch the event and handle processing the form data using the form instance
 * methods provided by Ant Design.
 *
 * @prop {ReactNode} [title] - Optional title for modal.
 * @prop {boolean} [allowReuse] - Allows reuse of modal without closing by pressing "Ctrl + Enter". Shows a message
 *    to advise users of this functionality.
 * @prop {object} [cancelButtonProps] - props for "cancel" button (see https://ant.design/components/modal)
 * @prop {string} [cancelText] - Used in place of "Cancel" in cancel button.
 * @prop {boolean} [closeable = true] - Shows "X" in the top-right of modal to close.
 * @prop {boolean} [closeDialog = true] - Closes dialog after OK button if onProcess/onOk is not aborted.
 * @prop {boolean} [confirmLoading] - Shows a loading spinner in the OK button, useful for asynchronous actions.
 * @prop {Array.<string|ReactNode>|null} [footer] - Custom modal footer. Set to null when you don't need default
 *    OK/Cancel controls.
 * @prop {FormInstance} [formInstance] - Optional form instance, useful if form instance already exists or if
 *    child component needs to control the form.
 * @prop {boolean} [hideRequirementMark=false] - If true, hides the red asterisk on form labels for
 *    required fields.
 * @prop {Object} [initialValues] - Initial form values, basic map of key/value pairs.
 * @prop {boolean} [maskClosable = true] - Whether clicking outside the modal will trigger onCancel.
 * @prop {string} modalId - ID of modal to display, comes from a static property "id" of component that
 *    is rendering FormModal.
 * @prop {boolean} [next] - Enable "Next" control, control only shown if "onNext" and "onPrev" exist.
 * @prop {Object} [okButtonProps] - Extra properties for "ok" button (see https://ant.design/components/modal)
 * @prop {string} [okText] - Used in place of "OK" in OK button.
 * @prop {Function} [onCancel] - onClick callback for Cancel button.
 * @prop {Function} [onNext] - onClick callback for "Next >" control, shown if both "onPrev" and "onNext" exist.
 * @prop {Function} [onOk] - Used to process dialog form contents.
 *    If you wish to abort the normal resetting and closing process without notifying the user, return an object
 *      with the key "abort" that has a truthy value: { abort: true }.
 *    If you wish to use custom form validation or show errors on a particular field, return an array of form
 *      field error objects: { errors: [{ key: 'fieldKey', message: 'There is an error' }] }.
 *    If you wish to call a function when the dialog closes, return an object with a nextAction
 *      function: { nextAction: () => someFunction(args) }.
 * @prop {Function} [onPrev] - onClick callback for "< Prev" control, shown if both "onPrev" and "onNext" exist.
 * @prop {Function} [onProcess] - Same functionality as props.onOk, usually used for modal dialogs that you want to
 *    process without closing (such as when using the "Ctrl-Enter" functionality).
 * @prop {boolean} [prev] - Enable "Prev" control, control only shown if "onNext" and "onPrev" exist.
 * @prop {number} [width = 520] - width of modal in px.
 */

/**
 *
 * @param {*} - TODO: JSDoc provides type information to TypeScript, making it difficult to use FormModal
 *   in TSX components...
 * @returns
 */
const FormModal = ({
  title,
  allowReuse,
  cancelButtonProps,
  cancelText,
  children,
  closable,
  closeDialog,
  confirmLoading,
  footer,
  formInstance,
  hideRequiredMark,
  initialValues,
  maskClosable,
  modalId,
  next,
  okButtonProps,
  okText,
  onCancel,
  onNext,
  onOk,
  onPrev,
  onProcess,
  prev,
  width,
}) => {
  const [form] = Form.useForm(formInstance)

  const mapErrorsToFields = useCallback(
    errors =>
      form.setFields(
        errors.map(({ field, message }) => ({
          errors: [message],
          name: [field],
        }))
      ),
    [form]
  )

  const hideModal = useHideModal()

  const handleProcess = useCallback(
    async _closeDialog => {
      try {
        const fieldValues = await form.validateFields()

        const handler = onProcess || onOk || (() => null)
        await Promise.resolve(handler(fieldValues)).then(response => {
          const { abort, errors, nextAction } = response || {}
          if (abort === true) return
          if (errors && errors.length) return mapErrorsToFields(errors)

          form.resetFields()
          if (_closeDialog) hideModal(modalId)
          if (nextAction) nextAction()
        })
      } catch (err) {
        console.error('Form Validation Error: ', err)
      }
    },
    [form, onProcess, onOk, mapErrorsToFields, modalId, hideModal]
  )

  const handleKeyDown = evt => {
    if (evt.key !== 'Enter' || evt.altKey || evt.shiftKey) return
    if (allowReuse && evt.ctrlKey) {
      evt.stopPropagation()
      return handleProcess(false)
    }
    if (evt.target instanceof HTMLInputElement) {
      evt.stopPropagation()
      return handleProcess(true)
    }
  }

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel()
    hideModal(modalId)
  }, [hideModal, modalId, onCancel])

  return (
    <Modal
      open
      title={title}
      cancelButtonProps={cancelButtonProps}
      cancelText={cancelText}
      closable={closable}
      confirmLoading={confirmLoading}
      footer={footer}
      maskClosable={maskClosable}
      okButtonProps={okButtonProps}
      okText={okText}
      onCancel={handleCancel}
      onOk={() => handleProcess(closeDialog)}
      width={width}
    >
      <Form
        component="div"
        form={form}
        requiredMark={!hideRequiredMark}
        initialValues={initialValues}
        layout="vertical"
        onKeyDown={handleKeyDown}
        validateTrigger={['onChange', 'onBlur']}
      >
        {/* TODO --
          There are two modals that needs the form argument ("AttachmentAddModal", "TemplateAddModal")
          which are just passing the form instance to "FileDrop.js". It's a poor use of the form instance,
          and we should consider a refactor of FileDrop.js, which will allow us to remove the form
          argument completely. For now it is moved to being the last argument.
        */}
        {typeof children === 'function' ? children(undefined, handleProcess, form) : children}
      </Form>
      {allowReuse && (
        <div>
          <i>Tip: CTRL+Enter to save and enter another</i>
        </div>
      )}
      {onPrev && onNext && (
        <Row>
          <Col span={2}>
            <Button
              disabled={!prev}
              onClick={() => {
                handleProcess(closeDialog)
                onPrev()
              }}
            >
              Previous
            </Button>
          </Col>
          <Col span={2}>
            <Button
              disabled={!next}
              onClick={() => {
                handleProcess(closeDialog)
                onNext()
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  )
}
FormModal.defaultProps = {
  closeDialog: true,
  hideRequiredMark: false,
  maskClosable: false,
  width: 520,
}

export default FormModal
