import React from 'react'
/** @jsxImportSource @emotion/react */
import { Typography } from 'antd'

import { button } from '../Controls/controlStyles'
import Legend from './Legend'

import printMagnitude from '../../utils/printMagnitude'

function printStat(statSelections) {
  const statName = []
  for (let index = statSelections.length - 1; index >= 0; index--) {
    // it's worth running the for from end to start since the
    // more specific range filters come later
    statName.push(statSelections[index].label.long)
  }
  return statName.join(' ')
}

function writeSummary(articleLabel, displayRange, statSelections, selectionsHowToFilter, howToGroupLabel) {
  let prefix = []
  let suffix = ' by'
  let rangeSet = false

  for (let index = selectionsHowToFilter.length - 1; index >= 0; index--) {
    // it's worth running the for from end to start since the
    // more specific range filters come later
    const filter = selectionsHowToFilter[index]
    if (!filter) continue
    if (!rangeSet && typeof filter.min === 'number') {
      suffix = printMagnitude({
        magnitude: [filter.min, filter.max],
        _displayFactor: statSelections[0].displayFactor,
        _displayRange: displayRange,
        _setting: 'fromto',
      })
      rangeSet = true
    }
    if (filter.adj) {
      let order = filter.order + 10
      let destination = prefix[order]
      while (destination) {
        order--
        destination = prefix[order]
      }
      prefix[order] = filter.adj + ' '
    }
  }
  prefix = prefix.length !== 0 ? prefix.join('') : suffix === ' by' ? 'All' : ''

  const groupedHow = statSelections[0].label.long === howToGroupLabel ? printStat(statSelections) : howToGroupLabel

  return `${prefix} ${articleLabel.plural} ${suffix} ${groupedHow}`
}

export default function FlexChartTitle({
  articleLabel,
  changeHowToFilterIdcs,
  displayRange,
  statSelections,
  selectionsHowToFilter,
  selectionsHowToGroup,
}) {
  const title = writeSummary(
    articleLabel,
    displayRange,
    statSelections,
    selectionsHowToFilter,
    selectionsHowToGroup[selectionsHowToGroup.length - 1].label.long
  )

  const back =
    selectionsHowToFilter.length > 1
      ? writeSummary(
          articleLabel,
          displayRange,
          statSelections,
          selectionsHowToFilter.slice(0, -2),
          selectionsHowToGroup[selectionsHowToGroup.length - 2].label.long
        )
      : ''

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div css={{ flexGrow: 1 }}>
        <div
          css={{
            background: 'none',
            display: 'block',
            position: 'relative',
            alignSelf: 'left',
            width: '50%',
          }}
        >
          <Typography.Title style={{ marginBottom: '0px' }} level={2}>
            {title}
          </Typography.Title>
        </div>
        <div
          css={{
            postion: 'relative',
            flexGrow: '0',
            height: '33px',
            marginBottom: '5px',
            fontSize: '8px',
          }}
        >
          <h2
            onClick={() => changeHowToFilterIdcs(0, null)}
            css={{
              ...button,
              display: selectionsHowToFilter.length > 1 ? 'inline-flex' : 'none',
              padding: '4px',
            }}
          >
            ← {back}
          </h2>
        </div>
      </div>
      <div>
        <Legend displayFactor={statSelections[0].displayFactor} />
      </div>
    </div>
  )
}
