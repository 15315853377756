import { ParticipationSessionType } from '@vms/vmspro3-core/dist/types'

import { SelectOption } from '../types'

export const ASSUMED_USER_ID_KEY = 'vmspro-assumed-user-id'
export const LOCAL_STORAGE_ACCOUNT_ID_KEY = 'vmspro-account-id'

export const SERVICE_DESK_URL = 'https://vms.atlassian.net/servicedesk/customer/portal/3'

export const colorSelectOptions: SelectOption[] = [
  { value: '#97968e', label: 'Sand' },
  { value: '#85ca43', label: 'Pear' },
  { value: '#d280e1', label: 'Orchid' },
  { value: '#59cd8b', label: 'Jade' },
  { value: '#f66b84', label: 'Salmon' },
  { value: '#5aedf6', label: 'Aqua' },
  { value: '#ed8847', label: 'Carrot' },
  { value: '#45a5ef', label: 'Cornflower' },
  { value: '#dcb94d', label: 'Dandelion' },
  { value: '#3cb7ce', label: 'Turquoise' },
  { value: '#e7b6da', label: 'Carnation' },
  { value: '#dedfd6', label: 'Cloud' },
]

export const LoadingStatus = {
  NotLoaded: 'NotLoaded',
  Requested: 'Requested',
  Loaded: 'Loaded',
  NotFound: 'NotFound',
}

export const ParticipationSessionLabel: Record<ParticipationSessionType, string> = {
  OptionRating: 'Option Rating',
  CriteriaPrioritization: 'Criteria Prioritization',
  OutcomeProbability: 'Outcome Probability',
}

export const API_NAME = 'vmspro'
