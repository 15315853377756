import asSet from 'arraysetjs'
import { produce } from 'immer'

import { AccountPolicy_App, GlobalPolicy_App } from '@vms/vmspro3-core/dist/types'
import { CreatePolicyAction, UpdatePolicyAction } from '@vms/vmspro3-core/dist/actions/policy'

import { LoadingStatus } from '../../types'
import {
  FetchPoliciesRequestAction,
  FetchPoliciesSuccessAction,
  LoadResourcePoliciesAction,
  ResetAccountStateAction,
  SignOutAction,
} from '../actions'

interface PoliciesState {
  byId: Record<string, GlobalPolicy_App>
  ids: string[]
  loadingStatus: LoadingStatus
  byResourceId: Record<string, AccountPolicy_App[]>
}

type PoliciesReducerAction =
  | SignOutAction
  | ResetAccountStateAction
  | FetchPoliciesRequestAction
  | FetchPoliciesSuccessAction
  | LoadResourcePoliciesAction
  | CreatePolicyAction
  | UpdatePolicyAction

const initialState: PoliciesState = {
  byId: {},
  ids: [],
  loadingStatus: 'NotLoaded',
  byResourceId: {},
}

export const policiesReducer = produce<PoliciesState, [PoliciesReducerAction?]>((state, action) => {
  if (!action) return

  switch (action.type) {
    case 'SignOut': {
      return initialState
    }
    case 'ResetAccountState': {
      state.byResourceId = initialState.byResourceId
      break
    }
    case 'FetchPoliciesRequest': {
      state.loadingStatus = 'Requested'
      break
    }
    case 'FetchPoliciesSuccess': {
      action.payload.policies.forEach(policy => {
        state.byId[policy.id] = policy
        state.ids.push(policy.id)
      })
      state.loadingStatus = 'Loaded'
      break
    }
    case 'LoadResourcePolicies': {
      Object.assign(state.byResourceId, action.payload)
      break
    }

    case 'Create Policy': {
      if (action.payload.policyType === 'RESOURCE') {
        const { policyId, resourceId, ...restPolicy } = action.payload

        if (!state.byResourceId[resourceId]) state.byResourceId[resourceId] = []

        state.byResourceId[resourceId].push({
          ...restPolicy,
          resourceId,
          id: policyId,
        })
      } else {
        const { policyId, ...restPolicy } = action.payload

        state.byId[policyId] = {
          ...restPolicy,
          id: policyId,
        }
        asSet(state.ids).add(policyId)
      }
      break
    }
    case 'Update Policy': {
      if (action.meta.policyType === 'RESOURCE') {
        const { resourceId, policyId } = action.meta

        if (state.byResourceId[resourceId]) {
          const policyIdx = state.byResourceId[resourceId].findIndex(policy => policy.id === policyId)

          if (policyIdx > -1) {
            state.byResourceId[resourceId][policyIdx] = {
              ...state.byResourceId[resourceId][policyIdx],
              ...action.payload,
            }
          }
        }
      }
      break
    }

    default: {
      break
    }
  }
}, initialState)
