import { ValidRating } from '../../types/rating'

import _sumBy from 'lodash/sumBy'
import _minBy from 'lodash/minBy'
import _maxBy from 'lodash/maxBy'
import _keyBy from 'lodash/keyBy'
import _groupBy from 'lodash/groupBy'
import _mean from 'lodash/mean'
import { NumberWithStats } from '../../stats/quartiles'

type ObjectWithId = { id: string }

export type RatingsToPrioritizationFunction =
  (subjects: ObjectWithId[], ratings: ValidRating[]) => Record<string, number>

export type PriorityRatingsAggregationFunction =
  (subjects: ObjectWithId[], ratings: ValidRating[]) => Record<string, number>

export type RatingToPrioritizationAlgorithm = 'Normalize' | 'RecenterAndNormalize'

export function recenterRatings(contextRatings: ValidRating[]) {
  const rs = contextRatings
    .map(({ subjectId, ratingVector }) => ({ subjectId, r: ratingVector[0] ?? 0 }))
  const minR = _minBy(rs, 'r')?.r ?? 0
  const maxR = _maxBy(rs, 'r')?.r ?? 0
  const d = maxR - minR  // distance between min and max rating
  const shift =  -(minR + d / 2) + 5
  const recentered = contextRatings.map(r => ({
    ...r,
    ratingVector: [r.ratingVector[0] + shift, ...r.ratingVector.slice(1)],
  }))
  return recentered
}

function Normalize(subjects: ObjectWithId[], ratings: ValidRating[]) {
  const sum = _sumBy(ratings, 'ratingVector.0') ?? 0
  if(sum === 0) return Object.fromEntries(subjects.map(c => [c.id, 0])) // edge case...everything rated 0
  const ratingsByCri = _keyBy(ratings, 'subjectId')
  return Object.fromEntries(subjects.map(c => {
    // here we assume that no ratings equate a priority of 0
    const r = ratingsByCri[c.id]?.ratingVector?.[0] ?? 0
    return [c.id, r / sum]
  }))
}

export const RatingsToPrioritizationAlgorithms:
  Record<RatingToPrioritizationAlgorithm, PriorityRatingsAggregationFunction> = {

  /**
   * Given criteria and a collection of ratings from a single actor (i.e. no duplicate ratings
   * for each criterion), maps context criteria to a numeric priority using the "Normalize" algorithm.
   * The Normalize algorithm simply sets the priority to the rating divided by the sum of all ratings.
   * Note that the Normalize algorithm produces a distortion based on where the participant's ratings
   * are clustered on the canvas.  That is, a participant who uses the top part of the canvas will
   * produce different priorities than a participant who uses the bottom part, even if the items
   * are positioned the same relative to one another.
   */
  Normalize,

  /**
   * Given criteria and a collection of ratings from a single participant (i.e. no duplicate ratings
   * for each criterion), maps context criteria to a numeric priority using the "Recenter and Normalize"
   * algorithm.  The Recenter and Normalize algorithm first "recenters" all ratings so that the
   * maximum rating is the same distance from 10 as the minimum rating is from 0.  This ensures
   * that participants who use the top part of the rating canvas get the same priorities as actors who
   * use the bottom half.  Each priority is then simply the rating divided by the sum of all ratings.
   */
  RecenterAndNormalize: (contextCriteria, ratings) => {
    return Normalize(contextCriteria, recenterRatings(ratings))
  },

}

export interface ContextPrioritization {
  [contextCriterionId: string]: {
    criterionId: string
    aggregate: number | null
    byParticipantId: Record<string, number>
  }
  // TODO: missing rating info; any criterion w/out ratings will result in a prioritization of 0
}

// TODO: this needs tests
export function prioritizeContext(
  /**
   * Subjects to prioritize.  It is assumed that these subjects are all from the same context;
   * it is up to the caller to ensure this, as this function only knows that these are objects with IDs.
   */
  subjects: ObjectWithId[],
  /**
   * Valid ratings for this context.  Note that this must be pre-filtered to only the relevant
   * ratings (i.e. they should all have the same contextType, contextId, and subjectType; note
   * note that this does NOT sort by update date, so it is currently not safe for use across
   * participation sessions; see ParticipationSet for that functionality).
   *
   * // TODO: increasingly, I think this functionality should be moved into ParticipationSet.
   */
  contextRatings: ValidRating[],
  ratingsToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm
): ContextPrioritization {
  const byCriByPart: Record<string, Record<string, number>> = {}
  const ratingsToPri = RatingsToPrioritizationAlgorithms[ratingsToPrioritizationAlgorithm]
  Object.entries(_groupBy(contextRatings, 'participantId')).forEach(([participantId, ratings]) => {
    const pris = ratingsToPri(subjects, ratings)
    Object.entries(pris).forEach(([criterionId, pri]) => {
      const byPart = byCriByPart[criterionId] || {}
      byPart[participantId] = pri
      byCriByPart[criterionId] = byPart
    })
  })
  const byCri: ContextPrioritization = {}
  subjects.forEach(c => {
    const byParticipantId = byCriByPart[c.id] || {}
    const ratings = Object.values(byParticipantId)
    // here is where we can detect missing ratings; ratings.length === 0 indicates no one rated
    // this criterion
    const aggregate = ratings.length === 0 ? null : _mean(ratings)
    byCri[c.id] = {
      criterionId: c.id,
      aggregate,
      byParticipantId,
    }
  })
  return byCri
}
