import { OptionOutcomeData } from '@vms/vmspro3-core/dist/nextgen/options'
import { Card } from 'antd'

interface OptionOutcomeCardProps {
  outcome: OptionOutcomeData
}
export function OptionOutcomeCard(props: OptionOutcomeCardProps) {
  const { outcome } = props
  const __html = outcome.description?.value ?? ''
  return (
    <Card>
      <h3>{outcome.name}</h3>
      {__html ? (
        <p dangerouslySetInnerHTML={{ __html }} />
      ) : (
        <p>
          <i>Select outcome in the navigator on the left, and edit the outcome to add a description.</i>
        </p>
      )}
    </Card>
  )
}
