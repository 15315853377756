import React from 'react'
/** @jsxImportSource @emotion/react */
import Dropdown from '../Structure/Controls/Dropdown'
import Toggle from '../Structure/Controls/Toggle'

// note the commented out param keys are for the initial dropdown, which is under construction
// see comments below.
export default function ControlBar({
  articleConfig,
  changeHowToGroupIdcs,
  changeStatSelectionIdcs,
  hasRelativeScale,
  indices,
  optionsHowToGroup,
  toggleRelativeScale,
  statSelections,
}) {
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
      }}
    >
      <div
        css={{
          position: 'absolute',
          bottom: '-45px',
          width: '100%',
          background: 'white',
          minHeight: '45px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingTop: '5px',
          '> *': {
            position: 'relative',
            alignItems: 'center',
            display: 'inline-flex',
            flexWrap: 'wrap',
          },
        }}
      >
        <div
          css={{
            flexDirection: 'row',
            '> *': {
              marginRight: '5px',
            },
          }}
        >
          {/* NOTE: we are getting this re-enabled, but I thinkl further improvements will be needed */}
          <Dropdown
            onChange={e => changeHowToGroupIdcs(e, optionsHowToGroup.length - 1)}
            label="Group by"
            defaultValue={0}
            value={indices.group[indices.group.length - 1]}
            options={optionsHowToGroup[optionsHowToGroup.length - 1].map((groupingOption, idx) => {
              const option = {
                value: idx,
                label: groupingOption.label.long,
              }
              return option
            })}
          />
        </div>
        <div
          css={{
            flexDirection: 'row-reverse',
            '> *': {
              marginLeft: '5px',
            },
          }}
        >
          <Dropdown
            onChange={e => changeStatSelectionIdcs(e, 1)}
            label={statSelections[0].pivot.label}
            options={statSelections[0].pivot.options.map((pivotOption, idx) => ({
              value: idx,
              label: pivotOption.label.long,
            }))}
          />
          <Toggle
            id="relative-scaler"
            label={`Scale ${articleConfig.label.plural} by Severity`}
            checked={hasRelativeScale}
            onChange={toggleRelativeScale}
          />
        </div>
      </div>
    </div>
  )
}
