import { createSelector } from 'reselect'

import { RootState } from '../store'

export const selectAuthState = (state: RootState) => state.auth
export const selectAuthUserId = (state: RootState) => state.auth.userId
export const selectAuthLoadingStatus = (state: RootState) => state.auth.loadingStatus
export const selectAssumedAuthz = (state: RootState) => state.auth.assumedAuthz
export const selectAssumedRoleIds = (state: RootState) => {
  if (state.auth.assumedAuthz?.type === 'ASSUME_ROLE') {
    return state.auth.assumedAuthz.assumedRoleIds
  }
}

export const selectAssumedRolePolicies = createSelector(
  selectAssumedRoleIds,
  (state: RootState) => state.policies.byId,
  (assumedRoleIds, policiesById) => assumedRoleIds?.map(policyId => policiesById[policyId])
)
