import { ASSUMED_USER_ID_KEY } from '../../utils/appConsts'
import { AssumedAuthz } from '../../utils/authzUtils'
import { getAuth, signOut as authServiceSignOut } from '../../services/authService'
import { AsyncAppThunk } from '../store'

export interface SetAuthAction {
  type: 'SetAuth'
  payload: {
    userId?: string
    email?: string
    assumedAuthz?: AssumedAuthz
  }
  meta: { ephemeral: true }
}
export function setAuth(payload: SetAuthAction['payload']): SetAuthAction {
  return {
    type: 'SetAuth',
    payload,
    meta: { ephemeral: true },
  }
}

export interface FetchAuthRequestAction {
  type: 'FetchAuthRequest'
  meta: { ephemeral: true }
}
export function fetchAuth(): AsyncAppThunk {
  return async dispatch => {
    try {
      dispatch({
        type: 'FetchAuthRequest',
        meta: { ephemeral: true },
      })

      const { userId, email, assumedAuthz } = await getAuth()

      if (!userId) localStorage.removeItem(ASSUMED_USER_ID_KEY)

      dispatch(
        setAuth({
          userId,
          email,
          assumedAuthz,
        })
      )
    } catch (error) {
      console.error('fetchAuth error', error)
    }
  }
}

export interface SignOutAction {
  type: 'SignOut'
  meta: { ephemeral: true }
}
export function signOut(): AsyncAppThunk {
  return async dispatch => {
    try {
      await authServiceSignOut()
      localStorage.removeItem(ASSUMED_USER_ID_KEY)

      dispatch({
        type: 'SignOut',
        meta: { ephemeral: true },
      })
    } catch (error) {
      console.error('signOut error: ', error)
    }
  }
}
