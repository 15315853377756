import { ReactElement } from 'react'
import { Modal } from 'antd'

import { MissingPerformanceRatingInfo } from '@vms/vmspro3-core/dist/nextgen/valueGraph/performanceValueNode'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'

function UnratedCriteriaContents({ unratedCriteria }: { unratedCriteria: Criterion[] }) {
  return (
    <>
      <h3>Not all criteria have been prioritized.</h3>
      <p>
        Performance can only be calculated when all criteria have been prioritized. The following criteria are
        missing priorities:
      </p>
      <ul>
        {unratedCriteria.map(c => (
          <li key={c.id}>{c.label({ skipAncestors: 2 })}</li>
        ))}
      </ul>
    </>
  )
}

function MissingOptionRatingContents({
  optionName,
  missingRatings,
}: {
  optionName: string
  missingRatings: MissingPerformanceRatingInfo[]
}) {
  return (
    <>
      <h3>Option "{optionName}" is missing ratings.</h3>
      <p>
        Performance can only be calculated when options have been rated against all criteria. The following
        criteria are missing ratings for this option:
      </p>
      <ul>
        {missingRatings.map(mr => (
          <li key={mr.criterion.id}>{mr.criterion.label({ skipAncestors: 2 })}</li>
        ))}
      </ul>
    </>
  )
}

export interface ValuemetricsMissingRatingsInfoModalProps {
  decisionId: string
  optionName: string
  missingRatings: MissingPerformanceRatingInfo[]
  unratedCriteria: Criterion[]
  hideModal: VoidFunction
}
/**
 * Displays missing ratings for a single option.  Important note: this assumes that
 * the missingRatings prop contains only missing ratings for the same option, and there
 * is at least one missing rating.
 */
export function ValuemetricsMissingRatingsInfoModal({
  optionName,
  missingRatings,
  unratedCriteria,
  hideModal,
}: ValuemetricsMissingRatingsInfoModalProps): ReactElement {
  return (
    <Modal
      open
      title="Missing Ratings"
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={hideModal}
      onCancel={hideModal}
    >
      {unratedCriteria.length > 0 ? (
        <UnratedCriteriaContents unratedCriteria={unratedCriteria} />
      ) : (
        <MissingOptionRatingContents optionName={optionName} missingRatings={missingRatings} />
      )}
    </Modal>
  )
}
