import React, { useState, useCallback } from 'react'
/** @jsxImportSource @emotion/react */
import Group from './Group'
import Tooltip from './Tooltip/Tooltip'
import Title from './Title/Title'
import { COLOR } from '../_riskConfig/color'
// utils
import printMagnitude from '../utils/printMagnitude'
// data

/**
 * A sorted array of articles:risks (descending by severity) and associated metadata
 * @type articleGroup
 * @prop {Object[]} articles
 * @prop {string|number} key
 * @prop {string?} label
 */

/**
 * sorts a bunch of articles into the groups you want to see
 * @param {Object[]} articles - the articles that we're currently sorting
 * @param {string} groupBy - "category" "phase" "output"
 * @param {number|string[]} groupNameSet - the major vertical groups of risks along the x axis
 * @param {number?} severityFactor - score that risks are 'out of' -- could be 10, 100, or 'percent' (undefined)
 * @returns {articleGroup[]} - sorted in descending order by severity, if the grouping logic is severity
 */
function groupArticles(articles, displayRange, groupNameSet, selectionsHowToGroup, statSelections) {
  const howToGroup = selectionsHowToGroup[selectionsHowToGroup.length - 1]
  const articleGroups = []
  // console.log("howToGroup.path", howToGroup.path)
  for (let index = 0; index < groupNameSet.length; index++) {
    // change to map
    articleGroups[index] = {
      key: groupNameSet[index],
      label:
        howToGroup === statSelections[0]
          ? printMagnitude({
              magnitude:
                index === groupNameSet.length - 2
                  ? [groupNameSet[index], groupNameSet[index + 1]]
                  : [groupNameSet[index], undefined],
              _displayFactor: statSelections[0].displayFactor,
              _displayRange: displayRange,
            })
          : undefined,
      articles: [],
    }
  }
  // const sampler = [0]
  if (howToGroup === statSelections[0]) articleGroups.pop()
  for (let index = 0; index < articles.length; index++) {
    const article = articles[index]
    let determinantTrait = undefined
    let destination = undefined
    switch (howToGroup) {
      case statSelections[0]:
        for (let index = 0; index < groupNameSet.length - 1; index++) {
          if (article.groupAs === groupNameSet[groupNameSet.length - 1]) {
            const lastGroup = articleGroups[articleGroups.length - 1]
            lastGroup.articles.push(article)
            break
          }
          if (article.groupAs >= groupNameSet[index] && article.groupAs < groupNameSet[index + 1]) {
            articleGroups[index].articles.push(article)
            break
          }
        }
        break
      default:
        determinantTrait = article[howToGroup.path]
        destination = articleGroups.find(group => group.key === determinantTrait)?.articles
        destination && destination.push(article)
        break
    }
  }
  for (let index = 0; index < articleGroups.length; index++) {
    const articleGroup = articleGroups[index]
    articleGroup.articles.sort((a, b) => b.groupAs - a.groupAs)
  }
  // console.log("the", articleGroups.length, "article groups are", articleGroups)
  return articleGroups
}

/**
 * Display is the chart itself, the title, and the tooltip. Not the controls.
 * @param {Object[]} articles - The things we are comparing on the chart
 * @param {Object} articleConfig - Information about the objects. (see data/articleConfig.js)
 * What are the things called? ("Risks")
 * What numerical stats within them do we want to compare? ("Severity"... "Total, Cost, Time," etc.)
 * Aside from their numerical stats, what traits are they grouped by? ("Categories", "Phases")
 * How are they clustered? (a positive cluster and a negative cluster)
 * How do we render the stats? (out of a hundred)
 * @param {Object[]} statSelections - array of objects from the the array articleConfig > stats.
 * Each object gives the each part of the path to the stat that is currently being visualized
 * for comparison, as well as label information for that stat.
 * for example, the first stat might be 'severity'; the second might be 'perf'. Together they
 * give a path the the relevant statistical information within the articles.
 * @param {Object[]} selectionsHowToFilter
 * lists the filters currently applied to the display.
 * each filter is either...
 * a range specification for the current stat (e.g., 'min: .2, max: .3'), or
 * a trait option in the form of an adjective, and a number that specifies the grammatical order
 * of that adjective based on the original ordering of the traits in the config
 * (e.g., adj: 'Political', order: 1)
 * @param {Object[]} selectionsHowToGroup
 * lists possible ways to sort your articles into groups.
 * either the current stat (e.g., 'severity'), or one of your articles' traits (e.g., 'phase').
 * This is an array because it forms the basis for filtering.
 * You can filter to see only articles from one of the current groups.
 * Then, these articles are grouped.
 * @param {number|string[]} groupSet - markers for the major groups to be shown on the chart.
 * @param {boolean} hasRelativeScale
 * whether or not the articles are visually scaled based on their magnitude in the current statSelection
 * @param {number} scale
 * in pixels (measurement of area), how large can the largest article element be, when scaled?
 * @returns {JSX} - a chart that can...
 * visualize the relative magnitudes of the stats you choose to visualize from a dataset you provide
 * visualize the sheer numbers of articles within various groups
 * via tooltip, report the name and specific categorical/statistical data about any given article displayed
 * summarize the current view using a human-readable title
 */
export default function Display({
  accountCommonId,
  articles,
  articleConfig,
  changeHowToFilterIdcs,
  displayHeightPortion,
  displayRange,
  selectionsHowToFilter,
  selectionsHowToGroup,
  groupSet,
  hasRelativeScale,
  scale,
  statSelections,
  TooltipContent,
  windowDimensions,
  entityId,
}) {
  // state
  const [{ currentTooltip, previousTooltip }, setTooltips] = useState({})
  const [pinnedTooltip, setPinnedTooltip] = useState(undefined)

  const changeCurrentTooltip = article =>
    setTooltips(tooltips => ({
      currentTooltip: article,
      previousTooltip: tooltips.currentTooltip || article,
    }))
  const pinTooltip = useCallback(article => {
    setPinnedTooltip(article)
  }, [])
  // assembly
  const articleGroups = groupArticles(articles, displayRange, groupSet, selectionsHowToGroup, statSelections)

  return (
    <>
      <Tooltip
        accountCommonId={accountCommonId}
        articleConfig={articleConfig}
        current={currentTooltip}
        changeCurrentTooltip={changeCurrentTooltip}
        displayRange={displayRange}
        pinned={pinnedTooltip}
        previous={previousTooltip}
        statSelections={statSelections}
        pinTooltip={pinTooltip}
        windowDimensions={windowDimensions}
        TooltipContent={TooltipContent}
        entityId={entityId}
      />
      <Title
        articleLabel={articleConfig.label}
        changeHowToFilterIdcs={changeHowToFilterIdcs}
        displayRange={displayRange}
        statSelections={statSelections}
        selectionsHowToFilter={selectionsHowToFilter}
        selectionsHowToGroup={selectionsHowToGroup}
      />
      <div
        id="layout-wrap"
        css={{
          position: 'relative',
          height: `${displayHeightPortion * windowDimensions.height + 5}px`,
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: `${COLOR.GREY_3}11`,
        }}
      >
        <div
          id="layout"
          css={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            height: `${displayHeightPortion * windowDimensions.height + 70}px`,
          }}
        >
          <div
            id="risk-groups"
            css={{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              flexDirection: 'row',
              margin: 'auto',
              overflow: 'visible',
            }}
          >
            {articleGroups.map((articleGroup, idx) => (
              <Group
                key={articleGroup.key}
                articleGroup={articleGroup}
                changeCurrentTooltip={changeCurrentTooltip}
                changeHowToFilterIdcs={changeHowToFilterIdcs}
                currentTooltip={currentTooltip}
                displayHeightPortion={displayHeightPortion}
                displayRange={displayRange}
                groupIdx={idx}
                hasRelativeScale={hasRelativeScale}
                scale={scale}
                statSelections={statSelections}
                pinnedTooltip={pinnedTooltip}
                pinTooltip={pinTooltip}
                windowDimensions={windowDimensions}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
