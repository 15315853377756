import React, { useState } from 'react'
import { EntityType, ROOT_RISK_ENTITY_ANCESTRY } from '@vms/vmspro3-core/dist/systemConsts'
import { Form } from 'antd'

import { StringInput, CostInput_Risk, DurationInputWithUnits_Risk } from '../controls'

import Server from '../../../server/VMSProServerAdapter'

function RiskEntityIdentityFormFields({ entityId, entityType, entityTypeLabel, readOnly }) {
  const [validatingCommonId, setValidatingCommonId] = useState(false)
  async function validateCommonId(rule, value) {
    if (value?.trim()) {
      setValidatingCommonId(true)
      return Server.getItemsByAncestryBeginsWith(ROOT_RISK_ENTITY_ANCESTRY, EntityType.PROJECT, [
        'commonId',
        'id',
      ]).then(({ entities: allAccountProjects }) => {
        setValidatingCommonId(false)
        if (allAccountProjects.some(p => p.id !== entityId && p.commonId === value)) {
          throw new Error('Project ID is already in use.')
        }
      })
    }
  }

  return (
    <>
      <Form.Item name="name" label={`${entityTypeLabel} Name`} rules={[{ required: true, whitespace: true }]}>
        <StringInput readOnly={readOnly} autoFocus />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <StringInput readOnly={readOnly} />
      </Form.Item>
      {entityType === EntityType.PROJECT && (
        <>
          <Form.Item
            label="Project ID"
            name="commonId"
            rules={[{ validator: validateCommonId }]}
            hasFeedback={validatingCommonId} // no need to show feedback otherwise
            validateTrigger="onSubmit"
          >
            <StringInput readOnly={readOnly} />
          </Form.Item>
          <Form.Item label="Project Cost" name="cost" rules={[{ required: true }]}>
            <CostInput_Risk readOnly={readOnly} unit="USD" />
          </Form.Item>
          <Form.Item label="Project Duration" name="time" rules={[{ required: true }]}>
            <DurationInputWithUnits_Risk readOnly={readOnly} defaultUnit="Months" />
          </Form.Item>
        </>
      )}
    </>
  )
}

export default RiskEntityIdentityFormFields
