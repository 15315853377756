import { useDroppable } from '@dnd-kit/core'
import { ReactNode } from 'react'

import { testId } from '../../../test-automation'
import style from './RatingNode.module.css'

interface RatingNodeProps {
  nodeId: string
  children: ReactNode[]
}
export function RatingNode({ nodeId, children }: RatingNodeProps) {
  const { setNodeRef } = useDroppable({ id: nodeId })

  return (
    <div className={style.ratingNode} ref={setNodeRef} {...testId(nodeId)}>
      {children}
    </div>
  )
}
