import { ReactElement, useMemo } from 'react'

import { ValueNode } from '@vms/vmspro3-core/dist/nextgen/valueGraph/valueNode'

import { ValueGraph } from './ValueGraph'

import { useDecision } from '../../../../redux/hooks'

function useValueNode(decisionId: string, optionId: string): ValueNode | undefined {
  const decision = useDecision(decisionId)
  return useMemo(() => decision.options.byId(optionId)?.valueGraph, [decision, optionId])
}

interface ValueGraphContainerProps {
  decisionId: string
  optionId: string
}
export function ValueGraphContainer({ decisionId, optionId }: ValueGraphContainerProps): ReactElement | null {
  const valueNode = useValueNode(decisionId, optionId)

  if (!valueNode) return null

  return <ValueGraph rootValueNode={valueNode} />
}
