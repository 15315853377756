import { CellProps, CellValue, Renderer } from 'react-table'
import { Rating } from '@vms/vmspro3-core/dist/types'
import { NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'
import { renderNumberWithStats } from './getNumberCellRenderer'

type RatingCellRendererProps = CellProps<Record<string, unknown>, Rating | number | undefined>

/**
 * Default rating cell renderer (with default fractional digits).
 */
export const RatingCellRenderer = getRatingCellRenderer()

/**
 * Get a rating cell renderer with the specified number of fractional digits (defaults to 1).
 */
export function getRatingCellRenderer(maximumFractionDigits = 1) {
  const formatter = Intl.NumberFormat(undefined, {
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits,
  })
  function RatingCellRenderer({ value: rating }: RatingCellRendererProps): Renderer<CellValue> | null {
    if (rating === null || rating === undefined) return ''
    if (typeof rating === 'number') return formatter.format(rating)
    if (rating?.abstain) return 'Abstained'

    const value = rating?.ratingVector?.[0]

    if (typeof value !== 'number' || Number.isNaN(value)) return null
    return formatter.format(value)
  }
  return RatingCellRenderer
}

type RatingWithStatsCellRendererProps = CellProps<
  Record<string, unknown>,
  { rating: Rating; stats: NumberWithStats } | undefined
>

/**
 * Get a rating (with stats) cell renderer with the specified number of fractional digits (defaults to 1).
 */
export function getRatingWithStatsCellRenderer(maximumFractionDigits = 1) {
  const formatter = Intl.NumberFormat(undefined, {
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits,
  })
  function RatingCellRenderer(props: RatingWithStatsCellRendererProps): Renderer<CellValue> | null {
    // value will be undefined for missing ratings (distinct from abstained ratings)
    if (!props.value) return null
    const { rating, stats } = props.value
    if (rating?.abstain) return 'Abstained'
    return renderNumberWithStats(stats, { formatter })
  }
  return RatingCellRenderer
}
