import { ReactElement } from 'react'
import { ApolloError } from '@apollo/client'
import { Spin } from 'antd'

interface QueryResultProps<T> {
  data?: T
  loading: boolean
  error?: ApolloError
  children: ((data: T) => ReactElement) | ReactElement
}
export function QueryResult<T>({ data, error, loading, children }: QueryResultProps<T>) {
  if (error) {
    return <p>GraphQL Error: {error.message}</p>
  }

  if (loading) {
    return <Spin />
  }

  if (!data) {
    return <p>Data not found...</p>
  }

  if (typeof children === 'function') {
    return children(data)
  }
  return children
}
