import { createContext, useContext as _useContext, ProviderProps, VFC } from 'react'

export function createTypedContext<T>(
  displayName: string
): readonly [useContext: () => T, Provider: VFC<ProviderProps<T>>] {
  const Context = createContext<T | undefined>(undefined)
  Context.displayName = displayName

  function useContext() {
    const c = _useContext(Context)
    if (c === undefined) {
      throw new Error(`${displayName} useContext may only be used within a ${displayName} Provider`)
    }
    return c
  }

  function Provider({ value, children }: ProviderProps<T>) {
    if (value === undefined) {
      throw new Error(`A value is required for the ${displayName} Provider`)
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
  }
  return [useContext, Provider]
}
