import React from 'react'

import AntTreeSelect from 'antd/lib/tree-select'
import 'antd/lib/tree-select/style/css'

const TreeSelect = React.forwardRef((props, ref) => {
  const { labelInValue, multiple, readOnly, treeCheckable, value } = props

  if (readOnly) {
    if (!labelInValue) throw new Error('TreeSelect: readOnly not supported without labelInValue configured!')
    if (multiple || treeCheckable) {
      throw new Error('TreeSelect: readOnly not supported with multiple selection configured!')
    }
    return <span style={readOnlyStyle}>{value?.label}</span>
  }

  return <AntTreeSelect ref={ref} {...props} />
})

const readOnlyStyle = {
  minHeight: '32px',
  paddingLeft: '12px',
  display: 'flex',
  alignItems: 'center',
}

export default TreeSelect
