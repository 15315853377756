import { useCallback, useMemo, useState } from 'react'
import { Button, Checkbox, InputNumber, Modal, Space } from 'antd'
import _shuffle from 'lodash/shuffle'
import _minBy from 'lodash/minBy'
import _maxBy from 'lodash/maxBy'

import { useAppDispatch } from '../../../../redux'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { createParticipant, updateRating, updateRatingNotes } from '@vms/vmspro3-core/dist/actions/decision'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { Html } from '@vms/vmspro3-core/dist/types'
import { PlayCircleOutlined } from '@ant-design/icons'
import { AnyAction } from 'redux'
import { useDecision } from '../../../../redux/hooks'

const NOTES_TEMPLATES = [
  `<max> is clearly the best, while <min> brings up the rear, based on my experience.`,
  `I hate <min>, and I don't understand why that's important to people.  <max> all the way.`,
  `I like <max> and <min> is kind of garbage.`,
  `I'm an expert here, and I can tell you <max> is the way to go.  <min> is not nearly as important.`,
  `I've consulted my crystal ball, and I'm all in for <max>.`,
  `After discussing this with my team, I've come to realize that <max> is more important than <min>.`,
  `I don't care what else is decided, <min> is just not important to me.`,
  `After attending the conference, I've changed my mind, and <max> dominates the importance of this decision.`,
  `I used to feel <min> was important, but lots has changed and now it's it's extremely important.`,
  `I'm not sure what other people think, but <min> is just not that important.  <max> is, though.`,
  `I'm going to go out on a limb here and say that <max> is vital to this decision.`,
  `Normally I would consider <min> important, but given our stakeholders, it loses out to <max>.`,
  `This one was tough, but after I remembered what Ms. Cooper told us about the history behind this ` +
    `decision, it's clear that <max> is of critical importance.`,
  `I can't believe I'm saying this, but I've been convinced by some of the newcomers that <max> is ` +
    `more important than <min>.`,
  `If it were just me, <min> would be more important, but if I think about the good of the organization, ` +
    `it's clear that <max> has a lot more weight.`,
  `I know all the more progressive folk are going to disagree with me, but <max> dominates this decision for me.`,
  `I imagine I'll be at odds here with my colleagues, but <min> just isn't that important to me.`,
]

async function generateRobotParticipantActions(ancestry: string, count: number) {
  const actions: AnyAction[] = []
  const { randFirstName, randLastName, randEmail, randPhoneNumber } = await import('@ngneat/falso')
  for (let i = 0; i < count; i++) {
    const firstName = randFirstName()
    const lastName = randLastName()
    const initials = firstName[0] + lastName[0]
    const fullName = firstName + ' ' + lastName
    const email = randEmail({ firstName, lastName })
    const phone = randPhoneNumber()
    actions.push(
      createParticipant(ancestry, {
        fullName,
        shortName: firstName,
        initials,
        email,
        phone,
        userId: null,
        trackingId: null,
        tags: ['Robot'],
      })
    )
  }
  return actions
}

function generateParticipationActions(
  decision: Decision,
  participationSessionId: string,
  participantIds: string[],
  ancestry: string,
  notesPct = 60
) {
  const actions: AnyAction[] = []
  const partTemplates: Array<
    ['rating', string, string, string, [number, number]] | ['notes', string, string, Html]
  > = []
  decision.criteria.contextCriteria.forEach(ctx => {
    if (ctx.type !== 'Performance' && ctx.type !== 'Rated') return
    const participants = participantIds.map(id => decision.participantsById[id])
    // add each participant/context/child combination to template array, and add notes (maybe)
    let notesRemaining = (participants.length * notesPct) / 100
    participants.forEach(p => {
      const ratings = ctx.children.map<[Criterion, number]>(c => [c, Math.random() * 10])
      ratings.forEach(([subj, r], i, l) =>
        partTemplates.push(['rating', p.id, ctx.id, subj.id, [r, i / l.length]])
      )
      if (notesRemaining--) {
        const min = _minBy(ratings, '1')?.[0].abbrev || '??'
        const max = _maxBy(ratings, '1')?.[0].abbrev || '??'
        const notes = _shuffle(NOTES_TEMPLATES)[0].replace(/<min>/g, min).replace(/<max>/g, max)
        partTemplates.push(['notes', p.id, ctx.id, createHtmlObject(notes)])
      }
    })
  })
  // generate random ratings for each template
  // TODO: base on template participant
  partTemplates.forEach(arr => {
    if (arr[0] === 'rating') {
      const [, participantId, contextId, subjectId, ratingVector] = arr
      actions.push(
        updateRating(ancestry, {
          participationSessionId,
          participantId,
          contextType: 'Criterion',
          contextId,
          subjectType: 'Criterion',
          subjectId,
          ratingVector,
          abstain: false,
        })
      )
    }
    if (arr[0] === 'notes') {
      const [, participantId, contextId, notes] = arr
      actions.push(
        updateRatingNotes(ancestry, {
          participationSessionId,
          participantId,
          contextType: 'Criterion',
          contextId,
          subjectType: 'Criterion',
          notes,
        })
      )
    }
  })
  return actions
}

interface ParticipationDemoModalProps {
  decisionId: string
  participationSessionId: string
  hideModal: () => void
}
export function ParticipationDemoModal(props: ParticipationDemoModalProps) {
  const { decisionId, participationSessionId, hideModal } = props

  const decision = useDecision(decisionId)

  const dispatch = useAppDispatch()

  const ancestry = joinAncestry(decision.data.ancestry, decision.id)

  const [participantCount, setParticipantCount] = useState(3)

  const [working, setWorking] = useState(false)
  const generateParticipants = useCallback(() => {
    setWorking(true)
    generateRobotParticipantActions(ancestry, participantCount).then(actions => actions.forEach(dispatch))
    setTimeout(() => setWorking(false), 500)
  }, [ancestry, dispatch, participantCount])

  const robots = useMemo(() => decision.participants.filter(p => p.tags.includes('Robot')), [decision])

  const [uncheckedRobots, setUncheckedRobots] = useState<string[]>([])
  const changeRobotCheck = useCallback(
    (id: string, checked: boolean) =>
      setUncheckedRobots(l => {
        if (checked) return l.filter(_id => _id !== id)
        return l.includes(id) ? l : l.concat(id)
      }),
    [setUncheckedRobots]
  )

  const generateRatings = useCallback(() => {
    setWorking(true)
    const participantIds = robots.filter(r => !uncheckedRobots.includes(r.id)).map(r => r.id)
    const actions = generateParticipationActions(decision, participationSessionId, participantIds, ancestry)
    actions.forEach(dispatch)
    setTimeout(() => setWorking(false), 500)
  }, [ancestry, decision, dispatch, participationSessionId, robots, uncheckedRobots])

  return (
    <Modal
      open
      onOk={hideModal}
      onCancel={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Close"
    >
      <Space>
        Add
        <InputNumber
          style={{ width: '5em' }}
          autoFocus
          min={0}
          max={20}
          precision={0}
          value={participantCount}
          disabled={working}
          onChange={value => value !== null && setParticipantCount(value)}
        />
        fake participants
        <Button icon={<PlayCircleOutlined />} onClick={generateParticipants} type="primary" loading={working}>
          Go
        </Button>
      </Space>
      {robots.length > 0 && (
        <>
          <hr style={{ margin: '1em 0' }} />
          <Space direction="vertical">
            Fake participants:
            {robots.map(p => (
              <Checkbox
                key={p.id}
                checked={!uncheckedRobots.includes(p.id)}
                onChange={e => changeRobotCheck(p.id, e.target.checked)}
              >
                {p.fullName}
              </Checkbox>
            ))}
            <Button icon={<PlayCircleOutlined />} onClick={generateRatings} type="primary" loading={working}>
              Add Fake Ratings for Selected
            </Button>
          </Space>
        </>
      )}
    </Modal>
  )
}
