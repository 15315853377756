import actions from '../actions'
// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
import { CreateAccountType } from '../actions/account/createAccount'

const defaultOptions = {
  onError: (...args) => console.error(...args), // eslint-disable-line no-undef
}

/**
 * Describe an action in (more) friendly language.  If the end user
 * needs to see information about action history, this will start to
 * close the gap in presenting it in a less technical way.  There's still
 * work to do; for example, actions often contain IDs for updates, deletes
 * etc, which is not particularly user-friendly, but also problematic because
 * it requires database or state access to get descriptive names.
 *
 * This has been designed not to fail when given bad input as is just provides
 * a UI nicety.
 *
 * @param {Action} action - The action to try to describe.
 * @param {(message: string, err: Error) => null} [onError] - If the action couldn't
 *  be correctly descried, this will receive that error.  This defaults to logging
 *  errors to the console, and can be set to a no-op to disable error output.
 *
 * @returns {string} - A (more) human-readable description of the action.
 */
function describeAction(action, options = defaultOptions) {
  try {
    switch (action.type) {
      // accounts
      case CreateAccountType:
        return `Create Account ${action.payload.name}`
      case actions.account.deleteAccount.toString():
        return `Delete Account ${action.meta.accountId}`
      case `Agree to EULA`:
        return `Agree to EULA for account ${action.meta.accountId}`

      // policies
      case actions.policy.create.toString():
        return `Create Policy`
      case actions.policy.update.toString():
        return `Update Policy ${action.meta.policyId}`

      // risks
      case actions.risk.update.toString():
        return `Update Risk ${action.meta.riskId}`
      case actions.risk.delete.toString():
        return `Delete Risk ${action.meta.riskId}`
      case actions.risk.create.toString():
        return `Create Risk ${action.payload.name}`

      // risk context
      case actions.riskContext.update.toString():
        return `Update Risk Configuration / Calibration`

      // risk entities
      case actions.riskEntity.update.toString():
        return `Update Risk Entity ${action.meta.entityId} (${action.meta.entityType})`
      case actions.riskEntity.delete.toString():
        return `Delete Risk Entity ${action.meta.entityId} (${action.meta.entityType})`

      // risk portfolio
      case actions.riskPortfolio.create.toString():
        return `Create Portfolio ${action.payload.name}`
      case actions.riskPortfolio.update.toString():
        return `Update Portfolio ${action.meta.portfolioId}`

      // risk program
      case actions.riskProgram.create.toString():
        return `Create Program ${action.payload.name}`
      case actions.riskProgram.update.toString():
        return `Update Program ${action.meta.programId}`

      // risk project
      case actions.riskProject.create.toString():
        return `Create Project ${action.payload.name}`
      case actions.riskProject.update.toString():
        return `Update Project ${action.meta.projectId}`
      case actions.riskProject.updateStatistics.toString():
        return `Update Statistics for Project ${action.meta.projectId}`

      // user
      case actions.user.update.toString():
        return `Update User ${action.meta.userId}`
      case actions.user.disable.toString():
        return `Disable User ${action.meta.userId}`
      case actions.user.enable.toString():
        return `Enable User ${action.meta.userId}`
      case actions.user.addPolicy.toString():
        return `Add Policy ${action.meta.policyId} to User ${action.meta.userId}`
      case actions.user.removePolicy.toString():
        return `Remove Policy ${action.meta.policyId} from User ${action.meta.userId}`
      case actions.user.setTableConfig.toString():
        return `Update Preferences for Table ${action.payload.tableId}`

      // TODO: while we may want to create synthetic actions for the ledger, the following actions
      // were never actually used in the system...
      // case actions.user.create.toString():
      //   return `Create User ${action.payload.email}`
      // case actions.user.changePassword.toString():
      //   return `Change Password`

      default: {
        return action.type || 'UNKNOWN'
      }
    }
  } catch (err) {
    options.onError('** error describing action: ', err)
    return action?.type || 'UNKNOWN'
  }
}

export default describeAction // eslint-disable-line no-undef
