import { ResourcesConfig, Amplify as AWSAmplify } from 'aws-amplify'

import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'

import config from '../config.json'

// TODO: update configuration to match new Amplify shape...instead of this munging we do here
const AMPLIFY_CONFIG: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: config.amplify.Auth.identityPoolId,
      userPoolId: config.amplify.Auth.userPoolId,
      userPoolClientId: config.amplify.Auth.userPoolWebClientId,
      allowGuestAccess: !config.amplify.Auth.mandatorySignIn,
    },
  },
  API: {
    REST: {
      vmspro: config.amplify.API.endpoints[0],
    },
    GraphQL: {
      endpoint: config.amplify.API.graphql_endpoint,
      defaultAuthMode: 'userPool',
    },
  },
  Storage: {
    S3: {
      bucket: config.amplify.Storage.AWSS3.bucket,
      region: config.amplify.Storage.AWSS3.region,
    },
  },
}
AWSAmplify.configure(AMPLIFY_CONFIG)

export async function getIdToken() {
  const idToken = (await fetchAuthSession()).tokens?.idToken?.toString()
  if (!idToken) throw new Error('failed to retrieve session ID token')
  // take this opportunity to refresh the cookie; this could be a hangover from ye olde bad way of doing things
  document.cookie = idToken
    ? `OptionLab_IDToken=${idToken}; SameSite=Strict; Max-Age=3600; Path=/`
    : `OptionLab_IDToken=; Max-Age=0; Path=/`
  return idToken
}

/**
 * This is not my favorite mechanism for determining what the "current" account is, but it replaces
 * something much worse, and it will do for now.  A better approach, I think, would be to have clear
 * typing for actions (and read "actions"?) that specify the accountId...then it would be unambiguous
 * when hitting one of hte API endpoints.
 */
export let currentAccount = '*'
export function setCurrentAccount(account: string) {
  if (account !== currentAccount) console.log(`[current account changed: ${currentAccount} -> ${account}]`)
  currentAccount = account
}

/**
 * Gets necessary headers for REST/GraphQL API access via AWS Amplify.  Uses current account
 * (but can be overridden).
 * @param accountId Value for account header; if omitted, will use current account.
 */
export async function getHeaders(accountId?: string) {
  const idToken = (await fetchAuthSession()).tokens?.idToken?.toString()
  if (!idToken) return { 'x-optionlab-accountid': accountId }
  const { preferred_username: userId } = await fetchUserAttributes()
  const headers = {
    'x-optionlab-accountid': accountId || currentAccount,
    'x-optionlab-userid': userId,
    'x-optionlab-idtoken': idToken,
  }
  return headers
}
