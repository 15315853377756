import { ReactElement, useEffect, useRef } from 'react'

import style from './Layout.module.css'

interface LayoutProps {
  headerLeft?: ReactElement
  headerRight?: ReactElement
  content: ReactElement
  // footer?: ReactElement,
  menu?: ReactElement
}
export function Layout({
  // footer,
  headerLeft,
  headerRight,
  content,
  menu,
}: LayoutProps) {
  const layoutRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    function setLayoutHeight() {
      layoutRef.current?.style.setProperty('--height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', setLayoutHeight)
    return () => window.removeEventListener('resize', setLayoutHeight)
  }, [])

  return (
    <div ref={layoutRef} className={style.layout}>
      <header className={style.header}>
        <div>{headerLeft}</div>
        <div>{headerRight}</div>
      </header>
      <main className={style.main}>
        {menu}
        {content}
      </main>
      {/* {footer} */}
    </div>
  )
}
