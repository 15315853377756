import { Spin } from 'antd'

import { useAppSelector } from '../../../redux'
import { selectAssumedRolePolicies } from '../../../redux/selectors'

/**
 * @prop {string} assumedRoleId
 */
function AssumingRole() {
  const assumedRolePolicies = useAppSelector(selectAssumedRolePolicies)

  // policies may not have been fetched when this component initially mounts
  if (!assumedRolePolicies?.every(Boolean)) {
    return <Spin />
  }

  return (
    <h3 style={style.message}>
      You are assuming roles: {assumedRolePolicies.map(policy => `"${policy.name}"`).join(', ')}
    </h3>
  )
}

const style = {
  message: {
    margin: '0',
  },
}

export default AssumingRole
