import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { Button, Result } from 'antd'

import { createRootRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { ROOT_RISK_PORTFOLIO_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core/dist'

import useAuthz from '../../../hooks/useAuthz'
import { selectAccountName } from '../../../redux/selectors'

function UnconfiguredPortfolio() {
  const dispatch = useDispatch()
  const accountName = useSelector(selectAccountName)

  const authz = useAuthz()
  const canCreateConfig = authz({
    module: 'Risk',
    type: actions.riskPortfolio.create.toString(),
  })

  const startConfig = () => {
    const payload = {
      id: ROOT_RISK_PORTFOLIO_ID,
      name: 'Portfolio',
      description: `Implicit root portfolio for OptionLab account ${accountName}`,
      riskContext: createRootRiskContext(),
      ancestry: '/',
    }
    dispatch(actions.riskPortfolio.create(payload))
  }

  return (
    <Result
      icon={<SettingOutlined />}
      title="Welcome to OptionLab Risk!"
      extra={
        <>
          <p>
            To get started, you'll first need to create a configuration for OptionLab Risk.
            {canCreateConfig
              ? ` Don't worry, we'll provide some reasonable defaults; all you have to do is push a button!`
              : ` Ask an authorized user to create a configuration.`}
          </p>
          {canCreateConfig && (
            <Button type="primary" onClick={startConfig}>
              <SettingOutlined /> Create Configuration
            </Button>
          )}
        </>
      }
    />
  )
}

export default UnconfiguredPortfolio
