import { Duration } from '@vms/vmspro3-core/dist/qty'

export const naturalAlphanumericSort = (a, b) => {
  if (!a && b) return -1
  if (!b && a) return 1
  if (!a && !b) return 0
  if (typeof a !== 'string') throw new Error('Unsupported data type: ', typeof a)
  if (typeof b !== 'string') throw new Error('Unsupported data type: ', typeof b)
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'variant' })
}

export const alphanumericSortByKey = key => (a, b) => naturalAlphanumericSort(a[key], b[key])

// Checks for null values since `isNaN(null) === false`.
// Does not check for other edge cases that evaluate to `false` such as '\t', '\n', '\v', '\r', [], etc.
export const numcomp = (a, b) => {
  if (typeof a === 'string') throw new Error('Unsupported data type: ', typeof a)
  if (typeof b === 'string') throw new Error('Unsupported data type: ', typeof b)
  return Number.isNaN(Number(a === null ? NaN : a))
    ? Number.isNaN(Number(b === null ? NaN : b))
      ? 0
      : -1
    : Number.isNaN(Number(b === null ? NaN : b))
    ? 1
    : a == b
    ? 0
    : a < b
    ? -1
    : 1 // eslint-disable-line eqeqeq
}

export const numcompByKey = key => (a, b) => numcomp(a?.[key], b?.[key])

/**
 * Sort function for comparing durations (suitable for use with Array#sort).
 * Null or undefined duration objects (or duration objects with null or undefined
 * values) are sorted to the bottom. All duration units are converted to months
 * using utils.duration.convert.
 *
 * @param {Object} [a] - Duration object a.
 * @param {Object} [b] - Duration object b.
 * @returns {number} - If a and b are equal, 0; if a < b, -1, if a > b, 1.
 */
export const compareDurations = (a, b) => {
  // note that empty objects ({}) are sometimes, unfortunately, considered valid durations.
  // in this case, they should be considered the same as null durations.
  if ((!a && b) || (!a?.unit && b?.unit)) return -1
  if ((!b && a) || (!b?.unit && a?.unit)) return 1
  if (!a && !b) return 0
  if (!a.unit && !b.unit) return 0
  return Duration.compare(a, b)
}

// NOTE: a and b must be arrays
// TODO: can be re-written for more flexibility if "label" is passed in as an arg.
export const compareKeysByLabel = (a, b, byKey) => {
  if (!a && b) return -1
  if (!b && a) return 1
  if (!a && !b) return 0
  if (a.length > 1 && b.length > 1) return 0
  if (a.length > 1 && b.length === 1) return 1
  if (b.length > 1 && a.length === 1) return -1
  return naturalAlphanumericSort(byKey[a].label, byKey[b].label)
}

export const compareIdsByLabel = (a, b, byId) => {
  if (!byId[a] && byId[b]) return -1
  if (!byId[b] && byId[a]) return 1
  if (!byId[a] && !byId[b]) return 0
  return naturalAlphanumericSort(byId[a].label, byId[b].label)
}

/**
 * Get a compare function that will compare two keys based on their
 * location in an enum array.  The enum array should be in the desired
 * order that corresponding values should be sorted by, and each value
 * should have a "key" property.
 *
 * NOTE: keys that are null, undefined, or do not appear in the enum
 * definition will be sorted to the bottom, with no error.
 *
 * @example
 *   const enumDef = [{ key: 'BEFORE' }, { key: 'AFTER' }]
 *   const values = ['AFTER', 'BEFORE', 'AFTER', 'nope', 'BEFORE']
 *   values.sort(getEnumKeyComparer(enumDef))
 *      // returns ['BEFORE', 'BEFORE', 'AFTER', 'AFTER', 'nope']
 *
 * @param {Array<Object>} enumDef - Enum definition; array of objects with 'key' properties.
 * @returns {function} - Compare function suitable for use with Array#sort.
 */
export const getEnumKeyComparer = enumDef => (a, b) => {
  const aIdx = enumDef.findIndex(s => s.key === a) || 0
  const bIdx = enumDef.findIndex(s => s.key === b) || 0
  if (aIdx < 0) return 1
  if (bIdx < 0) return -1
  return aIdx - bIdx
}
