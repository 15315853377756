import { useCallback, ReactElement } from 'react'
import { Form, Input, Modal } from 'antd'

import { createDecisionFolder, createDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import { useAppDispatch } from '../../../redux'

interface DecisionNodeCreateFormFields {
  name: string
  description?: string
  objective?: string
}
interface DecisionNodeCreateModalProps {
  decisionNodeType: 'Decision' | 'DecisionFolder'
  ancestry: string
  hide: VoidFunction
}
export function DecisionNodeCreateModal({
  decisionNodeType,
  ancestry,
  hide,
}: DecisionNodeCreateModalProps): ReactElement {
  const [form] = Form.useForm<DecisionNodeCreateFormFields>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(() => {
    form.validateFields().then(({ name, description, objective }) => {
      if (decisionNodeType === 'DecisionFolder') {
        dispatch(createDecisionFolder({ name, ancestry }))
      } else if (decisionNodeType === 'Decision') {
        dispatch(
          createDecision({
            name,
            type: 'VM',
            ancestry,
            description: createHtmlObject(description ?? null),
            objective: createHtmlObject(objective ?? null),
          })
        )
      }
    })

    hide()
  }, [form, dispatch, decisionNodeType, ancestry, hide])

  return (
    <Modal open onCancel={hide} onOk={onOk}>
      <h2>Create {decisionNodeType}</h2>
      <Form form={form} layout="vertical" requiredMark="optional">
        <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
          <Input autoFocus />
        </Form.Item>
        {decisionNodeType === 'Decision' && (
          <>
            <Form.Item name="objective" label="Objective">
              <Input.TextArea showCount maxLength={1200} rows={3} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea rows={6} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}
