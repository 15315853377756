import { produce } from 'immer'
import _omitBy from 'lodash/omitBy'
import _matches from 'lodash/matches'

import { RatingNotes } from '@vms/vmspro3-core/dist/types'
import {
  DeleteParticipantAction,
  DeleteParticipantDataAction,
  UpdateRatingNotesAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actions'

export type RatingNotesState = {
  byId: Record<string, RatingNotes>
}
type RatingNotesReducerAction =
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | UpdateRatingNotesAction
  | DeleteParticipantAction
  | DeleteParticipantDataAction

export const initialState: RatingNotesState = {
  byId: {},
}

export const ratingNotesReducer = produce<RatingNotesState, [RatingNotesReducerAction?]>((state, action) => {
  if (!action?.type) return

  switch (action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to fetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if (action.payload.ratings) {
        Object.assign(state.byId, action.payload.ratingNotes)
      }
      break
    }

    case 'Update Rating Notes': {
      state.byId[action.meta.id] = {
        ...action.payload,
        updated: {
          timestamp: Date.now(),
          location: '{}',
        },
      }
      break
    }

    case 'Delete Participant': {
      state.byId = _omitBy(state.byId, _matches({ participantId: action.meta.participantId }))
      break
    }

    case 'Delete Participant Data': {
      action.meta.participantIds.forEach(participantId => {
        if (action.meta.deleteParticipant) {
          state.byId = _omitBy(state.byId, _matches({ participantId }))
        } else {
          if (action.meta.deleteCriteriaPrioritizationData) {
            state.byId = _omitBy(state.byId, _matches({ participantId, subjectType: 'Criterion' }))
          }
          if (action.meta.deleteOptionRatingData) {
            state.byId = _omitBy(state.byId, _matches({ participantId, subjectType: 'OptionRating' }))
          }
          if (action.meta.deleteOutcomeProbabilityData) {
            state.byId = _omitBy(state.byId, _matches({ participantId, subjectType: 'OptionOutcome' }))
          }
        }
      })
      break
    }

    default: {
      break
    }
  }
}, initialState)
