import { createStore, applyMiddleware, AnyAction } from 'redux'
import { TypedUseSelectorHook, useSelector, useDispatch, Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { augmentationMiddleware, isomorphicReduxMiddleware, pubSubMiddleware } from './middleware'
import { rootReducer, RootState } from './reducers'

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware, augmentationMiddleware, isomorphicReduxMiddleware, pubSubMiddleware)
  )
)

export type Store = typeof store
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
export type AsyncAppThunk<ReturnType = void> = AppThunk<Promise<ReturnType>>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export { createStore, Provider, rootReducer }
export type { RootState }
