import { createDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { ValueFunctionLibrary } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'
import { Button, Checkbox, Form, Input, Modal } from 'antd'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { DecisionType, DecisionVersion } from '../../../../../core/src/nextgen/decision'
import { useAppDispatch } from '../../../redux'

interface CreateDecisionFormFields {
  name: string
  type: DecisionType
  version: DecisionVersion
  description?: string
  objective?: string
  useCost: boolean
  useTime: boolean
}

interface DecisionChoiceCardProps {
  children: ReactNode
}
function DecisionChoiceCard(props: DecisionChoiceCardProps) {
  return (
    <div style={{ margin: '12px 0', padding: '12px', border: 'solid 1px #eee', backgroundColor: '#eee' }}>
      {props.children}
    </div>
  )
}

interface CreateDecisionModalProps {
  hide: VoidFunction
  ancestry: string
}
export function CreateDecisionModal(props: CreateDecisionModalProps) {
  const { hide, ancestry } = props
  const [step, setStep] = useState(0)
  const [form] = Form.useForm<CreateDecisionFormFields>()
  const dispatch = useAppDispatch()
  const onDone = useCallback(() => {
    form.validateFields().then(({ name, type, version, description, objective, useCost, useTime }) => {
      const valueFunction =
        type === 'VM'
          ? ValueFunctionLibrary.Standard
          : useCost && useTime
          ? ValueFunctionLibrary.Linear
          : !useCost && !useTime
          ? ValueFunctionLibrary.LinearPerfOnly
          : useCost
          ? ValueFunctionLibrary.LinearNoTime
          : ValueFunctionLibrary.LinearNoCost
      dispatch(
        createDecision({
          name,
          type,
          version,
          ancestry,
          description: createHtmlObject(description ?? null),
          objective: createHtmlObject(objective ?? null),
          valueFunctionJson: JSON.stringify(valueFunction),
        })
      )
      hide()
    })
  }, [ancestry, dispatch, form, hide])
  const done = useMemo(
    () => (
      <Button type="primary" key="done" onClick={onDone}>
        OK
      </Button>
    ),
    [onDone]
  )
  const cancel = useMemo(
    () => (
      <Button key="cancel" onClick={hide}>
        Cancel
      </Button>
    ),
    [hide]
  )
  const footer = useMemo(() => {
    switch (step) {
      case 0:
        return [cancel]
      case 1:
        return [cancel, done]
      case 2:
        return [cancel, done]
      case 3:
        return [cancel, done]
      default:
        throw new Error('invalid step')
    }
  }, [cancel, done, step])

  const content = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <div>
            <p>What kind of decision would you like to create?</p>
            <DecisionChoiceCard>
              <h3>Decision</h3>
              <p>This is the best choice for most users. If you're not sure, start here!</p>
              <Button type="primary" onClick={() => setStep(1)}>
                Create Decision
              </Button>
            </DecisionChoiceCard>
            {/* This is an example of how we can enable creation of next-generation decision type
            <DecisionChoiceCard>
              <h3>Decision v3</h3>
              <p>This is some cutting edge stuff.  Do it!</p>
              <Button type="primary" onClick={() => setStep(3)}>
                Create Decision
              </Button>
            </DecisionChoiceCard>
            */}
            <DecisionChoiceCard>
              <h3>Value Methodology (VM) Decision</h3>
              <p>For consultants who specialize in Value Methodology.</p>
              <Button type="primary" onClick={() => setStep(2)}>
                Create VM Decision
              </Button>
            </DecisionChoiceCard>
          </div>
        )
      case 1:
        return (
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
            initialValues={{ type: 'Regular', version: '2.0.0', useCost: true, useTime: false }}
          >
            <Form.Item hidden name="type">
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
              <Input autoFocus />
            </Form.Item>
            <Form.Item name="objective" label="Objective">
              <Input.TextArea showCount maxLength={1200} rows={3} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea rows={6} />
            </Form.Item>
            <Form.Item name="useCost" valuePropName="checked">
              <Checkbox>Cost is relevant to this decision (e.g. the purchase cost)</Checkbox>
            </Form.Item>
            <Form.Item name="useTime" valuePropName="checked">
              <Checkbox>Time is relevant to this decision (e.g. the duration of a project)</Checkbox>
            </Form.Item>
          </Form>
        )
      case 2:
        return (
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
            initialValues={{ type: 'VM', version: '2.0.0', useCost: true, useTime: true }}
          >
            <Form.Item hidden name="type">
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
              <Input autoFocus />
            </Form.Item>
            <Form.Item name="objective" label="Objective">
              <Input.TextArea showCount maxLength={1200} rows={3} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea rows={6} />
            </Form.Item>
            {/* for now, cost & time fields are hidden for VM studies */}
            <Form.Item name="useCost" valuePropName="checked" hidden>
              <Checkbox>Cost is relevant to this decision (e.g. the purchase cost)</Checkbox>
            </Form.Item>
            <Form.Item name="useTime" valuePropName="checked" hidden>
              <Checkbox>Time is relevant to this decision (e.g. the duration of a project)</Checkbox>
            </Form.Item>
          </Form>
        )
      case 3:
        // not currently used; see commented-out code in step 0 above
        return (
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
            initialValues={{ type: 'Regular', version: '3.0.0' }}
          >
            <Form.Item hidden name="type">
              <Input />
            </Form.Item>
            <Form.Item hidden name="version">
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        )
      default:
        throw new Error('invalid step')
    }
  }, [form, step])
  return (
    <Modal open footer={footer} onCancel={hide}>
      <div style={{ margin: '24px 0 0 0' }}>{content}</div>
    </Modal>
  )
}
