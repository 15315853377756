import { produce } from 'immer'
import asSet from 'arraysetjs'
import _negate from 'lodash/negate'

import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { ratingIdMatchesWith } from '@vms/vmspro3-core/dist/utils/ratings'
import {
  CreateDecisionAction,
  UpdateDecisionAction,
  DeleteDecisionAction,
  ReloadDecisionAction,
  CreateChildCriterionAction,
  DeleteCriterionAction,
  CreateOptionAction,
  DeleteOptionAction,
  StartParticipationSessionAction,
  EndParticipationSessionAction,
  UpdateParticipationSessionAction,
  CreateParticipantAction,
  DeleteParticipantAction,
  DeleteParticipantDataAction,
  UpdateRatingAction,
  DeleteRatingAction,
  UpdateRatingNotesAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { LoadableDecision } from '../../types'
import {
  FetchDecisionEntityRequestAction,
  FetchDecisionEntitySuccessAction,
  FetchDecisionEntityFailureAction,
  FetchDecisionFolderChildrenSuccessAction,
  ResetAccountStateAction,
} from '../actions'
import { ParticipationSessionStatus } from '@vms/vmspro3-core/dist/types/participationSession'

export type DecisionsState = Record<string, LoadableDecision>
export const initialState: DecisionsState = {}

type Actions =
  | ResetAccountStateAction
  | FetchDecisionEntityRequestAction
  | FetchDecisionEntitySuccessAction
  | FetchDecisionEntityFailureAction
  | FetchDecisionFolderChildrenSuccessAction
  | CreateDecisionAction
  | UpdateDecisionAction
  | DeleteDecisionAction
  | ReloadDecisionAction
  | CreateChildCriterionAction
  | DeleteCriterionAction
  | CreateOptionAction
  | DeleteOptionAction
  | StartParticipationSessionAction
  | EndParticipationSessionAction
  | UpdateParticipationSessionAction
  | CreateParticipantAction
  | DeleteParticipantAction
  | DeleteParticipantDataAction
  | UpdateRatingAction
  | DeleteRatingAction
  | UpdateRatingNotesAction

function getLoadedDecisionByAncestry(state: DecisionsState, ancestry: string) {
  const decisionId = splitAncestry(ancestry).pop()
  const decision = decisionId ? state[decisionId] : undefined
  if (decision?.status === 'Success') return decision
}
function getLoadedDecisionById(state: DecisionsState, decisionId: string) {
  const decision = state[decisionId]
  if (decision?.status === 'Success') return decision
}

export const decisionsReducer = produce<DecisionsState, [Actions?]>((state, action) => {
  if (!action?.type) return

  switch (action.type) {
    case 'ResetAccountState': {
      return initialState
    }
    case 'Reload Decision': {
      delete state[action.meta.decisionId]
      break
    }

    case 'FetchDecisionEntityRequest': {
      if (action.meta.entityType === 'Decision') {
        state[action.meta.decisionEntityId] = { status: 'Loading' }
      }
      break
    }
    case 'FetchDecisionEntitySuccess': // fall through to fetch decision folder
    case 'FetchDecisionFolderChildrenSuccess': {
      Object.assign(state, action.payload.decisions)
      break
    }

    case 'Fetch Decision Entity Error': {
      state[action.meta.decisionEntityId] = {
        status: 'Error',
        error: action.payload.message,
      }
      break
    }

    // decision
    case 'Create Decision': {
      const { criteria, ...decision } = action.payload

      state[decision.id] = {
        status: 'Success',
        data: {
          // action.payload is not a complete Decision type
          ...decision,
          ancestry: action.meta.ancestry,
          entityType: 'Decision',
          owner: {
            // TODO: need to sort out types for augmented actions
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            userId: action.meta.authUserId,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            userName: action.meta.authUserName,
          },
        },
        children: {
          criteria: criteria.map(c => c.id),
        },
      }

      break
    }
    case 'Update Decision': {
      const decision = getLoadedDecisionById(state, action.meta.decisionId)
      if (decision) {
        Object.assign(decision.data, action.payload)
      }
      break
    }
    case 'Delete Decision': {
      delete state[action.meta.decisionId]
      break
    }

    // participation sessions
    case 'Open Participation Session': {
      const { decisionId, participationSessionId } = action.meta

      const decision = getLoadedDecisionById(state, decisionId)
      if (decision) {
        const ps = decision.data.participationSessions.find(p => p.id === participationSessionId)

        if (ps) {
          if (action.payload.status && action.payload.status !== ps.status) {
            // update history
            const history = ps.history || []
            history.push({
              status: action.payload.status as ParticipationSessionStatus,
              includeIntrinsic: !!ps.includeIntrinsic,
              timestamp: Date.now(),
            })
            ps.history = history
          }
          ps.status = 'Active'
        }
      }

      break
    }
    case 'Close Participation Session': {
      const { decisionId, participationSessionId } = action.meta

      const decision = getLoadedDecisionById(state, decisionId)
      if (decision) {
        const ps = decision.data.participationSessions.find(p => p.id === participationSessionId)

        if (ps) {
          if (action.payload.status && action.payload.status !== ps.status) {
            // update history
            const history = ps.history || []
            history.push({
              status: action.payload.status as ParticipationSessionStatus,
              includeIntrinsic: !!ps.includeIntrinsic,
              timestamp: Date.now(),
            })
            ps.history = history
          }
          ps.status = 'Inactive'
        }
      }

      break
    }
    case 'Update Participation Session': {
      const { decisionId, participationSessionId } = action.meta

      const decision = getLoadedDecisionById(state, decisionId)
      if (decision) {
        const participationSession = decision.data.participationSessions.find(p => p.id === participationSessionId)

        if (participationSession) {
          Object.assign(participationSession, action.payload)
        }
      }

      break
    }

    // criteria
    case 'Create Criterion': {
      const decision = getLoadedDecisionById(state, action.meta.decisionId)
      if (decision?.children) {
        if (!decision.children.criteria) decision.children.criteria = []
        asSet.mutable(decision.children.criteria).add(action.payload.id)
      }

      break
    }
    case 'Delete Criterion': {
      const { decisionId, criterionId, descendantCriteriaIds = [] } = action.meta

      const decision = getLoadedDecisionById(state, decisionId)

      if (decision?.children) {
        const criteriaIdsToDelete = [criterionId, ...descendantCriteriaIds]

        if (decision.children.criteria) {
          asSet.mutable(decision.children.criteria).minus(criteriaIdsToDelete)
        }
        if (decision.children.ratings) {
          decision.children.ratings = decision.children.ratings.filter(
            _negate(
              ratingIdMatchesWith(
                criteriaIdsToDelete
                  .map(criterionId => [{ contextId: criterionId }, { subjectId: criterionId }])
                  .flat()
              )
            )
          )
        }
        if (decision.children.ratingNotes) {
          decision.children.ratingNotes = decision.children.ratingNotes.filter(
            _negate(ratingIdMatchesWith(criteriaIdsToDelete.map(contextId => ({ contextId }))))
          )
        }
      }
      break
    }

    // options
    case 'Create Option': {
      const decision = getLoadedDecisionByAncestry(state, action.meta.ancestry)
      if (decision?.children) {
        if (!decision.children.options) decision.children.options = []
        asSet.mutable(decision.children.options).add(action.payload.id)
      }

      break
    }
    case 'Delete Option': {
      const { optionId, ancestry } = action.meta
      const decision = getLoadedDecisionByAncestry(state, ancestry)
      if (decision?.children) {
        if (decision.children.options) {
          asSet.mutable(decision.children.options).remove(optionId)
        }
        if (decision.children.ratings) {
          decision.children.ratings = decision.children.ratings.filter(
            _negate(
              ratingIdMatchesWith({
                subjectId: optionId,
              })
            )
          )
        }
      }
      break
    }

    // participants
    case 'Create Participant': {
      const decision = getLoadedDecisionByAncestry(state, action.meta.ancestry)
      if (decision?.children) {
        if (!decision.children.participants) decision.children.participants = []
        asSet.mutable(decision.children.participants).add(action.payload.id)
      }
      break
    }
    case 'Delete Participant': {
      const { participantId, decisionId } = action.meta
      const decision = getLoadedDecisionById(state, decisionId)
      if (decision?.children) {
        if (decision.children.participants) {
          asSet.mutable(decision.children.participants).remove(participantId)
        }
        if (decision.children.ratings) {
          decision.children.ratings = decision.children.ratings.filter(
            _negate(
              ratingIdMatchesWith({
                participantId,
              })
            )
          )
        }
        if (decision.children.ratingNotes) {
          decision.children.ratingNotes = decision.children.ratingNotes.filter(
            _negate(
              ratingIdMatchesWith({
                participantId,
              })
            )
          )
        }
      }
      break
    }
    case 'Delete Participant Data': {
      const {
        participantIds,
        decisionId,
        deleteParticipant,
        deleteCriteriaPrioritizationData,
        deleteOptionRatingData,
        deleteOutcomeProbabilityData,
      } = action.meta
      const decision = getLoadedDecisionById(state, decisionId)
      if (decision?.children) {
        if (deleteParticipant) {
          if (decision.children.participants) asSet.mutable(decision.children.participants).minus(participantIds)
          for (const participantId of participantIds) {
            if (decision.children.ratings) {
              decision.children.ratings = decision.children.ratings.filter(
                _negate(
                  ratingIdMatchesWith({
                    participantId,
                  })
                )
              )
            }
            if (decision.children.ratingNotes) {
              decision.children.ratingNotes = decision.children.ratingNotes.filter(
                _negate(
                  ratingIdMatchesWith({
                    participantId,
                  })
                )
              )
            }
          }
        } else {
          if (decision.children.ratings) {
            for (const participantId of participantIds) {
              if (deleteCriteriaPrioritizationData) {
                decision.children.ratings = decision.children.ratings.filter(
                  _negate(
                    ratingIdMatchesWith({
                      participantId,
                      subjectType: 'Criterion',
                    })
                  )
                )
              }
              if (deleteOptionRatingData) {
                decision.children.ratings = decision.children.ratings.filter(
                  _negate(
                    ratingIdMatchesWith({
                      participantId,
                      subjectType: 'Option',
                    })
                  )
                )
              }
              if (deleteOutcomeProbabilityData) {
                decision.children.ratings = decision.children.ratings.filter(
                  _negate(
                    ratingIdMatchesWith({
                      participantId,
                      subjectType: 'OptionOutcome',
                    })
                  )
                )
              }
            }
          }
          if (decision.children.ratingNotes) {
            for (const participantId of participantIds) {
              if (deleteCriteriaPrioritizationData) {
                decision.children.ratings = decision.children.ratingNotes.filter(
                  _negate(
                    ratingIdMatchesWith({
                      participantId,
                      subjectType: 'Criterion',
                    })
                  )
                )
              }
              if (deleteOptionRatingData) {
                decision.children.ratings = decision.children.ratingNotes.filter(
                  _negate(
                    ratingIdMatchesWith({
                      participantId,
                      subjectType: 'Option',
                    })
                  )
                )
              }
              if (deleteOutcomeProbabilityData) {
                decision.children.ratings = decision.children.ratingNotes.filter(
                  _negate(
                    ratingIdMatchesWith({
                      participantId,
                      subjectType: 'OptionOutcome',
                    })
                  )
                )
              }
            }
          }
        }
      }
      break
    }

    // ratings
    case 'Update Rating': {
      const decision = getLoadedDecisionByAncestry(state, action.meta.ancestry)
      if (decision?.children) {
        if (!decision.children.ratings) decision.children.ratings = []
        asSet.mutable(decision.children.ratings).add(action.meta.id)
      }
      break
    }
    case 'Delete Rating': {
      const decision = getLoadedDecisionById(state, action.meta.decisionId)
      if (decision?.children?.ratings) {
        asSet.mutable(decision.children.ratings).remove(action.meta.id)
      }
      break
    }

    // ratingNotes
    case 'Update Rating Notes': {
      const decision = getLoadedDecisionByAncestry(state, action.meta.ancestry)
      if (decision?.children) {
        if (!decision.children.ratingNotes) decision.children.ratingNotes = []
        asSet.mutable(decision.children.ratingNotes).add(action.meta.id)
      }
      break
    }

    default: {
      return state
    }
  }
}, initialState)
