function orderOfMagnitude(x) {
  return Math.log(x) / Math.log(10)
}

function prettyPrintRange([_a, _z], displayFactor, fixed, verbal) {
  const from = verbal === 'fromto' ? 'from ' : ''
  const a = typeof _a === 'undefined' ? '' : (_a * displayFactor).toFixed(fixed)
  /* const to =
    typeof _a === "undefined" || typeof _z === "undefined"
      ? ""
      : " – " // that's an en dash */
  const to = verbal === ('to' || 'fromto') ? ' to ' : ' – '
  const z = typeof _z === 'undefined' ? '' : (_z * displayFactor).toFixed(fixed)

  return from + a + to + z
}

export default function printMagnitude({ magnitude, _displayFactor, _displayRange, _prefix, _suffix, _setting }) {
  // the first param of this function can be a proportion float
  // or an array of two proportion floats that express a range
  // if there is no factor, it returns percent
  let verbal = false
  let prefix = _prefix || ''
  const suffix = _suffix || ''
  const displayFactor = _displayFactor || 1
  const displayRange = _displayRange || displayFactor

  const displayZoom = 2 + -1 * orderOfMagnitude(displayRange)
  const fixed = Math.max(0, displayZoom)

  let output = (magnitude * displayFactor).toFixed(fixed)

  const letterNotation =
    output > 1000
      ? number => {
          const notationOptions = ['K', 'M', 'B', 'T', 'Q']
          let notation = -1
          let reduce = number
          while (reduce > 1000) {
            reduce /= 1000
            notation += 1
          }
          return notationOptions[notation]
        }
      : ''

  switch (_setting) {
    case 'plus':
      prefix = magnitude >= 0 ? '+' : ''
      break
    case 'fromto':
      verbal = 'fromto'
      prefix = ''
      break
    case 'to':
      verbal = 'to'
      prefix = ''
      break
    case '""':
      break
    default:
      break
  }

  if (Array.isArray(magnitude)) {
    output = prettyPrintRange(magnitude, displayFactor, fixed, verbal)
  } else {
    output =
      output.slice(0, 1) === '-' // hyphen (negative)
        ? output.replace(/-$/, '−') // true minus sugn
        : output
  }
  return prefix + output + letterNotation + suffix
}
