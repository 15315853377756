import asSet from 'arraysetjs'
import { produce } from 'immer'

import { UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'
import {
  AddPolicyToUserAction,
  DisableUserAction,
  EnableUserAction,
  RemovePolicyAction,
} from '@vms/vmspro3-core/dist/actions/user'

import { LoadingStatus } from '../../types'
import {
  FetchUsersRequestAction,
  FetchUsersSuccessAction,
  ResetAccountStateAction,
  SignOutAction,
} from '../actions'

interface UsersState {
  byId: Record<string, UserAccountRecord_App>
  ids: string[]
  loadingStatus: LoadingStatus
}
type UsersReducerAction =
  | SignOutAction
  | ResetAccountStateAction
  | FetchUsersRequestAction
  | FetchUsersSuccessAction
  | DisableUserAction
  | EnableUserAction
  | AddPolicyToUserAction
  | RemovePolicyAction

const initialState: UsersState = {
  byId: {},
  ids: [],
  loadingStatus: 'NotLoaded',
}

export const usersReducer = produce<UsersState, [UsersReducerAction?]>((state, action) => {
  if (!action) return

  switch (action.type) {
    case 'SignOut': {
      return initialState
    }
    case 'ResetAccountState': {
      return initialState
    }

    case 'FetchUsersRequest': {
      state.loadingStatus = 'Requested'
      break
    }
    case 'FetchUsersSuccess': {
      action.payload.forEach(user => {
        state.byId[user.id] = user
        asSet.mutable(state.ids).add(user.id)
      })
      state.loadingStatus = 'Loaded'
      break
    }

    case 'Disable User': {
      state.byId[action.meta.userId].disabled = true
      break
    }
    case 'Enable User': {
      state.byId[action.meta.userId].disabled = false
      break
    }
    case 'Add Policy to User': {
      const { userId, policyId } = action.meta
      asSet.mutable(state.byId[userId].policyIds).add(policyId)
      break
    }
    case 'Remove Policy from User': {
      const { userId, policyId } = action.meta
      asSet.mutable(state.byId[userId].policyIds).remove(policyId)
      break
    }
    default:
      return state
  }
}, initialState)

export default usersReducer
