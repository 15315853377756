/* eslint-disable @typescript-eslint/no-explicit-any */
import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { augmentWithStats, NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'
import { Column } from 'react-table'
import { Table } from '../../../common/Table'
import {
  getNumberCellRenderer,
  getNumberCellRendererWithStats,
} from '../../../common/Table/TableCellRenderer/getNumberCellRenderer'
import { StatsLegend } from '../../../common/StatsLegend'
import { Tooltip } from 'antd'

const maximumFractionDigits = 1
const formatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: maximumFractionDigits,
  maximumFractionDigits,
  style: 'percent',
})

const PriorityRenderer = getNumberCellRenderer({ formatter })
const PriorityRendererWithStats = getNumberCellRendererWithStats({ formatter })

export type CriteriaPriorityTableData = {
  id: string
  name: string
  color: string
  priorityByParticipantId: {
    [participantId: string]: NumberWithStats
  }
  groupAvgLocal: number
  groupAvgGlobal: number
}

function getTableData(criteria: Criterion[], maximumFractionalDigits: number): CriteriaPriorityTableData[] {
  return criteria.map(c => {
    const withStats = augmentWithStats<{ participantId: string; localPri: number }>(
      Object.entries(c.localPriByParticipantId).map(([participantId, localPri]) => ({ participantId, localPri })),
      (datum: { localPri: number }) => datum.localPri,
      maximumFractionalDigits,
      'percent'
    )
    const priWithStats = _keyBy(withStats, 'datum.participantId')
    const priorityByParticipantId = _mapValues(priWithStats, ({ numberWithStats }) => numberWithStats)
    return {
      id: c.id,
      name: c.name,
      color: c.color,
      priorityByParticipantId,
      groupAvgGlobal: c.pri.global ?? 0,
      groupAvgLocal: c.pri.local ?? 0,
    }
  })
}

interface ContextPriorityTableProps {
  decision: Decision
  contextCriterionId: string
}
const PRI_COL_WIDTH = 60
export function ContextPriorityTable(props: ContextPriorityTableProps) {
  const { decision } = props
  const ctx = decision.criteria.byId[props.contextCriterionId]
  const tableData = getTableData(ctx.children, 1)
  const cols: Column<CriteriaPriorityTableData>[] = [
    {
      Header: 'Criterion',
      accessor: 'name',
    },
    {
      Header: 'Local Priority',
      columns: [
        {
          Header: 'Avg.',
          align: 'right',
          width: PRI_COL_WIDTH,
          maxWidth: PRI_COL_WIDTH,
          sortType: 'numeric',
          accessor: 'groupAvgLocal',
          Cell: PriorityRenderer,
        },
        ...decision.participants.map<Column<CriteriaPriorityTableData>>(p => ({
          id: p.id,
          Header: () => <Tooltip title={p.fullName}>{p.initials ?? p.fullName}</Tooltip>,
          align: 'right' as const,
          width: PRI_COL_WIDTH,
          maxWidth: PRI_COL_WIDTH,
          sortType: 'numeric',
          accessor: row => row.priorityByParticipantId[p.id] ?? null,
          // TODO: fix typing...currently fixing with "as any"
          Cell: PriorityRendererWithStats as any,
        })),
      ],
    },
  ]

  return (
    <>
      <Table data={tableData} columns={cols} fixFirstCol />
      <StatsLegend formatter={formatter} />
    </>
  )
}
