/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core'
import { Button } from 'antd'

import { Option } from '@vms/vmspro3-core/dist/nextgen/options'
import { Rating, ValidRating } from '@vms/vmspro3-core/dist/types'

import { ValuemetricsChart } from './ValuemetricsChart'
import { RatingNodeLayout } from '../../../common/Rating/RatingNodeLayout'
import { Table, TableCellRenderer } from '../../../common/Table'
import { RatingSubject } from '../../../common/Rating/RatingSubject'
import { RatingNode } from '../../../common/Rating/RatingNode'

import style from './SensitivityAnalysis.module.css'
import { useDecision } from '../../../../redux/hooks'
import { positionToRatingVector, useRatingDndContextConfig } from '../../../common/Rating/utils'
import { restrictToParentElement } from '@dnd-kit/modifiers'

/**
 * Sort function sortable for Array#sort, such that options are sorted by value.
 * Options with indeterminate value (null) will be sorted to the bottom.
 */
function sortByValue(a: Option, b: Option) {
  if (typeof a?.valueGraph.value === 'number') {
    if (typeof b?.valueGraph.value === 'number') return b.valueGraph.value - a.valueGraph.value
    return -1
  }
  if (typeof b?.valueGraph.value === 'number') return 1
  return 0
}

interface SensitivityAnalysisProps {
  decisionId: string
}

type SensitivityAnalysisTableData = {
  optionId: string
  name: string
  abbrev: string
  value: number | null
  updated: number | null
}

const initialSortState = {
  sortBy: [{ id: 'updated', desc: true }],
}
const columns: Column<SensitivityAnalysisTableData>[] = [
  {
    Header: 'Abbrev.',
    accessor: 'abbrev',
  },
  {
    Header: 'Option',
    accessor: 'name',
    Cell: TableCellRenderer.EntityName as any,
  },
  {
    Header: 'Value Index',
    accessor: 'value',
    Cell: cellProps => {
      if (cellProps.value === null) {
        return null
      }

      return Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(cellProps.value) as any
    },
    sortType: 'numeric',
    sortDescFirst: true,
    align: 'right',
  },
  {
    Header: 'Updated Value Index',
    accessor: 'updated',
    Cell: cellProps => {
      if (cellProps.value === null) {
        return null
      }

      return Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(cellProps.value) as any
    },
    sortType: 'numeric',
    sortDescFirst: true,
    align: 'right',
  },
]

export function SensitivityAnalysis({ decisionId }: SensitivityAnalysisProps) {
  const _decision = useDecision(decisionId)
  const decisionForSensitivityAnalysis = useMemo(() => _decision.cloneForSensitivityAnalysis(), [_decision])
  const ratingContexts = useMemo(
    () => decisionForSensitivityAnalysis.getRatingContexts('CriteriaPrioritization', '*'),
    [decisionForSensitivityAnalysis]
  )

  const [contextIndex, setContextIndex] = useState<number>(0)
  const maxContextIndex = ratingContexts.length - 1
  const onPrevContext = useCallback(
    () =>
      setContextIndex(currentContextIndex => {
        if (currentContextIndex > 0) {
          return currentContextIndex - 1
        }
        return maxContextIndex
      }),
    [maxContextIndex]
  )
  const onNextContext = useCallback(
    () =>
      setContextIndex(currentContextIndex => {
        if (currentContextIndex < maxContextIndex) {
          return currentContextIndex + 1
        }
        return 0
      }),
    [maxContextIndex]
  )
  const ratingContext = ratingContexts[contextIndex]

  const [zIndexOrder, setZIndexOrder] = useState<UniqueIdentifier[]>([])
  const [updatedRatings, setUpdatedRatingsBySubjectId] = useState<Record<string, Rating>>()

  const dndContextProps = useRatingDndContextConfig({
    modifiers: [restrictToParentElement],
    onDragStart: useCallback((event: DragStartEvent) => {
      setZIndexOrder(order => {
        const oldIdx = order.indexOf(event.active.id)
        if (oldIdx > -1) order.splice(oldIdx, 1)
        return order.concat(event.active.id)
      })
    }, []),
    onDragEnd: useCallback(
      (event: DragEndEvent) => {
        if (typeof event.active.id === 'string') {
          setUpdatedRatingsBySubjectId(_ratingsBySubjectId => {
            if (event.active.data.current?.rating && event.over) {
              const range = {
                min: ratingContext.ratingScaleConfig.minRating,
                max: ratingContext.ratingScaleConfig.maxRating,
              }

              const activeRect = event.active.rect.current.translated
              const overRect = event.over.rect
              const ratingVector = positionToRatingVector(range, activeRect, overRect)

              return {
                ..._ratingsBySubjectId,
                [event.active.id]: {
                  ...(event.active.data.current.rating as Rating),
                  ratingVector,
                },
              }
            }

            return _ratingsBySubjectId
          })
        }
      },
      [ratingContext.ratingScaleConfig.minRating, ratingContext.ratingScaleConfig.maxRating]
    ),
  })

  const allRatings = useMemo(
    () =>
      ratingContexts.reduce<Record<string, Rating>>(
        (acc, ratingContext) => Object.assign(acc, ratingContext.ratingBySubjectId),
        {}
      ),
    [ratingContexts]
  )

  const [tableData, sortedOptions] = useMemo(() => {
    const ratings = Object.values({ ...allRatings, ...updatedRatings }) as ValidRating[]
    const decisionWithNewRatings = decisionForSensitivityAnalysis.cloneWithNewCriteriaRatings(ratings)

    return [
      decisionWithNewRatings.options.all.map(option => ({
        name: option.name,
        abbrev: option.abbrev,
        optionId: option.id,
        value: decisionForSensitivityAnalysis.options.byId(option.id)?.valueGraph.value ?? null,
        updated: option.valueGraph.value,
      })),
      decisionWithNewRatings.options.all.slice().sort(sortByValue),
    ]
  }, [decisionForSensitivityAnalysis, updatedRatings, allRatings])

  return (
    <div className={style.container}>
      <div>
        <h1>{decisionForSensitivityAnalysis.data.name}</h1>
        <h2>Sensitivity Analysis</h2>
      </div>
      <div className={style.sensitivityAnalysis}>
        <div className={style.criteriaPrioritization}>
          <div className={style.contextControls}>
            <Button disabled={ratingContexts.length <= 1} type="primary" onClick={onPrevContext}>
              &lt; Prev
            </Button>
            {ratingContext.contextCriterion.name}
            <Button disabled={ratingContexts.length <= 1} type="primary" onClick={onNextContext}>
              Next &gt;
            </Button>
          </div>
          <DndContext {...dndContextProps}>
            <RatingNodeLayout
              minRatingLabel={ratingContext.ratingScaleConfig.minRatingLabel}
              maxRatingLabel={ratingContext.ratingScaleConfig.maxRatingLabel}
              ratingNode={
                <RatingNode nodeId="ratingCanvas">
                  {ratingContext.subjects.map(subject => (
                    <RatingSubject
                      key={subject.id}
                      subject={subject}
                      rating={updatedRatings?.[subject.id] ?? ratingContext.ratingBySubjectId[subject.id]}
                      minRating={ratingContext.ratingScaleConfig.minRating}
                      maxRating={ratingContext.ratingScaleConfig.maxRating}
                      hideRating
                      fontSize="16px"
                      zIndex={zIndexOrder.indexOf(subject.id) + 100}
                    />
                  ))}
                  {ratingContext.subjects.map(subject => (
                    <RatingSubject
                      disabled
                      key={subject.id}
                      subject={{ ...subject, id: subject.id + '-disabled' }}
                      rating={ratingContext.ratingBySubjectId[subject.id]}
                      minRating={ratingContext.ratingScaleConfig.minRating}
                      maxRating={ratingContext.ratingScaleConfig.maxRating}
                      hideRating
                      fontSize="16px"
                      zIndex={0}
                    />
                  ))}
                </RatingNode>
              }
            />
          </DndContext>
        </div>
        <div className={style.output}>
          <div className={style.valuemetricsChart}>
            <h3>Option Rankings</h3>
            <ValuemetricsChart
              options={sortedOptions}
              valueFunctionExpr={decisionForSensitivityAnalysis.valueFunctionExpr}
            />
          </div>
          <Table<SensitivityAnalysisTableData>
            columns={columns}
            data={tableData}
            initialState={initialSortState}
          />
          <div>
            <Button type="primary" onClick={() => setUpdatedRatingsBySubjectId(allRatings)}>
              Reset to Group Average
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
