import { useCallback } from 'react'
import { gql, useQuery, useMutation, QueryResult } from '@apollo/client'
import _some from 'lodash/some'

import { Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/options'
import { ParticipationSessionData } from '@vms/vmspro3-core/dist/nextgen/participationSession'

import {
  DecisionFieldsFragment,
  CriterionFieldsFragment,
  OptionFieldsFragment,
  ParticipationSessionFieldsFragment,
  RatingFieldsFragment,
  RatingNotesFieldsFragment,
} from '../graphql'

const GET_DECISION_FOR_PARTICIPANT = gql`
  query GetDecisionForParticipant($accountId: ID!, $decisionId: ID!, $participantId: ID!) {
    decisionForParticipant(accountId: $accountId, decisionId: $decisionId, participantId: $participantId) {
      ...DecisionFields
      criteria {
        ...CriterionFields
      }
      options {
        ...OptionFields
      }
      participationSessions {
        ...ParticipationSessionFields
      }
      ratings {
        ...RatingFields
      }
      ratingNotes {
        ...RatingNotesFields
      }
    }
  }
  ${DecisionFieldsFragment}
  ${CriterionFieldsFragment}
  ${OptionFieldsFragment}
  ${ParticipationSessionFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`
export type DecisionForParticipantData = {
  decisionForParticipant: {
    id: string
    ancestry: string
    name: string
    objective: { value: string }
    description: { value: string }
    criteria: CriterionData[]
    options: OptionData[]
    ratings: Rating[]
    ratingNotes: RatingNotes[]
    participationSessions: ParticipationSessionData[]
  }
}
type DecisionForParticipantVariables = {
  accountId: string
  decisionId: string
  participantId: string | undefined
}
export function useDecisionForParticipant(
  accountId: string,
  decisionId: string,
  participantId?: string
): QueryResult<DecisionForParticipantData, DecisionForParticipantVariables> {
  return useQuery<DecisionForParticipantData, DecisionForParticipantVariables>(GET_DECISION_FOR_PARTICIPANT, {
    variables: { accountId, decisionId, participantId },
    skip: !participantId,
  })
}

const UPDATE_RATING = gql`
  mutation UpdateRating(
    $accountId: ID!
    $decisionId: ID!
    $participationSessionId: ID!
    $participantId: ID!
    $contextType: String!
    $contextId: ID!
    $subjectType: String!
    $subjectId: ID!
    $ratingVector: [Float!]
    $abstain: Boolean
  ) {
    updateRating(
      accountId: $accountId
      decisionId: $decisionId
      participationSessionId: $participationSessionId
      participantId: $participantId
      contextType: $contextType
      contextId: $contextId
      subjectType: $subjectType
      subjectId: $subjectId
      ratingVector: $ratingVector
      abstain: $abstain
    ) {
      ...RatingFields
    }
  }
  ${RatingFieldsFragment}
`
type UpdateRatingData = {
  updateRating: Rating & {
    __typename: 'Rating'
  }
}
type UpdateRatingVariables = Omit<Rating, 'updated'> & {
  accountId: string
  decisionId: string
}
export function useUpdateRating(accountId: string, decisionId: string, participantId: string) {
  const [updateRatingMutation] = useMutation<UpdateRatingData, UpdateRatingVariables>(UPDATE_RATING, {
    onError: () => null, // handle errors with error object returned from useMutation hook
    update(cache, { data }) {
      if (data?.updateRating) {
        const cachedDecision = cache.readQuery<DecisionForParticipantData, DecisionForParticipantVariables>({
          query: GET_DECISION_FOR_PARTICIPANT,
          variables: { accountId, decisionId, participantId },
        })

        if (cachedDecision) {
          cache.modify({
            id: cache.identify(cachedDecision.decisionForParticipant),
            fields: {
              ratings(existingRatingRefs = []) {
                const updatedRatingRef = cache.writeFragment({
                  id: cache.identify(data.updateRating),
                  fragment: RatingFieldsFragment,
                  fragmentName: 'RatingFields',
                  data: data.updateRating,
                })

                if (!updatedRatingRef || _some(existingRatingRefs, updatedRatingRef)) {
                  return existingRatingRefs
                }
                return existingRatingRefs.concat(updatedRatingRef)
              },
            },
          })
        }
      }
    },
  })

  const updateRating = useCallback(
    (rating: Omit<Rating, 'updated'>) =>
      updateRatingMutation({
        variables: {
          accountId,
          decisionId,
          ...rating,
        },
        optimisticResponse: {
          updateRating: {
            __typename: 'Rating',
            // updated here is necessary to quell some console errors...it's overwritten below by value
            // in "rating", if any
            updated: {
              timestamp: Date.now(),
              location: '{}',
            },
            ...rating,
          },
        },
      }),
    [updateRatingMutation, accountId, decisionId]
  )

  return updateRating
}

const UPDATE_RATING_NOTES = gql`
  mutation UpdateRatingNotes(
    $accountId: ID!
    $decisionId: ID!
    $participationSessionId: ID!
    $participantId: ID!
    $contextType: String!
    $contextId: ID!
    $subjectType: String!
    $notesHtml: String!
  ) {
    updateRatingNotes(
      accountId: $accountId
      decisionId: $decisionId
      participationSessionId: $participationSessionId
      participantId: $participantId
      contextType: $contextType
      contextId: $contextId
      subjectType: $subjectType
      notesHtml: $notesHtml
    ) {
      ...RatingNotesFields
    }
  }
  ${RatingNotesFieldsFragment}
`
type UpdateRatingNotesData = {
  updateRatingNotes: Omit<RatingNotes, 'notes' | 'updated'> & {
    __typename: 'RatingNotes'
    notes: {
      __typename: 'Html'
      value: string
    }
  }
}
type UpdateRatingNotesVariables = Omit<RatingNotes, 'notes' | 'updated'> & {
  notesHtml: string
  accountId: string
  decisionId: string
}
export function useUpdateRatingNotes(accountId: string, decisionId: string) {
  const [updateRatingMutation] = useMutation<UpdateRatingNotesData, UpdateRatingNotesVariables>(
    UPDATE_RATING_NOTES,
    {
      onError: () => null, // handle errors with error object returned from useMutation hook
      update(cache, { data }, { variables }) {
        if (data?.updateRatingNotes) {
          const cachedDecision = cache.readQuery<DecisionForParticipantData, DecisionForParticipantVariables>({
            query: GET_DECISION_FOR_PARTICIPANT,
            variables,
          })

          if (cachedDecision) {
            cache.modify({
              id: cache.identify(cachedDecision.decisionForParticipant),
              fields: {
                ratingNotes(existingRatingNotesRefs = []) {
                  const updatedRatingNotesRef = cache.writeFragment({
                    id: cache.identify(data.updateRatingNotes),
                    fragment: RatingNotesFieldsFragment,
                    fragmentName: 'RatingNotesFields',
                    data: data.updateRatingNotes,
                  })

                  if (!updatedRatingNotesRef || _some(existingRatingNotesRefs, updatedRatingNotesRef)) {
                    return existingRatingNotesRefs
                  }
                  return existingRatingNotesRefs.concat(updatedRatingNotesRef)
                },
              },
            })
          }
        }
      },
    }
  )

  const updateRatingNotes = useCallback(
    ({ notes: { value: notesHtml }, ...ratingNotes }: Omit<RatingNotes, 'updated'>) =>
      updateRatingMutation({
        variables: {
          accountId,
          decisionId,
          ...ratingNotes,
          notesHtml: notesHtml ?? '',
        },
        optimisticResponse: {
          updateRatingNotes: {
            __typename: 'RatingNotes',
            ...ratingNotes,
            notes: {
              __typename: 'Html',
              value: notesHtml ?? '',
            },
          },
        },
      }),
    [updateRatingMutation, accountId, decisionId]
  )
  return updateRatingNotes
}
