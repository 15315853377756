// TODO: this should be moved together with AccountStatus in core/src/types/account.ts
const AccountStatus = {
  Unconfigured: 'Pending', // a newly-created account that doesn't yet have a subscription / product
  Active: 'Active',
  Canceled: 'Canceled',
  Deleted: 'Deleted',
}

const ASSUMED_AUTHZ_STORAGE_KEY = 'ASSUMED_AUTHZ'
const AssumeAuthzType = {
  ASSUME_ROLE: 'ASSUME_ROLE',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
}

const Color = {
  BLACK: 'rgba(0, 0, 0, 0.85)',
  BLUE: '#0093ff',
  BLUE_DARK: '#002766',
  BLUE_LIGHT: '#c1e7f3',
  BLUE_LINK: '#1890ff',
  BLUE_NAVY: '#003381',
  BLUE_SLIDER: '#296ed2',
  BLUE_SLIDER_DARK: '#0047aa',
  GRAY: '#838486',
  GRAY_DARK: 'rgba(0,0,0,0.65)',
  GRAY_LIGHT: '#e5e5e5',
  GRAY_LIGHTER: '#F8F8F8',
  GRAY_2: '#b9bbbe',
  GRAY_TEXT: 'rgba(0,0,0,0.45)',
  GRAY_BLUE_LIGHTER: '#f0f2f5',
  GREEN: '#72C040',
  GREEN_PALE: '#e1ebe1',
  GREEN_PALE_DARK: '#d7e1d7',
  LAVENDER: '#a497ef',
  ORANGE: '#faad14',
  PURPLE_PALE: '#e6e1eb',
  PURPLE_PALE_DARK: '#dcd7e1',
  PURPLE_DARK: '#0E0A10',
  RED: '#f5222d',
  TURQUOISE: '#13baba',
  VMS_BLUE: '#3d75b2',
  VMS_GREEN: '#06ad53',
  WHITE: '#ffffff',
  YELLOW_DARK: '#F2BE19',
  YELLOW_PALE: '#ebebe1',
  YELLOW_PALE_DARK: '#e1e1d7',
  ERROR: '#F5222D',
  WARNING: '#FAAD14',
  SUCCESS: '#52C41A',
  PRIMARY: '#096dd9',

  // Jeremy is designing a color pallet that works better with Ant Design overall.
  // TODO: We should eventually change out the older colors throughout the app with the new color pallet.
  GREY_1_LIGHTER: '#F9FAFD',
  GREY_1_LIGHT: '#F5F6F9',
  GREY_1: '#F1F2F5',
  GREY_1_DARK: '#E5E6E9',
  GREY_1_DARKER: '#DBDCDF',
  GREY_2_LIGHT: '#CED0D2',
  GREY_2: '#BABBBE',
  GREY_2_DARK: '#9A9C9F',
  GREY_3: '#616365',
}

// email address regex from W3C with a slight modification to require email address to have a top-level-domain.
// before modification: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
// reference: https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html
// see also: http://emailregex.com/
const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/

const EntityType = {
  ACCOUNT: 'ACCOUNT',
  PORTFOLIO: 'PORTFOLIO',
  PROGRAM: 'PROGRAM',
  PROJECT: 'PROJECT',
  PROPERTY_HISTORY: 'PROPERTY_HISTORY',
  RISK: 'RISK',
  RISK_PROJECT: 'RISK_PROJECT',
  ACTIVE_USER_RECORD: 'ACTIVE_USER_RECORD',
  ACTIVE_USER_COUNT: 'ACTIVE_USER_COUNT',
  USER_INVITATION: 'USER_INVITATION',
  // moving to PascalCase to be more TypeScript & GraphQL-friendly
  DecisionFolder: 'DecisionFolder',
  DecisionFolderPolicy: 'DecisionFolderPolicy',
  Decision: 'Decision',
  Criterion: 'Criterion',
  Option: 'Option',
  ParticipationSession: 'ParticipationSession',
  Participant: 'Participant',
  Rating: 'Rating',
}

const INTEGRATION_JOB_STATE_GRAPH_ID = 'orUBsDP3hvHg8NMtWKT8yd'
const IntegrationJobStates = {
  REQUESTED: 'REQUESTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
}

const Module = {
  PORTFOLIO_MANAGEMENT: 'PORTFOLIO_MANAGEMENT',
  PORTFOLIO_RISK: 'PORTFOLIO_RISK',
  PORTFOLIO_DECISION: 'PORTFOLIO_DECISION',
}

const PolicyEffect = {
  ALLOW: 'Allow',
  DENY: 'Deny',
}

const PolicyType = {
  /**
   * @deprecated Appears not to be used anywhere; use RESOURCE instead.
   */
  CONTEXT: 'CONTEXT',
  /**
   * Policy that's attached to a resource (e.g. a container).  For example,
   * a policy that's attached to a decision folder that specifies a user to
   * grand access to folder contents.
   */
  RESOURCE: 'RESOURCE',
  ROLE: 'ROLE',
  SYSTEM: 'SYSTEM',
  TEMPLATE: 'TEMPLATE',
}

const RiskColor = {
  THREAT_HIGH: '#e63250',
  THREAT_MED: '#d8a200',
  THREAT_LOW: '#50a028',
  OPPORTUNITY_HIGH: '#00cccc',
  OPPORTUNITY_MED: '#2299aa',
  OPPORTUNITY_LOW: '#446688',
  BOTH_HIGH: Color.GRAY_DARK,
  BOTH_MED: Color.GRAY,
  BOTH_LOW: Color.GRAY_LIGHT,
  MANAGED_BACKGROUND: '#616365',
  MANAGED_BACKGROUND_HOVER: '#000',
  COST_IMPACT: Color.GREEN_PALE,
  COST_IMPACT_HOVER: Color.GREEN_PALE_DARK,
  TIME_IMPACT: Color.PURPLE_PALE,
  TIME_IMPACT_HOVER: Color.PURPLE_PALE_DARK,
  PERF_IMPACT: Color.YELLOW_PALE,
  PERF_IMPACT_HOVER: Color.YELLOW_PALE_DARK,
}

const RISK_COLOR_MATRIX = [
  ['M', 'H', 'H', 'H', 'H'],
  ['L', 'M', 'H', 'H', 'H'],
  ['L', 'M', 'M', 'H', 'H'],
  ['L', 'L', 'M', 'M', 'H'],
  ['L', 'L', 'L', 'L', 'M'],
]

const ROOT_ACCOUNT_ID = '*'
const ROOT_RISK_ENTITY_ANCESTRY = '/'
const ROOT_RISK_PORTFOLIO_ID = 'cQaU3NUCALdVimZCazujtb'
const ROOT_DECISION_NODE_ID = '6Rhx9GGKEK7ib9TPZirkdb'

const SystemPolicyId = {
  BASE_USER: '7719cd8d-885a-4059-bfd9-1c509dc65733',
  DEVELOPER: 'fe92f906-8ccc-49e6-9972-1339f08ab84a',
  SUPERUSER: '06a5834f-424d-49a9-86d8-645ab95d6dab',
  DEMONSTRATOR: 'vbQT7WGu7d1zcKaXGHtanc',
  INTERNAL: 'sPrpWRQyvRBoxa99YTF8Rs', // can create "Internal" products (i.e., no CC reuqired)
  RISK_ADMINISTRATOR: 'Y9WbN5jV56ZYwYGDmuf5q',
  RISK_EDITOR: 'd7JJeSypg6EDuhEr7CBzch',
  RISK_REVIEWER: 'vnBQKsMvhw1w7dP6G6286K',
  BILLING_ADMIN: 'g1L2mEhGVAzxf6qAAheB26',
  DECISION_ADMIN: 'a9wfDjsMyZFAgcQL1HeipN',
  DECISION_USER: 'uerw0nj7wpcgxh1emj5k37x3',
}

const RiskAnalysisStatus = {
  ACTIVE: 'ACTIVE',
  DORMANT: 'DORMANT',
  RETIRED: 'RETIRED',
}

const riskAnalysisStatusByKey = {
  [RiskAnalysisStatus.ACTIVE]: { key: RiskAnalysisStatus.ACTIVE, label: 'Active' },
  [RiskAnalysisStatus.DORMANT]: { key: RiskAnalysisStatus.DORMANT, label: 'Dormant' },
  [RiskAnalysisStatus.RETIRED]: { key: RiskAnalysisStatus.RETIRED, label: 'Retired' },
}

const RiskImpactType = {
  NONE: 'NONE',
  THREAT: 'THREAT',
  OPPORTUNITY: 'OPPORTUNITY',
}

const riskImpactTypesByKey = {
  [RiskImpactType.NONE]: { key: RiskImpactType.NONE, label: 'Neither' },
  [RiskImpactType.THREAT]: { key: RiskImpactType.THREAT, label: 'Threat' },
  [RiskImpactType.OPPORTUNITY]: { key: RiskImpactType.OPPORTUNITY, label: 'Opportunity' },
}

module.exports = {
  // eslint-disable-line @typescript-eslint/no-unused-vars, no-undef
  AccountStatus,
  ASSUMED_AUTHZ_STORAGE_KEY,
  AssumeAuthzType,
  Color,
  EmailRegex,
  EntityType,
  INTEGRATION_JOB_STATE_GRAPH_ID,
  IntegrationJobStates,
  Module,
  PolicyEffect,
  PolicyType,
  RiskColor,
  RISK_COLOR_MATRIX,
  ROOT_ACCOUNT_ID,
  ROOT_RISK_ENTITY_ANCESTRY,
  ROOT_RISK_PORTFOLIO_ID,
  ROOT_DECISION_NODE_ID,
  SystemPolicyId,
  RiskAnalysisStatus,
  riskAnalysisStatusByKey,
  RiskImpactType,
  riskImpactTypesByKey,
}
