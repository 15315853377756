import { ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import _debounce from 'lodash/debounce'
import { CheckOutlined, SyncOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { createId } from '@vms/vmspro3-core/dist/idUtils'

import style from './DebounceTextArea.module.css'

export type DebounceTextAreaProps = {
  label: string
  value?: string | null
  onChange?: (value: string) => void
}
export function DebounceTextArea({ label, value, onChange }: DebounceTextAreaProps) {
  const instanceIdRef = useRef<string>('debounce-text-area-' + createId())

  const [loading, setLoading] = useState<boolean>(false)
  const [hasFeedback, setHasFeedback] = useState(false)

  const effectValue = value || ''
  const [inputValue, setInputValue] = useState<string>(effectValue)
  useEffect(() => setInputValue(effectValue), [effectValue])

  const onChangeValue = useMemo<ChangeEventHandler<HTMLTextAreaElement>>(() => {
    const debouncedSetHasFeedback = _debounce(setHasFeedback, 200)
    const debouncedOnChange = _debounce((value: string) => {
      onChange?.(value)
      setLoading(false)
    }, 500)

    return event => {
      setHasFeedback(false)
      setInputValue(event.target.value)
      setLoading(true)
      debouncedSetHasFeedback(true)
      debouncedOnChange(event.target.value)
    }
  }, [onChange])

  return (
    <div className={style.debounceTextArea}>
      <label className={style.label} htmlFor={instanceIdRef.current}>
        {label && `${label}: `}
        {hasFeedback &&
          (loading ? (
            <SyncOutlined className={style.syncIcon} spin />
          ) : (
            <CheckOutlined className={style.checkIcon} />
          ))}
      </label>
      <Input.TextArea
        size="large"
        autoSize={{ minRows: 4 }}
        className={style.input}
        id={instanceIdRef.current}
        value={inputValue}
        onChange={onChangeValue}
      />
    </div>
  )
}
