import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { produce } from 'immer'
import { Form, Input } from 'antd'

import { createId } from '@vms/vmspro3-core/dist/idUtils'
import { actions } from '@vms/vmspro3-core/dist'

import FormModal from './FormModal'
import { useModalData } from '../RiskModalContext'

const RiskPhaseModal = () => {
  const dispatch = useDispatch()

  const { entityId, entityType, riskContext, phase, entityAncestry, phases } = useModalData(RiskPhaseModal.id)
  const onOk = values => {
    const updatePhase = phase ? { ...phase, ...values } : { ...values, value: createId() }
    // note that we have to send the entire risk context to update
    const payload = {
      ...riskContext,
      types: produce(riskContext.types, types => {
        const t = types.phase
        t.values = phase
          ? t.values.map(v => (v.value === updatePhase.value ? updatePhase : v))
          : [...t.values, updatePhase]
      }),
    }
    const meta = {
      entityId,
      entityType,
      ancestry: entityAncestry,
    }
    const action = actions.riskContext.update(payload, meta)
    dispatch(action)
  }

  const abbrevMaxLength = 10

  const validateName = useCallback(
    async (_, value) => {
      const siblingNames = phases.filter(p => !p.isDeleted && p.value !== phase?.value).map(p => p.label.long)
      if (siblingNames.includes(value)) {
        throw new Error(`${value} is already in use. Phase names must be unique.`)
      }
    },
    [phase, phases]
  )
  const validateAbbrev = useCallback(
    async (_, value) => {
      const siblingAbbreviations = phases
        .filter(p => !p.isDeleted && (!phase || p.value !== phase.value))
        .map(p => p.label.short)
      if (siblingAbbreviations.includes(value)) {
        throw new Error(`${value} is already in use. Phase abbreviations must be unique`)
      }
    },
    [phase, phases]
  )

  return (
    <FormModal modalId={RiskPhaseModal.id} onOk={onOk} initialValues={phase}>
      <Form.Item
        label="Name"
        name={['label', 'long']}
        validateTrigger="onBlur"
        rules={[{ required: true, whitespace: true, message: 'Name is required.' }, { validator: validateName }]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        label="Abbreviation"
        name={['label', 'short']}
        validateTrigger="onBlur"
        rules={[
          { required: true, whitespace: true, message: 'Abbreviation is required.' },
          { max: abbrevMaxLength, message: `Abbreviation is limited to ${abbrevMaxLength} characters.` },
          { validator: validateAbbrev },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="desc">
        <Input />
      </Form.Item>
    </FormModal>
  )
}
RiskPhaseModal.id = 'RiskPhaseModal'

export default RiskPhaseModal
