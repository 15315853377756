import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { RatingScaleEditor } from '../Criteria/RatingScaleEditor'
import { colorSelectOptions } from '../../../../utils/appConsts'
import { Html } from '@vms/vmspro3-core/dist/types'
import { useAppDispatch } from '../../../../redux'
import { useCallback } from 'react'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { updateCriterion } from '@vms/vmspro3-core/dist/actions/decision'
import { RollbackOutlined } from '@ant-design/icons'

interface CriterionBasicFormFields {
  name: string
  abbrev: string
  color: string
  description: Html
}

interface CriterionBasicsEditorProps {
  decisionId: string
  criterion: Criterion
}
function CriterionBasicsEditor(props: CriterionBasicsEditorProps) {
  const { decisionId, criterion } = props
  const [form] = Form.useForm<CriterionBasicFormFields>()
  const dispatch = useAppDispatch()
  const onFinish = useCallback(
    () =>
      form.validateFields().then(({ description, ...restFields }) => {
        const data = {
          ...restFields,
          description: createHtmlObject(description?.value ?? null),
        }

        dispatch(updateCriterion(decisionId, criterion.id, data))
      }),
    [form, dispatch, decisionId, criterion.id]
  )
  return (
    <Form form={form} layout="vertical" initialValues={criterion.data} onFinish={onFinish}>
      <Form.Item label="Name" name="name" rules={[{ required: true, whitespace: true }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item label="Abbrev." name="abbrev" rules={[{ required: true, whitespace: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Color" name="color" rules={[{ required: true }]}>
        <Select>
          {colorSelectOptions.map(o => (
            <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
              <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                <div style={{ width: '50px', backgroundColor: o.value }} />
                <span style={{ alignSelf: 'center' }}>{o.label}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Description" name={['description', 'value']}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

interface CriterionEditorProps {
  decisionId: string
  criterion: Criterion
  onExit: () => void
}
export function CriterionEditor(props: CriterionEditorProps) {
  const { criterion, onExit } = props
  return (
    <>
      <Row gutter={24}>
        <Col>
          <h2>Editing "{criterion.name}"</h2>
        </Col>
        <Col>
          <Button type="primary" onClick={onExit} icon={<RollbackOutlined />}>
            Done
          </Button>
        </Col>
      </Row>
      <Row gutter={36}>
        <Col xs={24} lg={8}>
          <CriterionBasicsEditor decisionId={props.decisionId} criterion={criterion} />
        </Col>
        <Col xs={24} lg={16}>
          <RatingScaleEditor decisionId={props.decisionId} criterion={props.criterion} key={props.criterion.id} />
        </Col>
      </Row>
    </>
  )
}
