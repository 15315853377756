import { produce } from 'immer'
import _omitBy from 'lodash/omitBy'
import _matches from 'lodash/matches'

import { Rating } from '@vms/vmspro3-core/dist/types'
import {
  UpdateRatingAction,
  DeleteParticipantAction,
  DeleteParticipantDataAction,
  DeleteCriterionAction,
  DeleteOptionAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actions'

export type RatingsState = {
  byId: Record<string, Rating>
}
type RatingsReducerAction =
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | UpdateRatingAction
  | DeleteParticipantAction
  | DeleteParticipantDataAction
  | DeleteCriterionAction
  | DeleteOptionAction

export const initialState: RatingsState = {
  byId: {},
}

export const ratingsReducer = produce<RatingsState, [RatingsReducerAction?]>((state, action) => {
  if (!action?.type) return

  switch (action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to fetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if (action.payload.ratings) {
        Object.assign(state.byId, action.payload.ratings)
      }
      break
    }

    case 'Update Rating': {
      state.byId[action.meta.id] = {
        ...action.payload,
        updated: {
          timestamp: Date.now(),
          location: '{}',
        },
      }
      break
    }

    case 'Delete Participant': {
      state.byId = _omitBy(state.byId, _matches({ participantId: action.meta.participantId }))
      break
    }

    case 'Delete Participant Data': {
      let nextById = state.byId
      action.meta.participantIds.forEach(participantId => {
        if (action.meta.deleteParticipant) {
          nextById = _omitBy(state.byId, _matches({ participantId }))
        } else {
          if (action.meta.deleteCriteriaPrioritizationData) {
            nextById = _omitBy(state.byId, _matches({ participantId, subjectType: 'Criterion' }))
          }
          if (action.meta.deleteOptionRatingData) {
            nextById = _omitBy(state.byId, _matches({ participantId, subjectType: 'OptionRating' }))
          }
          if (action.meta.deleteOutcomeProbabilityData) {
            nextById = _omitBy(state.byId, _matches({ participantId, subjectType: 'OptionOutcome' }))
          }
        }
      })
      state.byId = nextById
      break
    }

    case 'Delete Criterion': {
      const { criterionId, descendantCriteriaIds = [] } = action.meta
      const criteriaIdsToDelete = [criterionId, ...descendantCriteriaIds]
      state.byId = _omitBy(state.byId, rating =>
        criteriaIdsToDelete.some(
          criterionId => rating.subjectId === criterionId || rating.contextId === criterionId
        )
      )
      break
    }

    case 'Delete Option': {
      state.byId = _omitBy(state.byId, _matches({ subjectId: action.meta.optionId }))
      break
    }

    default: {
      break
    }
  }
}, initialState)
