import React, { useCallback } from 'react'

import ReportDownloadButton from './ReportDownloadButton'
import Server from '../../../server/VMSProServerAdapter'

/**
 * @prop {string} entityId - entity that contains the risks (this will determine the risk context)
 * @prop {Object} style?
 */
const RiskContextExcelReportButton = ({ entityId, style }) => {
  const getReport = useCallback(() => Server.getRiskContextReport(entityId), [entityId])

  return <ReportDownloadButton style={style} getReport={getReport} label="Download Risk Project Report" />
}

export default RiskContextExcelReportButton
