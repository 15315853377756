import { useCallback } from 'react'
import _get from 'lodash/get'
import { Col, Row } from 'antd'

import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core/dist'

import PageMenu from './PageMenu'
import { RoleDetails } from './RoleDetails'
import { Page } from '../../common/Page'

import useAuthz from '../../../hooks/useAuthz'
import { assumeAuthz } from '../../../utils/authzUtils'
import { selectRolePolicyMenuConfig } from '../../../redux/selectors'
import { useFetchUsers } from '../../../redux/hooks'
import { useAppSelector } from '../../../redux'
import { useAuth } from '../../../context'
import { useQuerystringValue } from '../../../hooks/useQuerystringValue'

export function RolesPage() {
  useFetchUsers()

  const rolePolicyMenuConfig = useAppSelector(selectRolePolicyMenuConfig)

  const [selectedRolePolicyId, onTabChange] = useQuerystringValue(
    'role',
    _get(rolePolicyMenuConfig, '[0].items[0].key')
  )

  const { authUserId } = useAuth()
  const getAssumeRoleAction = useCallback(
    (policyId: string) =>
      actions.user.assumeAuthz(
        { policyId },
        {
          type: systemConsts.AssumeAuthzType.ASSUME_ROLE,
          authUserId,
        }
      ),
    [authUserId]
  )

  const authz = useAuthz()
  const assumeRole = () => assumeAuthz(authz, getAssumeRoleAction(selectedRolePolicyId))

  const loadingUsers = useAppSelector(state => state.users.loadingStatus !== 'Loaded')

  return (
    <Page loading={loadingUsers} title="Roles">
      <Row gutter={30}>
        <Col flex="1">
          {rolePolicyMenuConfig.map(({ title, items }) => (
            <PageMenu
              key={title}
              menuItems={items}
              menuTitle={title}
              onSelectKey={onTabChange}
              selectedKey={selectedRolePolicyId}
            />
          ))}
        </Col>
        <Col flex="3">
          {selectedRolePolicyId && (
            <RoleDetails
              assumeRole={assumeRole}
              canAssumeRole={authz(getAssumeRoleAction(selectedRolePolicyId))}
              policyId={selectedRolePolicyId}
            />
          )}
        </Col>
      </Row>
    </Page>
  )
}
