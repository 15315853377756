import { useSelector } from 'react-redux'

import { LoadingStatus } from '../../../utils/appConsts'

/**
 * Returns a loading status and an object of users keyed by ID
 *
 * TODO: remove this abomination as soon as the following files no longer use it:
 *  - RiskPropertyHistory.js
 *  - ResourceAuthorizationEditor.js
 */
function useUsers() {
  const usersState = useSelector(state => state.users)
  const { byId, loadingStatus } = usersState

  const loadingUsers = loadingStatus !== LoadingStatus.Loaded

  return [byId, loadingUsers]
}

export { useUsers }
