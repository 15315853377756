import { useCallback } from 'react'

import { useAuth } from '../context'
import { Action } from '../utils/authzUtils'
import { augmentAction } from '../redux/middleware/augmentAction'

export function useAugmentAction(accountId?: string) {
  const { authUserId, authUser, assumedAuthz } = useAuth()

  return useCallback(
    (action: Action) =>
      augmentAction(action, {
        accountId,
        authUserId,
        authUserName: authUser.fullName,
        assumedAuthz,
      }),
    [authUserId, authUser.fullName, accountId, assumedAuthz]
  )
}
