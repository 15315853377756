import _keyBy from 'lodash/keyBy'
import _sumBy from 'lodash/sumBy'
import _groupBy from 'lodash/groupBy'
import _mapValues from 'lodash/mapValues'
import _mapKeys from 'lodash/mapKeys'
import _has from 'lodash/has'

import type { ReadonlyDeep } from 'type-fest'

import * as valueFormula from './valueFormula'

import { Rating, RatingNotes } from '../types/rating'
import { Criterion } from '../types/criterion'
import {
  CriteriaIndex,
  CriteriaPrioritizationIndex,
  filterInvalidRatings,
} from './prioritization'
import { vectorMean, vectorStats, extractCoefficient } from './util'
import { Html } from '../types'
import { createId } from '../idUtils'
import evaluateValueExpression from './evaluateValueExpression'

/**
 * An option specifically for the purpose of valuemetrics within a specific context.  A "real"
 * option would probably look different; for example, cost and time would have units (possibly
 * nonhomogeneous) as well as many other properties.  However, none of that is needed for
 * valuemetrics: all intrinsic quantitative properties must be expressed in the same units,
 * and extraneous metadata is not relevant.
 *
 * Note that "perf" is a reserved key for quant values.
 */
export type VmOption = {
  id: string
  name: string
  quant: {
    [key: string]: number | null
  }
}

/**
 * Options indexed by ID.
 */
export type OptionsIndex<T> = {
  all: T[],
  byId: { [id: string]: T },
}

/**
 * Index options by ID.
 *
 * @deprecated see core/src/nextgen/options.ts
 */
export function indexOptions<T>(options: T[]): OptionsIndex<T> {
  return {
    all: options,
    byId: _keyBy(options, 'id'),
  }
}

/**
 * An aggregate option rating; the idea is that option ratings (which could include
 * multiple ratings for the same option/criterion by different participants) are
 * aggregated into a single option rating for the purpose of valuemetrics.  Alternately,
 * a single user's ratings can be converted directly to option ratings (since there will
 * be no duplicate option/criteiron ratings).
 */
export type VmOptionRating = {
  optionId: string
  criterionId: string
  ratingVector: number[]
}

/**
 * Valuemetrics option ratings indexed by option ID, then criteria ID (there are no
 * participant IDs here; they have already been aggregated, see VmOptionRating).
 */
export type VmOptionRatingsIndex = {
  all: VmOptionRating[]
  byOptionId: {
    [id: string]: { byCriteriaId: { [id: string]: VmOptionRating } }
  }
}

/**
 * Convenience function to index a collection of option ratings.  Additionally, this aggregates
 * option ratings from potentially many participants, resulting in averaged option/criterion ratings.
 * (Note that the function takes an array of Ratings, and VmOptionRatingsIndex deals
 * in VmOptionRatings, which do not have participant IDs, since they represent aggregated ratings.)
 */
export function indexVmOptionRatings(allRatings: Rating[]): VmOptionRatingsIndex {
  const ratings = filterInvalidRatings(allRatings)
  // we first key the ratings by optionId & criterionId
  const keyedRatings = ratings.map(rating => ({
    key: rating.subjectId + ':' + rating.contextId,
    optionId: rating.subjectId,
    criterionId: rating.contextId,
    ratingVector: rating.ratingVector,
  }))
  // then group by that key
  const ratingsByKey = _groupBy(keyedRatings, 'key')
  // now we can average the rating vectors
  const byOptionId: VmOptionRatingsIndex['byOptionId'] = {}
  Object.keys(ratingsByKey).forEach(key => {
    const [optionId, criterionId] = key.split(':')
    const ratingVectors = ratingsByKey[key].map(r => r.ratingVector)
    if(ratingVectors.length == 0) return
    if(!byOptionId[optionId]) byOptionId[optionId] = { byCriteriaId: {} }
    byOptionId[optionId].byCriteriaId[criterionId] =
      { optionId, criterionId, ratingVector: vectorMean(ratingVectors) }
  })
  return {
    all: Object.values(keyedRatings),
    byOptionId,
  }
}

/**
 * Valuemetrics results.
 */
export type OptionValuemetrics = {
  optionId: string
  quant: Record<string, number | null>
  perf: {
    unweighted: {
      aggregate: number | null
      byCriteriaId: Record<string, number | null>
    }
    weighted: {
      aggregate: number | null
      byCriteriaId: Record<string, number | null>
    }
  }
  score: {
    unweighted: Record<string, number | null>
    weighted: Record<string, number | null>
  }
  change?: Record<string, number | null>
  value: number | null
  missingRatings: MissingRatingInfo[]
}

// TODO: in the future, we may wish to expand this further to include:
//   - statistics
//   - referencs to individual participant ratings
//   - participation session information
//
// although...perhaps this is abusing the scope of what "valuemetrics" should be?  perhaps
// a different (but related) layer is neeeded....
export type PerfScoreDetails = {
  criterionId: string
  optionId: string
  value: number | null
  directValue: number | null
  localPri: number | null     // essentially the coefficient here
  score: number | null
}

/**
 * @deprecated see core/src/nextgen/options.ts
 */
export function performanceScoreDetails(
  criteria: ReadonlyDeep<CriteriaIndex>,
  criteriaPrioritizations: ReadonlyDeep<CriteriaPrioritizationIndex>,
  option: ReadonlyDeep<VmOption>,
  optionRatings: ReadonlyDeep<VmOptionRatingsIndex>) {
    const details: Record<string, PerfScoreDetails> = {}
    // we need to order the criteria to process from leafs up to the performance root
    const criteriaToProcess = (function() {
      const rootPerfCri = criteria.all.find(c => c.type === 'Performance')
      if(!rootPerfCri) throw new Error('missing root performance criterion')
      let nextBatch = [rootPerfCri]
      const criteriaToProcess: ReadonlyDeep<Criterion>[] = []
      while(nextBatch.length) {
        criteriaToProcess.unshift(...nextBatch)
        nextBatch = nextBatch.map(c => criteria.childrenByParentId[c.id]).filter(Boolean).flat()
      }
      return criteriaToProcess
    }())
    criteriaToProcess.forEach(c => {
      const directValue = optionRatings.byOptionId[option.id]?.byCriteriaId[c.id]?.ratingVector[0] ?? null
      let value = directValue
      const children = criteria.childrenByParentId[c.id]
      // note that we only sum the child scores if there's a score for *all* children. this
      // is not the only resolution algorithm we could choose here; we could also say if there's
      // *any* child rating, that would take precedence.  there is no obviously "correct" resolution.
      if(children && children.every(c => details[c.id].score !== null)) {
        // note the "?? 0" below is a TypeScript concession; the Array#every above ensures all scores are present
        const sum = _sumBy(children, c => details[c.id].score ?? 0)
        // if one or more children have been scored, the score for the parent is the sum of those
        // scored children; note that there is an ambiguous situation here where the parent has
        // a direct rating AND at least one of its children has a rating, but not all of them; there
        // is no obviously "correct" resolution.

        // TODO: it would be nice to consoidate this resolution algorithm in one place instead of
        // spreading it out all over hell and creation as it currently is.
        if(typeof sum === 'number' && !Number.isNaN(sum)) value = sum
      }
      const localPri = c.type === 'Performance' ? 1 : criteriaPrioritizations.localPriorityByCriteriaId[c.id]
      details[c.id] = {
        criterionId: c.id,
        optionId: option.id,
        value,
        directValue,
        localPri,
        score: value !== null && localPri !== null ? value * localPri : null,
      }
    })
  return details
}

import { ValueGraph, ValueGraphNode, ValueGraphEdge } from './valueGraph'

/**
 * Information about missing ratings for an option.  Note that valuemetrics can still be
 * calculated with missing ratings as long as the missing ratings have a rated ancestor
 * (the ancestor rating replaces the whole subtree's rating).  Missing rating info allows
 * the facilitator to determine which ratings need to be filled in.
 */
 export type MissingRatingInfo = {
  criterion: Criterion
  option: VmOption
  substitutionAncestorCriterion: Criterion | null
}
/**
 * Performance for a single option.
 */
type PerformanceResults = {
  perf: number | null
  missingRatings: MissingRatingInfo[]
  globalPerfByCriterion: Record<string, number | null>
  valueGraph: ValueGraph
}
/**
 * Determines performance for a single option.
 *
 * @deprecated see core/src/nextgen/options.ts; if you create an Options instance with a ParticipationSession
 *   instance, you can then access the performanceGraph property of each option (the value property of the
 *   performance graph is the value of the option).
 */
function performance(
    criteria: CriteriaIndex,
    criteriaPrioritizations: CriteriaPrioritizationIndex,
    option: VmOption,
    optionRatings: VmOptionRatingsIndex,
  ): PerformanceResults {

  // TODO: this function can probably be simplified considerably by using performanceScoreDetails...
  // and that will also consolidate the logic of what to do with missing ratings/priorities in one place

  const missingRatings: MissingRatingInfo[] = []
  let perf = 0
  const globalPerfByCriterion: Record<string, number | null> =
    criteria.all.reduce((byId, cri) => Object.assign(byId, { [cri.id]: null }), {})
  const criteriaProcessed = new Set<string>()
  for(const criterion of criteria.leafCriteria) {
    let criterionId: string | null = criterion.id
    do {
      if(criteriaProcessed.has(criterionId)) break  // this ensures we don't double-count ancestors
                                                    // in the event of missing ratings
      const r = optionRatings.byOptionId[option.id]?.byCriteriaId[criterionId]
      if(r) {
        // note that if a priority for this criterion isn't found, it's assumed to be 0
        // TODO: add warning ("rating found for unprioritized criterion")...could get tricky!
        const globalPri = criteriaPrioritizations.globalPriorityByCriteriaId[criterionId] || 0
        const globalPerf = globalPri * r.ratingVector[0]
        globalPerfByCriterion[criterionId] = globalPerf
        perf += globalPerf
        criteriaProcessed.add(criterionId)
        break
      } else {
        // if there's no rating for this leaf criteria, see if there's a rating for one of its
        // ancestors (including the root Performance node, but not higher up than that)...that
        // will produce a warning rather than an error
        if(!criteria.byId[criterionId]) {
          console.log(`missing criteria ${criterionId} for rating:`, r)
          console.log(`all criteria:`, criteria)
        }
        criterionId = criteria.byId[criterionId].parentId
      }
    } while(criterionId)
    if(criterionId !== criterion.id) {
      missingRatings.push({
        criterion,
        option,
        substitutionAncestorCriterion: criterionId ? criteria.byId[criterionId] : null
      })
    }
  }
  const details = performanceScoreDetails(criteria, criteriaPrioritizations, option, optionRatings)
  const nodeIdByCriId: Record<string, string> = {}
  const valueGraph = {
    nodes: Object.values(details).map(d => {
      const c = criteria.byId[d.criterionId]
      const id = createId()
      nodeIdByCriId[c.id] = id
      const n: ValueGraphNode = {
        id,
        type: c.type === 'Performance' ? 'PerformanceRoot' : 'Performance',
        criterionId: c.id,
        value: d.value,
        weightedValue: d.score,
      }
      if(c.type !== 'Performance') n.weight = d.localPri ?? undefined
      return n
    }),
    edges: Object.values(details)
      .filter(d => criteria.byId[d.criterionId].type !== 'Performance')
      .map(d => {
        const c = criteria.byId[d.criterionId]
        if(!c.parentId) throw new Error('unexpected root criterion')
        const parent = criteria.byId[c.parentId]
        const edge: ValueGraphEdge = {
          sourceId: nodeIdByCriId[c.id],
          targetId: nodeIdByCriId[parent.id],
          operation: 'Add',
        }
        return edge
      }),
  }
  if(missingRatings.some(mr => mr.substitutionAncestorCriterion === null))  {
    // cannot calculate performance; there is at least one criterion not rated (nor any of its ancestors)
    return { perf: null, globalPerfByCriterion, missingRatings, valueGraph }
  } else {
    return { perf, globalPerfByCriterion, missingRatings, valueGraph }
  }
}

/**
 * Signature for a function to map an option to a performance value.  If the function
 * returns null, there isn't sufficient information to establish a performance value.
 */
type PerfFunction = (option: VmOption) => PerformanceResults

/**
 * Creates a function to map options to a performance rating.  To construct such a function, you must
 * provide prioritized (weighted) hierarchical criteria as a basis for rating the option.
 *
 * @deprecated see core/src/nextgen/options.ts
 */
export function createPerfFunction(
  criteria: CriteriaIndex,
  criteriaPrioritizations: CriteriaPrioritizationIndex,
  optionRatings: VmOptionRatingsIndex,
): PerfFunction {
  return option => performance(criteria, criteriaPrioritizations, option, optionRatings)
}

/**
 * The inputs to the value function for a given option.  The parameters are a combination of the
 * rated attributes and intrinsic attributes.
 *
 * Note: we may want to consider this mapping more carefully in the future.  There's no fundamental
 * reason the value function shouldn't have acccess to all scores (including decomposed performance),
 * but we flatten perf and scores into the "parameters" list below.  One consequence of this is that
 * you can't, for example, have a score called 'perf' that's separate from performance.  Not that that
 * would be a good idea, but its illustrative of the compromise here.
 */
type ValueFunctionInputs = {
  optionId: string
  optionName: string
  parameters: Record<string, number | null>,
}

/**
 * Library of common value function parameters.
 *
 * Note: in the future the variables (perf, cost, time) will be changing format
 * to symbols (P, C, and T, nominally).
 */
export const ValueFunctionLibrary: { [key: string]:  valueFormula.Expression } = {
  Standard: [
    'Divide',
      'perf',
      ['Add',
        ['Multiply', 0.5, `cost'`],
        ['Multiply', 0.5, `time'`],
      ],
  ],
  Linear: [
    'Add',
      ['Multiply', 1/3, 'perf'],
      ['Multiply', 1/3, 'cost'],
      ['Multiply', 1/3, 'time'],
  ],
  LinearNoTime: [
    'Add',
      ['Multiply', 1/2, 'perf'],
      ['Multiply', 1/2, 'cost'],
  ],
  LinearNoCost: [
    'Add',
      ['Multiply', 1/2, 'perf'],
      ['Multiply', 1/2, 'time'],
  ],
  LinearPerfOnly: [ // this is overcomplicated (could just be 'perf'), but I'm afraid it might break something
    'Add',
      ['Multiply', 1, 'perf'],
  ],
}

const defaultSymbolLabels = {
  perf: 'P',
  cost: 'C',
  time: 'T',
  "perf'": "P'",
  "cost'": "C'",
  "time'": "T'",
}

const defaultNumberFormatter = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
}).format

function _valueExpressionToString(
  expression: valueFormula.Expression,
  symbolLabels: { [key: string]: string },
  numberFormatter = defaultNumberFormatter,
  str = '',
): string {
  if(Array.isArray(expression)) {
    const [op, ...args] = expression
    switch(op) {
      case 'Add':
        return `(${args.map(arg => _valueExpressionToString(arg, symbolLabels, numberFormatter, str)).join(' + ')})`
      case 'Multiply':
        return (args.length === 2 && typeof args[0] === 'number' && Math.abs(args[0] - 1) < Number.EPSILON)
          ? _valueExpressionToString(args[1], symbolLabels, numberFormatter, str)  // omit coefficients of 1
          : args.map(arg => _valueExpressionToString(arg, symbolLabels, numberFormatter, str)).join('·')
      case 'Divide':
        return args.map(arg => _valueExpressionToString(arg, symbolLabels, numberFormatter, str)).join(' / ')
      default:
        throw new Error('unsupported operation: ' + op)
    }
  } else {
    switch(typeof expression) {
      case 'number': return str + numberFormatter(expression)
      case 'string': return str + (symbolLabels[expression] || expression)
      default: throw new Error('unrecognized expression type: ' + typeof expression)
    }
  }
}

/**
 * Simple function to take a value expression and convert it to a "pretty" human-readable
 * string.  For example, the standard value formula would be converted to the
 * string "P / (C + T)" or, if you specify that coefficients should be included,
 * "P / (0.5·C + 0.5·T)".  Note that unitary coefficients are omitted.
 *
 * You can optionally include a list of key labels (which defaults to perf->P, cost->C,
 * time->T).
 */
export function valueExpressionToString(
  expression: valueFormula.Expression,
  symbolLabels: { [key: string]: string } = defaultSymbolLabels,
): string {
  const str = _valueExpressionToString(expression, symbolLabels)
  // the regex below is to cover an edge case: addititve terms with no denominator.
  // in this case, outer parentheses are not needed.
  return str.replace(/^\((.*)\)$/, '$1')
}

export function valueExpressionToExcelFormula(
  expression: valueFormula.Expression,
  symbolRefs: { [key: string]: string }
): string | number {
  if(Array.isArray(expression)) {
    const [op, ...args] = expression
    switch(op) {
      case 'Add':
        return `(${args.map(arg => valueExpressionToExcelFormula(arg, symbolRefs)).join('+')})`
      case 'Multiply': {
        const resolvedArgs = args.map(arg => valueExpressionToExcelFormula(arg, symbolRefs))
          .filter(v => v !== 1) // omit unitary factors
        return resolvedArgs.length === 1
          ? resolvedArgs[0]
          : `(${resolvedArgs.join('*')})`
      }
      case 'Divide':
        return args.map(arg => valueExpressionToExcelFormula(arg, symbolRefs)).join('/')
      default:
        throw new Error('unsupported operation: ' + op)
    }
  } else {
    switch(typeof expression) {
      case 'number': return expression
      case 'string': {
        if(expression.endsWith("'")) {
          const sym = expression.replace(/'$/, '')
          return `(10-${symbolRefs[sym] || sym})`
        } else {
          return symbolRefs[expression] || expression
        }
      }
      default: throw new Error('unrecognized expression type: ' + typeof expression)
    }
  }
}

/**
 * Converts a value expression to an Excel formula, suitable for use in the valuemetrics
 * worksheet.  In addition to providing the value expression, you must provide references
 * for the following symbols: perf, cost, and time (you don't need refs for the complements
 * perf', cost', and time': the function will handle that conversion).
 *
 * Unlike valueExpressionToString, no special handling of coefficients is necessary; it's
 * just another multiplication.  However, unit products are omitted (so 1*A1 just becomes A1).
 *
 * Note that the function doesn't care what you provide for the reference values, so you can
 * use meta-refs or whatever you want.
 *
 * @example
 *
 *   const valueExpression = ValueFunctionLibrary.Standard
 *   valueExpressionToExcelFormula(
 *     valueExpression,
 *     {
 *       perf: 'A1',
 *       cost: 'B1',
 *       time: 'C1',
 *     }
 *   )   // returns 'A1/(0.5*(10-10*B1)+0.5*(10-10*C1)'
 */

/**
 * Signature for a function to map valuemetrics inputs for a given option to a value
 * score.  If the return value is null, there isn't sufficient information to establish
 * a performance value.
 *
 * This also returns the weighted input parmeters (determined from the input paremeter
 * coefficients in the value expression), which can be useful for analysis.
 */
type ValueFunction = (inputs: ValueFunctionInputs) => {
  value: number | null
  weightedParameters: Record<string, number | null>
  coefficients: Record<string, number>
}

/**
 * Helper function to extract coefficients from a value function expression.  Coefficients look
 * like a 'Multiply' expression with exactly two arguments, one of which is a string (the parameter name)
 * and the other of which is a number (the coefficient).  For example, extracting the coefficients from the
 * standard value formula:
 *
 * @example
 *
 *   const expr = ['Divide',
 *     ['Multiply', 1, 'perf'],   // perf coefficient: 1
 *     ['Add',
 *       ['Multiply', 0.6, 'cost\''], // cost coefficient: 0.6
 *       ['Multiply, '0.4, 'time\''], // time coefficient: 0.4
 *     ],
 *   ]
 *   const coefficients = extractCoefficients(expr)
 *   // result: { perf: 1, cost: 0.6, time: 0.4 }
 *
 * Note that a single apostrophe ("prime") at the end of the parameter name is ignored for the purpose
 * of this algorithm.  That is, X and X', while not the same (they are complements of one another), they
 * represent the same parameter, and therefore have the same coefficient.  Note in the example above,
 * "cost" and "time" are returned as the results, but the actual parameters in th expression are "cost'"
 * and "time'".
 *
 * Note that this function doesn't handle parameters with multiple coefficients in the same expression, even
 * when they can be mathematically resolved (such as in ['Add', ['Multiply', 1, 'X'], ['Multiply, 2, 'X']]).
 * If multiple coefficients are found for the same named parameter, an error will be thrown.
 */
export const extractCoefficients = (expr: valueFormula.Expression, coefficients: Record<string, number> = {}) => {
  if(typeof expr === 'string' && !coefficients[expr]) coefficients[expr] = 1
  if(!Array.isArray(expr)) return coefficients
  const coeffAndVarname = extractCoefficient(expr)
  if(coeffAndVarname === null) {
    expr.slice(1).forEach(subExpr => extractCoefficients(subExpr, coefficients))
  } else {
    const [coeff, varname] = coeffAndVarname
    if(_has(coefficients, varname)) throw new Error(`found more than one coefficient for "${varname}"`)
    coefficients[varname] = coeff
  }
  return coefficients
}

/**
 * Create a value function from a value function expression.  The provided
 * value function will map value function inputs to a numeric value (or null
 * if value can't be calculated) and the weighted scores of each input (the
 * weight is determined by coefficients in the value expression; if a coefficient
 * can't be determined, it is assumed to be 1).
 *
 * @deprecated see core/src/nextgen/options.ts
 */
export function createValueFunction(expr: valueFormula.Expression): ValueFunction {
  const coefficients = extractCoefficients(expr)
  return inputs => {
    const vars = {
      // scores
      ...inputs.parameters,
      // score complements (the complement of X is called X', with value 10-X)
      ..._mapValues(_mapKeys(inputs.parameters, (v, k) => k + "'"), v => {
        return v === null ? null : 10 - v
      }),
    }
    const { value, valueGraph } = evaluateValueExpression(expr, vars)
    return {
      value,
      weightedParameters: _mapValues(inputs.parameters, (p, name) => {
        if(p === null) return null
        const c = _has(coefficients, name) ? coefficients[name] : 1
        return p * c
      }),
      coefficients,
      valueGraph,
    }
  }
}

/**
 * Calculate valuemetircs for a given set of options.  A performance function and value
 * function must be provided.  You may optionally provide the ID of an option to use as
 * as a baseline (in which case, % change values will also be provided).
 *
 * @deprecated see core/src/nextgen/options.ts
 */
export function valuemetrics(
  options: OptionsIndex<VmOption>,
  perfFunction: PerfFunction,
  valueFunction: ValueFunction,
  baselineOptionId?: string
): OptionValuemetrics[] {
  const quantSums = options.all.reduce<{ [key: string]: number }>((quantSums, o) => {
    Object.entries(o.quant).forEach(([k, v]) => {
      // here we treat nulls as 0
      quantSums[k] = (quantSums[k] || 0) + (v || 0)
    })
    return quantSums
  }, {})
  const optionValuemetrics = options.all.map<OptionValuemetrics>(option => {
    const { perf, globalPerfByCriterion, missingRatings } = perfFunction(option)
    const o: OptionValuemetrics = {
      optionId: option.id,
      quant: option.quant,
      perf: {
        unweighted: {
          aggregate: perf,
          byCriteriaId: globalPerfByCriterion,
        },
        // weighted perf will be set below after getting results from valueFunction
        weighted: {
          aggregate: null,
          byCriteriaId: {},
        },
      },
      score: {
        // note that a null quant value results in null corresponding score (and therefore % change)
        // TODO: currently, the only quant scores below will be cost and time, both of which are,
        // by default, the complement (they move in the oppositie direction of their corresponding value).
        // that is, a higher cost produces a lower cost score.  this won't always be the case (for example,
        // "sale price" is generally something you want to maximize), so we need a way to specify how the
        // scoring works for each parameter
        unweighted:
          _mapValues(quantSums, (qs, k) => {
            const quant = option.quant[k]
            return quant === null ? null : 10 - 10 * quant / qs
          }),
        weighted: {
          // set below, after getting results from valueFunction
        },
      },
      value: null,
      missingRatings,
    }
    const { value, weightedParameters, coefficients } = valueFunction({
      optionId: option.id,
      optionName: option.name,
      parameters: {
        perf: perf,
        ...o.score.unweighted,
      },
    })
    o.value = value
    // if perf doesn't have a coefficient, it's assumed to be unitary
    const perfCoefficient = coefficients.perf ?? coefficients["perf'"] ?? 1
    o.perf.weighted.aggregate = o.perf.unweighted.aggregate === null
      ? null
      : o.perf.unweighted.aggregate * perfCoefficient
    o.perf.weighted.byCriteriaId = _mapValues(o.perf.unweighted.byCriteriaId,
      v => v === null ? null : v * perfCoefficient)
    delete weightedParameters.perf  // if we don't do this, it'll get sucked into weighted scores below
    o.score.weighted = weightedParameters
    return o
  })
  const baseline = optionValuemetrics.find(o => o.optionId === baselineOptionId)
  if(baseline) {
    optionValuemetrics.forEach(o => {
      if(o.optionId === baselineOptionId) {
        // use all null change values for baseline
        o.change = {
          perf: null,
          ..._mapValues(o.score.unweighted, () => null),
          value: null,
        }
      } else {
        const perf = o.perf.unweighted.aggregate
        const bPerf = baseline.perf.unweighted.aggregate
        o.change = {
          perf: perf !== null && bPerf !== null
            ? (perf - bPerf) / bPerf
            : null,
          ..._mapValues(o.score.unweighted, (r, k) => {
            const baselineR = baseline.score.unweighted[k]
            if(baselineR === null || r === null) return null
            return (r - baselineR) / baselineR
          }),
          value: o.value !== null && baseline.value !== null
            ? (o.value - baseline.value) / baseline.value
            : null,
        }
      }
    })
  }
  return optionValuemetrics
}

export type OptionRatingsIndex = {
  byCriterionId: Record<string, {
    byOptionId: Record<string, {
      participantCount: number
      ratingMean: number
      ratingVariance: number
      ratingStddev: number
      byParticipantId: Record<string, {
        rating: number | null
      }>
    }>
    notesByParticipantId: Record<string, Html>
  }>
}

/**
 * Given a collection of option ratings and ratings notes from the same participation session,
 * will return an index of the ratings broken down by criteria, options, and participants, and
 * includes rating statistics for each criterion-option combination.
 *
 * Note that this will only index options and criteria found in the provided ratings and rating
 * notes.  In other words, if you have an option or criteria that hasn't been involved in a rating,
 * don't expect to find it in this index...so check first!
 *
 * IMPORTANT NOTE: this function does NOT check to ensure that all provided ratings and notes
 * are from the same participation session, nor does it check to ensure that the subject is
 * "Option" for each rating.  GIGO, here, folks.
 *
 * @deprecated see core/src/nextgen/options.ts
 */
export function indexOptionRatings(allRatings: Rating[], ratingNotes: RatingNotes[]): OptionRatingsIndex {
  const ratings = filterInvalidRatings(allRatings)
  const keyedRatings = _groupBy(
    ratings.filter(r => r.subjectType === 'Option' && r.ratingVector),
    r => `${r.subjectId}:${r.contextId}`
  )
  const keyedNotes = _groupBy(ratingNotes, 'contextId')
  const index: OptionRatingsIndex = { byCriterionId: {} }

  Object.entries(keyedRatings).forEach(([key, ratings]) => {
    const [optionId, criterionId] = key.split(':')
    const criteriaIndex = index.byCriterionId[criterionId] || { byOptionId: {}, notesByParticipantId: {} }
    const ratingVectors = ratings.map(r => r.ratingVector)
    const stats = vectorStats(ratingVectors)[0]
    if(stats) {
      criteriaIndex.byOptionId[optionId] = {
        participantCount: stats.sample.count,
        ratingMean: stats.sample.mean,
        ratingVariance: stats.sample.variance,
        ratingStddev: stats.sample.stddev,
        byParticipantId: _mapValues(_keyBy(ratings, 'participantId'), r => ({
          rating: r?.ratingVector?.[0] || null,
        })),
      }
    }
    criteriaIndex.notesByParticipantId = _mapValues(_keyBy(keyedNotes[criterionId], 'participantId'), n => n.notes)
    index.byCriterionId[criterionId] = criteriaIndex
  })

  return index
}
