import React from 'react'
import { useDispatch } from 'react-redux'
import { produce } from 'immer'
import { css } from 'glamor'
import _sumBy from 'lodash/sumBy'
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Col, Row, Button, Modal, Spin } from 'antd'

import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core/dist'
import { DurationUnitMetadata } from '@vms/vmspro3-core/dist/qty'

import { LoadingStatus } from '../../../utils/appConsts'
import RiskScale from './RiskScale'
import RiskAttributeWeights from './RiskAttributeWeights'
import NavConfirmation from '../controls/NavConfirmation'

import useAuthz from '../../../hooks/useAuthz'
import useFormDraftState from '../hooks/useFormDraftState'
import useRiskEntity from '../hooks/useRiskEntity'

const { RiskColor, EntityType, ROOT_RISK_PORTFOLIO_ID } = systemConsts

// exported to allow testing
export const riskScaleHasGapsOrOverlaps = scale =>
  scale.some(({ quantRange }, idx, l) => {
    if (idx === l.length - 1) return false // no gaps or overlaps at the end!
    const next = l[idx + 1]
    return Math.abs(quantRange[1] - next.quantRange[0]) > Number.EPSILON
  })

const getAttributeWeightsValid = total => Math.abs(1 - total) < 0.01 + Number.EPSILON // ±<1% tolerance

const attrLabels = {
  prob: 'Probability',
  cost: 'Cost',
  time: 'Schedule',
  perf: 'Performance',
}

/**
 * Small component to render a <ul> list that has up to a specified number of items.
 * If the items provided exceed that number, the last item is "...and {N} more".
 *
 * @params {string[]} props.items - The items to put in the list.
 * @params {number} [props.count = 5] - Display up to 5 items.  If provided more
 *  items, a 6th <li> will be added reading "...and {N} more" where {N} is the amount
 *  of additional items.
 */
const EtcList = ({ items, count = 5 }) => (
  <ul>
    {(items.length <= count ? items : items.slice(0, count).concat(`...and ${items.length - count} more`)).map(
      item => (
        <li key={item}>{item}</li>
      )
    )}
  </ul>
)

const getImpactTables = (includePerformance, riskContext, isPercentage = true) => {
  const costUnitLabel = riskContext.defaultCostUnit
  const timeUnitLabel = DurationUnitMetadata[riskContext.defaultDurationUnit].label.toLowerCase()

  // cost & time values will only exist for projects
  const costValue = riskContext.attributes.cost.value?.value
  const timeValue = riskContext.attributes.time.value?.value

  const impactTables = [
    {
      key: 'cost',
      title: 'Cost Impact',
      titleBgColor: RiskColor.COST_IMPACT,
      subtitle: isPercentage ? '% Range' : `Cost Range (${costUnitLabel})`,
      value: isPercentage ? null : costValue,
      tableId: isPercentage ? 'cost-percentage-impact-table' : 'cost-duration-impact-table',
      format: isPercentage ? 'percentage' : 'currency',
    },
    {
      key: 'time',
      title: 'Schedule Impact',
      titleBgColor: RiskColor.TIME_IMPACT,
      tableId: isPercentage ? 'time-percentage-impact-table' : 'time-duration-impact-table',
      subtitle: isPercentage ? '% Range' : `Schedule Range (${timeUnitLabel})`,
      value: isPercentage ? null : timeValue,
      format: isPercentage ? 'percentage' : 'duration',
    },
  ]
  if (includePerformance) {
    impactTables.push({
      key: 'perf',
      title: 'Performance Impact',
      titleBgColor: RiskColor.PERF_IMPACT,
      tableId: isPercentage ? 'perf-percentage-impact-table' : 'perf-duration-impact-table',
      subtitle: isPercentage ? '% Range' : `Performance Range`,
      value: isPercentage ? null : 1,
      format: isPercentage ? 'percentage' : 'number',
    })
  }
  return impactTables
}

const RiskContextScaleEditor = ({ entityId, includePerformance = true }) => {
  const dispatch = useDispatch()
  const authz = useAuthz()

  const { entity, entityLoadingStatus, riskContext, effectiveRiskContext, children } = useRiskEntity(entityId, {
    loadChildren: true,
  })

  const { ancestry } = entity

  const onSubmitScales = (update, clearDraftState) => {
    // validation
    const gapsAndOverlaps = Object.keys(update.riskScales || {}).filter(attr =>
      riskScaleHasGapsOrOverlaps(update.riskScales[attr])
    )

    if (gapsAndOverlaps.length) {
      return Modal.error({
        title: 'Validation Error',
        content: (
          <>
            <p>
              Risk scales cannot contain gaps or overlaps. That is, the maxiumum percentage must equal the minimum
              percentage of the next value. The following scale have gaps or overlaps that need to be corrected:
            </p>
            <ul>
              {gapsAndOverlaps.map(attr => (
                <li key={attr}>{attrLabels[attr]}</li>
              ))}
            </ul>
          </>
        ),
      })
    }

    if (update.attributes) {
      const attributeValues = Object.values(update.attributes)

      const total = _sumBy(attributeValues, 'weight')

      if (!getAttributeWeightsValid(total)) {
        return Modal.error({
          title: 'Validation Error',
          content: `Attribute weights must total 100%. The current total is ${(total * 100).toFixed()}%`,
        })
      }

      // there may be a <=1% remainder due to control precision, which will
      // need to be distributed (for example, a user who enters 33% for
      // each of 3 attributes will be 1% off, which we just distribute)
      const correction = (1 - total) / attributeValues.length
      attributeValues.forEach(attr => (attr.weight += correction))
    }

    let infoModalContent = undefined
    switch (entity.entityType) {
      case EntityType.PORTFOLIO: {
        const entityLabel = entity.id === ROOT_RISK_PORTFOLIO_ID ? 'portfolio' : 'sub-portfolio'
        infoModalContent = (
          <>
            <p>
              It's important to understand how the changes you make here affect risk analysis. Any <i>new</i>{' '}
              projects, programs, or sub-portfolios added directly as a child of this {entityLabel} will inherit a
              copy of this risk configuration. However, it's critical to note that <i>existing</i> projects,
              programs, or sub-portfolios (and their descendants) will not be updated with these new risk scales.
              You will have to edit existing children if you wish to update their risk configuration:
            </p>
            <EtcList items={children.map(c => c.name)} />
          </>
        )
        break
      }
      case EntityType.PROGRAM: {
        infoModalContent = (
          <>
            <p>
              It's important to understand how the changes you make here affect risk analysis. Any <i>new</i>{' '}
              project added directly as a child of this program will inherit a copy of this risk configuration.
              However, it's critical to note that <i>existing</i> projects will not be updated with these new risk
              scales. You will have to edit existing children if you wish to update their risk configuration:
            </p>
            <EtcList items={children.map(c => c.name)} />
          </>
        )
        break
      }
      case EntityType.PROJECT: {
        infoModalContent = (
          <p>
            It's important to understand how the changes you make here affect risk analysis. Changes to calibration
            affect the minimum, maximum, most likely, and expected values for the risks within this project. Making
            this change will update all {children.length} risks within this project to reflect these new scales.
          </p>
        )
        break
      }
      default:
        throw new Error('invalid entity type: ' + entity.entityType)
    }

    Modal.confirm({
      title: 'How Risk Calibration Works',
      icon: <InfoCircleOutlined />,
      content: infoModalContent,
      width: 800,
      onOk() {
        const meta = {
          entityId,
          ancestry,
          entityType: entity.entityType,
        }
        const riskContextUpdate = Object.entries(update).reduce(
          (riskContext, [path, v]) => _set(riskContext, path, v),
          _cloneDeep(riskContext)
        )
        dispatch(actions.riskContext.update(riskContextUpdate, meta))
        clearDraftState()
      },
    })
  }

  const [scalesDraft, updateScalesDraft, discardScalesDraft, submitScales] = useFormDraftState(
    `RISK_SCALES_DRAFT:${entityId}`,
    onSubmitScales
  )

  if (entityLoadingStatus !== LoadingStatus.Loaded) return <Spin />

  const includeValueScales = !!(
    effectiveRiskContext.attributes.cost.value && effectiveRiskContext.attributes.time.value
  )

  const scaleFields = Object.entries(scalesDraft || {}).reduce(
    (s, [k, v]) => _set(s, k, v),
    _cloneDeep(effectiveRiskContext)
  )

  const updateRiskScale =
    attr =>
    ({ key, param, val }) => {
      const riskScales = produce(scaleFields.riskScales, scales => {
        const scale = scales[attr]
        const v = scale.find(v => v.key === key)
        if (!v) throw new Error(`attempt to update scale with unrecognized key: ${key}`)
        if (!Number.isFinite(val)) throw new Error(`attempt to update scale with non-numeric value`)
        switch (param) {
          case 'min':
            v.quantRange[0] = val
            break
          case 'max':
            v.quantRange[1] = val
            break
          default:
            throw new Error(`attempt to update scale with unrecognized param: ${param}`)
        }
      })

      updateScalesDraft({ riskScales })
    }

  const updateAttributeWeights = (key, weight) =>
    updateScalesDraft({
      attributes: {
        ...scaleFields.attributes,
        [key]: { ...scaleFields.attributes[key], weight },
      },
    })

  const weightsTotal = _sumBy(Object.values(scaleFields.attributes), 'weight')
  const weightsValid = getAttributeWeightsValid(weightsTotal)

  const canEditRiskContext = authz(
    actions.riskContext.update(
      {},
      {
        entityId,
        ancestry,
        entityType: entity.entityType,
      }
    )
  )

  return (
    <>
      <NavConfirmation when={!!scalesDraft} modalParams={{ onOk: discardScalesDraft }} />
      <div style={style.container}>
        {canEditRiskContext && (
          <div {...style.controls}>
            <Button type="link" disabled={!scalesDraft} onClick={discardScalesDraft}>
              Discard Changes
            </Button>
            <Button type="primary" disabled={!scalesDraft} onClick={submitScales}>
              Save
            </Button>
          </div>
        )}
        <Row gutter={24}>
          <Col span={includePerformance ? 8 : 12}>
            <RiskScale
              tableId="prob-percentage-impact-table"
              title="Probability"
              subtitle="% Range"
              readOnly={!canEditRiskContext}
              includeMedian
              includeSubheader
              riskScale={scaleFields.riskScales.prob}
              onChange={updateRiskScale('prob')}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          {getImpactTables(includePerformance, effectiveRiskContext).map(({ key, ...riskScaleProps }) => (
            <Col span={includePerformance ? 8 : 12} key={key}>
              <RiskScale
                {...riskScaleProps}
                readOnly={!canEditRiskContext}
                riskScale={scaleFields.riskScales[key]}
                onChange={updateRiskScale(key)}
              />
            </Col>
          ))}
        </Row>
        {includeValueScales && (
          <Row gutter={24}>
            {getImpactTables(includePerformance, effectiveRiskContext, false).map(({ key, ...riskScaleProps }) => (
              <Col span={includePerformance ? 8 : 12} key={key}>
                <RiskScale
                  {...riskScaleProps}
                  readOnly={!canEditRiskContext}
                  riskScale={scaleFields.riskScales[key]}
                  onChange={updateRiskScale(key)}
                />
              </Col>
            ))}
          </Row>
        )}
        <Row>
          <Col span={24}>
            <RiskAttributeWeights
              attributes={scaleFields.attributes}
              readOnly={!canEditRiskContext}
              updateAttributeWeights={updateAttributeWeights}
              weightsTotal={weightsTotal}
              weightsValid={weightsValid}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

const style = {
  controls: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '12px',
    width: '100%',
    '& > *': {
      marginRight: '16px',
      '&:not(:first-child)': {
        marginLeft: '16px',
      },
    },
  }),
  container: {
    backgroundColor: 'white',
    margin: '0 30px',
    padding: '15px',
  },
  tableTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '700',
  },
}

export default RiskContextScaleEditor
