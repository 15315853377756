import { useCallback } from 'react'
import { Button, Space } from 'antd'
import { RollbackOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import { createParticipant } from '@vms/vmspro3-core/dist/actions/decision'

import { useAccount, useAuth } from '../../../context'
import { useAppDispatch } from '../../../redux/store'
import { useDecisionChildAncestry } from '../../../redux/hooks'
import userIcon from '../../../assets/svg/user-question-mark.svg'

const styles = {
  page: {
    backgroundColor: '#fff',
    margin: '24px 30px',
    padding: '12px 24px',
    display: 'flex',
  },
  content: {
    paddingLeft: '32px',
  },
}

type CreateAuthUserParticipantProps = {
  decisionId: string
}
export const CreateAuthUserParticipant: React.VFC<CreateAuthUserParticipantProps> = ({ decisionId }) => {
  const participantAncestry = useDecisionChildAncestry(decisionId)

  const { authUser } = useAuth()
  const { accountCommonId } = useAccount()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const onCreateParticipant = useCallback(() => {
    dispatch(
      createParticipant(participantAncestry, {
        userId: authUser.id,
        fullName: authUser.fullName,
        shortName: authUser.shortName ?? authUser.fullName.replace(/\s.*$/, ''),
        initials:
          authUser.initials ??
          authUser.fullName
            .split(/\s+/g)
            .map(s => s[0])
            .join(''),
        trackingId: null,
        tags: [],
      })
    )
  }, [dispatch, participantAncestry, authUser])

  const backToDecision = useCallback(
    () => navigate(`/${accountCommonId}/decision/${decisionId}`),
    [navigate, accountCommonId, decisionId]
  )

  return (
    <div style={styles.page}>
      <img src={userIcon} width="100" />
      <div style={styles.content}>
        <h1>You aren't a participant in this decision. Would you like to become one?</h1>
        <Space dir="horizontal">
          <Button type="primary" onClick={onCreateParticipant}>
            Yes
          </Button>
          <Button onClick={backToDecision}>
            <RollbackOutlined /> Back to Decision
          </Button>
        </Space>
      </div>
    </div>
  )
}
