import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, List, Spin } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'
import { createAccountPreflight } from '@vms/vmspro3-core/dist/actions/account/createAccount'

import { AccountCreateModal } from './AccountCreateModal'

import { useAuth, useUserAccounts } from '../../../context'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { acceptInvitation, fetchUserInvitations } from '../../../redux/actions'
import { useUserInvitations } from '../../../redux/hooks'
import { useAugmentAction } from '../../../hooks/useAugmentAction'
import useAuthz from '../../../hooks/useAuthz'
import style from './AccountSelectionPage.module.css'

export function AccountSelectionPage() {
  const loadingStatus = useAppSelector(state => state.user.invitationsLoadingStatus)
  const { authUser } = useAuth()
  const { userAccounts, refetchUserAccounts } = useUserAccounts()

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (loadingStatus === 'NotLoaded') {
      dispatch(fetchUserInvitations(authUser.email))
    }
  }, [dispatch, authUser.email, loadingStatus])

  const [invitations, invitationsLoading] = useUserInvitations()

  const [loadingInvitationAccountId, setLoadingInvitationAccountId] = useState<string | undefined>()
  const navigate = useNavigate()
  const augmentAction = useAugmentAction()
  const onAcceptInvitation = async (accountId: string) => {
    if (loadingInvitationAccountId) return // accept one at a time

    setLoadingInvitationAccountId(accountId)
    const augmentedAction = augmentAction(
      actions.account.acceptInvitation({
        userId: authUser.id,
        email: authUser.email,
        accountId,
      })
    )
    await dispatch(acceptInvitation(augmentedAction))

    const refetchResult = await refetchUserAccounts()
    setLoadingInvitationAccountId(undefined)

    const accountData = refetchResult?.data.userAccounts.find(({ account }) => account.id === accountId)
    if (accountData) {
      navigate(`/${accountData.account.commonId}`)
    }
  }

  const authz = useAuthz()
  const canCreateAccount = useMemo(() => authz(createAccountPreflight()), [authz])

  const [accountCreateModalVisible, setAccountCreateModalVisible] = useState<boolean>(false)

  if (invitationsLoading) return <Spin />

  return (
    <>
      <AccountCreateModal
        visible={accountCreateModalVisible}
        hideModal={() => setAccountCreateModalVisible(false)}
      />
      <div className={style.accountSelection}>
        <Card>
          {invitations && (
            <div>
              <h2>Welcome to OptionLab&reg;!</h2>
              <p>
                From here, you have access to any OptionLab accounts you have access to, or accept invitations from
                other users.
              </p>
              {!invitations.length && !userAccounts.length && (
                <p>
                  It looks like you're a new user, so you'll want to get started by creating a new OptionLab
                  account.
                </p>
              )}
            </div>
          )}
          {((invitations && invitations.length > 0) || userAccounts.length > 0) && (
            <div>
              <h3>Your Accounts:</h3>
              <List loading={!invitations} bordered>
                {invitations?.map(invitation => (
                  <List.Item
                    key={invitation.id}
                    className={style.accountListItem}
                    actions={[
                      <Button
                        type="primary"
                        loading={loadingInvitationAccountId === invitation.inviteToAccountId}
                        onClick={() => onAcceptInvitation(invitation.inviteToAccountId)}
                      >
                        Accept Invitation
                      </Button>,
                    ]}
                  >
                    {invitation.accountName}
                  </List.Item>
                ))}
                {userAccounts.map(({ account, userRecord }) => (
                  <List.Item
                    className={`${style.accountListItem} ${style.account}`}
                    key={account.id}
                    onClick={userRecord.disabled ? undefined : () => navigate(`/${account.commonId}`)}
                    actions={[
                      userRecord.disabled ? (
                        <span>
                          <i>Your access to this account has been disabled by an administrator</i>
                        </span>
                      ) : null,
                    ]}
                  >
                    {account.name}
                  </List.Item>
                ))}
              </List>
            </div>
          )}
          {canCreateAccount && (
            <Button
              className={style.newAccountButton}
              type={invitations?.length || userAccounts.length ? 'default' : 'primary'}
              onClick={() => setAccountCreateModalVisible(true)}
            >
              <PlusOutlined /> Create a New Account
            </Button>
          )}
        </Card>
      </div>
    </>
  )
}
