import { CSSProperties, ReactElement, useMemo } from 'react'

import { RatingScale } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import style from './RatingScaleOverlay.module.css'

interface RatingScaleOverlayProps {
  ratingScale?: RatingScale
  maxRating: number
  minRating: number
  bgColor?: string
  fgColor?: string
}
export function RatingScaleOverlay({
  ratingScale,
  maxRating,
  minRating,
  bgColor,
  fgColor,
}: RatingScaleOverlayProps): ReactElement {
  const scaleTotalSpan = maxRating - minRating

  const scaleItems = useMemo(
    () =>
      ratingScale?.map((ratingScaleItem, i, scale) => {
        const scaleItemMax = i > 0 ? ratingScaleItem.maxValue : maxRating
        const scaleItemMin = scale[i + 1]?.maxValue ?? minRating

        const scaleItemSpan = scaleItemMax - scaleItemMin

        return (
          <div
            key={scaleItemMax}
            className={style.ratingScaleItem}
            style={
              { '--rating-scale-item-span': scaleItemSpan, color: fgColor, borderColor: fgColor } as CSSProperties
            }
          >
            {ratingScaleItem.label}
          </div>
        )
      }),
    [ratingScale, maxRating, minRating, fgColor]
  )

  return (
    <div
      className={style.ratingScale}
      style={{ '--rating-scale-total-span': scaleTotalSpan, backgroundColor: bgColor } as CSSProperties}
    >
      {scaleItems}
    </div>
  )
}
