import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { Spin } from 'antd'

import { AuthUserParticipantContextProvider } from '../common/Participation'

import { useLoadDecision } from '../../redux/hooks'
import { useAccount } from '../../context'
import { Participation } from '../common/Participation/Participation'

export const ParticipationPage = (): ReactElement => {
  const { accountId } = useAccount()
  const { decisionId, participationSessionId } = useParams<'decisionId' | 'participationSessionId'>()
  invariant(decisionId, 'decisionId required in URL parameters')
  invariant(participationSessionId, 'participationSessionId required in URL parameters')

  const loading = useLoadDecision(accountId, decisionId)
  if (loading) return <Spin />

  return (
    <AuthUserParticipantContextProvider decisionId={decisionId}>
      <Participation decisionId={decisionId} participationSessionId={participationSessionId} />
    </AuthUserParticipantContextProvider>
  )
}
