import React, { useState } from 'react'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button } from 'antd'

/**
 * @prop {function} getReport - async server call to write report and get path
 * @prop {string} label - label for Button
 * @prop {Object} style - optional style object
 */

const ReportDownloadButton = ({ getReport, label, style }) => {
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const handleGetReport = async () => {
    setDownloadingExcel(true)
    getReport().then(report => {
      setDownloadingExcel(false)
      if (report?.path) {
        const a = document.createElement('a')
        a.style = { display: 'none' }
        a.download = report.path.split('/').pop()
        a.href = '/' + report.path
        document.body.appendChild(a)
        a.click()
      }
    })
  }

  return (
    <Button
      loading={downloadingExcel}
      icon={<DownloadOutlined />}
      disabled={downloadingExcel}
      onClick={handleGetReport}
      style={style}
    >
      {label}
    </Button>
  )
}

export default ReportDownloadButton
