import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Modal } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import Server from '../../../server/VMSProServerAdapter'
import { useAccount, useUserAccounts } from '../../../context'
import { useAugmentAction } from '../../../hooks/useAugmentAction'
import { useLazyRef } from '../../../hooks/useLazyRef'

interface EndSubscriptionFormFields {
  reason: string
}
interface EndSubscriptionConfirmModalProps {
  hideModal: () => void
}

/**
 * Modal to confirm subscription cancelation.  We use the terminology "end" instead of "cancel"
 * to avoid confusion with the common "Cancel" button (cancelling a cancellation is confusing!)
 */
export function EndSubscriptionConfirmModal({ hideModal }: EndSubscriptionConfirmModalProps) {
  const [loading, setLoading] = useState(false)

  const { current: randNum } = useLazyRef(() => Math.random().toFixed(4).substring(2, 6))

  const [form] = Form.useForm<EndSubscriptionFormFields>()

  const { refetchUserAccounts } = useUserAccounts()
  const { accountId } = useAccount()
  const augmentAction = useAugmentAction(accountId)
  const navigate = useNavigate()
  const onOk = async () => {
    const { reason } = await form.validateFields()
    setLoading(true)
    await Server.tryAction(augmentAction(actions.account.cancelSubscription(accountId, reason)))
    await refetchUserAccounts()
    navigate('/')
  }

  return (
    <Modal open onCancel={hideModal} onOk={onOk}>
      <Form form={form} requiredMark="optional" layout="vertical">
        <h3>End Subscription</h3>
        <p>
          Are you sure you want to end this subscription? Once you do, all users currently logged into the account
          will be logged out immediately.{' '}
          <b>
            None of the users in this organization, including yourself, will have access to this OptionLab account,
            and you will no longer have access to your data
          </b>
          . This action is permanent and cannot be undone.
        </p>
        <Form.Item name="reason" label="Please let us know why you're leaving">
          <Input.TextArea disabled={loading} autoFocus />
        </Form.Item>
        <Form.Item
          name="confirmation"
          label={`Please type ${randNum} to confirm:`}
          rules={[
            {
              required: true,
              message: 'Please type the confirmation code',
              validateTrigger: 'onChange',
            },
            {
              pattern: new RegExp(`^${randNum}$`),
              message: 'Confirmation code does not match',
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
