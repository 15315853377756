import { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

/**
 * Hook to encode a keyed value in the querystring.
 */
export function useQuerystringValue<T extends string | void, V = T extends string ? T : string>(
  key: string,
  defaultValue: V,
  replaceAll?: boolean
): [value: V, setValue: (nextValue?: V) => void]
export function useQuerystringValue<T extends string | void, V = T extends string ? T : string>(
  key: string,
  defaultValue?: undefined,
  replaceAll?: boolean
): [value: V | undefined, setValue: (nextValue?: V) => void]
export function useQuerystringValue<T extends string | void, V = T extends string ? T : string>(
  key: string,
  defaultValue?: V | undefined,
  replaceAll = false
): [value: V | undefined, setValue: (nextValue?: V) => void] {
  const location = useLocation()
  const parsedQuery = useMemo(() => {
    const query = queryString.parse(location.search)
    if (replaceAll) {
      return { [key]: query[key] }
    }
    return query
  }, [location, key, replaceAll])

  const navigate = useNavigate()
  const setValue = useCallback(
    (nextValue?: V) => {
      if (typeof nextValue === 'string' && nextValue !== defaultValue) {
        parsedQuery[key] = nextValue
      } else {
        delete parsedQuery[key]
      }

      navigate(
        queryString.stringifyUrl({
          url: location.pathname,
          query: parsedQuery,
        })
      )
    },
    [defaultValue, parsedQuery, key, location, navigate]
  )

  const value = (parsedQuery[key] ?? defaultValue) as V | undefined

  return [value, setValue]
}
