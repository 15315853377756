import { ReactElement, useMemo } from 'react'

import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'

import { RatingInterface } from '../RatingInterface/RatingInterface'

import { useAdHocAccount } from '../../../context/AdHocAccountContext'
import { useAdHocParticipant } from '../../../context/AdHocParticipantContext'
import { useAdHocDecision } from '../../../context/AdHocDecisionContext'
import { useQuerystringValue } from '../../../hooks/useQuerystringValue'
import { useUpdateRating, useUpdateRatingNotes } from '../../../services/adHocDecisionService'

interface AdHocParticipationProps {
  participationSessionId: string
}
export function AdHocParticipation({ participationSessionId }: AdHocParticipationProps): ReactElement {
  const { accountId } = useAdHocAccount()
  const { participantId, participant, unsetParticipant } = useAdHocParticipant()
  const { decisionId, decision: _decisionData, criteria, options, ratings, ratingNotes } = useAdHocDecision()

  const [contextId, setContextId] = useQuerystringValue('contextId')

  const updateRating = useUpdateRating(accountId, decisionId, participantId)
  const updateRatingNotes = useUpdateRatingNotes(accountId, decisionId)

  const decision = useMemo(() => {
    const { participationSessions, ...decisionData } = _decisionData
    return new Decision(
      decisionData,
      [participant],
      criteria,
      options,
      participationSessions,
      ratings,
      ratingNotes
    )
  }, [_decisionData, participant, criteria, options, ratings, ratingNotes])

  return (
    <RatingInterface
      participationSessionId={participationSessionId}
      participantId={participantId}
      contextId={contextId}
      setContextId={setContextId}
      decision={decision}
      participant={participant}
      unsetParticipant={unsetParticipant}
      updateRating={updateRating}
      updateRatingNotes={updateRatingNotes}
    />
  )
}
