import React, { CSSProperties, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import LockOutlined from '@ant-design/icons/LockOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { Card, Form, type FormInstance, Input, Button, Spin } from 'antd'

import {
  SignInOutput,
  fetchUserAttributes,
  signIn,
  confirmSignIn,
  resetPassword,
  confirmResetPassword,
} from 'aws-amplify/auth'

import PasswordField from '../../common/PasswordField'

import Server from '../../../server/VMSProServerAdapter'
import { useAppDispatch } from '../../../redux'
import { setAuth } from '../../../redux/actions'
import { getEmailFieldProps } from '../../../utils/getEmailFieldProps'

const passwordRulesCopy = 'Password must be at least 12 characters long.'
const unknownErrorCopy = 'An unknown error occurred. Please contact support.'

const setFormFieldError = (formInstance: FormInstance, name: string, errorMessage: string) =>
  formInstance.setFields([
    {
      name,
      errors: [errorMessage],
    },
  ])

type SignInFormProps<T = { email: string; password: string }> = {
  initialValues: T
  setForgotPassword: VoidFunction
  formInstance: FormInstance
  handleSignIn: (value: T) => void
  loading: boolean
  message: string
}
function SignInForm(props: SignInFormProps) {
  const { initialValues, setForgotPassword, formInstance, handleSignIn, loading, message } = props
  return (
    <>
      {message && (
        <div style={style.formMessage}>
          <h2>{message}</h2>
        </div>
      )}
      <Form
        form={formInstance}
        onFinish={handleSignIn}
        initialValues={initialValues}
        validateTrigger={['onChange', 'onBlur']}
        layout="vertical"
      >
        <Form.Item {...getEmailFieldProps()}>
          <Input
            type="email"
            placeholder="email@domain.com"
            prefix={<UserOutlined style={style.inputPrefix} />}
            autoFocus
          />
        </Form.Item>
        <PasswordField />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Sign In
          </Button>
          <Button type="link" style={{ float: 'right' }} onClick={() => setForgotPassword()}>
            <i>Forgot your password?</i>
          </Button>
        </Form.Item>
        <i>
          New to OptionLab? <Link to="/signup">Create an account.</Link>
        </i>
      </Form>
    </>
  )
}

type SetNewPasswordFormProps<T = { newPassword: string }> = {
  formInstance: FormInstance
  handleSetNewPassword: (values: T) => void
  loading: boolean
}
function SetNewPasswordForm(props: SetNewPasswordFormProps) {
  const { formInstance, handleSetNewPassword, loading } = props
  return (
    <Form
      form={formInstance}
      onFinish={handleSetNewPassword}
      validateTrigger={['onChange', 'onBlur']}
      layout="vertical"
    >
      <Form.Item hidden name="email">
        <Input autoFocus />
      </Form.Item>
      <h3>You must enter a new password:</h3>
      <PasswordField.SetNewPassword showSuggestion />
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Done
        </Button>
      </Form.Item>
    </Form>
  )
}

type RequestResetPasswordFormProps<T = { email: string }> = {
  cancelForgotPassword: VoidFunction
  formInstance: FormInstance
  handleSubmitForgotPassword: (values: T) => void
  message: string
  loading: boolean
}
function RequestResetPasswordForm(props: RequestResetPasswordFormProps) {
  const { cancelForgotPassword, formInstance, handleSubmitForgotPassword, message, loading } = props
  return (
    <Form
      form={formInstance}
      onFinish={handleSubmitForgotPassword}
      validateTrigger={['onChange', 'onBlur']}
      layout="vertical"
    >
      <div style={style.formMessage}>
        <h2>{message}</h2>
      </div>
      <Form.Item {...getEmailFieldProps()}>
        <Input
          autoFocus
          type="email"
          placeholder="email@domain.com"
          prefix={<UserOutlined style={style.inputPrefix} />}
        />
      </Form.Item>
      <Form.Item>
        <Button style={style.cancelButton} onClick={cancelForgotPassword}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  )
}

type ResetPasswordFormProps<T = { email: string; newPassword: string; confirmationCode: string }> = {
  formInstance: FormInstance
  handleResetForgotPassword: (values: T) => void
  loading: boolean
}
function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { formInstance, handleResetForgotPassword, loading } = props
  return (
    <Form form={formInstance} onFinish={handleResetForgotPassword} layout="vertical">
      <Form.Item hidden name="email">
        <Input autoFocus />
      </Form.Item>
      <h3>Check your email for a confirmation code:</h3>
      <Form.Item
        label="Confirmation Code"
        name="confirmationCode"
        normalize={code => code.trim()}
        rules={[
          {
            required: true,
            message: 'Please enter the verification code',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined style={style.inputPrefix} />}
          inputMode="numeric"
          placeholder="confirmation code"
          autoComplete="one-time-code"
        />
      </Form.Item>
      <h3>And choose a new password:</h3>
      <PasswordField.SetNewPassword showSuggestion />
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Done
        </Button>
      </Form.Item>
    </Form>
  )
}

function SignIn() {
  const dispatch = useAppDispatch()

  const [formInstance] = Form.useForm()

  const resetPasswordFields = () =>
    formInstance.setFieldsValue({
      password: undefined,
      newPassword: undefined,
      verifyNewPassword: undefined,
    })

  const [loading, setLoading] = useState(false)
  const [authFlowStep, setAuthFlowStep] = useState('signIn')
  const [message, setMessage] = useState('Sign in to OptionLab')
  const [pendingUser, setPendingUser] = useState<SignInOutput | undefined>()

  const setForgotPassword = (message?: string) => {
    setMessage(message ?? 'Reset your password')
    setAuthFlowStep('forgotPasswordRequest')
  }
  const cancelForgotPassword = () => setAuthFlowStep('signIn')

  const navigate = useNavigate()
  const handleSignIn = ({ email, password }: { email: string; password: string }) => {
    setLoading(true)
    signIn({ username: email, password })
      .then(async user => {
        resetPasswordFields()
        if (user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          setPendingUser(user)
          setAuthFlowStep('newPasswordRequired')
          setLoading(false)
        } else {
          const { preferred_username: userId, email } = await fetchUserAttributes()
          dispatch(setAuth({ userId, email }))
          navigate('/')
        }
      })
      .catch(err => {
        // TODO: I have no idea if these codes are still valid or how to correctly type this.
        // seems like Amplify doesn't provide anything; see https://github.com/aws-amplify/amplify-js/issues/9104
        switch (err.code) {
          case 'NotAuthorizedException': {
            if (err.message === 'User is disabled') {
              setFormFieldError(formInstance, 'email', err.message)
            } else {
              setFormFieldError(formInstance, 'password', 'Incorrect password')
            }
            break
          }
          case 'UserNotFoundException': {
            setFormFieldError(formInstance, 'email', 'User not found')
            break
          }
          case 'PasswordResetRequiredException': {
            setForgotPassword('A password reset is required')
            break
          }
          case 'UserNotConfirmedException': {
            setFormFieldError(formInstance, 'email', 'This user has not completed registration.')
            break
          }
          default: {
            setFormFieldError(formInstance, 'password', unknownErrorCopy)
            break
          }
        }
        setLoading(false)
      })
  }

  const handleSetNewPassword = ({ newPassword }: { newPassword: string }) => {
    setLoading(true)
    confirmSignIn({ challengeResponse: newPassword })
      .then(async () => {
        resetPasswordFields()
        const { preferred_username: userId, email } = await fetchUserAttributes()
        dispatch(setAuth({ userId, email }))
      })
      .catch(err => {
        setFormFieldError(
          formInstance,
          'newPassword',
          err.code === 'InvalidPasswordException' ? passwordRulesCopy : unknownErrorCopy
        )
        setLoading(false)
      })
  }

  const handleSubmitForgotPassword = ({ email }: { email: string }) => {
    setLoading(true)
    resetPassword({ username: email })
      .then(() => {
        resetPasswordFields()
        setAuthFlowStep('forgotPasswordChooseNewPassword')
      })
      .catch(err => {
        switch (err.code) {
          case 'UserNotFoundException': {
            return setFormFieldError(formInstance, 'email', 'User not found')
          }
          case 'NotAuthorizedException': {
            // Since we are confirming users email on user creation, we won't get the standard
            // 'UserNotConfirmedException'. This should be the only case where this error code is returned
            // from Amplify.auth.forgotPassword(), so we resend the password email.
            return Server.resendPassword(email)
              .then(() => {
                setMessage('This user has not confirmed. The confirmation email has been resent to ' + email)
              })
              .catch(() => setMessage(unknownErrorCopy))
              .finally(() => setAuthFlowStep('userNotConfirmedResendEmail'))
          }
          default: {
            return setFormFieldError(formInstance, 'email', unknownErrorCopy)
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const handleResetForgotPassword = (args: { email: string; newPassword: string; confirmationCode: string }) => {
    const { email, newPassword, confirmationCode } = args
    setLoading(true)
    confirmResetPassword({
      username: email,
      confirmationCode,
      newPassword,
    })
      .then(() => {
        resetPasswordFields()
        setMessage('Please sign in with your new password')
        setAuthFlowStep('signIn')
      })
      .catch(err => {
        console.log('err:', err)
        switch (err.code) {
          case 'InvalidPasswordException': {
            setFormFieldError(formInstance, 'password', passwordRulesCopy)
            break
          }
          case 'CodeMismatchException': {
            setFormFieldError(
              formInstance,
              'verificationCode',
              'The verification code you provided does not match'
            )
            break
          }
          default: {
            setFormFieldError(formInstance, 'password', unknownErrorCopy)
            break
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const location = useLocation()
  const initialValues = React.useMemo<{ email: string; password: string }>(() => {
    const qs = new URLSearchParams(location.search)
    return {
      email: qs.get('email') || '',
      password: qs.get('password') || '',
    }
  }, [location.search])

  switch (authFlowStep) {
    case 'signIn': {
      return (
        <SignInForm
          initialValues={initialValues}
          setForgotPassword={setForgotPassword}
          formInstance={formInstance}
          handleSignIn={handleSignIn}
          loading={loading}
          message={message}
        />
      )
    }
    case 'newPasswordRequired': {
      return (
        <SetNewPasswordForm
          formInstance={formInstance}
          handleSetNewPassword={handleSetNewPassword}
          loading={loading}
        />
      )
    }
    case 'forgotPasswordRequest': {
      return (
        <RequestResetPasswordForm
          cancelForgotPassword={cancelForgotPassword}
          formInstance={formInstance}
          handleSubmitForgotPassword={handleSubmitForgotPassword}
          message={message}
          loading={loading}
        />
      )
    }
    case 'forgotPasswordChooseNewPassword': {
      return (
        <ResetPasswordForm
          formInstance={formInstance}
          handleResetForgotPassword={handleResetForgotPassword}
          loading={loading}
        />
      )
    }
    case 'userNotConfirmedResendEmail': {
      return loading ? <Spin /> : <span>message</span>
    }
    default: {
      return null
    }
  }
}

export function SignInPage() {
  return (
    <div style={style.container}>
      <Card style={style.card} bordered={false}>
        <SignIn />
      </Card>
    </div>
  )
}

const style: Record<string, CSSProperties> = {
  container: {
    padding: '60px 24px',
  },
  card: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  cancelButton: {
    marginRight: '24px',
  },
  formMessage: {
    textAlign: 'center',
  },
  inputPrefix: {
    color: 'rgba(0,0,0,.25)',
  },
}
