import { produce } from 'immer'
import _set from 'lodash/set'

import { Account, Invitation, RiskEntity_App } from '@vms/vmspro3-core/dist/types'
import { UpdateAccountSubscriptionAction } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'
import { InviteUserAction, UpdatePaymentMethodAction } from '@vms/vmspro3-core/dist/actions/account'
import { CreateProjectActionType } from '@vms/vmspro3-core/dist/actions/riskProject'
import { DeleteRiskEntityActionType } from '@vms/vmspro3-core/dist/actions/riskEntity'

import { LoadingStatus } from '../../types'
import {
  SetAccountAction,
  FetchPendingInvitationsForAccountRequestAction,
  FetchPendingInvitationsForAccountSuccessAction,
  ResetAccountStateAction,
  SignOutAction,
} from '../actions'

export interface AccountState {
  account: Account | undefined
  accountLoadingStatus: LoadingStatus
  pendingInvitations: Invitation[] | undefined
  pendingInvitationsLoadingStatus: LoadingStatus
}

interface CreateRiskProjectAction {
  type: typeof CreateProjectActionType
}
interface DeleteRiskEntityAction {
  type: typeof DeleteRiskEntityActionType
  meta: {
    entityType: RiskEntity_App['entityType']
  }
}

type AccountReducerAction =
  | SignOutAction
  | ResetAccountStateAction
  | SetAccountAction
  | FetchPendingInvitationsForAccountRequestAction
  | FetchPendingInvitationsForAccountSuccessAction
  | UpdateAccountSubscriptionAction
  | InviteUserAction
  | UpdatePaymentMethodAction
  | CreateRiskProjectAction
  | DeleteRiskEntityAction

export const initialState: AccountState = {
  account: undefined,
  accountLoadingStatus: 'NotLoaded',
  pendingInvitations: undefined,
  pendingInvitationsLoadingStatus: 'NotLoaded',
}

export const accountReducer = produce<AccountState, [AccountReducerAction?]>((state, action) => {
  if (!action) return

  switch (action.type) {
    case 'SignOut': {
      return initialState
    }
    case 'ResetAccountState': {
      return initialState
    }

    case 'SetAccount': {
      state.account = action.payload.account
      state.accountLoadingStatus = 'Loaded'
      break
    }

    case 'FetchPendingInvitationsForAccountRequest': {
      state.pendingInvitationsLoadingStatus = 'Requested'
      break
    }
    case 'FetchPendingInvitationsForAccountSuccess': {
      state.pendingInvitations = action.payload
      state.pendingInvitationsLoadingStatus = 'Loaded'
      break
    }

    case 'Update Subscription': {
      if (!state.account) {
        // this will happen when creating an account from the account mgmt screen
        break
      }

      const { product } = action.payload
      state.account.productId = product.id

      Object.keys(product.quotas).forEach(k => {
        // Object.keys does not strictly type object keys, TS complains about index signature mismatch
        const key = k as keyof typeof product.quotas

        if (state.account) {
          state.account.quotas[key].allowed = product.quotas[key]
        }
      })

      break
    }
    case 'Invite User': {
      if (!Array.isArray(state.pendingInvitations)) {
        state.pendingInvitations = []
      }

      state.pendingInvitations.push(action.payload)
      break
    }
    case 'Update Payment Method': {
      _set(state, ['account', 'integrations', 'stripe', 'defaultPaymentMethodId'], action.payload.paymentMethodId)
      break
    }

    // TODO: need to consider how best to handle account resource quotas on
    // GraphQL queries, for now selectors are still using Redux for quotas
    case 'Create Project': {
      if (state.account) {
        state.account.quotas.projects.used++
      }
      break
    }
    case 'Delete Risk Entity': {
      if (state.account && action.meta.entityType === 'PROJECT') {
        state.account.quotas.projects.used--
      }
      break
    }

    default: {
      break
    }
  }
}, initialState)
