import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { Tabs, Spin, Result } from 'antd'
import tourIds from '../../../tourIds'

import { Page } from '../../common/Page'
import { Participants } from './Participants'
import { DecisionDownloadButton } from './DecisionDownloadButton'
import { Overview } from './Overview'
import { OptionsPage } from './Op2'
import { Valuemetrics } from './Valuemetrics'

import { useQuerystringValue } from '../../../hooks/useQuerystringValue'
import { useAccount } from '../../../context'
import {
  useLoadDecision,
  useDecisionData,
  useDecisionBreadcrumbRoutes,
  useLoadDecision2,
} from '../../../redux/hooks'
import { CriteriaPage } from './Cri2'
import { ResultsPage } from './Results'

function renderTabContents(tabKey: string, decisionId: string) {
  switch (tabKey) {
    case 'overview':
      return <Overview decisionId={decisionId} />
    case 'criteria':
      return <CriteriaPage decisionId={decisionId} />
    case 'options':
      return <OptionsPage decisionId={decisionId} />
    case 'participants':
      return <Participants decisionId={decisionId} />
    case 'option-valuemetrics':
      return <Valuemetrics decisionId={decisionId} />
    case 'results':
      return <ResultsPage decisionId={decisionId} />
    default:
      return null
  }
}

export function DecisionPageContent({ decisionId }: { decisionId: string }): ReactElement {
  const { accountCommonId } = useAccount()
  const decision = useDecisionData(decisionId)
  const breadcrumbRoutes = useDecisionBreadcrumbRoutes(accountCommonId, decisionId)

  const tabs = useMemo(
    () => [
      // [key, tourId, label]
      ['overview', tourIds.tab.overview, 'Overview'],
      ['criteria', tourIds.tab.criteria, 'Criteria'],
      ['options', tourIds.tab.options, 'Options'],
      ['participants', tourIds.tab.participants, 'Participants'],
      decision.type === 'Regular'
        ? ['results', tourIds.tab.results, 'Results']
        : ['option-valuemetrics', tourIds.tab.valuemetrics, 'Valuemetrics'],
    ],
    [decision.type]
  )

  const [tab, setTab] = useQuerystringValue('tab', 'overview', true)

  return (
    <Page
      title={decision.name}
      breadcrumbRoutes={breadcrumbRoutes}
      extra={decision.type !== 'Regular' && <DecisionDownloadButton decisionId={decisionId} />}
    >
      <Tabs
        activeKey={tab}
        onChange={setTab}
        items={tabs.map(([key, tourId, label]) => ({
          key,
          label: <span id={tourId}>{label}</span>,
        }))}
      />
      {renderTabContents(tab, decisionId)}
    </Page>
  )
}

export function DecisionPage(): ReactElement {
  const { decisionId } = useParams<'decisionId'>()
  invariant(decisionId, 'decisionId required in URL parameters')

  const { accountId } = useAccount()

  const res = useLoadDecision2(accountId, decisionId)

  if (res.status === 'Error')
    return (
      <Result
        status="error"
        title="Error Loading Decision"
        subTitle="Either this decision does not exist or you do not have access."
      />
    )

  if (res.status !== 'Success') return <Spin />

  return <DecisionPageContent decisionId={decisionId} />
}
