import { ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { createId } from '@vms/vmspro3-core/dist/idUtils'

import clientId from '../../utils/clientId'
import { Action, AssumedAuthz } from '../../utils/authzUtils'

export interface AugmentedActionProps {
  accountId?: string
  authUserId?: string
  authUserName?: string
  assumedAuthz?: AssumedAuthz
}

type AugmentedAction<T extends Action> = T & {
  meta: {
    accountId: string
    authUserId?: string
    authUserName?: string
    actualUserId?: string
    assumedRoleIds?: string[]
    clientId: string
    timestamp: number
    seq: string
  }
}

/**
 * Augments Redux standard action (RSA) with "boilerplate" common to all VMSPro actions
 *
 *  - Account ID (VMSPro account being affected by this action)
 *  - Actual user ID (the ID of the original user login, before any impersonation)
 *  - Assumed role IDs (any roles an authorized user is temporarily assuming)
 *  - Authorizing user ID (the effective ID of the user; either the original user or the user being impersonated)
 *  - Client ID (unique random ID for each client/browser)
 *  - Timestamp (timestamp of when the action was dispatched)
 *  - Sequence (timestamp with random UUID appended for disambiguation)
 */
export function augmentAction<T extends Action>(
  action: T,
  augmentedActionProps: AugmentedActionProps
): AugmentedAction<T> {
  const { accountId = ROOT_ACCOUNT_ID, assumedAuthz, authUserId, authUserName } = augmentedActionProps

  const timestamp = Date.now()

  const augmentedAction: AugmentedAction<T> = {
    ...action,
    meta: {
      accountId,
      authUserId,
      authUserName,
      clientId,
      timestamp,
      // "seq" is to disambiguate when multiple clients produce a colliding timestamp
      seq: `${timestamp}:${createId()}`,
      ...action.meta,
    },
  }

  if (assumedAuthz) {
    switch (assumedAuthz.type) {
      case 'ASSUME_ROLE': {
        augmentedAction.meta.assumedRoleIds = assumedAuthz.assumedRoleIds
        break
      }
      case 'IMPERSONATE_USER': {
        augmentedAction.meta.actualUserId = assumedAuthz.actualUserId
        break
      }
      default: {
        break
      }
    }
  }

  return augmentedAction
}
