import { ReactElement, useCallback, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { Button } from 'antd'

import { filterInvalidRatings } from '@vms/vmspro3-core/dist/valuemetrics/prioritization'

import {
  useDecisionData,
  useParticipationSessionId,
  useParticipants,
  useCriteria,
  useRatingNotes,
  useOptions,
  useParticipationSessionsData,
  useRatings,
} from '../../../redux/hooks'
import { CompleteDecision } from '@vms/vmspro3-core/dist/reports/reportUtils'

interface DecisionDownloadButtonProps {
  decisionId: string
  label?: string
}

export function DecisionDownloadButton(props: DecisionDownloadButtonProps): ReactElement {
  const { decisionId, label = 'Download Decision Excel' } = props

  const decision = useDecisionData(decisionId)
  const optionsData = useOptions(decisionId)
  const participationSessions = useParticipationSessionsData(decisionId)
  const participationSessionIds = {
    criteria: useParticipationSessionId(decisionId, 'CriteriaPrioritization'),
    options: useParticipationSessionId(decisionId, 'OptionRating'),
  }
  const participants = useParticipants(decisionId)
  const criteriaData = useCriteria(decisionId)
  const ratings = useRatings(decisionId)
  const optionRatings = ratings.filter(r => r.participationSessionId === participationSessionIds.options)
  const criteriaRatings = ratings.filter(r => r.participationSessionId === participationSessionIds.criteria)

  const ratingNotes = useRatingNotes(decisionId)
  const optionRatingNotes = ratingNotes.filter(
    r => r.participationSessionId === participationSessionIds.options && r.subjectType === 'Option'
  )
  const criteriaRatingNotes = ratingNotes.filter(
    r => r.participationSessionId === participationSessionIds.criteria && r.subjectType === 'Criterion'
  )

  const [working, setWorking] = useState(false)

  const onClick = useCallback(() => {
    setWorking(true)
    const d: CompleteDecision = {
      decision,
      participationSessions,
      participants,
      criteriaData,
      criteriaRatings: filterInvalidRatings(criteriaRatings),
      criteriaRatingNotes,
      optionRatings: filterInvalidRatings(optionRatings),
      optionRatingNotes,
      optionsData,

      allRatings: ratings,
      allRatingNotes: ratingNotes,
    }
    if (!decision || !('name' in decision)) {
      setWorking(false)
    }
    import('@vms/vmspro3-core/dist/reports/decisionExcelWriter')
      .then(async ({ createDecisionWorkbook }) => {
        const filename = `${decision.name || decisionId}`
        const wb = await createDecisionWorkbook(d)
        // write out the spreadsheet
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), filename + '.xlsx')
      })
      .finally(() => setWorking(false))
  }, [
    decision,
    participationSessions,
    participants,
    criteriaData,
    criteriaRatings,
    criteriaRatingNotes,
    optionRatings,
    optionRatingNotes,
    optionsData,
    ratings,
    ratingNotes,
    decisionId,
  ])

  return (
    <Button loading={working} onClick={onClick} icon={<DownloadOutlined />}>
      {label}
    </Button>
  )
}
