import React from 'react'
import { Avatar } from 'antd'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'

const avatarStyle = (backgroundColor, color = Color.WHITE) => ({
  backgroundColor,
  color,
  fontSize: '16px',
  fontWeight: '700',
  marginRight: '16px',
  minHeight: '32px',
  minWidth: '32px',
  verticalAlign: 'middle',
})

const style = {
  blue: avatarStyle(Color.BLUE),
  gray: avatarStyle(Color.GRAY_LIGHT),
  lavender: avatarStyle(Color.LAVENDER),
  navy: avatarStyle(Color.BLUE_NAVY),
  turquoise: avatarStyle(Color.TURQUOISE),
  white: avatarStyle(Color.WHITE, Color.BLACK),
}

const FormAvatar = props => {
  const newProps = Object.assign({}, props, { style: style[props.color] })
  return <Avatar {...newProps} />
}

export default FormAvatar
