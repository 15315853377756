import { ReactElement, useCallback, useMemo } from 'react'
import classnames from 'classnames'

import style from './TreeNode.module.css'
import { useTreeContext } from './treeContext'

export interface TreeNodeData<T extends TreeNodeData<T>> {
  id: string
  name: string
  parent: T | null
  children: T[]
}
interface TreeNodeProps<T extends TreeNodeData<T>> {
  node: T
  renderLabel: (item: T) => string | ReactElement
  isNodeDisabled?: (item: T) => boolean
  sortFn?: (a: T, B: T) => number
  /** Children IDs not to display. */
  pruneIds?: string[]
  skipRoot?: boolean
}
export const TreeNode = <T extends TreeNodeData<T>>({
  node,
  renderLabel,
  isNodeDisabled,
  sortFn,
  pruneIds,
  skipRoot = false,
}: TreeNodeProps<T>): JSX.Element => {
  const { readOnly, selectedItemId, onSelectItem } = useTreeContext()

  const nodeDisabled = isNodeDisabled instanceof Function && isNodeDisabled(node)

  const itemId = node.id
  const onClick = useCallback(() => {
    if (nodeDisabled || !onSelectItem) return
    onSelectItem(itemId)
  }, [nodeDisabled, onSelectItem, itemId])

  const children = useMemo(() => {
    const children = node.children.filter(c => !pruneIds || !pruneIds.includes(c.id))
    return sortFn ? children.sort(sortFn) : children
  }, [node.children, pruneIds, sortFn])

  return (
    <div>
      {!skipRoot && (
        <span
          className={classnames(style.node, {
            [style.selected]: itemId === selectedItemId,
            [style.readOnly]: readOnly || !onClick,
            [style.disabled]: nodeDisabled,
            [style.hasClickHandler]: !!onSelectItem,
          })}
          onClick={onClick}
        >
          {renderLabel(node)}
        </span>
      )}
      <div className={skipRoot ? style.treeNodeChildrenFlush : style.treeNodeChildren}>
        {children.map(childNode => (
          <TreeNode<T>
            key={childNode.id}
            node={childNode}
            renderLabel={renderLabel}
            isNodeDisabled={isNodeDisabled}
            pruneIds={pruneIds}
            sortFn={sortFn}
          />
        ))}
      </div>
    </div>
  )
}
