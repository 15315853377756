/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, ReactElement } from 'react'
import { createSelector } from 'reselect'
import { Column } from 'react-table'

import { Rating } from '@vms/vmspro3-core/dist/types'
import { NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'

import { Table, TableCellRenderer } from '../../../common/Table'

import { useAppSelector } from '../../../../redux'
import { useDecision } from '../../../../redux/hooks'
import { getSelectParticipants } from '../../../../redux/selectors'
import { Tooltip } from 'antd'
import {
  getNumberCellRenderer,
  getNumberCellRendererWithStats,
} from '../../../common/Table/TableCellRenderer/getNumberCellRenderer'

const maximumFractionDigits = 1
const formatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: maximumFractionDigits,
  maximumFractionDigits,
  style: 'percent',
})

const PriorityRendererWithStats = getNumberCellRendererWithStats({ formatter })
const PriorityRenderer = getNumberCellRenderer({ formatter })

type OutcomeProbTableData = {
  name: string
  ratingsByParticipantId: Record<string, { rating?: Rating; stats?: NumberWithStats } | undefined>
  groupAverage: number | null
}
type OutcomeProbTableProps = {
  decisionId: string
  optionId: string
}
export function OutcomeProbTable({ decisionId, optionId }: OutcomeProbTableProps): ReactElement {
  const columns = useAppSelector(
    useMemo(
      () =>
        createSelector(getSelectParticipants(decisionId), (participants = []): Column<OutcomeProbTableData>[] => [
          {
            Header: 'Outcome',
            accessor: 'name',
            Cell: TableCellRenderer.EntityName as any,
          },
          {
            Header: 'Avg. Prob.',
            accessor: 'groupAverage',
            align: 'right',
            sortType: 'numeric',
            Cell: PriorityRenderer,
          },
          {
            Header: 'Participant Ratings',
            columns: participants.map<any>(participant => ({
              id: participant.id,
              Header: () => <Tooltip title={participant.fullName}>{participant.initials}</Tooltip>,
              accessor: (row: any) => row.ratingsByParticipantId[participant.id],
              align: 'right',
              sortType: 'wrappedRating',
              Cell: PriorityRendererWithStats,
            })),
          },
        ]),
      [decisionId]
    )
  )

  const decision = useDecision(decisionId)
  const data: OutcomeProbTableData[] =
    decision.options.byId(optionId)?.outcomes.all.map(oo => {
      const data = oo.getProbDataWithStats()
      return {
        name: oo.name,
        ratingsByParticipantId: data.byParticipantId,
        groupAverage: data.aggregate,
      }
    }) || []

  return <Table<OutcomeProbTableData> columns={columns} data={data} />
}
