import EntityAttachmentAddModal from './EntityAttachmentAddModal'
import EntityAttachmentEditModal from './EntityAttachmentEditModal'
import EntityDeleteModal from './EntityDeleteModal'
import RiskCategoryModal from './RiskCategoryModal'
import RiskCopyModal from './RiskCopyModal'
import RiskCreateModal from './RiskCreateModal'
import RiskPhaseModal from './RiskPhaseModal'
import RiskEntityCreateModal from './RiskEntityCreateModal'
import RiskTableCustomizationModal from './RiskTableCustomizationModal'
import UsersAddResourcePolicyModal from './UsersAddResourcePolicyModal'

export const modals = [
  EntityAttachmentAddModal,
  EntityAttachmentEditModal,
  EntityDeleteModal,
  RiskCategoryModal,
  RiskCopyModal,
  RiskCreateModal,
  RiskPhaseModal,
  RiskEntityCreateModal,
  RiskTableCustomizationModal,
  UsersAddResourcePolicyModal,
]
