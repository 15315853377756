import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _keyBy from 'lodash/keyBy'
import _cloneDeep from 'lodash/cloneDeep'
import { css } from 'glamor'
import { WarningOutlined } from '@ant-design/icons'
import { Button, Col, Collapse, Form, Modal, PageHeader, Row, Input, Spin, Tabs } from 'antd'

import { createId } from '@vms/vmspro3-core/dist/idUtils'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils/createHtmlObject'
import { completeRiskUpdate } from '@vms/vmspro3-core/dist/utils/risk'
import { actions } from '@vms/vmspro3-core/dist'

import RiskCopyModal from '../modals/RiskCopyModal'
import NavConfirmation from '../controls/NavConfirmation'
import RiskImpacts from './RiskImpacts'
import RiskCategorySelect from './RiskCategorySelect'
import RiskPropertyHistory from './RiskPropertyHistory'
import RiskContextScaleReference from './RiskContextScaleReference'
import RiskEntityAttachmentManager from './RiskEntityAttachmentManager'
import RiskPortfolioNodeHeader from './RiskPortfolioNodeHeader'
import RichText2 from '../controls/RichText2'
import { CostInput_Risk, DurationInputWithUnits_Risk, Select, StringInput } from '../controls'

import useAuthz from '../../../hooks/useAuthz'
import useFormDraftState from '../hooks/useFormDraftState'
import useRiskEntity from '../hooks/useRiskEntity'
import useQuerystringTabKey from '../hooks/useQuerystringTabKey'
import usePrevious from '../../../hooks/usePrevious'
import { LoadingStatus } from '../../../utils/appConsts'
import { entityById } from '../selectors'
import { useAccount } from '../../../context'
import { useShowModal } from '../RiskModalContext'

const { Color, RiskAnalysisStatus } = systemConsts

const rtMaxHeight = 185 // 6 lines visible
const rtMinHeight = 70 // 2 lines visible

// TODO: this component copied from project; we could either make
// this a generic component, or make it more appropriate to risks
const NotFound = ({ accountCommonId, projectId }) => (
  <>
    <PageHeader title="404 - Not Found" />
    <div style={style.container}>
      <p>
        This risk may have been deleted. Back to
        <Link to={`/${accountCommonId}/proj/${projectId}`}>project</Link>.
      </p>
    </div>
  </>
)

// TODO: needs dev review; this should probably live in its own component.
// also, this is essentially doing the same thing as RiskManagementPlan
// below, but more.  the name & status should be broken out into separate
// components, and then turn these two into a generic component that can
// just spit out a specified list of rich text fields
const RiskInfo = ({ risk, effectiveRiskContext, updateRisk, readOnly }) => {
  const props = ['description', 'trigger', 'comments']
    .map(propName => {
      const prop = effectiveRiskContext.fields[propName]
      if (!prop) {
        console.warn(`no definition for property "${propName}" in effective risk context`)
        return undefined
      }
      return {
        propName,
        ...prop,
      }
    })
    .filter(Boolean)

  const nameInputRef = useRef(null)
  useEffect(() => {
    nameInputRef.current.focus()
  }, [])
  const phasesByValue = useMemo(
    () => _keyBy(effectiveRiskContext.types.phase.values, 'value'),
    [effectiveRiskContext]
  )
  const phase = useMemo(() => phasesByValue[risk.phase], [phasesByValue, risk.phase])

  const onChangeRiskType = type =>
    updateRisk({
      type,
      riskRespStrat: null, // need to reset since they are different based on risk types
    })

  return [
    <Row key="name" gutter={24}>
      <Col span={3}>
        <Form.Item label="Number">
          <StringInput readOnly value={risk.num} placeholder="Risk Number" />
        </Form.Item>
      </Col>
      <Col span={21}>
        <Form.Item label="Name">
          <StringInput
            readOnly={readOnly}
            ref={nameInputRef}
            value={risk.name}
            placeholder="Risk name"
            onChange={name => updateRisk({ name })}
          />
        </Form.Item>
      </Col>
    </Row>,
    <Row key="status" gutter={24}>
      <Col span={6}>
        <Form.Item label="Status:">
          <Select
            readOnly={readOnly}
            value={risk.status}
            onChange={status => updateRisk({ status })}
            allowClear={false}
            options={effectiveRiskContext.types.status.values.map(({ value, label }) => ({
              value,
              label: label.long,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Type:">
          <Select
            readOnly={readOnly}
            value={risk.type}
            onChange={onChangeRiskType}
            allowClear={false}
            options={effectiveRiskContext.types.riskType.values.map(({ value, label }) => ({
              value,
              label: label.long,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Category:">
          <RiskCategorySelect
            category={risk.category}
            categoryValues={effectiveRiskContext.types.category.values}
            onChange={(category = null) => updateRisk({ category })}
            readOnly={readOnly}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Phase:">
          <Select
            readOnly={readOnly}
            value={phase && !phase.isDeleted ? phase.value : null}
            onChange={(phase = null) => updateRisk({ phase })}
            allowClear
            options={effectiveRiskContext.types.phase.values
              .filter(p => !p.isDeleted)
              .map(({ value, label }) => ({
                value,
                label: label.long,
              }))}
          />
        </Form.Item>
      </Col>
    </Row>,
    ...props.map(({ propName, label }) => {
      const value = _get(risk, [propName, 'value'])
      return (
        <RichText2
          basic
          heightMax={rtMaxHeight}
          heightMin={rtMinHeight}
          key={propName}
          noAutoExpand
          onChange={v => updateRisk({ [propName]: createHtmlObject(v) })}
          readOnly={readOnly}
          title={label.long}
          value={value}
        />
      )
    }),
  ]
}

const RiskManagementPlan = ({ risk, effectiveRiskContext, updateRisk, readOnly }) => {
  const propNames = [
    'riskRespStrat',
    'riskRespFocus',
    'primaryActionPlan',
    'fallbackActionPlan',
    'riskOwnerNotes',
    'reviewDateAndFreq',
    'baseCostImpacts',
    'baseScheduleImpacts',
    'statusUpdateComments',
  ]
  const props = propNames
    .map(propName => {
      const prop = effectiveRiskContext.fields[propName]
      if (!prop) {
        console.warn(`no definition for property "${propName}" in effective risk context`)
        return undefined
      }
      return {
        propName,
        ...prop,
      }
    })
    .filter(Boolean)
  return props.map(({ propName, type, label }) => {
    const value = _get(risk, [propName, 'value'])
    const [baseType, enumKey] = type.split(':')
    switch (baseType) {
      case 'string':
        if (enumKey) {
          // frustratingly, we have to handle riskRespStrat specially....
          const enumValues = effectiveRiskContext.types[enumKey].values
          const filteredEnumValues =
            propName === 'riskRespStrat' ? enumValues.filter(v => v.scope === _get(risk, 'type')) : enumValues
          return (
            <Row style={{ marginBottom: '12px' }} key={propName}>
              <Col span={6} style={{ textAlign: 'right', paddingRight: '12px' }}>
                {label.long}:
              </Col>
              <Col span={18}>
                <Select
                  readOnly={readOnly}
                  value={risk[propName]}
                  onChange={key => updateRisk({ [propName]: key })}
                  options={filteredEnumValues.map(({ value, label }) => ({ value, label: label.long }))}
                />
              </Col>
            </Row>
          )
        }
        return (
          <Row style={{ marginBottom: '12px' }} key={propName}>
            <Col span={6} style={{ textAlign: 'right', paddingRight: '12px' }}>
              {label}:
            </Col>
            <Col span={18}>
              <Input
                readOnly={readOnly}
                value={risk[propName]}
                onChange={key => updateRisk({ [propName]: key })}
              />
            </Col>
          </Row>
        )
      case 'html':
        return (
          <RichText2
            basic
            heightMax={rtMaxHeight}
            heightMin={rtMinHeight}
            key={propName}
            noAutoExpand
            onChange={v => updateRisk({ [propName]: createHtmlObject(v) })}
            readOnly={readOnly}
            title={label.long}
            value={value}
          />
        )
      case 'currency':
        return (
          <Row style={{ margin: '12px 0' }} key={propName}>
            <Col span={6} style={{ textAlign: 'right', paddingRight: '12px' }}>
              {label.long} ({effectiveRiskContext.defaultCostUnit}):
            </Col>
            <Col span={8}>
              <CostInput_Risk
                readOnly={readOnly}
                currency={effectiveRiskContext.defaultCostUnit}
                value={risk[propName]}
                onChange={value => updateRisk({ [propName]: value })}
              />
            </Col>
          </Row>
        )
      case 'duration':
        return (
          <Row style={{ margin: '12px 0' }} key={propName}>
            <Col span={6} style={{ textAlign: 'right', paddingRight: '12px' }}>
              {label.long}:
            </Col>
            <Col span={12}>
              <DurationInputWithUnits_Risk
                readOnly={readOnly}
                defaultUnit={effectiveRiskContext.defaultDurationUnit}
                value={risk[propName]}
                onChange={value => updateRisk({ [propName]: value })}
              />
            </Col>
          </Row>
        )
      default:
        console.warn(`unrecognized risk field type: "${type}"`)
        return null
    }
  })
}

// TODO: needs design and dev review; this should probably live in its own component
const RiskEditor = ({
  effectiveRiskContext,
  includePerformance,
  propertyPrefix = '',
  readOnly,
  risk,
  updateRisk,
}) => {
  const panelStyle = {
    background: 'white',
    border: 0,
  }

  return (
    <Collapse bordered={false} defaultActiveKey={['info', 'impacts', 'plan']}>
      <Collapse.Panel key="scales" header="Risk Scales (Reference)" style={panelStyle}>
        <RiskContextScaleReference
          effectiveRiskContext={effectiveRiskContext}
          includePerformance={includePerformance}
        />
      </Collapse.Panel>
      <Collapse.Panel key="info" header="Risk Information" style={panelStyle}>
        <RiskInfo
          effectiveRiskContext={effectiveRiskContext}
          readOnly={readOnly}
          risk={risk}
          updateRisk={updateRisk}
        />
      </Collapse.Panel>
      <Collapse.Panel key="impacts" header="Probability & Impacts" style={panelStyle}>
        <RiskImpacts
          effectiveRiskContext={effectiveRiskContext}
          propertyPrefix={propertyPrefix}
          risk={risk}
          readOnly={readOnly}
          updateRisk={updateRisk}
        />
      </Collapse.Panel>
      <Collapse.Panel key="plan" header="Risk Management Plan" style={panelStyle}>
        <RiskManagementPlan
          readOnly={readOnly}
          risk={risk}
          effectiveRiskContext={effectiveRiskContext}
          updateRisk={updateRisk}
        />
      </Collapse.Panel>
    </Collapse>
  )
}

const Risk = ({ project, risk, effectiveRiskContext }) => {
  const dispatch = useDispatch()
  const [deletingRisk, setDeletingRisk] = useState(false)

  const navigate = useNavigate()
  const { accountCommonId } = useAccount()

  const { id: riskId, ancestry: riskAncestry, name: riskName, status: riskStatus } = risk ?? {}

  const { id: projectId, ancestry: projectAncestry } = project

  /**
   * Show confirmation modal to delete the risk
   */
  const deleteRisk = () => {
    setDeletingRisk(true)
    Modal.confirm({
      title: `Deleting ${riskName}`,
      icon: <WarningOutlined />,
      content: 'Are you sure you want to delete this risk?',
      onOk() {
        discardRiskDraft() // clean up cache and delete the risk draft

        navigate(`/${accountCommonId}/proj/${projectId}`)
        const meta = {
          projectId,
          ancestry: riskAncestry, // projectId and ancestry needed for pubsub
          riskId,
        }
        dispatch(actions.risk.delete(null, meta))

        // Check status before decrementing the statistics!
        if (riskStatus === RiskAnalysisStatus.ACTIVE) {
          // If status is 'ACTIVE' dispatch to update the statistics
          dispatch(
            actions.riskProject.updateStatistics(
              {
                activeRisks: {
                  operation: 'DECREMENT',
                  value: 1,
                },
                // TODO: Handle different entities other than projects
              },
              { projectId, ancestry: riskAncestry }
            )
          )
        }
      },
      onCancel() {
        setDeletingRisk(false)
      },
    })
  }

  const onSubmitRisk = useCallback(
    (update, clearDraftState) => {
      batch(() => {
        dispatch(
          actions.risk.update(update, {
            projectId,
            riskId,
            ancestry: riskAncestry,
          })
        )

        if (update.status && riskStatus !== update.status) {
          const dispatchUpdateActiveRisks = operation =>
            dispatch(
              actions.riskProject.updateStatistics(
                {
                  activeRisks: {
                    value: 1,
                    operation,
                  },
                },
                { projectId, ancestry: projectAncestry }
              )
            )

          if (update.status !== riskStatus) {
            // if risk status is moving to "ACTIVE" from any other status, increment activeRisks count in project
            if (update.status === RiskAnalysisStatus.ACTIVE) dispatchUpdateActiveRisks('INCREMENT')
            // if risk status is moving from "ACTIVE" to any other status, decrement activeRisks count in project
            else if (riskStatus === RiskAnalysisStatus.ACTIVE) dispatchUpdateActiveRisks('DECREMENT')
          }
        }
      })

      clearDraftState()
    },
    [dispatch, projectId, riskStatus, riskId, projectAncestry, riskAncestry]
  )

  const [riskDraft, updateDraftState, discardRiskDraft, submitRisk] = useFormDraftState(
    `RISK_DRAFT:${riskId}`,
    onSubmitRisk
  )

  // overlay risk draft field values onto risk to get effective risk fields when component initializes,
  // then directly update with updateRiskDraft.
  const [riskDataChanged, setRiskDataChanged] = useState(false)
  const [riskFields, setRiskFields] = useState(() =>
    Object.entries(riskDraft ?? {}).reduce((r, [k, v]) => _set(r, k, v), _cloneDeep(risk))
  )

  /**
   * discards risk draft and resets risk form field state
   */
  const discardDraftRiskFieldState = useCallback(() => {
    discardRiskDraft()
    setRiskFields(_cloneDeep(risk))
    setRiskDataChanged(false)
  }, [discardRiskDraft, risk])
  // if draft exists and risk changes come from external updates, show modal explaining to user
  // that underlying data changed and their draft is no longer valid and revoke ability to submit
  // the form by disabling the "save" button.
  // TODO: obviously this is not the ideal way to handle this situation but will have to do for now.
  const prevRiskData = usePrevious(risk)
  useEffect(() => {
    if (riskDraft) {
      if (prevRiskData && risk !== prevRiskData) {
        setRiskDataChanged(true)
        Modal.warning({
          title: 'Risk Data Changed',
          content:
            'Another user updated this risk, making the current draft changes incompatible. Please ' +
            'copy any draft changes you wish to save, then discard the draft state and review the updated ' +
            'risk, applying changes as needed.',
        })
      }
    } else {
      // no draft state, just set risk data
      setRiskFields(_cloneDeep(risk))
    }
  }, [risk, riskDraft, prevRiskData])

  /**
   * Given a risk update object, passes existing risk fields state, the update object, and the effective risk
   * context to the completeRiskUpdate function and applies the flat completed update to the localStorage risk
   * draft state and merges deeply with the risk field state in the component.
   * @param {Object} fieldUpdate - object containing updates to merge into risk draft state
   */
  const updateRiskDraft = useCallback(
    fieldUpdate =>
      setRiskFields(state => {
        // complete update with current risk fields state
        const completedUpdate = completeRiskUpdate(state, fieldUpdate, effectiveRiskContext)

        // update flat risk draft state with completed update
        updateDraftState(completedUpdate)

        // merge (deep set at path) completed risk update with existing risk fields state
        return Object.entries(completedUpdate).reduce((r, [k, v]) => _set(r, k, v), _cloneDeep(state))
      }),
    [effectiveRiskContext, updateDraftState]
  )

  const { tabKey, onTabChange } = useQuerystringTabKey('unmanaged')

  // quick and dirty method to fetch fresh state when switching back to the history tab. When the key changes
  // on the risk history component it will force a re-render and fetch fresh risk history. This will need to
  // change when we finalize the risk history components and determine how to handle pub-sub for risk history
  // updates.
  const [historyRenderKey, setHistoryRenderKey] = useState(() => createId())
  useEffect(() => {
    if (tabKey === 'history') setHistoryRenderKey(createId())
  }, [tabKey])

  const authz = useAuthz()
  const riskActionMeta = {
    riskId,
    projectId,
    ancestry: riskAncestry,
  }
  const canDeleteRisk = authz(actions.risk.delete(null, riskActionMeta))
  const canEditRisk = authz(actions.risk.update(null, riskActionMeta))
  const canCreateRisk = authz({ module: 'Risk', type: actions.risk.create.toString(), meta: riskActionMeta })

  const tabBarControls = (
    <div {...style.tabBarControls}>
      <Button type="link" style={riskDraft ? undefined : style.hidden} onClick={discardDraftRiskFieldState}>
        Discard Changes
      </Button>
      {canEditRisk && (
        <Button type="primary" onClick={submitRisk} disabled={!riskDraft || riskDataChanged}>
          Save
        </Button>
      )}
      {canDeleteRisk && (
        <Button type="danger" onClick={deleteRisk}>
          Delete Risk
        </Button>
      )}
    </div>
  )

  const riskEditorProps = {
    risk: riskFields,
    effectiveRiskContext,
    includePerformance: true,
    updateRisk: updateRiskDraft,
    readOnly: !canEditRisk || riskDataChanged,
  }

  const showModal = useShowModal()
  const showRiskCopyModal = () =>
    showModal(RiskCopyModal.id, {
      sourceProjectId: projectId,
      sourceRiskId: riskId,
    })

  const headerExtra = (
    <div {...style.headerExtra}>
      {canCreateRisk && (
        <Button type="secondary" onClick={showRiskCopyModal}>
          Create Copy
        </Button>
      )}
    </div>
  )

  return (
    <>
      <NavConfirmation when={!!riskDraft && !deletingRisk} modalParams={{ onOk: discardRiskDraft }} />
      <RiskPortfolioNodeHeader ancestry={riskAncestry} entityId={riskId} extra={headerExtra} />
      <div style={style.container}>
        <Tabs
          defaultActiveKey="unmanaged"
          tabBarExtraContent={tabBarControls}
          onChange={onTabChange}
          style={style.tabs}
        >
          <Tabs.TabPane style={style.tabPane} tab="Unmanaged" key="unmanaged">
            <RiskEditor {...riskEditorProps} />
          </Tabs.TabPane>
          <Tabs.TabPane style={style.tabPane} tab="Managed" key="managed">
            <RiskEditor {...riskEditorProps} propertyPrefix="managed." />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attachments" key="attachments">
            <RiskEntityAttachmentManager entityId={riskId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Risk History" key="history">
            <RiskPropertyHistory
              effectiveRiskContext={effectiveRiskContext}
              key={historyRenderKey}
              projectId={projectId}
              riskId={riskId}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  )
}

const RiskWrapper = () => {
  const { accountCommonId, projectId, riskId } = useParams()

  // here we're "overfetching" by getting the project this risk belongs to, which
  // will also load all of it's children...which includes this risk, but also all
  // it's siblings.  however, this is currently needed for the copy risk functionality
  // (to constrcut copy name).
  const { entity: project, effectiveRiskContext } = useRiskEntity(projectId, { loadChildren: true })

  const risk = useSelector(entityById(riskId))

  if (risk?.loadingStatus === LoadingStatus.NotFound) {
    return <NotFound projectId={projectId} accountCommonId={accountCommonId} />
  }
  if ([project, risk].some(entity => entity?.loadingStatus !== LoadingStatus.Loaded)) return <Spin />

  return <Risk project={project} risk={risk} effectiveRiskContext={effectiveRiskContext} />
}

const style = {
  tabBarControls: css({
    '& > *': {
      marginRight: '16px',
      '&:not(:first-child)': {
        marginLeft: '16px',
      },
    },
  }),
  hidden: {
    opacity: 0,
    visibility: 'hidden',
  },
  container: {
    backgroundColor: Color.WHITE,
    margin: '24px 30px',
  },
  tabs: {
    padding: '6px 12px',
  },
  tabPane: {
    padding: '0px 24px 24px',
  },
}

export default RiskWrapper
