import { useCallback, useMemo } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { actions } from '@vms/vmspro3-core/dist'
import { EmailRegex } from '@vms/vmspro3-core/dist/systemConsts'

import useAuthz from '../../../hooks/useAuthz'
import { useAuth, useAccount } from '../../../context'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { selectAccountName, selectRolePolicies, selectUsers } from '../../../redux/selectors'

interface InviteUserFormFields {
  email: string
  fullName: string
  policyIds: string[]
}

interface InviteUserModalProps {
  hideModal: () => void
}
export function InviteUserModal({ hideModal }: InviteUserModalProps) {
  const { accountId, account } = useAccount()

  const dispatch = useAppDispatch()
  const accountName = useAppSelector(selectAccountName)
  const { authUserId, authUser } = useAuth()
  const allUsers = useAppSelector(selectUsers)

  const [form] = Form.useForm<InviteUserFormFields>()

  const onOk = useCallback(
    () =>
      form.validateFields().then(({ email, fullName, policyIds }) => {
        form.resetFields()
        const inviteUserAction = actions.account.inviteUser({
          accountId,
          accountName: account.name,
          email,
          fullName,
          policyIds,
          invitedById: authUserId,
          invitedByName: authUser.fullName,
        })

        dispatch(inviteUserAction)
        hideModal()
      }),
    [dispatch, account.name, accountId, authUserId, authUser.fullName, hideModal, form]
  )

  const rolePolicies = useAppSelector(selectRolePolicies)
  const authz = useAuthz()
  const policyOptions = useMemo(
    () =>
      rolePolicies
        .filter(({ id: policyId }) =>
          authz(
            actions.user.addPolicy(undefined, {
              userId: 'ANY',
              authUserId,
              policyId,
            })
          )
        )
        .map(({ id, name }) => ({ value: id, label: name })),
    [authUserId, rolePolicies, authz]
  )

  return (
    <Modal open onOk={onOk} onCancel={hideModal}>
      <h2>Invite a user to join {accountName}.</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          normalize={email => email.trim()}
          rules={[
            {
              required: true,
              message: 'Please enter an email address',
            },
            {
              validateTrigger: 'onBlur',
              pattern: EmailRegex,
              message: 'Please enter a valid email address',
            },
            {
              validator(_, value) {
                return allUsers.some(u => u.email === value)
                  ? // eslint-disable-next-line prefer-promise-reject-errors
                    Promise.reject('A user with that email already has access to this account')
                  : Promise.resolve()
              },
            },
          ]}
        >
          <Input type="email" placeholder="email@domain.com" prefix={<UserOutlined />} autoFocus />
        </Form.Item>
        <Form.Item name="fullName" label="Full Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Global roles" name="policyIds" rules={[{ required: true }]}>
          <Select mode="multiple" options={policyOptions} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
