/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { CellValue, Column } from 'react-table'
import { QuestionCircleFilled } from '@ant-design/icons'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'
import { Options } from '@vms/vmspro3-core/dist/nextgen/options'
import { extractCoefficients } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'
import { Duration } from '@vms/vmspro3-core/dist/qty'
import { MissingPerformanceRatingInfo } from '@vms/vmspro3-core/dist/nextgen/valueGraph/performanceValueNode'

import { Table, TableCellRenderer } from '../../../common/Table'
import { ValuemetricsMissingRatingsInfoModal } from './ValuemetricsMissingRatingsInfoModal'

import { useModalState } from '../../../../hooks/useModalState'
import { useDecision } from '../../../../redux/hooks'

const initialSortState = {
  sortBy: [{ id: 'value', desc: true }],
}

const format: Record<string, (cellProps: CellValue) => string | null> = {
  cost: ({ value }) => {
    if (!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(value)
  },
  time: ({ value }) => {
    if (!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value)
  },
  score: ({ value }) => {
    if (!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value)
  },
  percent: ({ value }) => {
    if (!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value)
  },
}

type ValuemetricsTableData = {
  optionId: string
  name: string
  abbrev: string
  color: string
  isBaseline: boolean
  quant: {
    cost: number | null
    time: number | null
  }
  scores: Record<'unweighted' | 'weighted', Record<'cost' | 'time' | 'perf', number | null>>
  value: number | null
  change?: Record<string, number | null>
  missingRatings: MissingPerformanceRatingInfo[]
  subRows?: ValuemetricsTableData[]
  prob: number
  expanded?: boolean
}

interface ValuemetricsTableProps {
  decisionId: string
  hasBaseline: boolean
  options: Options
  setOptionsSortOrder: (optionIds: string[]) => void
  setValueGraphOptionId: (optionId: string | undefined) => void
  updateBaselineOptionId: (baselineOptionId: string | undefined) => void
}
export function ValuemetricsTable({
  decisionId,
  hasBaseline,
  options,
  setOptionsSortOrder,
  setValueGraphOptionId,
  updateBaselineOptionId,
}: ValuemetricsTableProps) {
  const { modal, showModal, hideModal } = useModalState()

  const data = useMemo(() => {
    const coefficients = extractCoefficients(options.valueFormula)

    return options.all.map(option => {
      const normalizedDuration = Duration.isDuration(option.quantAttrs.T)
        ? Duration.convert(option.quantAttrs.T as Duration, 'Months')
        : undefined

      const datum: ValuemetricsTableData = {
        optionId: option.id,
        name: option.name,
        abbrev: option.abbrev,
        color: option.color,
        isBaseline: !!options.baseline && options.baseline === option,
        quant: {
          cost: option.quantAttrs.C?.value ?? null,
          time: normalizedDuration?.value ?? null,
        },
        scores: {
          unweighted: {
            cost: option.quantScores.C,
            time: option.quantScores.T,
            perf: option.performanceGraph?.value ?? null,
          },
          weighted: {
            cost: typeof option.quantScores.C === 'number' ? option.quantScores.C * coefficients.C : null,
            time: typeof option.quantScores.T === 'number' ? option.quantScores.T * coefficients.T : null,
            perf:
              typeof option.performanceGraph?.value === 'number'
                ? option.performanceGraph.value * coefficients.P
                : null,
          },
        },
        change: {
          cost: option.changeInQuantScores.C,
          time: option.changeInQuantScores.T,
          perf: option.changeInPerf,
          value: option.changeInValue,
        },
        prob: 1, // by definition, options have a probability of 100% (which all outcomes, if any sum to)
        value: option.value,
        missingRatings: option.performanceGraph?.missingRatings || [],
        expanded: true,
      }
      if (option.outcomes.all.length > 0) {
        datum.subRows = option.outcomes.all.map(oo => ({
          optionId: oo.id,
          name: oo.name,
          abbrev: oo.abbrev,
          color: oo.color,
          isBaseline: false,
          quant: {
            cost: null,
            time: null,
          },
          scores: {
            unweighted: {
              cost: null,
              time: null,
              perf: oo.performanceGraph?.value ?? null,
            },
            weighted: {
              cost: null,
              time: null,
              perf: null,
            },
          },
          change: {
            cost: null,
            time: null,
            perf: null,
            value: null,
          },
          prob: oo.prob,
          value: oo.valueGraph?.value ?? null,
          missingRatings: [],
        }))
      }
      return datum
    })
  }, [options])

  const unratedCriteria = useMemo(
    () =>
      options.criteria?.all.filter(c => ['Performance', 'Rated'].includes(c.type) && c.pri.global === null) || [],
    [options.criteria]
  )

  const decision = useDecision(decisionId)

  const columns = useMemo<Column<ValuemetricsTableData>[]>(() => {
    const coefficients = extractCoefficients(decision.valueFunctionExpr)
    const hasCost = typeof coefficients.cost === 'number'
    const hasTime = typeof coefficients.time === 'number'
    return [
      {
        Header: 'Option Information',
        columns: [
          {
            id: 'baseline-checkbox',
            Header: 'Baseline',
            accessor: 'optionId',
            Cell: (cellProps: any) => (
              <input
                type="checkbox"
                checked={cellProps.row.original.isBaseline}
                onChange={() => updateBaselineOptionId(cellProps.value)}
              />
            ),
            disableSortBy: true,
          },
          {
            Header: 'Abbrev.',
            accessor: 'abbrev',
            Cell: ({ row }: any) =>
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${row.depth * 20}px`,
                    },
                  })}
                >
                  {row.original.abbrev} <span style={{ userSelect: 'none' }}>{row.isExpanded ? '▼' : '▶︎'}</span>
                </span>
              ) : (
                <span style={{ paddingLeft: `${row.depth * 20}px` }}>{row.original.abbrev}</span>
              ),
          },
          {
            Header: 'Option',
            accessor: 'name',
            Cell: TableCellRenderer.EntityName as any,
          },
          {
            Header: 'Cost (USD)',
            accessor: (row: any) => row.quant.cost,
            Cell: format.cost as any,
            sortType: 'numeric',
            align: 'right',
            hide: !hasCost,
          },
          {
            Header: 'Time (months)',
            accessor: (row: any) => row.quant.time,
            Cell: format.time,
            sortType: 'numeric',
            align: 'right',
            hide: !hasTime,
          },
        ].filter(col => !col.hide),
      },
      {
        Header: 'Scores',
        columns: [
          {
            id: 'unweighted-perf',
            Header: 'Perf.',
            accessor: (row: any) => row.scores.unweighted.perf,
            Cell: (cellProps: CellValue) => {
              const {
                value,
                row: {
                  original: { name, missingRatings },
                },
              } = cellProps
              // the "canExpand" prop is a cheap way of determining if it's an option,
              // as opposed to the option outcome sub-rows
              if (cellProps.row.canExpand && !Number.isFinite(value)) {
                return (
                  <QuestionCircleFilled
                    style={{ color: Color.BLUE_LINK }}
                    onClick={() =>
                      showModal(
                        <ValuemetricsMissingRatingsInfoModal
                          optionName={name}
                          decisionId={decisionId}
                          missingRatings={missingRatings}
                          unratedCriteria={unratedCriteria}
                          hideModal={hideModal}
                        />
                      )
                    }
                  />
                )
              }
              return format.score(cellProps) as any
            },
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
          {
            id: 'unweighted-cost',
            Header: 'Cost',
            accessor: (row: any) => row.scores.unweighted.cost,
            Cell: format.score,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
            hide: !hasCost,
          },
          {
            id: 'unweighted-time',
            Header: 'Time',
            accessor: (row: any) => row.scores.unweighted.time,
            Cell: format.score,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
            hide: !hasTime,
          },
        ].filter(col => !col.hide),
      },
      {
        Header: '% Change',
        id: 'baseline-changes',
        columns: [
          {
            id: 'baseline-change-perf',
            Header: 'Perf.',
            accessor: (row: any) => row.change?.perf,
            Cell: format.percent as any,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
          {
            id: 'baseline-change-cost',
            Header: 'Cost',
            accessor: (row: any) => row.change?.cost,
            Cell: format.percent,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
            hide: !hasCost,
          },
          {
            id: 'baseline-change-time',
            Header: 'Time',
            accessor: (row: any) => row.change?.time,
            Cell: format.percent,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
            hide: !hasTime,
          },
        ].filter(col => !col.hide),
      },
      {
        Header: 'Probability',
        align: 'right',
        accessor: row => row.prob,
        Cell: format.percent as any,
      },
      {
        Header: 'Value Index',
        accessor: 'value',
        Cell: cellProps => {
          if (cellProps.value === null) {
            return null
          }

          return (
            <span
              style={{ color: Color.BLUE_LINK, cursor: 'pointer' }}
              onClick={() => setValueGraphOptionId(cellProps.row.original.optionId)}
            >
              {Intl.NumberFormat('en-US', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(cellProps.value)}
            </span>
          )
        },
        sortType: 'numeric',
        sortDescFirst: true,
        align: 'right',
      },
      {
        id: 'baseline-change-value',
        Header: '% Change',
        accessor: row => row.change?.value,
        Cell: format.percent as any,
        sortType: 'numeric',
        sortDescFirst: true,
        align: 'right',
      },
    ]
  }, [
    decision.valueFunctionExpr,
    updateBaselineOptionId,
    showModal,
    decisionId,
    unratedCriteria,
    hideModal,
    setValueGraphOptionId,
  ])

  const hiddenColumns = useMemo<string[]>(() => {
    function getBaselineColumnIds(columns: Column<ValuemetricsTableData>[]) {
      const baselineColumnIds: string[] = []
      columns.forEach(column => {
        if (column.id?.startsWith('baseline')) {
          baselineColumnIds.push(column.id)
        }
        if ('columns' in column && column.columns) {
          baselineColumnIds.push(...getBaselineColumnIds(column.columns))
        }
      })
      return baselineColumnIds
    }

    return hasBaseline ? [] : getBaselineColumnIds(columns)
  }, [columns, hasBaseline])

  const onSortByChanged = useCallback(
    (data: ValuemetricsTableData[]) => setOptionsSortOrder(data.map(row => row.optionId)),
    [setOptionsSortOrder]
  )

  return (
    <>
      {modal}
      <Table<ValuemetricsTableData>
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
        onSortByChanged={onSortByChanged}
        initialState={initialSortState}
      />
    </>
  )
}
