import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EntityType, SystemPolicyId, ROOT_RISK_PORTFOLIO_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { Row, Col, Spin, Tabs } from 'antd'

import RiskPortfolioNodeHeader from './RiskPortfolioNodeHeader'
import RiskContextScaleEditor from './RiskContextScaleEditor'
import RiskContextCategoryEditor from './RiskContextCategoryEditor'
import RiskContextPhaseEditor from './RiskContextPhaseEditor'
import RiskEntityIdentity from './RiskEntityIdentity'
import RiskEntityLocation from './RiskEntityLocation'
import ResourceAuthorizationEditor from '../../common/ResourceAuthorizationEditor'

import { entityById } from '../selectors'
import { LoadingStatus } from '../../../utils/appConsts'
import { fetchRiskEntity } from '../../../redux/actions'

import useQuerystringTabKey from '../hooks/useQuerystringTabKey'

const RiskEntityConfig = () => {
  const { entityId = ROOT_RISK_PORTFOLIO_ID } = useParams()
  const dispatch = useDispatch()

  const { tabKey, onTabChange } = useQuerystringTabKey('identity-and-location')

  const entity = useSelector(entityById(entityId))

  const loadingStatus = entity?.loadingStatus || LoadingStatus.NotLoaded

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NotLoaded) {
      dispatch(fetchRiskEntity(entityId))
    }
  }, [dispatch, loadingStatus, entityId])

  // TODO: ensure risk entity loaded
  if (loadingStatus !== LoadingStatus.Loaded) return <Spin />

  const riskAuthzOptions = [
    { key: 'read', value: SystemPolicyId.RISK_REVIEWER, label: 'Read-Only' },
    { key: 'edit', value: SystemPolicyId.RISK_EDITOR, label: 'Risk Editor' },
    { key: 'admin', value: SystemPolicyId.RISK_ADMINISTRATOR, label: 'Risk Admin' },
  ]

  const tabItems = [
    {
      key: 'identity-and-location',
      label: 'Identity & Location',
      children: (
        <Row gutter={30}>
          <Col span={12}>
            <h3>Identity</h3>
            <RiskEntityIdentity entityId={entityId} />
          </Col>
          <Col span={12}>
            <h3>Location</h3>
            <RiskEntityLocation entityId={entityId} />
          </Col>
        </Row>
      ),
    },
    {
      key: 'calibration',
      label: 'Risk Calibration',
      children: <RiskContextScaleEditor entityId={entityId} />,
    },
    {
      key: 'categories',
      label: 'Categories',
      children: <RiskContextCategoryEditor entityId={entityId} />,
    },
    {
      key: 'phases',
      label: 'Phases',
      children: <RiskContextPhaseEditor entityId={entityId} />,
    },
    {
      key: 'access',
      label: 'Access',
      children: (
        <ResourceAuthorizationEditor
          ancestry={entity.ancestry}
          authzOptions={riskAuthzOptions}
          resourceType={EntityType.PROJECT}
          resourceId={entityId}
        />
      ),
    },
  ]

  return (
    <>
      <RiskPortfolioNodeHeader
        ancestry={entity.ancestry}
        entityId={entityId}
        title="Configuration"
        breadcrumbAdditions={[{ breadcrumbName: 'Configuration' }]}
      />
      <div style={style.container}>
        <Tabs activeKey={tabKey} onChange={onTabChange} items={tabItems} />
      </div>
    </>
  )
}

const style = {
  button: {
    margin: '12px 24px 0 12px',
  },
  collapse: {
    margin: '12px',
  },
  table: {
    margin: '12px 24px',
  },
  container: {
    backgroundColor: '#fff',
    margin: '24px 30px',
    padding: '24px 30px',
  },
  projectsMenu: {
    maxHeight: '20vh',
    overflow: 'auto',
  },
}

export default RiskEntityConfig
