import React from 'react'
import { Input } from 'antd'

const StringInput = React.forwardRef(
  ({ allowEmptyOrWhitespace, onBlur, onChange, readOnly, value, ...props }, ref) => {
    if (readOnly) {
      return (
        <span style={readOnlyStyle} ref={ref}>
          {typeof value === 'undefined' || value === null ? null : value}
        </span>
      )
    }

    const sanitizeValue = v => (allowEmptyOrWhitespace || /\S/.test(v) ? v : null)

    const handleBlur = e => onBlur && onBlur(sanitizeValue(e.target.value))

    const handleChange = e => onChange && onChange(sanitizeValue(e.target.value))

    return <Input {...props} ref={ref} onBlur={handleBlur} onChange={handleChange} value={value} />
  }
)
StringInput.defaultProps = {
  allowEmptyOrWhitespace: false,
}

const readOnlyStyle = {
  minHeight: '32px',
  paddingLeft: '12px',
}

export default StringInput
