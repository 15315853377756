import React from 'react'
/** @jsxImportSource @emotion/react */
import { colorScale } from '../../_riskConfig/color'
import printMagnitude from '../../utils/printMagnitude'

export default function Legend({ displayFactor }) {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        paddingTop: '12px',
      }}
    >
      {colorScale.map((colorRange, idx) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          css={{
            display: 'flex',
            alignSelf: 'flex-end',
            height: '19px',
            margin: '0 0 0 10px',
          }}
        >
          <span
            css={{
              display: 'inline-block',
              height: '15px',
              width: '15px',
              background: colorRange.color,
              margin: '2px',
              alignSelf: 'center',
            }}
          />
          <span
            css={{
              height: '19px',
              fontSize: '15px',
              lineHeight: '1',
              alignSelf: 'center',
              fontWeight: '500',
              padding: '2px',
              whiteSpace: 'nowrap',
            }}
          >
            {' = ' +
              printMagnitude({
                magnitude: colorRange.magnitude,
                _displayFactor: displayFactor,
                _setting: 'to',
              })}
          </span>
        </div>
      ))}
    </div>
  )
}
