import { Button, Col, Modal, Row, Space } from 'antd'
import { useCallback, useMemo } from 'react'
import { Option, OptionData, Outcome } from '@vms/vmspro3-core/dist/nextgen/options'
import { useQuerystringValue } from '../../../../hooks/useQuerystringValue'
import { useDecision, useDecisionChildAncestry } from '../../../../redux/hooks'
import { ParticipationSessionControl } from '../Cri2/ParticipationSessionControl'
import { OptionNavigator } from './OptionNavigator'
import { OptionNotFound } from './OptionNotFound'
import { OptionRatingsTable } from './OptionRatingsTable'
import { OptionsList } from './OptionsList'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useModalState } from '../../../../hooks/useModalState'
import { OptionModal } from '../Options/OptionModal'
import { useAppDispatch } from '../../../../redux'
import { deleteOption, updateOption } from '@vms/vmspro3-core/dist/actions/decision'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { OptionOutcomeModal } from '../Options/OptionOutcomeModal'
import { OptionOutcomeCard } from './OptionOutcomeCard'
import { OptionRatingNotes } from './OptionRatingNotes'
import Title from 'antd/lib/typography/Title'
import { OutcomeProbTable } from './OutcomeProbTable'
import { useTour } from '../../../../hooks/useTour'

interface OptionDetailHeaderProps {
  option: Option
  onEdit: (o: OptionData) => void
  onDelete: (o: OptionData) => void
}
function OptionDetailHeader(props: OptionDetailHeaderProps) {
  const { option } = props
  // TODO: I think we need a better solution for display name...this code's all over the place
  const displayName = !option.name.trim().localeCompare(option.abbrev.trim(), undefined, { sensitivity: 'base' })
    ? `${option.name}`
    : `${option.abbrev} (${option.name})`

  const onEdit = () => props.onEdit(option.data)
  const onDelete = () => props.onDelete(option.data)
  return (
    <Row justify="space-between">
      <Col>
        <h1>
          {option.commonId} {displayName}
        </h1>
      </Col>
      <Col>
        <Space>
          <Button onClick={onEdit} type="default" icon={<EditOutlined />}>
            Edit
          </Button>
          <Button onClick={onDelete} type="default" danger icon={<DeleteOutlined />}>
            Delete
          </Button>
        </Space>
      </Col>
    </Row>
  )
}

interface OutcomeDetailHeaderProps {
  outcome: Outcome
  onEdit: (o: Outcome) => void
  onDelete: (o: Outcome) => void
}
function OutcomeDetailHeader(props: OutcomeDetailHeaderProps) {
  const { outcome } = props
  const { option } = outcome
  const optionName = !option.name.trim().localeCompare(option.abbrev.trim(), undefined, { sensitivity: 'base' })
    ? `${option.name}`
    : `${option.abbrev} (${option.name})`
  const outcomeName = !outcome.name.trim().localeCompare(outcome.abbrev.trim(), undefined, { sensitivity: 'base' })
    ? `${outcome.name}`
    : `${outcome.abbrev} (${outcome.name})`

  const onEdit = () => props.onEdit(outcome)
  const onDelete = () => props.onDelete(outcome)
  return (
    <>
      <Row>
        <Col>
          <h1>
            {option.commonId} {optionName}
          </h1>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <h2>Outcome: {outcomeName}</h2>
        </Col>
        <Col>
          <Space>
            <Button onClick={onEdit} type="default" icon={<EditOutlined />}>
              Edit Outcome
            </Button>
            <Button onClick={onDelete} type="default" danger icon={<DeleteOutlined />}>
              Delete Outcome
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )
}

interface OptionDetailProps {
  decisionId: string
  optionId: string
}
function OptionDetail(props: OptionDetailProps) {
  const decision = useDecision(props.decisionId)
  const option = decision.options.byId(props.optionId)
  const optionAncestry = useDecisionChildAncestry(props.decisionId)

  const { modal, showModal, hideModal } = useModalState()

  const onEditOption = useCallback(
    (option: OptionData) =>
      showModal(<OptionModal option={option} decisionId={props.decisionId} hideModal={hideModal} />),
    [showModal, props.decisionId, hideModal]
  )

  const dispatch = useAppDispatch()

  const onDeleteOption = useCallback(
    (option: OptionData) =>
      Modal.confirm({
        title: `Deleting option "${option.name}"`,
        content: 'Are you sure you want to delete this option?',
        onOk: () => dispatch(deleteOption(optionAncestry, option.id)),
      }),
    [dispatch, optionAncestry]
  )

  const onCreateOutcome = useCallback(() => {
    if (!option) throw new Error("option is undefined (shouldn't happen)")
    showModal(<OptionOutcomeModal decisionId={props.decisionId} option={option.data} hideModal={hideModal} />)
  }, [option, showModal, props.decisionId, hideModal])

  if (!option) return <OptionNotFound optionId={props.optionId} />

  return (
    <>
      {modal}
      <OptionDetailHeader onEdit={onEditOption} onDelete={onDeleteOption} option={option} />
      <p dangerouslySetInnerHTML={{ __html: option.description.value || '' }} />
      <Title level={3}>Ratings</Title>
      <OptionRatingsTable decisionId={decision.id} optionId={option.id} />
      <Title level={3} style={{ marginTop: '12px' }}>
        Notes
      </Title>
      <i>
        Participants leave option rating notes on a per-criteria basis; notes shown here therefore apply to all
        options.
      </i>
      <OptionRatingNotes decisionId={decision.id} />
      <div style={{ margin: '12px 0' }}>
        <Title level={3}>Outcomes</Title>
        {option.outcomes.all.length === 0 && (
          <p>
            <i>Outcomes</i> imagine possible futures if this option is chosen. It allows you to describe different
            visions for the future, determine how good they are, and how probable they are. Creating outcomes
            produces additional work for participants, but can produce more nuanced results.
          </p>
        )}
        <Button onClick={onCreateOutcome} icon={<PlusOutlined />}>
          Add Outcome
        </Button>
        {option.outcomes.all.length > 0 && (
          <>
            <Row>
              {option.outcomes.all.map(outcome => (
                <Col key={outcome.id} span={24} style={{ padding: '4px 0' }}>
                  <OptionOutcomeCard outcome={outcome.outcomeData} />
                </Col>
              ))}
            </Row>
            <Title level={3}>Outcome Probabilities</Title>
            <OutcomeProbTable decisionId={decision.id} optionId={option.id} />
          </>
        )}
      </div>
    </>
  )
}

interface OutcomeDetailProps {
  decision: Decision
  outcome: Outcome
}
function OutcomeDetail(props: OutcomeDetailProps) {
  const { decision, outcome } = props
  const { option } = outcome

  const { modal, showModal, hideModal } = useModalState()

  const onEditOutcome = useCallback(
    () =>
      showModal(
        <OptionOutcomeModal
          decisionId={decision.id}
          option={option.data}
          outcome={outcome.outcomeData}
          hideModal={hideModal}
        />
      ),
    [showModal, decision.id, option.data, outcome.outcomeData, hideModal]
  )

  const dispatch = useAppDispatch()

  const onDeleteOutcome = useCallback(
    (option: Outcome) =>
      Modal.confirm({
        // for some reason i con't figure out, using "option.name" below results in the name of the outcome???
        title: `Deleting outcome "${outcome.name}" from option "${outcome.option.name}"`,
        content: 'Are you sure you want to delete this option?',
        onOk: () => {
          const action = updateOption(decision.id, outcome.option.id, {
            outcomes: option.outcomes.all.filter(oo => oo.id !== outcome.id).map(oo => oo.outcomeData),
          })
          dispatch(action)
        },
      }),
    [decision.id, dispatch, outcome.id, outcome.name, outcome.option.id, outcome.option.name]
  )

  return (
    <>
      {modal}
      <OutcomeDetailHeader onEdit={onEditOutcome} onDelete={onDeleteOutcome} outcome={outcome} />
      <p dangerouslySetInnerHTML={{ __html: outcome.outcomeData.description?.value || '' }} />
      <OptionRatingsTable decisionId={decision.id} optionId={option.id} outcomeId={outcome.id} />
    </>
  )
}

interface OptionPageProps {
  decisionId: string
}
export function OptionsPage(props: OptionPageProps) {
  const decision = useDecision(props.decisionId)
  // for some reason, I couldn't use useQueryStringValue twice (one for optionId, one for outcomeId), so I
  // had to do it this way, which is more convoluted
  const [sel, _setSel] = useQuerystringValue<string, string | undefined>('selection')
  // for now, making option and outcome selection mutually exclusive; in the future, we may
  // want to indicate the selected option when we select an outcome...maybe
  const setSel = useCallback(
    (optionId?: string, outcomeId?: string) => {
      if (outcomeId && !optionId) throw new Error('when selecting outcome, must select its parent option')
      _setSel(outcomeId ? `${optionId}.${outcomeId}` : optionId)
    },
    [_setSel]
  )
  const [selOptionId, selOutcomeId] = (sel || '').split('.').filter(Boolean)

  const showProb = useMemo(() => decision.options.all.some(o => o.outcomes.all.length > 0), [decision.options.all])

  const selOutcome = selOutcomeId ? decision.options.byId(selOptionId)?.outcomes.byId[selOutcomeId] : undefined

  const { tour } = useTour('Criteria Page', [
    {
      target: "[data-tour-id='Option Navigator']",
      placement: 'right',
      content: (
        <>
          <p>
            This is where you can navigate your options, and option outcomes (if any). If you choose "All Options",
            you can access a table of all options.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Add Option']",
      placement: 'right',
      content: (
        <>
          <p>Creating some options is a good place to start!</p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Participation Session Control']",
      placement: 'right',
      content: (
        <>
          <p>
            This is where you control participation in this decision. When you're ready to invite other people to
            participate in the decision (or if you just want to rate the options yourself), you can start the
            option rating session.
          </p>
          <p>
            Once the session is started, you can copy the link to share with users, rate options yourself, or show
            a presentation screen with all of the relevant information so people can joint the session. You can
            also stop the session to prevent further participation.
          </p>
          <p>
            If you add outcomes to an option, you will also see a participation control for probability assessment.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Option Overview']",
      placement: 'top',
      content: (
        <>
          <p>
            You'll see details about the selected criterion here. If the criterion has children, you'll see
            prioritization data for it (assuming at least one person has prioritized the criteria).
          </p>
        </>
      ),
    },
  ])
  return (
    <>
      {tour}
      <Row gutter={36}>
        <Col xs={24} lg={6}>
          <OptionNavigator
            decision={decision}
            selectedOptionId={selOptionId}
            selectedOutcomeId={selOutcomeId}
            setSelected={setSel}
          />
          <ParticipationSessionControl decision={decision} type="OptionRating" />
          {showProb && <ParticipationSessionControl decision={decision} type="OutcomeProbability" />}
        </Col>
        <Col xs={24} lg={18} data-tour-id="Option Overview">
          {selOptionId ? (
            selOutcomeId ? (
              selOutcome ? (
                <OutcomeDetail decision={decision} outcome={selOutcome} />
              ) : (
                // TODO: this is a poor UX...we can do better
                <h3>Not found: option outcome with ID ${selOutcomeId}</h3>
              )
            ) : (
              <OptionDetail decisionId={decision.id} optionId={selOptionId} />
            )
          ) : (
            <OptionsList decisionId={decision.id} />
          )}
        </Col>
      </Row>
    </>
  )
}
