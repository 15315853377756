import React from 'react'
/** @jsxImportSource @emotion/react */
import printMagnitude from '../utils/printMagnitude'
import printStatLabel from '../utils/printStatLabel'
import { COLOR } from './color'

function createMarkup(html) {
  return { __html: html }
}

function StatMarker({ magnitude, color, stat, current }) {
  return (
    <span
      css={{
        width: '46px',
        height: '32px',
        background: current ? COLOR.GREY_2D : 'none',
        borderBottom: current ? 'none' : `2px ${COLOR.GREY_2} solid`,
      }}
    >
      <span
        css={{
          width: '100%',
          height: '32px',
          paddingTop: '4px',
          background: current ? color : 'none',
          color: current ? 'white' : stat.getColor(magnitude),
          textAlign: 'center',
          fontWeight: current ? '700' : '600',
        }}
      >
        {magnitude
          ? printMagnitude({
              magnitude,
              _displayFactor: stat.displayFactor,
              _setting: 'plus',
            })
          : '--'}
      </span>
    </span>
  )
}

export default function RiskCard({ article, articleConfig, statSelections }) {
  const risk = article
  const severity = articleConfig.stats[0]
  const columnLeft = (
    <div
      css={{
        gridArea: 'left',
        color: COLOR.GREY_3,
        marginTop: '10px',
        '*': {
          marginBottom: '5px',
          display: 'inline-block',
        },
      }}
    >
      <div>
        {severity.pivot.options.map(attribute => (
          <div css={{ marginLeft: '4px' }} key={'stat-tooltip-' + attribute.path}>
            <StatMarker
              magnitude={risk.severity?.[attribute.path]}
              color={risk.color}
              stat={severity}
              current={attribute.label === statSelections[1].label && !risk.isOverlaid}
            />
            <span
              css={{
                color: COLOR.GREY_3,
                margin: '0 5px',
                width: '14px',
              }}
            >
              →
            </span>
            <StatMarker
              magnitude={risk.managed?.severity?.[attribute.path]}
              color={risk.color}
              stat={severity}
              current={attribute.label === statSelections[1].label && risk.isOverlaid}
            />
            <p
              css={{
                display: 'inline-block',
                width: '100%',
                textAlign: 'center',
                marginBottom: '3px',
                paddingBottom: '3px',
                fontWeight: '600',
              }}
            >
              {printStatLabel([severity, attribute], ['long', 'short'])}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
  const columnRight = (
    <div
      css={{
        gridArea: 'right',
        color: COLOR.GREY_3,
        overflowY: 'scroll',
        margin: '10px 0 0 10px',
        paddingRight: '10px',
        '> *': {
          marginBottom: '10px',
        },
      }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={createMarkup(risk.description?.value)}
    />
  )

  return (
    <>
      {columnLeft}
      {columnRight}
    </>
  )
}
