import React from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import _truncate from 'lodash/truncate'
import { css } from 'glamor'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

const Plot = createPlotlyComponent(Plotly)

class TornadoChartDisplay extends React.Component {
  static plotlyConfig = {
    displaylogo: false,
    // TODO: determine if hover bar should be removed from the plot
    // displayModeBar: false,
    modeBarButtonsToRemove: [
      'select2d',
      'lasso2d',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
    ],
    responsive: true,
  }

  /**
   * @return {Object} "Tornado chart" implemented as Plotly grouped bar chart.
   */
  render() {
    const { data, domain, router, truncate } = this.props

    const addLink = data?.[0]?.routes?.length
    const tickvals = data?.[0]?.y
    const ticktext = truncate ? tickvals.map(val => _truncate(val, { length: 70, separator: ' ' })) : tickvals

    const tickfont = addLink ? { color: Color.BLUE_LINK } : {}

    /**
     * handle a click on a yaxis label - navigate to the corresponding route for the specified <text> tag.
     *
     * @param {Object} d - the html element data - unused in this function
     * @param {Number} routeIdx - the index of the text tag in the yaxis array coresponding to the index
     *    in the routes array
     */
    const handleClick = (d, routeIdx) => {
      const route = data[0].routes[routeIdx]
      router.navigate(route)
    }

    /*
     * after Plotly loads the plot (onAfterPlot event) attach event listeners for tick text clicks.
     */
    const afterPlot = () => {
      if (!addLink) return

      /*
       * plotly is built on top of d3. D3 has built in handlers for events:
       * https://github.com/d3/d3-selection/blob/v1.4.1/README.md#handling-events
       *
       * The code snippet below is finding all yaxislayer-above classes.
       * In the case of multiple charts on a screen, there will be multiple layers.
       * Each layer, will have it's own list of yaxis ticks, stored in <text> tags on the SVG.
       *
       * The double selectAll() will return a nested array (with an array of nodes holding an array of texts):
       * [ [ textObj ], [ textObj ] ]
       *
       * .on('click') attaches an event handler which has properties:
       * datum (d), current idx (i), and current group (node)
       *
       * handleClick(d, i, node)
       */
      Plotly.d3.selectAll('.yaxislayer-above').selectAll('text').on('click', handleClick)
    }

    // The height multiplier is how high we want the chart to be for each data object in the plot.
    // we need to take into consideration the bargap and bargroupgap for the plot.
    const heightMult = 30

    const layout = {
      barmode: 'group', // 'group' vs 'overlay'. We want group so the results stack.

      bargap: 0.1, // bargap is a value from 0-1, which determines the amount of space between
      // each group of results in the table. Default is 0.3.
      // The larger this gets the large the multiplier on the plot height should be

      bargroupgap: 0, // bargroupgap is a value from 0-1 which determines the space between each result
      // in each group in the plot (i.e. the distance between the unmanaged (pre) and
      // managed (post) values). Default is 0.3.
      // ** bargroup has no affect on traces when width is set on the data **
      hovermode: 'closest',
      showlegend: false,
      xaxis: {
        range: domain,
        showgrid: false, // hides the vertical grid lines in the tornado.
      },
      yaxis: {
        automargin: true, // automargin handles large tick labels and will resize the plot to account for them
        // However, it is not a perfect solution, and long risks can get cause many UI issues

        showgrid: true, // adds faint gridlines connecting the data to its' tick label.
        tickmode: 'array',
        tickvals,
        ticktext,
        tickfont,
      },
      autosize: true,
      margin: {
        l: 425, // This assumes that the minimum screen size for the tornado chart is 1024 px, which
        // may not always be the case. This splits the plotly view for a 1024 px screen
        // 'roughly' in half. Left hand side will be filled with labels. larger screens will
        // use the yaxis' automargin to expand the area for longer labels if applicable.
        r: 0,
        b: 5,
        t: 5,
        pad: 1,
      },
    }

    return (
      <div {...style.plotlyGraph(addLink)} style={style.plotContainer}>
        <Plot
          key="plot"
          data={data}
          layout={layout}
          ref={elt => (this.chartContainer = elt)}
          style={{ height: data[0].x.length * heightMult, ...style.plot }}
          config={TornadoChartDisplay.plotlyConfig}
          useResizeHandler
          onAfterPlot={afterPlot}
        />
      </div>
    )
  }
}
TornadoChartDisplay.defaultProps = {
  truncate: false,
}

const style = {
  plotContainer: { width: '100%', height: '100%' },
  plot: { width: '100%' },
  plotlyGraph: addLink =>
    addLink
      ? css({
          '& .yaxislayer-above': {
            cursor: 'pointer',
            pointerEvents: 'all',
          },
        })
      : {},
}

// courtesy https://reactrouterdotcom.fly.dev/docs/en/v6/faq#what-happened-to-withrouter-i-need-it
function customWithRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

export default customWithRouter(TornadoChartDisplay)
