import React from 'react'
/** @jsxImportSource @emotion/react */
import Label from './Label'
import { dropdownWrap, subOption, closed } from './controlStyles'

export default function Dropdown({ onChange, options, label, subordinate, isClosed, value }) {
  return (
    <div
      css={{
        ...dropdownWrap,
        ...(subordinate ? subOption : {}),
        ...(isClosed ? closed : {}),
      }}
    >
      <Label text={label} />
      <select
        onChange={onChange}
        css={{
          cursor: 'pointer',
          color: 'var(--fg-color)',
          width: '150px',
          fontSize: '16px',
          fontWeight: '600',
          display: 'inline-flex',
          background: 'none',
          height: '40px',
          border: '0px solid #000',
          borderRadius: '0px',
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          '&:hover,&:focus': {
            boxShadow: '0 0 0 0px -moz-mac-focusring',
            outline: 'none',
          },
          option: {
            fontWeight: 'normal',
          },
        }}
        value={value}
      >
        {options.map((option, idx) => (
          <option
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            i={option.i}
            value={option.value}
            css={{
              fontWeight: 'normal',
            }}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
