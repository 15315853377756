import React from 'react'
/** @jsxImportSource @emotion/react */
import printMagnitude from '../../utils/printMagnitude'
import printStatLabel from '../../utils/printStatLabel'
import { COLOR } from '../../_riskConfig/color'

export default function Magnitude({ article, displayRange, statSelections }) {
  const statLabel = printStatLabel(statSelections, ['long'])

  const magnitude = article
    ? printMagnitude({
        magnitude: article.magnitude,
        _displayFactor: statSelections[0].displayFactor,
        _displayRange: displayRange,
        _setting: 'plus',
      })
    : ''

  return (
    <>
      <div
        id="grey-bg"
        css={{
          gridArea: 'magnitude',
          height: '100px',
          width: '120px',
          background: COLOR.GREY_2D,
        }}
      >
        <div
          css={{
            height: '100px',
            width: '120px',
            blendMode: 'multiply',
            background: article.color,
          }}
        />
      </div>
      <div
        css={{
          gridArea: 'magnitude',
          alignSelf: 'center',
          textAlign: 'center',
          color: 'white',
          fontWeight: '700',
          padding: '10px',
          lineHeight: '1',
        }}
      >
        <p
          css={{
            fontWeight: '700',
            fontSize: `${40 - magnitude.length * 2.5}px`,
            marginBottom: '5px',
            paddingRight: '2px',
          }}
        >
          {magnitude}
        </p>
        <p
          css={{
            fontWeight: '600',
            fontSize: '14px',
            marginBottom: '5px',
          }}
        >
          {statLabel}
        </p>
      </div>
    </>
  )
}
