/** @jsxImportSource @emotion/react */
import facepaint from 'facepaint'

import { ReactComponent as Logo } from '../../../assets/OptionLab_whiteText.svg'
import heroImage from '../../../assets/img/hero.jpg'

const breakpoints = [576, 768, 992, 1200]
const mediaQuery = facepaint(breakpoints.map(bp => `@media(min-width: ${bp}px)`))

function Hero() {
  return (
    <div css={style.heroContainer}>
      <div css={style.heroContent}>
        <Logo title="OptionLab Logo" />
        <p css={style.tagline}>
          A decision support solution for small businesses, enterprises, and individuals. Whether you're a sole
          decision-maker who wants to make more strategic decisions or a business leader who wants to unlock the
          wisdom in your organization, OptionLab can help.
        </p>
      </div>
    </div>
  )
}

export function HomePage() {
  const version = process.env.REACT_APP_VMSPRO_VERSION ?? '?'
  const build = process.env.REACT_APP_GIT_HEAD_COMMIT_ID
  return (
    <>
      <Hero />
      <p style={{ fontSize: '2em', margin: '2em 4em' }}>
        Thank you for your interest in OptionLab! OptionLab is currently in a closed beta; please see{' '}
        <a href="https://trial.optionlab.com">https://trial.optionlab.com</a> if you are interested in
        participating in the trial. General availability is expected in Q1 2024.
      </p>
      <footer css={style.footer}>
        Version {version} (build {build})<br />
        &copy;{new Date().getFullYear()} OptionLab, Inc.
        <br />
        Microsoft and Microsoft Excel are trademarks of the Microsoft group of companies.
      </footer>
    </>
  )
}

const style = {
  heroContainer: mediaQuery({
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: ['400px', '480px', '600px', '800px'],
    padding: ['0 40px', '0 52px', '0 76px', '0 112px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      opacity: '0.75',
    },
  }),
  heroContent: {
    maxWidth: '800px',
    position: 'relative',
    color: '#ffffff',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '36px',
  },
  introContainer: {
    width: '100%',
    padding: '24px',
  },
  tagline: {
    fontSize: '1.5em',
  },
  introParagraph: {
    margin: '0 32px',
    fontSize: '1.2em',
  },
  introList: {
    margin: '12px 24px',
    listStyle: 'none',
    textIndent: '-1em',
    paddingLeft: '1em',
    fontSize: '1.2em',
    'li::before': {
      content: '"• "',
      color: '#e72062',
    },
    li: {
      marginTop: '6px',
    },
  },
  card: mediaQuery({
    textAlign: 'center',
    background: '#ffffff',
    paddingTop: '20px 12px 6px',
    boxShadow: '0 0 12px 1px rgba(0,0,0,0.2)',
    height: '100%',
    ul: {
      textAlign: 'left',
    },
    p: {
      textAlign: 'left',
    },
  }),
  cardPara: {
    fontSize: '1.2em',
  },
  cardSpan: {
    sm: 24,
    md: 12,
    lg: 8,
  },
  icon: {
    fontSize: '40px',
  },
  pricing: {
    padding: '30px',
  },
  footer: {
    width: '100%',
    fontSize: '0.8em',
    marginTop: '12px',
    padding: '24px',
  },
}
