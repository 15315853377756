import React from 'react'
import numeral from 'numeral'
import { Input } from 'antd'
import { testId } from '../../../test-automation'

const formatValue = (val, format, allowNull) => {
  if (allowNull && ((typeof val === 'string' && !val.trim()) || val === null || typeof val === 'undefined')) {
    return ''
  }
  const numFormat = `0,0${format === 'currency' ? '' : '.[00]'}${format === 'percentage' ? '%' : ''}`
  return numeral(val).format(numFormat)
}

const parseValue = (val, format, allowNull) => {
  const decimalSep = (1.1).toLocaleString()[1]
  const localizedValue = (decimalSep === ',' ? val.replace('.', '').replace(',', '.') : val).replace(
    /[^\d.-]/g,
    ''
  )
  const numValue = parseFloat(localizedValue / (format === 'percentage' ? 100 : 1))
  return localizedValue === '' ? (allowNull ? null : 0) : Number.isFinite(Number(numValue)) ? numValue : 0
}

class TableInput extends React.Component {
  state = {
    displayValue: '',
    editing: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const displayValue = formatValue(nextProps.value, nextProps.format, nextProps.allowNull)
    if (!prevState.editing && prevState.displayValue !== displayValue) return { displayValue }
    return null
  }

  handleChange = displayValue => {
    const { onChange, format, allowNull } = this.props
    onChange(parseValue(displayValue, format, allowNull))
    this.setState({
      displayValue,
      editing: true,
    })
  }

  handleBlur = value => {
    const { format, allowNull } = this.props
    this.setState({
      displayValue: formatValue(parseValue(value, format, allowNull), format, allowNull),
      editing: false,
    })
  }

  render() {
    const inputProps = {
      ...testId(this.props.id),
      style: style(this.props.align),
      value: this.state.displayValue,
      onChange: e => this.handleChange(e.target.value),
      onBlur: e => this.handleBlur(e.target.value),
    }
    return this.props.isReadOnly ? this.state.displayValue : <Input {...inputProps} />
  }
}

const style = textAlign => ({
  border: 'none',
  height: '24px',
  textAlign,
})

export default TableInput
