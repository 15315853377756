import React, { useCallback, useEffect, useState } from 'react'
import { Dimensionless } from '@vms/vmspro3-core/dist/qty'
import { Input } from 'antd'

import { formatNumValue, parseNumValue } from '../../../utils/inputUtils'

const DimensionlessInput = React.forwardRef(
  (
    {
      align,
      allowNull,
      decimalPlaces,
      isPercentage,
      onBlur,
      onChange,
      readOnly,
      style,
      trimDecimals,
      value,
      ...props
    },
    ref
  ) => {
    if (!value) value = {}

    const formatNum = useCallback(
      v =>
        formatNumValue(v, {
          allowNull,
          decimalPlaces,
          isPercentage,
          trimDecimals,
        }),
      [allowNull, decimalPlaces, isPercentage, trimDecimals]
    )

    const [displayValue, setDisplayValue] = useState(() => formatNum(value.value))
    const [editing, setEditing] = useState(false)

    useEffect(() => {
      if (!editing) setDisplayValue(formatNum(value.value))
    }, [formatNum, editing, value])

    if (readOnly) {
      return (
        <div ref={ref} style={readOnlyStyle}>
          {displayValue}
        </div>
      )
    }

    const handleBlur = event => {
      setEditing(false)
      if (onBlur) onBlur(event)
    }

    // const handleChange = event => {
    //   const v = event.target.value.replace(/[^\d.,-]/g, '')
    //   setEditing(true)
    //   setDisplayValue(v)
    //   onChange(parseNumValue(v, allowNull) / (isPercentage ? 100 : 1))
    // }

    const handleChange = event => {
      const v = event.target.value.replace(/[^\d.,-]/g, '')
      setEditing(true)
      setDisplayValue(v)
      onChange(new Dimensionless(parseNumValue(v, allowNull) / (isPercentage ? 100 : 1)))
    }

    return (
      <Input
        {...props}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={ref}
        style={getStyle(align, style)}
        value={displayValue}
      />
    )
  }
)
DimensionlessInput.defaultProps = {
  align: 'right',
  allowNull: true,
  decimalPlaces: 0,
  inputMode: 'numeric',
  isPercentage: false,
  style: {},
  trimDecimals: false,
}

const readOnlyStyle = {
  minHeight: '32px',
  padding: '6px 12px 5px',
  textAlign: 'right',
  width: '100%',
}
const getStyle = (textAlign, style) => ({
  textAlign,
  ...style,
})

export default DimensionlessInput
