import React, { useMemo } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { Duration, DurationUnit } from '@vms/vmspro3-core/dist/qty'
import { createOverlayRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { Form, Button, Space } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import RiskEntityIdentityFormFields from './RiskEntityIdentityFormFields'
import EntityDeleteModal from '../modals/EntityDeleteModal'

import useAuthz from '../../../hooks/useAuthz'
import { getEntityTypeLabel } from '../utils/getEntityTypeLabel'
import { effectiveRiskContextFromAncestry } from '../selectors'
import { useShowModal } from '../RiskModalContext'
import { useAccount } from '../../../context'

const entityDetailsByType = {
  [EntityType.PORTFOLIO]: {
    actionKey: 'riskPortfolio',
    metaIdKey: 'portfolioId',
  },
  [EntityType.PROGRAM]: {
    actionKey: 'riskProgram',
    metaIdKey: 'programId',
  },
  [EntityType.PROJECT]: {
    actionKey: 'riskProject',
    metaIdKey: 'projectId',
  },
}

const RiskEntityIdentity = React.memo(({ entityId }) => {
  const { accountId, accountCommonId } = useAccount()
  const entity = useSelector(state => state.riskEntities.byId[entityId])
  const { ancestry, entityType, name: entityName } = entity
  const entityTypeLabel = getEntityTypeLabel(entityType, ancestry)
  const { actionKey, metaIdKey } = entityDetailsByType[entityType]

  const authz = useAuthz()
  const dispatch = useDispatch()
  const [formInstance] = Form.useForm()

  const canEditEntity = useMemo(
    () =>
      authz(
        actions[actionKey].update(
          {},
          {
            ancestry,
            [metaIdKey]: entityId,
          }
        )
      ),
    [authz, actionKey, metaIdKey, ancestry, entityId]
  )
  const effectiveRiskContext = useSelector(effectiveRiskContextFromAncestry(ancestry))
  const updateEntity = values => {
    if (!canEditEntity) return
    if (entityType === EntityType.PROJECT) {
      values.commonId = values.commonId?.trim()
      values.name = values.name.trim()
      // TODO: see https://vms.atlassian.net/browse/VP3-2220
      values.time = Duration.convert(values.time, DurationUnit.Months)

      const riskContext = createOverlayRiskContext(effectiveRiskContext, values.cost, values.time)

      batch(() => {
        // updating existing project
        dispatch(
          actions.riskProject.update(values, {
            ancestry,
            projectId: entityId,
            riskContext,
          })
        )
        // update risk context in existing project, which will kick off
        // a bulk child risk update
        dispatch(
          actions.riskContext.update(riskContext, {
            ancestry,
            entityType: EntityType.PROJECT,
            entityId,
          })
        )
      })
    } else {
      dispatch(
        actions[actionKey].update(values, {
          accountId,
          ancestry,
          [metaIdKey]: entityId,
        })
      )
    }

    formInstance.resetFields()
  }

  const canDeleteEntity = useMemo(
    () =>
      authz(
        actions.riskEntity.delete(
          {},
          {
            entityId,
            entityType,
            ancestry,
          }
        )
      ),
    [authz, entityId, entityType, ancestry]
  )

  const showModal = useShowModal()
  const showDeleteEntityConfirmationModal = () =>
    showModal(EntityDeleteModal.id, {
      afterDeletePath: `/${accountCommonId}/port`,
      entityId,
      entityName,
      entityType: EntityType.PROJECT,
      entityAncestry: ancestry,
    })

  return (
    <Form layout="vertical" initialValues={entity} onFinish={updateEntity} form={formInstance}>
      <RiskEntityIdentityFormFields
        entityId={entityId}
        entityType={entityType}
        readOnly={!canEditEntity}
        entityTypeLabel={entityTypeLabel}
      />
      <Space size="large">
        {canEditEntity && (
          <Form.Item shouldUpdate>
            {form => (
              <Button disabled={!form.isFieldsTouched()} type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        )}
        {canDeleteEntity && (
          <Form.Item>
            <Button onClick={showDeleteEntityConfirmationModal} type="danger">
              Delete {entityTypeLabel}
            </Button>
          </Form.Item>
        )}
      </Space>
    </Form>
  )
})

export default RiskEntityIdentity
