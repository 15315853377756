/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { Modal } from 'antd'

import describeAction from '@vms/vmspro3-core/dist/utils/describeAction'

import { Table, TableCellRenderer } from '../common/Table'

import clientId from '../../utils/clientId'
import { clearLocalStorageQueue } from '../../redux/middleware/isomorphicReduxMiddleware'
import { useAppDispatch } from '../../redux'

interface UnprocessedActionDispositionModalProps {
  hideModal: VoidFunction
  queuedActions: any[] // TODO: this was AnyAction from react-redux, but that's not exported, so....
}

/**
 * Modal to give the opportunity to decide what to do with unprocessed actions that were saved to
 * local storage before they could be processed.
 */
export function UnprocessedActionDispositionModal({
  hideModal,
  queuedActions,
}: UnprocessedActionDispositionModalProps) {
  const dispatch = useAppDispatch()

  const onOk = () => {
    queuedActions
      // we will have a new clientId, so the actions will have to be updated
      // with the new clientId, otherwise isomorphic Redux will ignore these actions
      .map(action => ({ ...action, meta: { ...action.meta, clientId } }))
      .forEach(dispatch)
    clearLocalStorageQueue()
    hideModal()
  }

  const onCancel = () => {
    // user has chosen to discard changes
    clearLocalStorageQueue()
    hideModal()
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: (action: any) => action.meta.timestamp,
        Cell: TableCellRenderer.Timestamp,
      },
      {
        Header: 'Activity',
        id: 'activity',
        Cell: ({ row: { original: action } }: any) => describeAction(action),
      },
    ],
    []
  )

  return (
    <Modal
      open
      title="Recover Unsaved Changes?"
      onOk={onOk}
      okText="Recover"
      onCancel={onCancel}
      cancelText="Discard"
      closable={false}
    >
      <p>
        It appears that you closed your browser before OptionLab could save all of your work to the cloud.
        Fortunately, you can choose to save that work now. Note that the older this unsaved work is, the greater
        the chance that there will be errors in saving it to the cloud. If the following activity is very old, and
        you don't recognize it, you may wish to discard this work.
      </p>
      <p>If you have OptionLab open in another window or tab, try reloading this window first.</p>
      <Table data={queuedActions} columns={columns as any} />
    </Modal>
  )
}
