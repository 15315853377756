import { PubSub } from '@aws-amplify/pubsub'
import _throttle from 'lodash/throttle'

import config from '../config.json'

import { fetchAuthSession } from 'aws-amplify/auth'
import VMSProServerAdapter from '../server/VMSProServerAdapter'

const pubSub = new PubSub({
  region: config.pubsub.aws_pubsub_region,
  endpoint: config.pubsub.aws_pubsub_endpoint,
})

// PubSub.addPluggable(new AWSIoTProvider(config.pubsub))

type Observable = ReturnType<typeof pubSub.subscribe>
type Subscription = ReturnType<Observable['subscribe']>

interface CustomErrorMessage {
  header: string
  body: string
  details: string
}
interface ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: any, customMessage?: CustomErrorMessage): void
}

let errorHandler: ErrorHandler | undefined = undefined
const accountSubscriptions: { [topic: string]: Subscription } = {}

// configure IoT provider pluggable in PubSub class

/**
 * Posts a request to the API to attach the required IoT PubSub IAM
 * policy to the Cognito identity.
 */
async function configure() {
  const { identityId } = await fetchAuthSession()
  await VMSProServerAdapter.post('/register-pubsub', { identityId })
}

/**
 * Adds handler for caught subscription errors.
 */
function registerErrorHandler(handler: ErrorHandler | undefined) {
  errorHandler = handler
}

const handleError = _throttle((error, message) => {
  if (typeof errorHandler !== 'function') {
    console.error('error: pubsub error handler not configured properly')
    console.error(error)
  } else {
    errorHandler(error, message)
  }
}, 1000)

function isSubscribedToAccountTopic(topic: string) {
  return !!accountSubscriptions[topic]
}

function unsubscribe(topic: string, subscriptions: { [topic: string]: Subscription }) {
  if (!subscriptions[topic]) return
  subscriptions[topic].unsubscribe()
  delete subscriptions[topic]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function subscribeToAccountTopic(topic: string, onNextData: (value: any) => void) {
  if (typeof topic !== 'string') {
    throw new Error('only single topic subscriptions are supported at this time')
  }

  accountSubscriptions[topic] = pubSub.subscribe({ topics: [topic] }).subscribe({
    next: onNextData,
    error: error => {
      error.topic = topic
      // TODO: need to investigate this
      // console.log('pub/sub subscription error:', error)
      // Most error types here come back as an error object, but the undefined socket error
      // comes back as a nested error object - so we call error.error
      if (error.error && error.error.errorMessage === 'AMQJS0007E Socket error:undefined.') {
        // TODO: there is a breaking change in amplify since 1.1.40 that appears to have eliminated a way
        // to reconnect after the Mqtt socket is destroyed.
        // There are open tickets for this, with little to no response from AWS development team.
        // https://github.com/aws-amplify/amplify-js/issues/3039 (opened AUG 2019, active JAN 2020)
        // https://github.com/aws-amplify/amplify-js/issues/4459 (opened NOV 2019, no response from AWS dev)
        // Trigger our server disconnect here.
        handleError(error.error, {
          header: 'Session Expired',
          body: 'Your session has expired or you have lost your network connection.',
          details: 'Please verify your device is connected to the internet then reload the page to continue.',
        })
      } else {
        // TODO: there are still issues with PubSub subscription stability
        unsubscribe(topic, accountSubscriptions)
        // TODO:
        // the following is from @jakefeldmann and likely needs investigation. The first
        // point should be a non-issue as unsubscribing is a synchronous process.

        // use a timeout here for a couple reasons:
        //    1) We want to make sure the unsubscribe has had plenty of time to do its thing
        //    2) Without a timeout, if there is no network connection, this may be triggered many times per second
        //       and can crash the app.
        //    3) We do not want to throttle the calls, because we may be re-subscribing to many different
        //       connections and we do not want any of these swallowed.
        setTimeout(() => subscribeToAccountTopic(topic, onNextData), 15000)
      }
    },
    complete: () => {
      console.log(`pub/sub: AWSAmplify subscription closed for topic: `, topic)
    },
  })
}

function unsubscribeFromAccountTopics() {
  Object.keys(accountSubscriptions).forEach(topic => {
    accountSubscriptions[topic].unsubscribe()
    delete accountSubscriptions[topic]
  })
}

function unsubscribeFromAccountTopic(topic: string) {
  unsubscribe(topic, accountSubscriptions)
}

export default {
  configure,
  registerErrorHandler,
  isSubscribedToAccountTopic,
  subscribeToAccountTopic,
  unsubscribeFromAccountTopics,
  unsubscribeFromAccountTopic,
}
