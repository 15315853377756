// TODO: add attribute to this function
export default function findClusterWidth(
  articleCluster,
  chunkMargin,
  columnWidth,
  displayHeightPortion,
  greatestMagnitude,
  hasRelativeScale,
  windowHeight
) {
  const idealClusterHeight = (windowHeight * displayHeightPortion - 16) * articleCluster.portion
  // console.log(".... this cluster is", idealClusterHeight, "tall")
  let totalColumnWidth = columnWidth + chunkMargin
  // console.log(".... columnWidth", columnWidth)
  let currentColumnHeight = 0
  let currentColumnWidth = columnWidth
  for (let index = 0; index < articleCluster.articles.length; index++) {
    const article = articleCluster.articles[index]
    const relativeMagnitude = hasRelativeScale ? Math.abs(article.magnitude / greatestMagnitude) : 1
    let elementHeight = columnWidth * relativeMagnitude
    if (relativeMagnitude <= 0.5) {
      currentColumnWidth = (columnWidth - chunkMargin) / 2
      elementHeight *= columnWidth / currentColumnWidth
      /* console.log(
        "::::", article.name, "is",
        100 * relativeMagnitude + "%",
        "the size of the largest chunk in the cluster")
        */
    }
    elementHeight += chunkMargin
    // console.log(":::: w margin,", article.name, "is", elementHeight, "px tall")
    if (currentColumnHeight + elementHeight > idealClusterHeight) {
      // console.log(":::: bump:", currentColumnHeight, "+", elementHeight, ">", idealClusterHeight)
      totalColumnWidth += currentColumnWidth + chunkMargin
      currentColumnHeight = elementHeight + chunkMargin
    } else {
      currentColumnHeight += elementHeight
    }
    // console.log(":::: the current column is", currentColumnHeight, "tall")
  }
  // console.log("'''' width of this cluster is", totalColumnWidth)

  return totalColumnWidth
}
