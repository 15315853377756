import { normalize, schema } from 'normalizr'

import { identifyRating } from '@vms/vmspro3-core/dist/utils/ratings'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/options'
import { Participant, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'

import { LoadableDecision, LoadableDecisionFolder } from '../../types'
import { getDecisionNode, getDecisionNodesByAncestry } from '../../services/decisionService'
import { AsyncAppThunk } from '../store'

const optionSchema = new schema.Entity<OptionData>('options', undefined, {})
const criterionSchema = new schema.Entity<CriterionData>('criteria', undefined, {})
const participantSchema = new schema.Entity<Participant>('participants', undefined, {})
const ratingSchema = new schema.Entity<Rating>('ratings', undefined, {
  idAttribute: identifyRating,
})
const ratingNotesSchema = new schema.Entity<RatingNotes>('ratingNotes', undefined, {
  idAttribute: identifyRatingNotes,
})
const decisionSchema = new schema.Entity<LoadableDecision>(
  'decisions',
  {
    children: {
      options: [optionSchema],
      criteria: [criterionSchema],
      participants: [participantSchema],
      ratings: [ratingSchema],
      ratingNotes: [ratingNotesSchema],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  },
  {
    processStrategy: input => {
      const { options, criteria, participants, ratings, ratingNotes, ...data } = input
      return {
        status: 'Success',
        data,
        children: {
          options,
          criteria,
          participants,
          ratings,
          ratingNotes,
        },
      }
    },
  }
)
const decisionFolderSchema = new schema.Entity<LoadableDecisionFolder>('decisionFolders', undefined, {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processStrategy: data => ({
    status: 'Success',
    data,
  }),
})
const decisionEntitiesSchema = new schema.Array(
  {
    decisions: decisionSchema,
    decisionFolders: decisionFolderSchema,
  },
  entity => {
    switch (entity.entityType) {
      case 'Decision': {
        return 'decisions'
      }
      case 'DecisionFolder': {
        return 'decisionFolders'
      }
      default: {
        throw new Error(`entityType "${entity.entityType}" not recognized`)
      }
    }
  }
)

export interface FetchDecisionEntityRequestAction {
  type: 'FetchDecisionEntityRequest'
  meta: {
    decisionEntityId: string
    entityType: 'Decision' | 'DecisionFolder'
    ephemeral: true
  }
}
export interface FetchDecisionEntitySuccessAction {
  type: 'FetchDecisionEntitySuccess'
  payload: {
    decisions?: Record<string, LoadableDecision>
    decisionFolders?: Record<string, LoadableDecisionFolder>
    criteria?: Record<string, CriterionData>
    options?: Record<string, OptionData>
    participants?: Record<string, Participant>
    ratings?: Record<string, Rating>
    ratingNotes?: Record<string, RatingNotes>
  }
  meta: {
    decisionEntityId: string
    ephemeral: true
  }
}
export type FetchDecisionEntityFailureAction = {
  type: 'Fetch Decision Entity Error'
  payload: {
    message: string
  }
  meta: {
    decisionEntityId: string
    ephemeral: true
  }
}
export function fetchDecisionEntity(
  accountId: string,
  decisionEntityId: string,
  entityType: 'Decision' | 'DecisionFolder'
): AsyncAppThunk {
  return async dispatch => {
    dispatch({
      type: 'FetchDecisionEntityRequest',
      meta: {
        decisionEntityId,
        entityType,
        ephemeral: true,
      },
    })

    try {
      // includes ancestor decision folders and children of decision
      const decisionEntities = await getDecisionNode(accountId, decisionEntityId)
      const { entities } = normalize(decisionEntities, decisionEntitiesSchema)

      dispatch({
        type: 'FetchDecisionEntitySuccess',
        payload: entities as FetchDecisionEntitySuccessAction['payload'],
        meta: {
          decisionEntityId,
          ephemeral: true,
        },
      })
    } catch (err) {
      console.error(`Error fetching decision ${decisionEntityId}:`, err)
      dispatch({
        type: 'Fetch Decision Entity Error',
        payload: { message: err && typeof err === 'object' && 'message' in err ? err.message : 'unknown' },
        meta: { decisionEntityId, ephemeral: true },
      })
    }
  }
}

export interface FetchDecisionFolderChildrenRequestAction {
  type: 'FetchDecisionFolderChildrenRequest'
  meta: {
    ancestry: string
    ephemeral: true
  }
}
export interface FetchDecisionFolderChildrenSuccessAction {
  type: 'FetchDecisionFolderChildrenSuccess'
  payload: {
    decisions?: Record<string, LoadableDecision>
    decisionFolders?: Record<string, LoadableDecisionFolder>
    criteria?: Record<string, CriterionData>
    options?: Record<string, OptionData>
    participants?: Record<string, Participant>
    ratings?: Record<string, Rating>
    ratingNotes?: Record<string, RatingNotes>
  }
  meta: {
    ancestry: string
    ephemeral: true
  }
}

// TODO: it would be great to do this by decision folder ID if we can, let the server handle the mapping
export function fetchDecisionFolderChildren(accountId: string, ancestry: string): AsyncAppThunk {
  return async dispatch => {
    dispatch({
      type: 'FetchDecisionFolderChildrenRequest',
      meta: {
        ancestry,
        ephemeral: true,
      },
    })

    const nodes = await getDecisionNodesByAncestry(accountId, ancestry)
    const { entities } = normalize(nodes, decisionEntitiesSchema)

    dispatch({
      type: 'FetchDecisionFolderChildrenSuccess',
      // TODO: maybe consider validating incoming data?
      payload: entities as FetchDecisionFolderChildrenSuccessAction['payload'],
      meta: {
        ancestry,
        ephemeral: true,
      },
    })
  }
}
