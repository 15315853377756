import React from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import FileExcelOutlined from '@ant-design/icons/FileExcelOutlined'
import FileImageOutlined from '@ant-design/icons/FileImageOutlined'
import FileOutlined from '@ant-design/icons/FileOutlined'
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined'
import FilePptOutlined from '@ant-design/icons/FilePptOutlined'
import FileWordOutlined from '@ant-design/icons/FileWordOutlined'
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined'

import { getFileIcon } from '../../../utils/inputUtils'

const icons = {
  excel: FileExcelOutlined,
  file: FileOutlined,
  pdf: FilePdfOutlined,
  picture: FileImageOutlined,
  ppt: FilePptOutlined,
  video: VideoCameraOutlined,
  word: FileWordOutlined,
}

class FileDrop extends React.Component {
  state = { acceptedFiles: [], multipleFilesAttempt: false }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { form } = this.props
    if (rejectedFiles.length > 0) {
      console.error('rejectedFiles:', rejectedFiles)
    }
    this.setState({ acceptedFiles, multipleFilesAttempt: rejectedFiles.length > 1 })
    form.getFieldValue('files') // WTF: if we don't call form.getFieldValue('files') we can't set the field
    form.setFieldsValue({ files: acceptedFiles })
    if (this.props.onDrop) this.props.onDrop(acceptedFiles)
  }

  render() {
    return (
      <div>
        {this.state.multipleFilesAttempt && (
          <p>
            Please upload only <strong>one file at a time</strong>.
          </p>
        )}
        <Dropzone onDrop={this.onDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
              style={{ border: '1px solid black', padding: '10px', borderRadius: '10px' }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop your file here...</p>
              ) : (
                <p>Drop a file here, or click to select a file to upload.</p>
              )}
            </div>
          )}
        </Dropzone>
        <div>
          <ul>
            {this.state.acceptedFiles.map(f => {
              const { name: filename } = f
              const Icon = icons[getFileIcon(filename)]

              return [<Icon key={`${filename}-icon`} />, <span key={filename}> {filename} </span>]
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default FileDrop
