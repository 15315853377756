import { getAssumedAuthz, AssumedAuthz } from '../utils/authzUtils'
import { ASSUMED_USER_ID_KEY } from '../utils/appConsts'
import { fetchAuthSession, fetchUserAttributes, signOut as _signOut } from 'aws-amplify/auth'

type GetAuthResponse =
  | {
      userId: string
      email: string
      assumedAuthz?: AssumedAuthz
    }
  | { userId: undefined; email: undefined; assumedAuthz: undefined }

export async function getAuth(): Promise<GetAuthResponse> {
  const { identityId } = await fetchAuthSession()
  if (!identityId) return { userId: undefined, email: undefined, assumedAuthz: undefined }

  const { preferred_username: userId, email } = await fetchUserAttributes()

  if (!userId || !email) throw new Error(`unexpected: userId (preferred_username) or email are undefined`)

  const res: GetAuthResponse = {
    userId,
    email,
  }

  const assumedAuthz = getAssumedAuthz()
  if (assumedAuthz) {
    const { type } = assumedAuthz
    switch (type) {
      case 'ASSUME_ROLE': {
        res.assumedAuthz = assumedAuthz
        break
      }
      case 'IMPERSONATE_USER': {
        res.userId = assumedAuthz.assumedUserId
        // TODO: it's somewhat unclear how all of this fits together
        res.assumedAuthz = {
          type,
          assumedUserId: assumedAuthz.assumedUserId,
          actualUserId: userId,
        }
        break
      }
      default: {
        throw new Error(`Assumed authz set with unknown type ${type}`)
      }
    }
  }

  return res
}

export async function signOut() {
  return _signOut().then(() => {
    localStorage.removeItem(ASSUMED_USER_ID_KEY)
    window.location.href = '/'
  })
}
