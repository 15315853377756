import React from 'react'

import style from './Pagination.module.css'

const pageSizeOptions = [2, 10, 25, 50]

export function Pagination({ tableInstance }) {
  const {
    gotoPage,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    state: { pageIndex, pageSize },
    setPageSize,
  } = tableInstance

  return (
    <div className={style.pagination}>
      <span className={style.pagination__transport}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'|<'}
        </button>
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <span>
          Page{' '}
          <strong>
            {' '}
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>|'}
        </button>
      </span>
      <span>
        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
          {pageSizeOptions.map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </span>
    </div>
  )
}
