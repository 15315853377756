import { useCallback } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import cuid from 'cuid'

import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { updateOption } from '@vms/vmspro3-core/dist/actions/decision'

import { useOptions } from '../../../../redux/hooks'
import { colorSelectOptions } from '../../../../utils/appConsts'
import { useAppDispatch } from '../../../../redux'
import { getRandomColorFromLeastFrequent } from '../../../../utils/getRandomColorFromLeastFrequent'
import { useLazyRef } from '../../../../hooks/useLazyRef'
import { OptionData, OptionOutcomeData } from '@vms/vmspro3-core/dist/nextgen/options'

const requiredFieldRule = [
  { required: true, validateTrigger: 'onChange' },
  { whitespace: true, validateTrigger: 'onBlur' },
]

type OptionOutcomeFields = Omit<OptionOutcomeData, 'id'>

export type OptionOutcomeModalProps = {
  decisionId: string
  option: OptionData
  outcome?: OptionOutcomeData
  hideModal: VoidFunction
}
export function OptionOutcomeModal({ decisionId, option, outcome, hideModal }: OptionOutcomeModalProps) {
  const [form] = Form.useForm<OptionOutcomeFields>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(async () => {
    const { description, ...restFields } = await form.validateFields()

    const optionData: Pick<OptionData, 'outcomes'> = {}
    const outcomeData = {
      id: outcome?.id || cuid(),
      ...outcome,
      ...restFields,
      description: createHtmlObject(description?.value ?? null),
    }
    if (outcome && option.outcomes) {
      optionData.outcomes = option.outcomes.map(o => (o.id === outcomeData.id ? { ...o, ...outcomeData } : o))
    } else {
      optionData.outcomes = option.outcomes ? [...option.outcomes, outcomeData] : [outcomeData]
    }

    const action = updateOption(decisionId, option.id, optionData)
    dispatch(action)

    hideModal()
  }, [form, outcome, option.outcomes, option.id, dispatch, decisionId, hideModal])

  const options = useOptions(decisionId)
  const defaultColorRef = useLazyRef(() =>
    getRandomColorFromLeastFrequent(
      colorSelectOptions.map(o => o.value),
      options
    )
  )

  const initialValues = outcome ?? { color: defaultColorRef.current, cost: null, time: null, commonId: null }

  return (
    <Modal open onCancel={hideModal} onOk={onOk}>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Name" name="name" rules={requiredFieldRule}>
          <Input autoFocus />
        </Form.Item>
        <Form.Item label="Abbreviation" name="abbrev" rules={requiredFieldRule}>
          <Input />
        </Form.Item>
        <Form.Item label="Color" name="color" rules={[{ required: true }]}>
          <Select>
            {colorSelectOptions.map(o => (
              <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                  <div style={{ width: '50px', backgroundColor: o.value }} />
                  <span style={{ alignSelf: 'center' }}>{o.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name={['description', 'value']}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
