import React from 'react'

import Radio, { RadioGroupProps } from 'antd/lib/radio'
import { RadioChangeEvent } from 'antd'
import { getOptionLabel } from '../utils/getOptionLabel'

import 'antd/lib/radio/style/css'
import { SelectOption } from '../../../types'

interface RadioGroupComponentProps {
  button?: boolean
  groupStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  options: SelectOption[]
  onChange?: (value: RadioChangeEvent['target']['value']) => void
  readOnly?: boolean
  style?: React.CSSProperties
  value?: RadioGroupProps['value']
}

const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupComponentProps>(
  ({ button, groupStyle, inputStyle, onChange, options, readOnly, value, ...props }, ref) => {
    if (readOnly) {
      return typeof value === 'undefined' || value === null ? (
        <div style={readOnlyStyle} />
      ) : (
        <div ref={ref} style={readOnlyStyle}>
          {getOptionLabel(value, options)}
        </div>
      )
    }

    const Input = button ? Radio.Button : Radio

    return (
      <Radio.Group
        ref={ref}
        onChange={evt => onChange?.(evt.target.value)}
        style={groupStyle}
        value={value}
        {...props}
      >
        {options.map(o => (
          <Input key={o.label} style={inputStyle} value={o.value}>
            {o.label}
          </Input>
        ))}
      </Radio.Group>
    )
  }
)

const readOnlyStyle = {
  lineHeight: '21px',
  marginLeft: '24px',
}

export { RadioGroup }
export default Radio
