import { useDispatch } from 'react-redux'
import { Form, Input, Modal, Select } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import { useAuth } from '../../../context'
import { testId } from '../../../test-automation'

interface UserPreferencesFormFields {
  fullname: string
  shortName: string
  initials: string
  dateFormat: string
  timeFormat: string
  dateTimeFormat?: string
}

interface UserPreferencesModalProps {
  hideModal: VoidFunction
}
export function UserPreferencesModal({ hideModal }: UserPreferencesModalProps) {
  const { authUser } = useAuth()

  const dispatch = useDispatch()

  const [form] = Form.useForm<UserPreferencesFormFields>()

  const onOk = async () => {
    const user = await form.validateFields()
    // we are reserving the option for the user to configure dateTimeFormat in the future, but for now it's just
    // the concatenation of the date and time format.
    const { dateFormat, timeFormat } = user
    user.dateTimeFormat = dateFormat && timeFormat ? `${dateFormat} ${timeFormat}` : 'YYYY-MM-DD hh:mm a'

    dispatch(actions.user.update(user, { userId: authUser.id }))
    hideModal()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  }

  return (
    <Modal open onCancel={hideModal} onOk={onOk} width="65vw">
      <Form form={form} initialValues={authUser}>
        <h2>Account Settings</h2>
        <Form.Item
          {...formItemLayout}
          label="Full name"
          name="fullName"
          rules={[{ required: true, message: 'Full name is required.' }]}
        >
          <Input autoFocus {...testId('fullNameField')} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="Short name"
          name="shortName"
          rules={[{ required: true, message: 'Short name is required.' }]}
        >
          <Input {...testId('shortNameField')} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="Initials"
          name="initials"
          rules={[{ required: true, message: 'Initials are required.' }]}
        >
          <Input {...testId('initialsField')} />
        </Form.Item>
        <Form.Item {...formItemLayout} label="Date format" name="dateFormat">
          <Select style={{ width: '30ch' }} allowClear={false}>
            <Select.Option key="YYYY-MM-DD">YYYY-MM-DD</Select.Option>
            <Select.Option key="MM/DD/YYYY">MM/DD/YYYY</Select.Option>
            <Select.Option key="MM/DD/YY">MM/DD/YY</Select.Option>
            <Select.Option key="DD/MM/YYYY">DD/MM/YYYY</Select.Option>
            <Select.Option key="DD/MM/YY">DD/MM/YY</Select.Option>
            <Select.Option key="MMM Do, YYYY">Mo DD, YYYY</Select.Option>
            <Select.Option key="MMMM Do, YYYY">Month DD, YYYY</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} label="Time format" name="timeFormat">
          <Select style={{ width: '30ch' }} allowClear={false}>
            <Select.Option key="HH:mm">24-hour</Select.Option>
            <Select.Option key="hh:mm a">12-hour</Select.Option>
            <Select.Option key="HH:mm Z">24-hour w/ time zone</Select.Option>
            <Select.Option key="hh:mm a Z">12-hour w/ time zone</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
