import { Account, Invitation } from '@vms/vmspro3-core/dist/types'

import { AsyncAppThunk } from '../store'
import { getPendingInvitationsForAccount } from '../../services/accountService'

export interface ResetAccountStateAction {
  type: 'ResetAccountState'
  meta: { ephemeral: true }
}
export function resetAccountState(): ResetAccountStateAction {
  return {
    type: 'ResetAccountState',
    meta: { ephemeral: true },
  }
}

export interface SetAccountAction {
  type: 'SetAccount'
  payload: { account: Account }
  meta: { ephemeral: true }
}
export function setAccount(account: Account): SetAccountAction {
  return {
    type: 'SetAccount',
    payload: { account },
    meta: { ephemeral: true },
  }
}

export interface FetchPendingInvitationsForAccountRequestAction {
  type: 'FetchPendingInvitationsForAccountRequest'
  meta: { ephemeral: true }
}
export interface FetchPendingInvitationsForAccountSuccessAction {
  type: 'FetchPendingInvitationsForAccountSuccess'
  payload: Invitation[]
  meta: { ephemeral: true }
}
export function fetchPendingInvitationsForAccount(accountId: string): AsyncAppThunk {
  return async dispatch => {
    try {
      const fetchPendingInvitationsForAccountRequest: FetchPendingInvitationsForAccountRequestAction = {
        type: 'FetchPendingInvitationsForAccountRequest',
        meta: { ephemeral: true },
      }
      dispatch(fetchPendingInvitationsForAccountRequest)

      const pendingInvitations = await getPendingInvitationsForAccount(accountId)
      const fetchPendingInvitationsForAccountSuccess: FetchPendingInvitationsForAccountSuccessAction = {
        type: 'FetchPendingInvitationsForAccountSuccess',
        payload: pendingInvitations,
        meta: { ephemeral: true },
      }
      dispatch(fetchPendingInvitationsForAccountSuccess)
    } catch (error) {
      console.error('fetchPendingUsers error: ', error)
    }
  }
}
