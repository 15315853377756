import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css } from 'glamor'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { Button, Col, Collapse, Row, Statistic, Spin, Switch, Tabs } from 'antd'

import { Color, EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { DurationUnit } from '@vms/vmspro3-core/dist/qty'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { actions } from '@vms/vmspro3-core/dist'

import RiskCreateModal from '../modals/RiskCreateModal'
import RiskEntityAttachmentManager from './RiskEntityAttachmentManager'
import RiskExplorer from './RiskExplorer'
import RiskPortfolioNodeHeader from './RiskPortfolioNodeHeader'
import RiskTornado from './RiskTornado'
import { RiskInventoryTable } from '../tables'
import { ButtonLink, RiskContextExcelReportButton, Select } from '../controls'

import useAuthz from '../../../hooks/useAuthz'
import useQuerystringTabKey from '../hooks/useQuerystringTabKey'
import { formatCost, formatDuration } from '../../../utils/formatUtils'
import { fetchRiskEntityChildren } from '../../../redux/actions'
import { useShowModal } from '../RiskModalContext'
import { useAccount } from '../../../context'
import { getRiskProject, getRisksByAncestry, effectiveRiskContextFromAncestry } from '../selectors'

const QUAL = 'QUAL'
const QUANT = 'QUANT'
const COST = 'cost'
const TIME = 'time'
const PERF = 'perf'

const RiskProject = ({ projectId }) => {
  const [activeKeys, setActiveKeys] = useState([QUAL, QUANT])
  const [chart, setChart] = useState(COST)
  const [numRows, setNumRows] = useState(10)
  const [truncate, setTruncate] = useState(false)
  const includePerformance = true

  // Plotly does not render correctly if it's component is not visible when rendered. If we update
  // 'chart' or 'numRows', we default expand the collapse wrappers for the tornado charts.
  useEffect(() => {
    setActiveKeys([QUAL, QUANT])
  }, [chart, numRows])

  const dispatch = useDispatch()
  const authz = useAuthz()
  const { accountCommonId } = useAccount()

  // TODO: switch to using useRiskEntity
  const project = useSelector(getRiskProject(projectId))

  const projectAncestry = project.ancestry
  const riskAncestry = joinAncestry(projectAncestry, projectId)

  const effectiveRiskContext = useSelector(effectiveRiskContextFromAncestry(riskAncestry))

  // TODO: I think "getRisksByAncestry" also gets subprojects...should look at naming/correcness
  const risks = useSelector(getRisksByAncestry(riskAncestry))

  const childrenLoaded = !!risks
  useEffect(() => {
    if (!childrenLoaded) {
      dispatch(fetchRiskEntityChildren(riskAncestry))
    }
  }, [dispatch, childrenLoaded, riskAncestry])

  const { tabKey, onTabChange } = useQuerystringTabKey('risks')

  const showModal = useShowModal()
  const showRiskCreateModal = () =>
    showModal(RiskCreateModal.id, {
      projectAncestry,
      projectId,
    })

  if (!risks) return <Spin />

  const canEditProject = authz(
    actions.riskProject.update(
      {},
      {
        projectId,
        ancestry: projectAncestry,
      }
    )
  )
  const canEditConfig = authz(
    actions.riskContext.update(
      {},
      {
        entityId: projectId,
        entityType: EntityType.PROJECT,
        ancestry: projectAncestry,
      }
    )
  )

  const headerExtra = (
    <div {...style.headerExtra}>
      <Statistic title="Cost" value={formatCost(project.cost, true, 0)} valueStyle={{ fontSize: '18px' }} />
      <Statistic
        title="Duration"
        value={formatDuration(project.time, DurationUnit.Months, true, 0)}
        valueStyle={{ fontSize: '18px' }}
      />
      {(canEditProject || canEditConfig) && (
        <div {...style.headerExtraControls}>
          {canEditConfig && (
            <ButtonLink to="config">
              <SettingOutlined /> Configuration
            </ButtonLink>
          )}
        </div>
      )}
    </div>
  )

  const canCreateRisk = authz({
    module: 'Risk',
    type: actions.risk.create.toString(),
    meta: { ancestry: projectAncestry, authz: { resources: [projectId] } },
  })
  const tabBarControls = (
    <div {...style.tabBarControls}>
      {canCreateRisk && (
        <Button type="primary" onClick={showRiskCreateModal}>
          Create Risk
        </Button>
      )}
      <RiskContextExcelReportButton entityId={projectId} />
    </div>
  )

  const tabItems = [
    {
      label: 'Risk Register',
      key: 'risks',
      children: <RiskInventoryTable effectiveRiskContext={effectiveRiskContext} rows={risks} project={project} />,
    },
    {
      label: 'Heatmap',
      key: 'heatmap',
      children: (
        <RiskInventoryTable
          effectiveRiskContext={effectiveRiskContext}
          rows={risks}
          project={project}
          heatmapFilter
        />
      ),
    },
    {
      label: 'Tornado Charts',
      key: 'tornado',
      children: (
        <>
          <Row style={style.filterRow} gutter={20}>
            <Col span={8}>
              <Select value={chart} onChange={val => setChart(val)} allowClear={false}>
                <Select.Option key={COST} value={COST}>
                  Cost
                </Select.Option>
                <Select.Option key={TIME} value={TIME}>
                  Schedule
                </Select.Option>
                {includePerformance && (
                  <Select.Option key={PERF} value={PERF}>
                    Performance
                  </Select.Option>
                )}
              </Select>
            </Col>
            <Col span={8}>
              <Select value={numRows} onChange={val => setNumRows(val)} allowClear={false}>
                <Select.Option key="ten" value={10}>
                  Top 10
                </Select.Option>
                <Select.Option key="twentyFive" value={25}>
                  Top 25
                </Select.Option>
                <Select.Option key="fifty" value={50}>
                  Top 50
                </Select.Option>
                <Select.Option key="all" value={-1}>
                  All
                </Select.Option>
              </Select>
            </Col>
            <Col span={8} style={style.switch}>
              <div title="Truncate long risk names">
                Truncate: <Switch checked={truncate} onChange={check => setTruncate(check)} />
              </div>
            </Col>
          </Row>
          <Collapse
            activeKey={activeKeys}
            expandIconPosition="right"
            style={style.collapse}
            onChange={keys => setActiveKeys(keys)}
          >
            <Collapse.Panel header="Qualitative Risk Tornado" key={QUAL}>
              <RiskTornado
                chart={chart}
                numRows={numRows}
                projectId={projectId}
                effectiveRiskContext={effectiveRiskContext}
                baseRoute={`/${accountCommonId}/proj/${projectId}/risk`}
                truncate={truncate}
                risks={risks}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Quantitative Risk Tornado" key={QUANT}>
              <RiskTornado
                chart={chart}
                dataType={QUANT}
                numRows={numRows}
                projectId={projectId}
                effectiveRiskContext={effectiveRiskContext}
                baseRoute={`/${accountCommonId}/proj/${projectId}/risk`}
                truncate={truncate}
                risks={risks}
              />
            </Collapse.Panel>
          </Collapse>
        </>
      ),
    },
    {
      key: 'risk-explorer',
      label: 'Risk Explorer',
      children: <RiskExplorer accountCommonId={accountCommonId} risks={risks} entityId={projectId} />,
    },
    {
      key: 'attachments',
      label: 'Attachments',
      children: <RiskEntityAttachmentManager entityId={projectId} />,
    },
  ]

  return (
    <>
      <RiskPortfolioNodeHeader ancestry={projectAncestry} entityId={project.id} extra={headerExtra} />
      <div style={style.container}>
        <Tabs
          activeKey={tabKey}
          onChange={onTabChange}
          tabBarExtraContent={tabBarControls}
          style={style.tabs}
          items={tabItems}
        />
      </div>
    </>
  )
}

const style = {
  filterRow: {
    margin: '0px 20px',
  },
  headerExtra: css({
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: '24px',
    },
    '& > :not(:last-child)': {
      marginRight: '24px',
    },
  }),
  headerExtraControls: css({
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: '12px',
    },
  }),
  tabBarControls: css({
    '& > *': {
      marginRight: '16px',
      '&:not(:first-child)': {
        marginLeft: '16px',
      },
    },
  }),
  collapse: {
    margin: '12px',
  },
  tabs: {
    padding: '6px 12px',
  },
  container: {
    backgroundColor: Color.WHITE,
    margin: '24px 30px',
  },
  switch: {
    marginTop: '5px',
  },
}

export default RiskProject
