import { useCallback, useEffect, useMemo } from 'react'
import { createSelector } from 'reselect'
import _isMatch from 'lodash/isMatch'
import _pickBy from 'lodash/pickBy'

import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { TreeNode, createTree, traverse } from '@vms/vmspro3-core/dist/utils/createTree'
import { Decision, DecisionData } from '@vms/vmspro3-core/dist/nextgen/decision'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import {
  ParticipationSessionData,
  ParticipationSessionType,
} from '@vms/vmspro3-core/dist/nextgen/participationSession'
import { DecisionFolder, Participant, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'

import { SelectOption } from '../../types'
import { RootState, useAppDispatch, useAppSelector } from '../store'
import { fetchDecisionEntity, fetchDecisionFolderChildren } from '../actions'
import {
  getSelectCriteriaIds,
  getSelectDecision,
  getSelectDecisionAncestorFolders,
  getSelectDecisionAncestry,
  getSelectDecisionFolder,
  getSelectDecisionFolderAncestorFolders,
  getSelectDecisionFolderAncestry,
  getSelectDecisionFolderChildrenCount,
  getSelectLeafCriteriaSelectOptions,
  getSelectLeafCriterionLabel,
  getSelectOptionIds,
  getSelectOptionSelectOptions,
  getSelectOptionsCount,
  getSelectParticipantIds,
  getSelectParticipationSession,
  getSelectParticipationSessionId,
  getSelectRatingNotesIds,
  getSelectParticipationSessionRatings,
  RatingFilterKeys,
  getSelectParticipationSessions,
  getSelectRatings,
} from '../selectors/decisionSelectors'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/options'

export function useLoadDecision(accountId: string, decisionId: string): boolean {
  const status = useAppSelector(state => state.decisions[decisionId]?.status ?? 'Idle')

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (status === 'Idle') {
      dispatch(fetchDecisionEntity(accountId, decisionId, 'Decision'))
    }
  }, [dispatch, accountId, decisionId, status])

  const loading = status !== 'Success'
  return loading
}

/**
 * Same as `useLoadDecision`, but provides more information about the loading state.
 */
export function useLoadDecision2(
  accountId: string,
  decisionId: string
): { status: 'Idle' | 'Loading' | 'Error' | 'Success'; error?: string } {
  const decision = useAppSelector(state => state.decisions[decisionId])

  const status = decision?.status || 'Idle'

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (status === 'Idle') {
      dispatch(fetchDecisionEntity(accountId, decisionId, 'Decision'))
    }
  }, [dispatch, accountId, decisionId, status])

  const error = decision?.status === 'Error' ? decision.error : undefined
  return { status, error }
}

export function useLoadDecisionFolderChildren(accountId: string, decisionFolderId: string) {
  const loadableDecisionFolder = useAppSelector(
    useCallback(
      (state: RootState) => {
        const loadableDecisionFolder = state.decisionFolders[decisionFolderId]
        if (loadableDecisionFolder?.status === 'Success') {
          return loadableDecisionFolder
        }
      },
      [decisionFolderId]
    )
  )

  const ancestry = loadableDecisionFolder?.data.ancestry
  const childAncestry = ancestry ? joinAncestry(ancestry, decisionFolderId) : undefined

  const dispatch = useAppDispatch()
  const loadChildren = loadableDecisionFolder ? !loadableDecisionFolder.children : false
  useEffect(() => {
    if (loadChildren && childAncestry) {
      dispatch(fetchDecisionFolderChildren(accountId, childAncestry))
    }
  }, [loadChildren, dispatch, accountId, childAncestry])

  return loadChildren
}
export function useDecisionFolderHasChildren(decisionFolderId: string) {
  const decisionFolderChildrenCount = useAppSelector(
    useMemo(() => getSelectDecisionFolderChildrenCount(decisionFolderId), [decisionFolderId])
  )

  return typeof decisionFolderChildrenCount === 'number' && decisionFolderChildrenCount > 0
}

export function useDecisionFolder(decisionFolderId: string, allowUndefined?: false): DecisionFolder
export function useDecisionFolder(decisionFolderId: string, allowUndefined: true): DecisionFolder | undefined
export function useDecisionFolder(decisionFolderId: string, allowUndefined = false): DecisionFolder | undefined {
  const decisionFolder = useAppSelector(
    useCallback(
      (state: RootState) => {
        const loadableDecisionFolder = state.decisionFolders[decisionFolderId]
        if (loadableDecisionFolder?.status === 'Success') {
          return loadableDecisionFolder.data
        }
      },
      [decisionFolderId]
    )
  )

  if (!allowUndefined && !decisionFolder) {
    throw new Error('useDecisionFolder can only be rendered after decision folder is loaded')
  }
  return decisionFolder
}
export function useDecisionData(decisionId: string, allowUndefined?: false): DecisionData
export function useDecisionData(decisionId: string, allowUndefined: true): DecisionData | undefined
export function useDecisionData(decisionId: string, allowUndefined = false): DecisionData | undefined {
  const decision = useAppSelector(
    useCallback(
      (state: RootState) => {
        const loadableDecision = state.decisions[decisionId]
        if (loadableDecision?.status === 'Success') {
          const { participationSessions, ...data } = loadableDecision.data
          return data
        }
      },
      [decisionId]
    )
  )

  if (!allowUndefined && !decision) {
    throw new Error('useDecisionData can only be rendered after decision is loaded')
  }
  return decision
}

export function useDecisionFolderAncestry(decisionFolderId: string): string {
  return useAppSelector(useMemo(() => getSelectDecisionFolderAncestry(decisionFolderId), [decisionFolderId]))
}
export function useDecisionAncestry(decisionId: string): string {
  return useAppSelector(useMemo(() => getSelectDecisionAncestry(decisionId), [decisionId]))
}

export function useDecisionFolderChildAncestry(decisionFolderId: string): string {
  const decisionFolderAncestry = useDecisionFolderAncestry(decisionFolderId)
  return joinAncestry(decisionFolderAncestry, decisionFolderId)
}
export function useDecisionChildAncestry(decisionId: string): string {
  const decisionAncestry = useDecisionAncestry(decisionId)
  return joinAncestry(decisionAncestry, decisionId)
}

type BreadcrumbRoute = { path: string; breadcrumbName: string }
export function useDecisionFolderBreadcrumbRoutes(
  accountCommonId: string,
  decisionFolderId: string,
  appendRoute?: BreadcrumbRoute
): BreadcrumbRoute[] {
  const selectDecisionFolderBreadcrumbRoutes = useMemo(
    () =>
      createSelector(
        getSelectDecisionFolderAncestorFolders(decisionFolderId),
        getSelectDecisionFolder(decisionFolderId),
        (ancestorDecisionFolders, decisionFolder) => {
          const breadcrumbRoutes: BreadcrumbRoute[] = [
            { path: `/${accountCommonId}`, breadcrumbName: 'Home' },
            ...ancestorDecisionFolders.concat(decisionFolder).map(decisionFolder => ({
              path: `/${accountCommonId}/decision?folder=${decisionFolder.id}`,
              breadcrumbName: decisionFolder.name,
            })),
          ]

          if (appendRoute) {
            breadcrumbRoutes.push(appendRoute)
          }

          return breadcrumbRoutes
        }
      ),
    [accountCommonId, decisionFolderId, appendRoute]
  )

  return useAppSelector(selectDecisionFolderBreadcrumbRoutes)
}
export function useDecisionBreadcrumbRoutes(
  accountCommonId: string,
  decisionId: string,
  appendRoute?: BreadcrumbRoute
): BreadcrumbRoute[] {
  const selectDecisionBreadcrumbRoutes = useMemo(
    () =>
      createSelector(
        getSelectDecisionAncestorFolders(decisionId),
        getSelectDecision(decisionId),
        (ancestorDecisionFolders, decision) => {
          const breadcrumbRoutes: BreadcrumbRoute[] = [
            { path: `/${accountCommonId}`, breadcrumbName: 'Home' },
            ...ancestorDecisionFolders.map(decisionFolder => ({
              path: `/${accountCommonId}/decision?folder=${decisionFolder.id}`,
              breadcrumbName: decisionFolder.name,
            })),
            { path: `/${accountCommonId}/decision/${decisionId}`, breadcrumbName: decision.name },
          ]

          if (appendRoute) {
            breadcrumbRoutes.push(appendRoute)
          }

          return breadcrumbRoutes
        }
      ),
    [accountCommonId, decisionId, appendRoute]
  )

  return useAppSelector(selectDecisionBreadcrumbRoutes)
}

export function useParticipationSessionsData(decisionId: string): ParticipationSessionData[] {
  return useAppSelector(useMemo(() => getSelectParticipationSessions(decisionId), [decisionId]))
}

export function useParticipationSession(
  decisionId: string,
  participationSessionType: ParticipationSessionType
): ParticipationSessionData {
  return useAppSelector(
    useMemo(
      () => getSelectParticipationSession(decisionId, participationSessionType),
      [decisionId, participationSessionType]
    )
  )
}
export function useParticipationSessionId(
  decisionId: string,
  participationSessionType: ParticipationSessionType
): string {
  return useAppSelector(
    useMemo(
      () => getSelectParticipationSessionId(decisionId, participationSessionType),
      [decisionId, participationSessionType]
    )
  )
}

type RatingFilters = {
  participationSessionId?: string
  participantId?: string
  subjectId?: string
  subjectType?: Rating['subjectType']
}
function useRatingsFilter<T extends RatingFilters>({
  participationSessionId,
  participantId,
  subjectId,
  subjectType,
}: RatingFilters = {}): (arg: T) => boolean {
  return useCallback(
    (arg: T) => {
      const filters = _pickBy(
        {
          participationSessionId,
          participantId,
          subjectId,
          subjectType,
        },
        value => typeof value === 'string'
      )

      return _isMatch(arg, filters)
    },
    [participationSessionId, participantId, subjectId, subjectType]
  )
}

export function useRatings(decisionId: string): Rating[] {
  return useAppSelector(useMemo(() => getSelectRatings(decisionId), [decisionId]))
}

/**
 * Use decision ratings by participation session type. Allows filtering on participantId,
 * contextId, and subjectId.
 */
export function useParticipationSessionRatings<K extends RatingFilterKeys>(
  decisionId: string,
  participationSessionType: ParticipationSessionType,
  filterBy?: Pick<Rating, K>
): Rating[] {
  const selectValidatedAndFilteredRatings = useMemo(
    () => getSelectParticipationSessionRatings<K>(decisionId, participationSessionType, filterBy),
    [decisionId, participationSessionType, filterBy]
  )

  return useAppSelector(selectValidatedAndFilteredRatings)
}

/**
 * Use decision rating notes, allowing filtering by participation session, participant,
 * and subject type.
 */
export function useRatingNotes(decisionId: string, filters?: RatingFilters): RatingNotes[] {
  const ratingNotesFilter = useRatingsFilter(filters)
  const selectFilteredRatingNotes = useMemo(
    () =>
      createSelector(
        getSelectRatingNotesIds(decisionId),
        (state: RootState) => state.ratingNotes.byId,
        (ids, byId) => {
          const ratingNotes: RatingNotes[] = ids?.map(id => byId[id]) ?? []
          // TODO: added .filter(Boolean) because in the wild found rating note IDs that didn't
          // map to rating notes...a problem to investigate
          return ratingNotes.filter(Boolean).filter(ratingNotesFilter)
        }
      ),
    [decisionId, ratingNotesFilter]
  )

  return useAppSelector(selectFilteredRatingNotes)
}

function useSelectDecisionCriteria(decisionId: string): (state: RootState) => CriterionData[] {
  return useMemo(
    () =>
      createSelector(
        getSelectCriteriaIds(decisionId),
        state => state.criteria.byId,
        (ids: string[] | undefined, byId) => ids?.map(id => byId[id]) ?? []
      ),
    [decisionId]
  )
}

export function useCriteria(decisionId: string): CriterionData[] {
  const selectDecisionCriteria = useSelectDecisionCriteria(decisionId)

  return useAppSelector(selectDecisionCriteria)
}
export function useCriterion(criterionId?: string): CriterionData | undefined {
  return useAppSelector(state => (criterionId ? state.criteria.byId[criterionId] : undefined))
}

export function useRootPerfCriterion(decisionId: string): CriterionData {
  const selectDecisionCriteria = useSelectDecisionCriteria(decisionId)

  const selectPerformanceCriterion = useMemo(
    () => createSelector(selectDecisionCriteria, criteria => criteria.find(c => c.type === 'Performance')),
    [selectDecisionCriteria]
  )
  const rootPerfCriterion = useAppSelector(selectPerformanceCriterion)

  if (!rootPerfCriterion) {
    throw new Error(`Root performance criterion not found in decision ${decisionId}`)
  }

  return rootPerfCriterion
}

export function useRootPerfCriterionId(decisionId: string): string {
  const rootPerfCriterion = useRootPerfCriterion(decisionId)
  return rootPerfCriterion.id
}

/**
 * @deprecated use Criteria to generate criterion instances with tree linking
 */
function useCriteriaTree(decisionId: string, rootCriterionId?: string): TreeNode<CriterionData> {
  const criteria = useCriteria(decisionId)

  const criteriaTree = useMemo(() => {
    const criteriaTreeData = createTree(criteria)

    if (rootCriterionId) {
      return criteriaTreeData.byId[rootCriterionId]
    }
    return criteriaTreeData.all.find(c => c.parent === null) as TreeNode<CriterionData>
  }, [criteria, rootCriterionId])

  return criteriaTree
}

export function usePerformanceCriteriaTreeData(decisionId: string): TreeNode<CriterionData> | undefined {
  return useAppSelector(
    useMemo(
      () =>
        createSelector<
          RootState,
          Array<string> | undefined,
          Record<string, CriterionData>,
          TreeNode<CriterionData> | undefined
        >(
          getSelectCriteriaIds(decisionId),
          state => state.criteria.byId,
          (criteriaIds, criteriaById) => {
            const criteria = criteriaIds?.map(criterionId => criteriaById[criterionId])
            const criteriaTreeData = criteria ? createTree(criteria) : undefined
            const performanceTreeData = criteriaTreeData?.all.find(c => c.data.type === 'Performance')

            return performanceTreeData
          }
        ),
      [decisionId]
    )
  )
}

export function useContextCriteriaSelectOptions(decisionId: string, rootCriterionId?: string): SelectOption[] {
  const criteriaTreeData = useCriteriaTree(decisionId, rootCriterionId)

  const selectOptions = useMemo(() => {
    const contextCriteria: SelectOption[] = []
    traverse(criteriaTreeData, (node, path) => {
      if (node.children.length > 0) {
        // root criterion and direct children of root criterion should only have own name in label,
        // indirect descendants of root should show path in label. if root criterion children are
        // not in path, no prefix will be added to the label.
        const rootPathNames = path.slice(1).map(p => p.data.name)
        const label = [...rootPathNames, node.data.name].join(': ')

        contextCriteria.push({
          value: node.data.id,
          label,
        })
      }
    })
    return contextCriteria
  }, [criteriaTreeData])

  return selectOptions
}

export function useLeafCriterionLabel(
  decisionId: string,
  criterionId: string,
  rootCriterionId?: string
): string | undefined {
  return useAppSelector(
    useMemo(
      () => getSelectLeafCriterionLabel(decisionId, criterionId, rootCriterionId),
      [decisionId, criterionId, rootCriterionId]
    )
  )
}
export function useLeafCriteriaSelectOptions(decisionId: string, rootCriterionId?: string): SelectOption[] {
  return useAppSelector(
    useMemo(() => getSelectLeafCriteriaSelectOptions(decisionId, rootCriterionId), [decisionId, rootCriterionId])
  )
}

export function useOptions(decisionId: string): OptionData[] {
  const selectOptions = useMemo(
    () =>
      createSelector(
        getSelectOptionIds(decisionId),
        (state: RootState) => state.options.byId,
        (ids: string[] | undefined, byId) => ids?.map(id => byId[id]) ?? []
      ),
    [decisionId]
  )

  return useAppSelector(selectOptions)
}

export function useOptionsCount(decisionId: string): number {
  return useAppSelector(useMemo(() => getSelectOptionsCount(decisionId), [decisionId]))
}

export function useOptionSelectOptions(decisionId: string): SelectOption[] {
  return useAppSelector(useMemo(() => getSelectOptionSelectOptions(decisionId), [decisionId]))
}

export function useParticipants(decisionId: string): Participant[] {
  const selectParticipants = useMemo(
    () =>
      createSelector(
        getSelectParticipantIds(decisionId),
        (state: RootState) => state.participants.byId,
        (ids: string[] | undefined, byId) => ids?.map(id => byId[id]) ?? []
      ),
    [decisionId]
  )

  return useAppSelector(selectParticipants)
}

export function useParticipant(participantId: string): Participant | undefined {
  const participant = useAppSelector(state => state.participants.byId[participantId])

  return participant
}

/**
 * Returns the decision participant record for the current authenticated user
 * or undefined if a participant record does not exist.
 */
export function useAuthUserParticipant(decisionId: string): Participant | undefined {
  const authUserId = useAppSelector(state => state.auth.userId)

  const decisionParticipants = useParticipants(decisionId)
  const participant = decisionParticipants.find(p => p.userId === authUserId)

  return participant
}

export function useDecision(decisionId: string): Decision {
  const decisionData = useDecisionData(decisionId)
  const participants = useParticipants(decisionId)
  const criteriaData = useCriteria(decisionId)
  const optionData = useOptions(decisionId)
  const participationSessionsData = useParticipationSessionsData(decisionId)
  const ratings = useRatings(decisionId)
  const ratingNotes = useRatingNotes(decisionId)

  return useMemo(() => {
    const d = new Decision(
      decisionData,
      participants,
      criteriaData,
      optionData,
      participationSessionsData,
      ratings,
      ratingNotes
    )
    /**
      // The following can be really helpful when tracking down rating data issues
      console.group(`useDecision hook for decision ${d.id} (${d.name})`)
        console.group('option ratings')
          const allOptionRatings = ratings.filter(r => r.subjectType === 'Option')
          const optionIds = _uniq(allOptionRatings.map(r => r.subjectId))
          optionIds.forEach(optionId => {
            const option = d.options.byId(optionId)
            console.group(option ? `option "${option.name}" (${option.id})` : `option ${optionId}`)
            const optionRatings = allOptionRatings.filter(r => r.subjectId === optionId)
              .sort((a, b) =>
                a.contextId.localeCompare(b.contextId) || a.participantId.localeCompare(b.participantId))
            console.table(optionRatings.map(r => ({
              'Context (Criterion)': r.contextType === 'Criterion'
                ? d.criteria.byId[r.contextId].label({ skipAncestors: 2 })
                : `ERROR: ${r.contextType} ${r.contextId}`,
              'Participant': d.participantsById[r.participantId].fullName,
              'Subject (Option)': r.subjectType === 'Option'
                ? d.options.byId(r.subjectId)?.name ?? r.subjectId
                : `ERROR: ${r.subjectType} ${r.subjectId}`,
              'Rating': r.abstain
                ? `(abstained @ ${r.ratingVector ? r.ratingVector[0].toFixed(1) : '-'})`
                : r.ratingVector ? r.ratingVector[0].toFixed(1) : '-',
            })))
            console.groupEnd()
          })
        console.groupEnd()
      console.groupEnd()
      */
    return d
  }, [decisionData, participants, criteriaData, optionData, participationSessionsData, ratings, ratingNotes])
}
