import { RatingToPrioritizationAlgorithm } from '../valuemetrics/quickPrioritization'
import { Html } from './html'
import { ParticipationSession } from './participationSession'

export type DecisionNodeCore = {
  id: string
  ancestry: string
  name: string
  status?: string
  owner: {
    userName: string
    userId: string
  }
}

export type DecisionFolder = DecisionNodeCore & {
  entityType: 'DecisionFolder'
  policies?: Array<{
    userId: string
    policyKey: DecisionFolderPolicyKey
  }>
}

/* @deprecated see core/src/nextgen/decision.ts */
export type Decision = DecisionNodeCore & {
  entityType: 'Decision'
  // should be using ../nextgen/decision#DecisionType here, but causes dependency cycle
  type?: 'Regular' | 'VM'
  objective: Html
  description: Html
  valueFunctionJson?: string
  ratingsToPrioritizationAlgorithm?: RatingToPrioritizationAlgorithm
  baselineOptionId: string | null
  participationSessions: ParticipationSession[]
}

export type DecisionNode = DecisionFolder | Decision

// TODO: for now, we are not including "Read-Only" as a policy level because it will require a lot more work
export const decisionPolicyFolderKeys = ['Inherit', 'None', /*'Read-Only',*/ 'Administrator', 'Editor'] as const

export type DecisionFolderPolicyKey = typeof decisionPolicyFolderKeys[number]
