import { CellProps } from 'react-table'

import { Cost } from '@vms/vmspro3-core/dist/qty'

import { formatCost } from '../../../../utils/formatUtils'

export function CostCellRenderer<T extends Record<string, unknown>, P extends CellProps<T, Cost>>({ value }: P) {
  if (!Number.isFinite(value?.value)) return null

  return formatCost(value)
}
