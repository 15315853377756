import { gql } from '@apollo/client'

const TimestampAndLocationFieldsFragment = gql`
  fragment TimestampAndLocationFieldsFragment on TimestampAndLocation {
    timestamp
    location
  }
`
const CostFieldsFragment = gql`
  fragment CostFields on Cost {
    base
    value
    unit
    updated
  }
`
const DurationFieldsFragment = gql`
  fragment DurationFields on Duration {
    base
    value
    unit
  }
`
const HtmlFieldsFragment = gql`
  fragment HtmlFields on Html {
    value
  }
`
const RatingScaleConfigFragment = gql`
  fragment RatingScaleConfigFields on RatingScaleConfig {
    maxRating
    maxRatingLabel
    minRating
    minRatingLabel
    abstainLabel
    bgColor
    fgColor
    ratingScale {
      label
      maxValue
    }
  }
`

export const DecisionFolderFieldsFragment = gql`
  fragment DecisionFolderFields on DecisionFolder {
    id
    ancestry
    name
    policies {
      userId
      policyKey
    }
    owner {
      userId
      userName
    }
  }
`
export const DecisionFieldsFragment = gql`
  fragment DecisionFields on Decision {
    id
    type
    ancestry
    name
    description {
      ...HtmlFields
    }
    objective {
      ...HtmlFields
    }
    valueFunctionJson
    ratingsToPrioritizationAlgorithm
    baselineOptionId
    owner {
      userId
      userName
    }
  }
  ${HtmlFieldsFragment}
`
export const CriterionFieldsFragment = gql`
  fragment CriterionFields on Criterion {
    id
    name
    description {
      ...HtmlFields
    }
    abbrev
    parentId
    type
    color
    optionRatingScaleConfig {
      ...RatingScaleConfigFields
    }
  }
  ${HtmlFieldsFragment}
  ${RatingScaleConfigFragment}
`
export const OptionFieldsFragment = gql`
  fragment OptionFields on Option {
    id
    name
    abbrev
    color
    commonId
    description {
      ...HtmlFields
    }
    cost {
      ...CostFields
    }
    time {
      ...DurationFields
    }
    outcomes {
      id
      name
      abbrev
      color
      description {
        ...HtmlFields
      }
    }
  }
  ${CostFieldsFragment}
  ${DurationFieldsFragment}
  ${HtmlFieldsFragment}
`
export const ParticipantFieldsFragment = gql`
  fragment ParticipantFields on Participant {
    id
    userId
    associatedUser {
      accountId
      id
      fullName
    }
    trackingId
    fullName
    shortName
    initials
    email
    phone
    tags
    created {
      ...TimestampAndLocationFieldsFragment
    }
    updated {
      ...TimestampAndLocationFieldsFragment
    }
  }
  ${TimestampAndLocationFieldsFragment}
`
export const ParticipationSessionFieldsFragment = gql`
  fragment ParticipationSessionFields on ParticipationSession {
    id
    name
    type
    status
    method
    includeIntrinsic
    history {
      status
      includeIntrinsic
      timestamp
    }
  }
`
export const RatingFieldsFragment = gql`
  fragment RatingFields on Rating {
    participationSessionId
    participantId
    contextType
    contextId
    subjectType
    subjectId
    ratingVector
    abstain
    updated {
      timestamp
      location
    }
  }
`
export const RatingNotesFieldsFragment = gql`
  fragment RatingNotesFields on RatingNotes {
    participationSessionId
    participantId
    contextType
    contextId
    subjectType
    notes {
      ...HtmlFields
    }
    updated {
      timestamp
      location
    }
  }
  ${HtmlFieldsFragment}
`
export const CompleteDecisionFragment = gql`
  fragment CompleteDecision on Decision {
    ...DecisionFields
    criteria {
      ...CriterionFields
    }
    options {
      ...OptionFields
    }
    participants {
      ...ParticipantFields
    }
    participationSessions {
      ...ParticipationSessionFields
    }
    ratings {
      ...RatingFields
    }
    ratingNotes {
      ...RatingNotesFields
    }
  }
  ${DecisionFieldsFragment}
  ${CriterionFieldsFragment}
  ${OptionFieldsFragment}
  ${ParticipantFieldsFragment}
  ${ParticipationSessionFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`

export const AccountFieldsFragment = gql`
  fragment AccountFields on Account {
    id
    name
    status
    commonId
    createdOn
    createdBy {
      id
      email
    }
    contacts {
      billing {
        attn {
          name
          title
          email
          phone
        }
        company
        address1
        address2
        city
        state
        zip
      }
    }
    quotas {
      users {
        allowed
        used
      }
      projects {
        allowed
        used
      }
      decisions {
        allowed
        used
      }
    }
    productId
    integrations {
      stripe {
        customerId
        subscriptionId
        defaultPaymentMethodId
      }
    }
    canceled {
      canceledOn
      reason
    }
  }
`

export const AccountUserFieldsFragment = gql`
  fragment AccountUserFields on AccountUser {
    id
    email
    fullName
    policyIds
    shortName
    initials
    phone
    disabled
    accountId
    eulasJSON
    createdAt
    updatedAt
  }
`
