import { ReactElement, useCallback, useMemo } from 'react'
import { Button, ButtonProps, Modal, Space, Tooltip } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { deleteCriterion } from '@vms/vmspro3-core/dist/actions/decision'

import { CriterionLabel } from './CriterionLabel'

// TODO: maybe these need to be copied to here or replaced?
import { CriterionModal } from '../Criteria/CriterionModal'
import { Tree } from '../../../common/Tree'
// end TODO

import style from './CriteriaNavigator.module.css'
import { useAppDispatch } from '../../../../redux'
import { useModalState } from '../../../../hooks/useModalState'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { getCriteriaSorter } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { match } from 'ts-pattern'
import { extractCoefficients } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'

const criteriaSorter = getCriteriaSorter('pri')

function renderCriterionLabel(criterion: Criterion) {
  return <CriterionLabel criterion={criterion} />
}

interface CriteriaNavigatorProps {
  decision: Decision
  selectedCriterionId?: string
  onSelectCriterion: (criterionId?: string) => void
}
export function CriteriaNavigator(props: CriteriaNavigatorProps): ReactElement {
  const { decision, selectedCriterionId } = props
  const { criteria } = decision

  const selectedCriterion = selectedCriterionId ? criteria.byId[selectedCriterionId] : undefined

  const { modal, showModal, hideModal } = useModalState()
  const onAddChild = useCallback(
    () =>
      showModal(<CriterionModal decisionId={decision.id} parentId={selectedCriterionId} hideModal={hideModal} />),
    [showModal, decision.id, selectedCriterionId, hideModal]
  )

  const dispatch = useAppDispatch()
  const onDeleteCriterion = useCallback(() => {
    if (!selectedCriterion) return
    const hasDescendants = selectedCriterion.descendants.length > 0

    Modal.confirm({
      title: `Deleting criterion "${selectedCriterion.name}"`,
      content: (
        <>
          <p>
            This action will delete criterion "{selectedCriterion.name}"
            {hasDescendants ? ' and all of its descendants:' : '.'}
          </p>
          {hasDescendants && (
            <>
              <Tree<Criterion> rootNode={selectedCriterion} renderLabel={renderCriterionLabel} />
              <br />
            </>
          )}
          <p>Are you sure you want to proceed?</p>
        </>
      ),
      onOk: () => {
        const descendantIds = hasDescendants ? selectedCriterion.descendants.map(d => d.id) : undefined
        const deleteCriterionAction = deleteCriterion(decision.id, selectedCriterion.id, descendantIds)
        dispatch(deleteCriterionAction)
        props.onSelectCriterion(selectedCriterion.parent?.id)
      },
    })
  }, [selectedCriterion, decision.id, dispatch, props])

  const DeleteButton = useCallback(() => {
    const disableDelete = selectedCriterion?.type !== 'Rated'
    const tooltipText = match(selectedCriterion?.type)
      .with('Performance', () => "You can't delete the root subjective criterion.")
      .with('IntrinsicRoot', () => "You can't delete the root objective criterion.")
      .with('Intrinsic', () => 'You cannot currently delete objective criteria.')
      .otherwise(() => '')
    return disableDelete ? (
      <Tooltip title={tooltipText} placement="bottom">
        <Button danger disabled icon={<DeleteOutlined />}>
          Delete
        </Button>
      </Tooltip>
    ) : (
      <Button danger onClick={onDeleteCriterion} icon={<DeleteOutlined />}>
        Delete
      </Button>
    )
  }, [onDeleteCriterion, selectedCriterion?.type])

  const AddButton = useCallback(() => {
    const disableAdd =
      !selectedCriterion || (selectedCriterion.type !== 'Performance' && selectedCriterion.type !== 'Rated')
    const tooltipText = match(selectedCriterion?.type)
      .with('IntrinsicRoot', () => 'You cannot currently add new objective criteria.')
      .with('Intrinsic', () => "Objective criteria can't have sub-criteria.")
      .otherwise(() => '')
    const buttonText = selectedCriterion?.type === 'Performance' ? 'Add criterion' : 'Add sub-criterion'
    const buttonProps: ButtonProps = {
      type: 'default',
      icon: <PlusOutlined />,
    }
    return disableAdd ? (
      <Tooltip title={tooltipText} placement="bottom">
        <Button data-tour-id="Add Criterion" {...buttonProps} disabled>
          {buttonText}
        </Button>
      </Tooltip>
    ) : (
      <Button {...buttonProps} data-tour-id="Add Criterion" onClick={onAddChild}>
        {buttonText}
      </Button>
    )
  }, [onAddChild, selectedCriterion])

  const pruneIds: string[] = useMemo(() => {
    if (decision.type === 'VM') return [] // don't prune anything for VM decisions
    const coefficients = extractCoefficients(decision.valueFunctionExpr)
    const hasCost = typeof coefficients.cost === 'number'
    const hasTime = typeof coefficients.time === 'number'
    if (!hasCost && !hasTime) {
      const objectiveRoot = decision.criteria.all.find(c => c.type === 'IntrinsicRoot')
      return objectiveRoot ? [objectiveRoot.id] : []
    }
    if (!hasCost) {
      const cost = decision.criteria.all.find(c => c.isLegacyCost)
      return cost ? [cost.id] : []
    }
    if (!hasTime) {
      const time = decision.criteria.all.find(c => c.isLegacyTime)
      return time ? [time.id] : []
    }
    return []
  }, [decision.criteria.all, decision.type, decision.valueFunctionExpr])

  return (
    <div data-tour-id="Criteria Navigator" className={style.criteriaNavigator}>
      {modal}
      <div>
        <div>
          <Tree<Criterion>
            rootNode={criteria.root}
            renderLabel={renderCriterionLabel}
            onSelectItem={props.onSelectCriterion}
            selectedItemId={selectedCriterionId}
            sortFn={criteriaSorter}
            pruneIds={pruneIds}
            skipRoot
          />
          <Space className={style.criteriaControls}>
            <AddButton />
            <DeleteButton />
          </Space>
        </div>
      </div>
    </div>
  )
}
