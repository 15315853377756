import { RootState } from '../reducers'

export const selectAccount = (state: RootState) => state.account.account

export const selectAccountName = (state: RootState) => state.account.account?.name

export const selectAccountQuotas = (state: RootState) => state.account.account?.quotas
export const selectAccountUsersQuota = (state: RootState) => state.account.account?.quotas.users
export const selectAccountProjectsQuota = (state: RootState) => state.account.account?.quotas.projects
export const selectAccountDecisionsQuota = (state: RootState) => state.account.account?.quotas.decisions

export const selectPendingInvitationsForAccount = (state: RootState) => state.account.pendingInvitations
export const selectPendingInvitationsLoadingStatusForAccount = (state: RootState) =>
  state.account.pendingInvitationsLoadingStatus
