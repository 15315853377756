import { z } from 'zod'

import { ROOT_ACCOUNT_ID } from '../systemConsts'
import { Invitation } from '../types'

const nonEmptyString = z.string().regex(/\S/)

const ReadAccountActionType = 'Read Account'
interface ReadAccountAction {
  module: 'System',
  type: typeof ReadAccountActionType,
  payload: Record<never, never>,
  meta: Record<never, never>,
}
function readAccount(): ReadAccountAction {
  return {
    module: 'System',
    type: ReadAccountActionType,
    payload: {},
    meta: {},
  }
}
readAccount.toString = () => ReadAccountActionType

const CancelSubscriptionActionType = 'Cancel Subscription'
export interface CancelSubscriptionAction {
  module: 'System',
  type: typeof CancelSubscriptionActionType,
  payload: Record<never, never>,
  meta: {
    accountId: string,
    reason: string,
  },
}
function cancelSubscription(accountId: string, reason: string): CancelSubscriptionAction {
  return {
    module: 'System',
    type: 'Cancel Subscription',
    payload: {},
    meta: { accountId, reason },
  }
}
cancelSubscription.toString = () => CancelSubscriptionActionType

const UpdatePaymentMethodActionType = 'Update Payment Method'
export interface UpdatePaymentMethodAction {
  module: 'System',
  type: typeof UpdatePaymentMethodActionType,
  payload: { paymentMethodId: string },
  meta: Record<never, never>,
}
function updatePaymentMethod(paymentMethodId: string): UpdatePaymentMethodAction {
  return {
    module: 'System',
    type: UpdatePaymentMethodActionType,
    payload: { paymentMethodId },
    meta: {},
  }
}
updatePaymentMethod.toString = () => UpdatePaymentMethodActionType

const ReadInvoicesActionType = 'Read Invoices'
export interface ReadInvoicesAction {
  module: 'System',
  type: typeof ReadInvoicesActionType,
  payload: Record<never, never>,
  meta: Record<never, never>,
}
function readInvoices(): ReadInvoicesAction {
  return {
    module: 'System',
    type: ReadInvoicesActionType,
    payload: {},
    meta: {},
  }
}
readInvoices.toString = () => ReadInvoicesActionType

const ReadSubscriptionActionType = 'Read Subscription'
export interface ReadSubscriptionAction {
  module: 'System',
  type: typeof ReadSubscriptionActionType,
  payload: Record<never, never>,
  meta: Record<never, never>,
}
function readSubscription(): ReadSubscriptionAction {
  return {
    module: 'System',
    type: ReadSubscriptionActionType,
    payload: {},
    meta: {},
  }
}
readSubscription.toString = () => ReadSubscriptionActionType

const DeleteAccountActionType = 'Delete Account'
export interface DeleteAccountAction {
  module: 'System',
  type: typeof DeleteAccountActionType,
  payload: Record<never, never>,
  meta: { accountId: string },
}
function deleteAccount(payload: undefined, meta: DeleteAccountAction['meta']): DeleteAccountAction {
  return {
    module: 'System',
    type: DeleteAccountActionType,
    payload: {},
    meta,
  }
}
deleteAccount.toString = () => DeleteAccountActionType

const InviteUserActionType = 'Invite User'
export interface InviteUserAction {
  module: 'System',
  type: typeof InviteUserActionType,
  payload: Invitation,
  meta: Record<never, never>,
}
type InviteUserActionParamKeys =
  | 'accountId'
  | 'accountName'
  | 'email'
  | 'fullName'
  | 'invitedById'
  | 'invitedByName'
  | 'policyIds'
function inviteUser(params: Pick<InviteUserAction['payload'], InviteUserActionParamKeys>): InviteUserAction {
  z.object({
    accountId: nonEmptyString,
    accountName: nonEmptyString,
    email: z.string().email(),
    fullName: nonEmptyString,
    invitedById: nonEmptyString,
    invitedByName: nonEmptyString,
    policyIds: z.array(nonEmptyString),
  }).strict()
    .parse(params)

  const { accountId, accountName, email, fullName, invitedById, invitedByName, policyIds } = params

  return {
    module: 'System',
    type: InviteUserActionType,
    payload: {
      accountId: ROOT_ACCOUNT_ID,
      // accountId first for queries about all invites from an account
      id: `${params.accountId}/${params.email}`,
      // email first for queries about all invites for a user
      ancestry: `${params.email}/${params.accountId}`,
      entityType: 'USER_INVITATION',
      fullName,
      email,
      accountName,
      // accountId reserved for database primary index key
      inviteToAccountId: accountId,
      invitedById,
      invitedByName,
      created: Date.now(),
      policyIds,
    },
    meta: {},
  }
}
inviteUser.toString = () => InviteUserActionType

const AcceptInvitationActionType = 'Accept User Invitation'
export interface AcceptInvitationAction {
  module: 'System',
  type: typeof AcceptInvitationActionType,
  payload: {
    accountId: string,
    userId: string,
    email: string,
  },
  meta: Record<never, never>,
}
function acceptInvitation(payload: AcceptInvitationAction['payload']): AcceptInvitationAction {
  return {
    module: 'System',
    type: AcceptInvitationActionType,
    payload,
    meta: {},
  }
}
acceptInvitation.toString = () => AcceptInvitationActionType

export default {
  readAccount,
  cancelSubscription,
  updatePaymentMethod,
  readInvoices,
  readSubscription,
  inviteUser,
  acceptInvitation,
  deleteAccount,
}
