import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { produce } from 'immer'
import { Form, Input } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'
import { createId } from '@vms/vmspro3-core/dist/idUtils'

import FormModal from './FormModal'
import { useModalData } from '../RiskModalContext'

const RiskCategoryModal = () => {
  const dispatch = useDispatch()

  const { entityId, entityType, riskContext, cat, categoryAncestry, entityAncestry, categories } = useModalData(
    RiskCategoryModal.id
  )

  const onOk = values => {
    const updateCat = cat ? { ...cat, ...values } : { ...values, value: createId() }
    updateCat.ancestry = categoryAncestry
    // note that we have to send the entire risk context to update
    const payload = {
      ...riskContext,
      types: produce(riskContext.types, types => {
        const t = types.category
        t.values = cat
          ? t.values.map(v => (v.value === updateCat.value ? updateCat : v))
          : [...t.values, updateCat]
      }),
    }
    const meta = {
      entityId,
      entityType,
      ancestry: entityAncestry,
    }
    const action = actions.riskContext.update(payload, meta)
    dispatch(action)
  }

  const abbrevMaxLength = 6

  const categoryParent = categoryAncestry && categories.find(c => categoryAncestry.endsWith(c.value))

  const validateName = useCallback(
    async (_, value) => {
      const siblingNames = categories
        .filter(c => !c.isDeleted && c.ancestry === categoryAncestry && (!cat || c.value !== cat.value))
        .map(c => c.label.long)
      if (siblingNames.includes(value)) {
        const msg = categoryParent
          ? `${value} is already in use.  Category names must be unique in ${categoryParent.label.long}.`
          : `${value} is already in use.  Top-level category names must be unique.`
        throw new Error(msg)
      }
    },
    [cat, categories, categoryAncestry, categoryParent]
  )
  const validateAbbrev = useCallback(
    async (_, value) => {
      const siblingAbbreviations = categories
        .filter(c => !c.isDeleted && c.ancestry === categoryAncestry && (!cat || c.value !== cat.value))
        .map(c => c.label.short)
      if (siblingAbbreviations.includes(value)) {
        const msg = categoryParent
          ? `${value} is already in use.  Category abbreviations must be unique in ${categoryParent.label.long}.`
          : `${value} is already in use.  Top-level category abbreviations must be unique.`
        throw new Error(msg)
      }
    },
    [cat, categories, categoryAncestry, categoryParent]
  )

  return (
    <FormModal modalId={RiskCategoryModal.id} onOk={onOk} initialValues={cat}>
      <Form.Item
        label="Name"
        name={['label', 'long']}
        validateTrigger="onBlur"
        rules={[{ required: true, whitespace: true, message: 'Name is required.' }, { validator: validateName }]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        label="Abbreviation"
        name={['label', 'short']}
        validateTrigger="onBlur"
        rules={[
          { required: true, whitespace: true, message: 'Abbreviation is required.' },
          { max: abbrevMaxLength, message: `Abbreviation is limited to ${abbrevMaxLength} characters.` },
          { validator: validateAbbrev },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="desc">
        <Input />
      </Form.Item>
    </FormModal>
  )
}
RiskCategoryModal.id = 'RiskCategoryModal'

export default RiskCategoryModal
