/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useCallback, useMemo } from 'react'
import _partition from 'lodash/partition'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { EditOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { ParticipationSessionType } from '@vms/vmspro3-core/dist/nextgen/participationSession'
import { RatingContext } from '@vms/vmspro3-core/dist/nextgen/RatingContext'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import {
  Participant,
  Rating as IRating,
  RatingContextType,
  RatingNotes,
  RatingSubjectType,
} from '@vms/vmspro3-core/dist/types'

import { Rating } from '../Rating'
import { RatingContextDetails } from './RatingContextDetails'
import { DrawerWrapper } from './DrawerWrapper'
import { DebounceTextArea } from './DebounceTextArea'
import { RatingOverview } from './RatingOverview'
import { RenderHtmlObject } from '../RenderHtmlObject'

import style from './RatingInterface.module.css'
import { RatingDetailPanel } from '../Rating/RatingDetailPanel'
import { UpdateRatingArgs } from '../Rating/Rating'

interface ParticipationSessionConfig {
  contextType: RatingContextType
  subjectType: RatingSubjectType
  activityLabel: string
  subjectTypeLabel: string
  sessionTypeLabel: string
  hideRating: boolean
}
const configBySessionType: Record<ParticipationSessionType, ParticipationSessionConfig> = {
  CriteriaPrioritization: {
    contextType: 'Criterion',
    subjectType: 'Criterion',
    activityLabel: 'Prioritization',
    subjectTypeLabel: 'Child Criteria',
    sessionTypeLabel: 'Criteria Prioritization',
    hideRating: true,
  },
  OptionRating: {
    contextType: 'Criterion',
    subjectType: 'Option',
    activityLabel: 'Rating',
    subjectTypeLabel: 'Options',
    sessionTypeLabel: 'Option Rating',
    hideRating: false,
  },
  OutcomeProbability: {
    contextType: 'Option',
    subjectType: 'OptionOutcome',
    activityLabel: 'Estimating Probability',
    subjectTypeLabel: 'Outcome',
    sessionTypeLabel: 'Outcome probability',
    hideRating: true,
  },
}

function useParticipationSessionConfiguration({
  participationSessionId,
  participantId,
  decision,
}: {
  participationSessionId: string
  participantId: string
  decision: Decision
}) {
  const participationSessionType = useMemo(() => {
    const session = decision.participationSessions.find(ps => ps._data.id === participationSessionId)
    if (!session) throw new Error('Participation session not found in decision')
    return session.type
  }, [decision, participationSessionId])

  const sessionConfig = configBySessionType[participationSessionType]

  const ratingContexts = useMemo<RatingContext[]>(
    () => decision.getRatingContexts(participationSessionType, participantId),
    [decision, participationSessionType, participantId]
  )

  return {
    ratingContexts,
    sessionConfig,
  }
}

export type RatingInterfaceProps = {
  contextId?: string
  setContextId: (contextId?: string) => void
  updateRating: (rating: Omit<IRating, 'updated'>) => void
  updateRatingNotes: (ratingNotes: Omit<RatingNotes, 'updated'>) => void
  participant: Participant
  unsetParticipant?: VoidFunction
  decision: Decision
  participationSessionId: string
  participantId: string
  backToDecision?: VoidFunction
}
export function RatingInterface({
  contextId,
  setContextId,
  updateRatingNotes,
  updateRating,
  participant,
  unsetParticipant,
  participationSessionId,
  participantId,
  decision,
  backToDecision,
}: RatingInterfaceProps): ReactElement {
  const { ratingContexts, sessionConfig } = useParticipationSessionConfiguration({
    participationSessionId,
    participantId,
    decision,
  })

  const [completedContexts, inProgressContexts] = useMemo(
    () => _partition(ratingContexts, context => context.remainingCount === 0),
    [ratingContexts]
  )

  const ratingContext = useMemo(
    () => ratingContexts.find(ratingContext => ratingContext.contextCriterion.id === contextId),
    [ratingContexts, contextId]
  )

  const onUpdateRating = useCallback(
    ({ subjectId, subjectType, ratingVector, abstain }: UpdateRatingArgs) => {
      if (!contextId) throw new Error('contextId not defined')
      updateRating({
        participationSessionId,
        participantId,
        contextType: sessionConfig.contextType,
        contextId,
        subjectType: subjectType || sessionConfig.subjectType,
        subjectId,
        ratingVector,
        abstain,
      })
    },
    [
      contextId,
      updateRating,
      participationSessionId,
      participantId,
      sessionConfig.contextType,
      sessionConfig.subjectType,
    ]
  )

  const onUpdateRatingNotes = useCallback(
    (value: string) => {
      if (!contextId) throw new Error('contextId not defined')
      updateRatingNotes({
        participationSessionId,
        participantId,
        contextType: sessionConfig.contextType,
        contextId,
        subjectType: sessionConfig.subjectType,
        notes: createHtmlObject(value),
      })
    },
    [
      updateRatingNotes,
      participationSessionId,
      participantId,
      sessionConfig.contextType,
      contextId,
      sessionConfig.subjectType,
    ]
  )

  if (!contextId || !ratingContext) {
    return (
      <RatingOverview
        participationSessionTypeLabel={sessionConfig.sessionTypeLabel}
        setContextId={setContextId}
        completedRatingContexts={completedContexts}
        inProgressRatingContexts={inProgressContexts}
        decisionName={decision.data.name}
        // TODO: replace fullName with shortName
        participantShortName={participant.fullName}
        unsetParticipant={unsetParticipant}
        backToDecision={backToDecision}
      />
    )
  }

  const ratingNotesInput = (
    <DebounceTextArea
      key={ratingContext.contextCriterion.id}
      label={`Notes for ${ratingContext.contextCriterion.name}`}
      value={ratingContext.ratingNotes?.notes.value}
      onChange={onUpdateRatingNotes}
    />
  )

  const participationComplete =
    inProgressContexts.length === 0 || (inProgressContexts.length === 1 && inProgressContexts[0] === ratingContext)

  return (
    <div className={style.container}>
      <div className={style.ratingInterfaceContainer}>
        <div className={style.ratingInterface}>
          <div className={style.decisionLabel}>
            <div className={style.decisionNameOverflowEllipsis}>{decision.data.name}</div>
            <div>:&nbsp;{sessionConfig.activityLabel}</div>
          </div>
          <DrawerWrapper
            trigger={setVisible => (
              <div className={style.contextLabel} onClick={setVisible}>
                <h2>{ratingContext.contextCriterion.name}</h2>
                <FontAwesomeIcon icon={faInfoCircle as any} className={style.infoIcon} />
              </div>
            )}
            content={
              <RatingContextDetails
                decisionObjective={decision.data.objective}
                subjectTypeLabel={sessionConfig.subjectTypeLabel}
                ratingContextLabel={ratingContext.contextCriterion.name}
                ratingContextDescription={ratingContext.contextCriterion.description}
                subjects={ratingContext.subjects}
              />
            }
          />
          <Rating
            subjects={ratingContext.subjects}
            ratingBySubjectId={ratingContext.ratingBySubjectId}
            ratingScaleConfig={ratingContext.ratingScaleConfig}
            onUpdateRating={onUpdateRating}
            hideRating={sessionConfig.hideRating}
            ratingDetailPanel={
              <RatingDetailPanel
                subjectType={sessionConfig.subjectType}
                total={ratingContext.subjects.length}
                remaining={ratingContext.remainingCount}
                participationComplete={participationComplete}
                contextName={ratingContext.contextCriterion.name}
              />
            }
            extra={
              <div className={style.notesDrawerTrigger}>
                <DrawerWrapper
                  trigger={setVisible => (
                    <Button type="primary" onClick={setVisible}>
                      Notes
                      <EditOutlined />
                    </Button>
                  )}
                  content={ratingNotesInput}
                />
              </div>
            }
          />
        </div>
        <div className={style.desktopRatingNotesContainer}>
          <div className={style.desktopRatingNotesContainerSpace}>
            <h2>Decision Objective:</h2>
            <RenderHtmlObject htmlObject={decision.data.objective} />
          </div>
          <div className={style.desktopRatingNotesTextArea}>{ratingNotesInput}</div>
        </div>
      </div>
      <div className={style.controlsContainer}>
        <div className={style.controls}>
          <Button onClick={() => setContextId(undefined)}>Menu</Button>
          <Button
            type="primary"
            disabled={ratingContext.remainingCount > 0}
            onClick={() => setContextId(inProgressContexts[0]?.contextCriterion.id)}
          >
            {participationComplete ? (
              'Done'
            ) : (
              <span>
                Next <RightOutlined />
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
