import React, { useCallback, useState, useEffect } from 'react'
import { ReloadOutlined } from '@ant-design/icons'

import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import { testId } from '../../test-automation'

const { Color } = systemConsts

const defaultGenerator = () =>
  import('correcthorse').then(async ({ default: generate }) =>
    // 'correcthorse' doesn't seem to be the most robust of the xkcd
    // password generators, but it does use a solid word list without
    // inappropriate words.  xkcd-z-password-nobadwords appears to
    // be more robust, but doesn't work in a browser environment yet
    generate({ length: 12 }).replace(/-/g, ' ')
  )

const noop = () => null

const PasswordSuggestor = ({ onPasswordSuggested = noop, generatePassword = defaultGenerator }) => {
  const [suggestedPassword, setSuggestedPassword] = useState('')
  const reloadSuggestedPassword = useCallback(
    evt => {
      evt && evt.preventDefault()
      generatePassword().then(pwd => {
        setSuggestedPassword(pwd)
        onPasswordSuggested(pwd)
      })
    },
    [generatePassword, onPasswordSuggested]
  )
  useEffect(() => {
    reloadSuggestedPassword()
  }, [reloadSuggestedPassword])

  return (
    <>
      <p style={{ lineHeight: 'normal' }}>
        <strong>Hint</strong>: we recommend a <i>passphrase</i>. It's easier to type,{' '}
        <a href="https://xkcd.com/936" target="__blank">
          easier to remember, and more secure
        </a>
        . Here's one for you:
      </p>
      <pre style={{ lineHeight: 'normal' }} {...testId('suggested-password')}>
        <ReloadOutlined
          style={{ color: Color.BLUE_LINK }}
          onClick={reloadSuggestedPassword}
          {...testId('reload-suggested-password')}
        />
        &nbsp;&nbsp;{suggestedPassword}
      </pre>
    </>
  )
}

export default PasswordSuggestor
