import React, { useState, useMemo, useCallback } from 'react'
/** @jsxImportSource @emotion/react */
import Display from './Display'
import ControlBar from '../_riskConfig/ControlBar'
import magnitudeFromArticle from '../utils/magnitudeFromArticle'
import TooltipContent from '../_riskConfig/Tooltip'
import useWindowDimensions from '../utils/useWindowDimensions'

/**
 * Our goal is to create a broad visualization comparison
 * for some stat (piece of quantitative data) among many
 * articles (objects with stats and traits for comparison).
 *
 * But our article set may have
 * several stats
 * complex, multifaceted stats
 *
 * What is our path to the user-selected stat?
 *
 * We keep the indices of the user's selections as an
 * array in state. This function looks up the stats
 * in the articleConfig with the correct indices and
 * returns them as a new array.
 *
 * EXAMPLE statIdcs = [0, 3]
 *
 * This means of going to return an array of
 * 0: stats[0] from articleConfig.stats (path: 'severity')
 * 1: pivot.options[3] from that stat (path: 'perf')
 *
 * Later we can use this array to make the complete path
 * or label we need.
 *
 * @param {number[]} statIdcs - (from state) indices to select stats from the following array
 * @param {Object[]} configStats - (from articleConfig) an array of the stats in each article object
 */
function getStatSelections(statIdcs, configStats) {
  const statSelections = [configStats[statIdcs[0]]]
  let depth = 0
  while (statSelections[statSelections.length - 1].pivot) {
    const statIdx = statIdcs[depth + 1] || 0 // default to option zero if further stat indices aren't specified
    const furthestSelection = statSelections[depth]
    statSelections.push(furthestSelection.pivot.options[statIdx])
    depth += 1
  }
  // console.log("+statSelections", ...selections)
  return statSelections
}

/**
 *
 * @param {*} articles
 * @param {*} statSelections
 */
function processArticles(articles, statSelections) {
  const overlay = statSelections[0].overlay && statSelections[0].overlay[0].path
  for (let index = 0; index < articles.length; index++) {
    const article = articles[index]
    const magnitudeData = magnitudeFromArticle(article, statSelections, overlay)
    const { magnitude, isOverlaid } = magnitudeData

    article.magnitude = magnitude
    article.isOverlaid = isOverlaid
    article.groupAs = statSelections[0].groupAs(magnitude)
    article.color = statSelections[0].getColor(magnitude)
  }

  return articles
}

/**
 * gives valid options for each layer of grouping/filtering,
 * removing grouping options that have already been chosen
 * except for the zeroth 'currentStat' option, which is never removed.
 * @param {Number[]} indices
 * numerical addresses used to pick selections from the optionsHowToGroup
 * @param {Object[]} traits
 * array of objects that define and locate categorical/qualitative axes for the object
 * for risks, we have an object for 'category' and an object for phase
 * the one for category lists
 * the key-path to this trait's value in a risk as a string ("category")
 * the label of this trait ("Category")
 * array of options objects giving the possible values ("Political, Social, etc")
 * @param {Object} currentStat
 * the first object from the statSelections array (for a risk, "Severity")
 */
function getOptionsHowToGroup(indicesHowToGroup, traits, currentStat) {
  const optionsHowToGroup = [[currentStat, ...traits]]
  for (let layer = 0; layer < indicesHowToGroup.length - 1; layer++) {
    const newOptionSet = optionsHowToGroup[layer].slice(0)
    // console.log("+-newOptionSet", ...newOptionSet)
    const isGroupedByTrait = indicesHowToGroup[layer] !== 0
    if (isGroupedByTrait) {
      newOptionSet.splice(indicesHowToGroup[layer], 1)
    }
    optionsHowToGroup.push(newOptionSet)
  }

  return optionsHowToGroup
}

function getGroupNameSets(articles, howManyStatGroups, indices, currentStat, selectionsHowToGroup) {
  const groupNameSets = []
  const selectionsHowToFilter = []
  let range
  let { bounds } = currentStat || { undefined }
  if (typeof bounds === 'undefined') {
    const [magnitudes] = articles.map(article => article.magnitude)
    bounds.max = Math.max(currentStat.groupAs(Math.min(magnitudes)), 0, magnitudes)
    bounds.min = Math.min(currentStat.groupAs(Math.min(magnitudes)), 0)
  }
  // console.log("+bounds A", bounds)
  for (let layer = 0; layer < selectionsHowToGroup.length; layer++) {
    const groupIdx = indices.group[layer]
    const filterIdx = indices.filter[layer]
    let selection = undefined
    let groupNameSet = []
    // console.log("+++ comparison \n", selectionsHowToGroup[layer], "\n", currentStat)
    if (selectionsHowToGroup[layer] === currentStat) {
      range = bounds.max - bounds.min
      for (let index = 0; index <= howManyStatGroups; index++) {
        const portion = Number.parseFloat((index / howManyStatGroups).toPrecision(4))
        groupNameSet.push(portion * range + bounds.min)
      }
      // ("+bounds B", bounds, "from", groupNameSet)
      bounds =
        filterIdx === 0
          ? bounds
          : {
              min: groupNameSet[filterIdx - 2] || 0,
              max: groupNameSet[filterIdx - 1],
            }
      selection = filterIdx === 0 ? undefined : bounds
    } else {
      // use KEY here and deal with display issues downstream
      groupNameSet = selectionsHowToGroup[layer].options.map(option => option.label.long)
      selection = { order: groupIdx, adj: groupNameSet[filterIdx - 2] }
    }
    groupNameSets.push(groupNameSet)
    selectionsHowToFilter.push(selection)
  }
  const displayRange = Number.parseFloat((range * currentStat.displayFactor).toPrecision(2))

  return { groupNameSets, selectionsHowToFilter, displayRange }
}

function filterArticles(_articles, indices, selectionsHowToGroup, statSelections, groupNameSets) {
  let articles = _articles
  for (let layer = 0; layer < indices.filter.length; layer++) {
    const groupNameSet = groupNameSets[layer]
    const filterIdx = indices.filter[layer]
    // console.log("+filterIdx", filterIdx)
    switch (filterIdx) {
      case 0:
        return articles
      default:
        if (selectionsHowToGroup[layer] === statSelections[0]) {
          // helpful to what stat range is being filtered to
          // console.log(
          //   "articles must be greater than or equal to",
          //   groupNameSet[filterIdx - 2],
          //   "and less than",
          //   groupNameSet[filterIdx - 1]
          // )
          articles = articles.filter(
            article =>
              article.groupAs === groupNameSet[filterIdx - 2] ||
              (article.groupAs > groupNameSet[filterIdx - 2] && article.groupAs < groupNameSet[filterIdx - 1])
          )
        } else {
          articles = articles.filter(
            article =>
              article[selectionsHowToGroup[layer].path] === groupNameSets[layer][indices.filter[layer] - 2]
          )
        }
    }
  }
}
/**
 * An interactive infographic.
 * @param {Object[]} articles - an array of structurally identical objects that you want to compare, such as risks
 * @param {Object[]} articleConfig - details about those objects
 * @param {JSX} ControlBar - component for user controls for the display. Thes controls live below the display.
 * @param {number} displayHeightPortion - 0 makes the display 0px tall; 1 makes it the height of the window.
 * @param {JSX} TooltipContent - component for custom data layout in the lower half of the tooltip, unique to risks
 */
export default function Explorer({ accountCommonId, articles, articleConfig, displayHeightPortion, entityId }) {
  // listeners
  const windowDimensions = useWindowDimensions()
  // settings
  const [hasRelativeScale, setHasRelativeScale] = useState(false)
  const [scale, setScale] = useState(articleConfig.defaultSettings.scale)
  const [howManyStatGroupsPrime, setHowManyStatGroupsPrime] = useState(articleConfig.defaultSettings.statGroups)
  const [howManyStatGroups, setHowManyStatGroups] = useState(articleConfig.defaultSettings.statGroups)
  const [statIdcs, setStatIdcs] = useState([0])
  // console.log("+indices: stats", ...statIdcs)
  const [indices, setIndices] = useState({ group: [0], filter: [0] })
  // console.log("+indices: groups", ...indices.group, "filters", ...indices.filter)
  // derived state
  const statSelections = useMemo(
    () => getStatSelections(statIdcs, articleConfig.stats),
    [articleConfig.stats, statIdcs]
  )

  useMemo(() => processArticles(articles, statSelections), [articles, statSelections])

  const optionsHowToGroup = getOptionsHowToGroup(indices.group, articleConfig.traits, statSelections[0])
  const selectionsHowToGroup = indices.group.map((groupIdx, layer) => optionsHowToGroup[layer][groupIdx])

  const { groupNameSets, selectionsHowToFilter, displayRange } = getGroupNameSets(
    articles,
    howManyStatGroups,
    indices,
    statSelections[0],
    selectionsHowToGroup
  )
  // console.log("+groupNameSets", ...groupNameSets)
  // console.log("+selectionsHowToFilter", ...selectionsHowToFilter)
  // console.log("+displayRange", range * currentStat.displayFactor, "rounded to", displayRange)

  const articlesFiltered = useMemo(
    () => filterArticles(articles, indices, selectionsHowToGroup, statSelections, groupNameSets),
    [articles, indices, selectionsHowToGroup, statSelections, groupNameSets]
  )

  // handles
  const changeStatIdcs = useCallback(
    (e, idx) => {
      const input = parseInt(e.target.value, 10)
      const statIdcsNew = statIdcs.slice(0, idx)
      statIdcsNew[idx] = input
      setStatIdcs(statIdcsNew)
    },
    [statIdcs]
  )

  const changeHowToGroupIdcs = useCallback(
    (e, idx) => {
      const input = parseInt(e.target.value, 10)
      const groupIdcsNew = indices.group.slice(0, idx + 1)
      const filterIdcsNew = indices.filter.slice(0, idx + 1)
      filterIdcsNew[idx] = 0
      groupIdcsNew[idx] = input
      setIndices({ group: groupIdcsNew, filter: filterIdcsNew })
    },
    [indices.filter, indices.group]
  )

  const changeHowToFilterIdcs = useCallback(
    (e, _idx) => {
      const input = typeof e === 'number' ? e : parseInt(e.target.value, 10)
      let idx
      switch (typeof _idx) {
        case 'number':
          idx = _idx
          break
        case 'object':
          idx = indices.filter.length - 2
          break
        default:
          idx = indices.filter.length - 1
          break
      }
      const filterIdcsNew = indices.filter.slice(0, idx + 1)
      let groupIdcsNew = indices.group.slice(0, idx + 1)
      filterIdcsNew[idx] = input
      if (input === 0) {
        groupIdcsNew = indices.group.slice(0, idx + 1)
      } else {
        groupIdcsNew[idx + 1] = 0
        filterIdcsNew[idx + 1] = 0
      }
      setIndices({ group: groupIdcsNew, filter: filterIdcsNew })
    },
    [indices.filter, indices.group]
  )

  const changeHowManyStatGroupsPrime = e => {
    setHowManyStatGroupsPrime(e.target.value)
    if (document.activeElement === document.getElementById('how-many-mag-groups')) {
      setHowManyStatGroups(e.target.value)
    }
  }
  const changeHowManyStatGroups = () => setHowManyStatGroups(howManyStatGroupsPrime)
  const toggleRelativeScale = e => setHasRelativeScale(e.target.checked)
  const changeScale = e => setScale(e.target.value)

  return (
    <div
      css={{
        height: 'auto',
        // height: `${displayHeightPortion * windowDimensions.height + 70}px`,
        marginBottom: '70px',
        width: '100%',
      }}
    >
      {/* Display is a "dumb", state-less component (excepting perhaps window & mouse
          stuff) that takes everything it needs to render...it essentially becomes a pure function */}
      <Display
        accountCommonId={accountCommonId}
        articles={articlesFiltered}
        articleConfig={articleConfig}
        changeHowToFilterIdcs={changeHowToFilterIdcs}
        displayHeightPortion={displayHeightPortion}
        displayRange={displayRange}
        selectionsHowToFilter={selectionsHowToFilter}
        groupSet={groupNameSets[groupNameSets.length - 1]}
        hasRelativeScale={hasRelativeScale}
        howManyStatGroups={howManyStatGroups}
        selectionsHowToGroup={selectionsHowToGroup}
        scale={scale}
        statSelections={statSelections}
        TooltipContent={TooltipContent}
        windowDimensions={windowDimensions}
        entityId={entityId}
      />
      {/* ControlBar is a "dumb" state-less component that lives only to display the controls...
          this component has state, and will respond to "onChange" callback from this component */}
      <ControlBar
        articleConfig={articleConfig}
        changeHowToFilterIdcs={changeHowToFilterIdcs}
        changeHowToGroupIdcs={changeHowToGroupIdcs}
        changeHowManyStatGroups={changeHowManyStatGroups}
        changeHowManyStatGroupsPrime={changeHowManyStatGroupsPrime}
        changeScale={changeScale}
        changeStatSelectionIdcs={changeStatIdcs}
        groupNameSets={groupNameSets}
        hasRelativeScale={hasRelativeScale}
        howManyStatGroupsPrime={howManyStatGroupsPrime}
        howToGroup={selectionsHowToGroup}
        indices={indices}
        optionsHowToGroup={optionsHowToGroup}
        scale={scale}
        toggleRelativeScale={toggleRelativeScale}
        statSelections={statSelections}
      />
    </div>
  )
}

export { Display }
