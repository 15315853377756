import { useMemo } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { css } from 'glamor'
import _keyBy from 'lodash/keyBy'
import { PolicyType, SystemPolicyId } from '@vms/vmspro3-core/dist/systemConsts'
import { PlusCircleFilled } from '@ant-design/icons'
import { actions } from '@vms/vmspro3-core'
import { Row, Spin } from 'antd'

import UsersAddResourcePolicyModal from '../risk/modals/UsersAddResourcePolicyModal'
import { ResourceAuthorizationTable } from '../risk/tables'
import { LinkButton2 } from '../risk/controls'

import useAuthz from '../../hooks/useAuthz'
import { useFetchUsers } from '../../redux/hooks'
import { useUsers } from '../risk/hooks/hooks'
import { useAccount } from '../../context'
import { useShowModal } from '../risk/RiskModalContext'

/**
 * UI for adding, updating, or removing authorization to a resource for a user.
 *
 * @param {string} props.ancestry - the ancestry of the resource being edited
 * @param {Object} props.authzOptions - the selectable authorization levels for the users
 * @param {String} props.resourceId - the resource being edited
 * @param {String} props.resourceType - the type of the resource being edited
 */
type ResourceAuthorizationEditorProps = {
  ancestry: string
  authzOptions: Array<{ key: string; value: string; label: string }>
  resourceId: string
  resourceType: string
}
export default function ResourceAuthorizationEditor({
  ancestry,
  authzOptions,
  resourceId,
  resourceType,
}: ResourceAuthorizationEditorProps) {
  useFetchUsers()

  const dispatch = useDispatch()
  const { accountId } = useAccount()
  const authz = useAuthz()

  const resourcePolicies = useSelector((state: any) => state.policies.byResourceId[resourceId] || [])
  const resourcePoliciesByParent = useMemo(() => _keyBy(resourcePolicies, 'extends'), [resourcePolicies])

  const [usersById, loadingUsers] = useUsers()

  const showModal = useShowModal()
  const showAddUserModal = () => {
    const readOnlyPolicyId = authzOptions.find(opt => opt.label === 'Read-Only')?.value || null
    showModal(UsersAddResourcePolicyModal.id, {
      accountId,
      readOnlyPolicyId,
      resourceId,
      resourceType,
      resourcePolicies,
    })
  }

  const canEditResourcePolicy = authz(
    actions.policy.update({}, { accountId, policyId: '*', resourceId, resourceType } as any)
  )

  // convenience function to get the metadata for policy update actions
  const getUpdateMeta = (policy: any) => ({
    accountId,
    ancestry,
    extends: policy.extends,
    policyId: policy.id,
    policyType: policy.policyType,
    resourceId: policy.resourceId,
    resourceType: policy.resourceType,
  })

  const onPolicyChange = (principal: string, oldPolicyId: string, newPolicyId: string) => {
    const oldPolicy = resourcePoliciesByParent[oldPolicyId]
    const newPolicy = resourcePoliciesByParent[newPolicyId]
    batch(() => {
      // remove from old resource policy (which may not yet exist in the case of the "All Users" policy)
      if (oldPolicy) {
        dispatch(
          actions.policy.update(
            {
              Principal: oldPolicy.Principal.filter((p: string) => p !== principal),
            },
            getUpdateMeta(oldPolicy)
          )
        )
      }
      // create or update new policy
      if (newPolicy) {
        dispatch(
          actions.policy.update(
            {
              Principal: newPolicy.Principal.concat(principal),
            },
            getUpdateMeta(newPolicy)
          )
        )
      } else {
        dispatch(
          actions.policy.create(
            {
              accountId,
              ancestry,
              extends: newPolicyId,
              policyType: PolicyType.RESOURCE as 'SYSTEM' | 'ROLE' | 'RESOURCE',
              Principal: [principal],
              resourceId,
              resourceType,
            } as any,
            {}
          )
        )
      }
    })
  }

  const onPolicyRemove = (principal: string, policyId: string) => {
    const policy = resourcePoliciesByParent[policyId]
    // remove from old resource policy
    dispatch(
      actions.policy.update(
        {
          Principal: policy.Principal.filter((p: string) => p !== principal),
        },
        getUpdateMeta(policy)
      )
    )
  }

  const ALL_USERS_LABEL = '(All Users)'

  const policiesByPrincipal = useMemo(() => {
    if (loadingUsers) return
    const byPrincipal = (resourcePolicies || []).reduce((byPrincipal: Record<string, any>, policy: any) => {
      const principals = Array.isArray(policy.Principal) ? policy.Principal : [policy.Principal]
      principals.forEach((principal: string) => {
        if (byPrincipal[principal]) {
          console.log(`WARNING: ${principal} associated with multiple policies in resource ${resourceId}`)
        }
        const user = usersById[principal.replace(/^user:/, '')]
        if (principal !== 'user:*' && !user) {
          console.log(`WARNING: no user record found for ${principal}`)
          return
        }
        byPrincipal[principal] =
          principal === 'user:*'
            ? {
                key: 'user:*',
                name: ALL_USERS_LABEL,
                email: '',
                extends: policy.extends,
                removable: false,
              }
            : {
                key: principal,
                name: user.fullName,
                email: user.email,
                extends: policy.extends,
                removable: true,
              }
      })
      return byPrincipal
    }, {})
    // add default "all users" policy if it's missing
    if (!byPrincipal['user:*']) {
      byPrincipal['user:*'] = {
        key: 'user:*',
        name: '(All Users)',
        email: '',
        extends: SystemPolicyId.RISK_REVIEWER,
        removable: false,
      }
    }
    return byPrincipal
  }, [resourcePolicies, usersById, loadingUsers, resourceId])

  if (loadingUsers) return <Spin />

  const dataRows = Object.values(policiesByPrincipal).sort((a: any, b: any) =>
    a.key === 'user:*' || b.key === 'user:*'
      ? a.key.localeCompare(b.key) // make sure "All Users" policy comes first
      : a.name.localeCompare(b.name)
  )

  return (
    <>
      <div style={style.container}>
        {canEditResourcePolicy && (
          <Row justify="end">
            <LinkButton2 onClick={showAddUserModal as any}>
              <PlusCircleFilled twoToneColor="#1890ff" /> Add User
            </LinkButton2>
          </Row>
        )}
        <ResourceAuthorizationTable
          authzOptions={authzOptions}
          dataRows={dataRows}
          editable={canEditResourcePolicy}
          onPolicyChange={onPolicyChange}
          onPolicyRemove={onPolicyRemove}
        />
      </div>
    </>
  )
}

const style = {
  controls: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '12px',
    width: '100%',
    '& > *:not(:first-child)': {
      marginLeft: '16px',
    },
  }),
  container: {
    backgroundColor: 'white',
    margin: '0 30px',
    padding: '15px',
  },
  tableTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '700',
  },
}
