/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { css } from 'glamor'
import { createSelector } from 'reselect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
import { CellProps, Column } from 'react-table'

import { actions } from '@vms/vmspro3-core/dist'
import { UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'

import { UsersAddRoleModal } from './UsersAddRoleModal'
import { Table } from '../../common/Table'

import useAuthz from '../../../hooks/useAuthz'
import { useAccount, useAuth } from '../../../context'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { selectActiveUsers } from '../../../redux/selectors'
import { useModalState } from '../../../hooks/useModalState'

function useActiveUsersWithPolicyId(policyId: string) {
  const selectActiveUsersWithPolicy = useMemo(
    () => createSelector(selectActiveUsers, users => users.filter(user => user.policyIds.includes(policyId))),
    [policyId]
  )

  return useAppSelector(selectActiveUsersWithPolicy)
}

interface RoleDetailsProps {
  assumeRole: () => void
  canAssumeRole: boolean
  policyId: string
}
export function RoleDetails({ assumeRole, canAssumeRole, policyId }: RoleDetailsProps) {
  const { authUserId } = useAuth()
  const rolePolicy = useAppSelector(state => state.policies.byId[policyId])
  const { name: roleName, description } = rolePolicy ?? {}

  const usersWithPolicy = useActiveUsersWithPolicyId(policyId)

  const dispatch = useAppDispatch()

  const showRemoveRoleModal = useCallback(
    (user: UserAccountRecord_App) =>
      Modal.confirm({
        title: 'Remove User from Role',
        content: `Are you sure you want to remove user ${user.fullName} from the ${roleName} role?`,
        onOk: () => {
          dispatch(actions.user.removePolicy(undefined, { policyId, userId: user.id, authUserId }))
        },
      }),
    [dispatch, policyId, roleName, authUserId]
  )

  const authz = useAuthz()
  const getCanRemovePolicy = useCallback(
    (userId: string) =>
      authz(
        actions.user.removePolicy(undefined, {
          userId,
          policyId,
          authUserId,
        })
      ),
    [authz, policyId, authUserId]
  )

  const canAddPolicy = authz(actions.user.addPolicy(undefined, { userId: 'ANY', policyId, authUserId }))

  const { accountCommonId } = useAccount()
  const columns = useMemo(() => {
    const columns: Column<UserAccountRecord_App>[] = [
      {
        Header: 'Name',
        accessor: 'fullName',
        Cell: ({ row }: CellProps<UserAccountRecord_App>) => (
          <Link to={`/${accountCommonId}/user/${row.original.id}`}>{row.original.fullName}</Link>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ]

    if (getCanRemovePolicy('ANY')) {
      columns.push({
        id: 'remove-user-column',
        align: 'right',
        Cell: ({ row }: any) => {
          if (getCanRemovePolicy(row.original.id)) {
            return (
              <Button type="link" size="small" onClick={() => showRemoveRoleModal(row.original)}>
                Remove User
              </Button>
            )
          }
          return null
        },
      })
    }

    return columns
  }, [accountCommonId, getCanRemovePolicy, showRemoveRoleModal])

  const { modal, showModal, hideModal } = useModalState()

  return (
    <div>
      {modal}
      <span style={style.name}>{roleName}</span>
      <span style={style.description}>{description}</span>
      <div style={style.tableHeadingBar}>
        <span style={style.tableHeading} />
        <div {...style.tableHeadingControls}>
          {canAssumeRole && (
            <Button type="link" onClick={assumeRole}>
              Assume Role
            </Button>
          )}
          {canAddPolicy && (
            <Button
              type="link"
              onClick={() => showModal(<UsersAddRoleModal hideModal={hideModal} policyId={policyId} />)}
            >
              <FontAwesomeIcon icon={faPlusCircle as any} />
              &nbsp;Add User
            </Button>
          )}
        </div>
      </div>
      <Table data={usersWithPolicy} columns={columns} />
    </div>
  )
}

const style = {
  description: {
    fontWeight: 300,
  },
  name: {
    display: 'block',
    fontSize: '20px',
    marginBottom: '12px',
  },
  tableHeadingBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px',
  },
  tableHeading: {
    fontSize: '20px',
    margin: '16px 0 10px',
  },
  tableHeadingControls: css({
    display: 'flex',
    alignItems: 'center',
    '> :not(:last-child)': {
      marginRight: '20px',
    },
  }),
}
