import { useMemo } from 'react'
import _cloneDeep from 'lodash/cloneDeep'

const defaultPageSize = 25
const defaultConfigKey = 'custom'

/**
 * get the table configuration filters, pagination and column config when state configuration changes
 *
 * @param {Object} configuration - the table config object from the state
 *
 * @return {Object} the table filters, pagination, and column configuration
 */
function useTableConfig(configuration) {
  return useMemo(() => {
    const configKey = configuration?.configKey ?? defaultConfigKey

    return _cloneDeep({
      configKey,
      filters: configuration?.filters ?? {},
      pageSize: configuration?.pagination?.pageSize ?? defaultPageSize,
      tableConfig: configuration?.[configKey],
    })
  }, [configuration])
}

export { useTableConfig }
