import { ReactElement, useMemo, useState } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'

import style from './DrawerWrapper.module.css'

export type DrawerWrapperProps = {
  content: ReactElement
  trigger: (setVisible: () => void) => ReactElement
}
export function DrawerWrapper({ content, trigger }: DrawerWrapperProps): ReactElement {
  const [visible, setVisibleState] = useState(false)

  const { setVisible, setHidden } = useMemo(
    () => ({
      setVisible: () => setVisibleState(true),
      setHidden: () => setVisibleState(false),
    }),
    []
  )

  return (
    <>
      {trigger(setVisible)}
      <Drawer open={visible} onClose={setHidden} width="100%" closable={false} className={style.drawer}>
        <div className={style.content}>{content}</div>
        <Button type="primary" onClick={setHidden}>
          Close <RightOutlined />
        </Button>
      </Drawer>
    </>
  )
}
