import React, { useCallback, useMemo } from 'react'
import numeral from 'numeral'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { css } from 'glamor'
import { Cost, Duration, DurationUnitMetadata, Dimensionless } from '@vms/vmspro3-core/dist/qty'
import { RiskImpactType } from '@vms/vmspro3-core/dist/systemConsts'
import { Row, Col } from 'antd'

import Heatmap from '../common/Heatmap'
import { Table, NumberInput_Risk } from '../controls'
import SeverityDisplay from './SeverityDisplay'
import { testId } from '../../../test-automation'

const formatValue = (value, format) => {
  if (value === null || value === undefined) return
  return numeral(value?.value).format(`0,0${format === 'cost' ? '' : '.[00]'}${format === 'perf' ? '%' : ''}`)
}

/**
 * Generic "next value" rotator that rotates an enumerable value through
 * it's available choices, "wrapping" at the end.
 *
 * For example, if value is "Blue", and values is ["Red", "Green",
 * "Blue"], this will return the value "Red".
 *
 * @param {*} value - current value of the property.
 * @param {values} values - possible values for the property.
 * @param {*} defaultValue - default value for property (if any).  note that
 *  defaultValue can't meaningfully be undefined.
 *
 * @returns {*} - the next value in the progression.
 */
const getNextValue = (value, values, defaultValue) => {
  const idx = values.indexOf(value)
  const nextValue =
    idx < 0 ? (typeof defaultValue === 'undefined' ? values[0] : defaultValue) : values[(idx + 1) % values.length]
  return nextValue
}

// TODO: this replaces a core utility that is no longer as relevant
// (as written) with the new context shape.  something like this may
// be useful as a utility, but I think a general review/refactor of
// this component and the way context data is used
const getTypeConfig = (context, path) => {
  const dfn = _get(context, path)
  return dfn.values.reduce(
    (config, v) => {
      config.byValue[v.value] = v
      config.values.push(v.value)
      return config
    },
    { ...dfn, byValue: {}, list: dfn.values, values: [] }
  )
}

// TODO: see TODO for getTypeConfig above
const getRiskScaleConfig = (context, path) => {
  const dfn = _get(context, path)
  return dfn.reduce(
    (config, v) => {
      config.byKey[v.key] = v
      config.keys.push(v.key)
      if (v.isDefault) config.defaultKey = v.key
      return config
    },
    { byKey: {}, list: dfn.keys, keys: [] }
  )
}

const RiskImpacts = ({ effectiveRiskContext, propertyPrefix = '', risk, readOnly, updateRisk }) => {
  const cost = effectiveRiskContext.attributes.cost.value
  const time = effectiveRiskContext.attributes.time.value

  // generate enum configs. memoized, only computes when `config` reference updates.
  const { impactTypeConfig, probValueConfig, impactValueConfigs } = useMemo(
    () => ({
      impactTypeConfig: getTypeConfig(effectiveRiskContext, 'types.riskType'),
      probValueConfig: getRiskScaleConfig(effectiveRiskContext, 'riskScales.prob'),
      impactValueConfigs: {
        cost: getRiskScaleConfig(effectiveRiskContext, 'riskScales.cost'),
        time: getRiskScaleConfig(effectiveRiskContext, 'riskScales.time'),
        perf: getRiskScaleConfig(effectiveRiskContext, 'riskScales.perf'),
      },
    }),
    [effectiveRiskContext]
  )

  /**
   * Given a risk attribute key and the current impact type, updates the risk attribute with the next
   * impact type defined in the effective risk context. If the attribute's qualitative impact isn't
   * already set, it is set to the default value.
   * @param {string} attrKey - key of risk attribute to update
   * @param {string} impactType - current impact type
   */
  const setNextImpactType = useCallback(
    (attrKey, impactType) => {
      if (readOnly) return
      const nextImpactType = getNextValue(impactType, impactTypeConfig.values)
      updateRisk({ [`${propertyPrefix}impact.${attrKey}.type`]: nextImpactType })
    },
    [impactTypeConfig, propertyPrefix, readOnly, updateRisk]
  )

  /**
   * Given a risk attribute key, impact type, and the current qualitative value, updates the risk
   * attribute with the next qualitative value in the risk scale for the attribute.
   * @param {string} attrKey - key of risk attribute to update
   * @param {string} impactType - impact type
   * @param {string} impactQual - current qualitative impact key
   */
  const setNextImpactQual = useCallback(
    (attrKey, impactType, impactQual) => {
      // don't do anything if impact type is "none"
      if (readOnly || impactType === impactTypeConfig.nullProxyValue) return
      const { keys, defaultKey } = impactValueConfigs[attrKey]
      const nextImpactQual = getNextValue(impactQual, keys, defaultKey)
      updateRisk({ [`${propertyPrefix}impact.${attrKey}.qual`]: nextImpactQual })
    },
    [impactTypeConfig, impactValueConfigs, propertyPrefix, readOnly, updateRisk]
  )

  /**
   * Updates the quantitiative ML value for a given attribute.
   * @param {number} quantMlValue - quantitative ml value
   * @param {string} attrKey - key of risk attribute to update
   */
  const updateImpactQuant = useCallback(
    (attrKey, quantMlValue) => {
      if (attrKey === 'cost') quantMlValue = new Cost(cost.unit, quantMlValue)
      if (attrKey === 'time') quantMlValue = new Duration(time.unit, quantMlValue)
      if (attrKey === 'perf') quantMlValue = new Dimensionless(quantMlValue)
      updateRisk({ [`${propertyPrefix}impact.${attrKey}.quant.ml`]: quantMlValue })
    },
    [propertyPrefix, cost, time, updateRisk]
  )

  /**
   * Given the current qualitiative probability, updates the risk with the next qualitative value in the
   * risk scale for probability.
   * @param {string} probQual - current qualitative probability value
   */
  const setNextProbQual = useCallback(
    probQual => {
      if (readOnly) return
      const nextProbQual = getNextValue(probQual, probValueConfig.keys)
      updateRisk({ [`${propertyPrefix}prob.qual`]: nextProbQual })
    },
    [probValueConfig, propertyPrefix, readOnly, updateRisk]
  )

  /**
   * Updates the quantitative probability value.
   * @param {number} probQuant - quantitative probability value
   */
  const updateProbQuant = useCallback(
    probQuant => {
      updateRisk({ [`${propertyPrefix}prob.quant`]: probQuant })
    },
    [propertyPrefix, updateRisk]
  )

  /**
   * generate memoized row data – the length of this array determines the height of the probability column cells
   */
  // this can be modified to support configurable attribute data
  const { defaultCostUnit, defaultDurationUnit } = effectiveRiskContext
  const durationLabel = DurationUnitMetadata[defaultDurationUnit].label
  const attrs = useMemo(
    () => [
      { key: 'cost', label: `Cost (${defaultCostUnit})` },
      { key: 'time', label: `Schedule (${durationLabel})` },
      { key: 'perf', label: 'Performance' },
    ],
    [defaultCostUnit, durationLabel]
  )
  const impactRows = useMemo(
    () =>
      attrs.map(({ key, label }) => ({
        key,
        label,
        impact: _get(risk, `${propertyPrefix}impact.${key}`, { type: impactTypeConfig.nullProxyValue }),
      })),
    [attrs, impactTypeConfig, propertyPrefix, risk]
  )

  // format propertyPrefix for use in test-ids
  const testIdPrefix = propertyPrefix ? 'managed-' : ''

  /**
   * returns a table column render function for quantitative impacts cells.
   * @param {boolean} showInput - if true, shows an input when readOnly === false
   * @returns {Function} - ant design table column render function
   */
  function renderImpactQuant(showInput, key) {
    return (value, { impact: { type: impactType }, key: attrKey }) => {
      if (impactType === impactTypeConfig.nullProxyValue) return null

      return !showInput || readOnly ? (
        <span {...testId(`${testIdPrefix}risk-${attrKey}-impact-${key}`)} style={style.cellReadOnly}>
          {formatValue(value, attrKey)}
        </span>
      ) : (
        <NumberInput_Risk
          {...testId(`${testIdPrefix}risk-${attrKey}-impact-${key}`)}
          {...style.cellInput}
          allowNull={false}
          trimDecimals
          align="right"
          isPercentage={attrKey === 'perf'}
          decimalPlaces={attrKey === 'cost' ? 0 : 2}
          value={value?.value}
          onChange={v => updateImpactQuant(attrKey, v)}
        />
      )
    }
  }

  // column definitions
  const columns = [
    {
      // probability cells render data from outside the scope of the table row data source array. each
      // cell's render method returns a props object with a rowSpan configuration and the children to
      // render. if they are rendered by the first table row (index === 0), they are given a rowSpan
      // height to match the height of the table, and subsequent rows (index > 0) are given a rowSpan
      // of 0.
      title: 'Probability',
      dataIndex: 'prob',
      children: [
        {
          title: 'Qual',
          dataIndex: ['prob', 'qual'],
          align: 'center',
          width: 70,
          onCell: (_, rowIdx) => ({
            ...testId(`${testIdPrefix}risk-prob-qual-impact`),
            onClick: () => setNextProbQual(_get(risk, `${propertyPrefix}prob.qual`)),
            rowSpan: rowIdx === 0 ? impactRows.length : 0,
            style: {
              ...style.impactTypeCell(readOnly),
              ...style.removeHoverHighlight,
            },
          }),
          render: () => _get(risk, `${propertyPrefix}prob.qual`),
        },
        {
          title: 'Quant',
          dataIndex: ['prob', 'quant'],
          align: 'center',
          width: 70,
          onCell: (_, rowIdx) => ({
            style: style.removeHoverHighlight,
            rowSpan: rowIdx === 0 ? impactRows.length : 0,
          }),
          render: () => {
            const value = _get(risk, `${propertyPrefix}prob.quant`)
            return readOnly ? (
              // formatValue expects a Quantity object with a value property, and
              // prob.quant is a plain numeric value
              <span style={style.cellReadOnly}>{formatValue({ value }, 'perf')}</span>
            ) : (
              <NumberInput_Risk
                {...testId(`${testIdPrefix}risk-prob-quant-impact`)}
                {...style.cellInput}
                allowNull={false}
                isPercentage
                trimDecimals
                align="center"
                value={value}
                onChange={updateProbQuant}
              />
            )
          },
        },
      ],
    },
    {
      dataIndex: 'label',
      width: 160,
      render: label => <span style={style.cellReadOnly}>{label}</span>,
    },
    {
      dataIndex: ['impact', 'type'],
      width: 120,
      onCell: ({ key, impact: { type } }) => ({
        style: style.impactTypeCell(readOnly),
        onClick: () => setNextImpactType(key, type),
      }),
      render: impactType => impactTypeConfig.byValue[impactType ?? impactTypeConfig.nullProxyValue]?.label.long,
    },
    {
      title: 'Impacts',
      dataIndex: 'impact',
      children: [
        {
          title: 'Qual',
          dataIndex: ['impact', 'qual'],
          align: 'center',
          width: 60,
          onCell: ({ key, impact: { type, qual } }) => ({
            ...testId(`${testIdPrefix}risk-${key}-qual-impact`),
            style: style.impactTypeCell(readOnly, type === impactTypeConfig.nullProxyValue),
            onClick: () => setNextImpactQual(key, type, qual),
          }),
          render: (qual, { impact }) => (impact.type === impactTypeConfig.nullProxyValue ? 'N/A' : qual),
        },
        ...[
          { title: 'Min', key: 'min', showInput: false },
          { title: 'Most Likely', key: 'ml', showInput: true },
          { title: 'Max', key: 'max', showInput: false },
          { title: 'Expected Value', key: 'ev', showInput: false },
        ].map(({ title, key, showInput }) => ({
          title,
          dataIndex: ['impact', 'quant', key],
          align: 'right',
          width: 110,
          render: renderImpactQuant(showInput, key),
        })),
      ],
    },
  ]

  const probability = _get(risk, `${propertyPrefix}prob.qual`)
  const heatmapProps = attrs.reduce((cfg, { key: attrKey }) => {
    const { type, qual } = _get(risk, `${propertyPrefix}impact.${attrKey}`, {})
    if (type && type !== impactTypeConfig.nullProxyValue) _set(cfg, `${type}.${attrKey}Impact`, qual)
    return cfg
  }, {})

  const totalSeverity = _get(risk, `${propertyPrefix}severity.total`)

  return (
    <div>
      <Table columns={columns} dataSource={impactRows} rowKey="key" size="default" />
      <Row style={style.heatmapContainer}>
        <Col span={8} style={style.totalSeverityCell}>
          <div>
            <h3>Total Severity: </h3>
            <SeverityDisplay severity={totalSeverity} />
          </div>
        </Col>
        <Col span={8}>
          <Heatmap
            impactType={RiskImpactType.THREAT}
            probability={probability}
            {...heatmapProps[RiskImpactType.THREAT]}
          />
        </Col>
        <Col span={8}>
          <Heatmap
            impactType={RiskImpactType.OPPORTUNITY}
            probability={probability}
            {...heatmapProps[RiskImpactType.OPPORTUNITY]}
          />
        </Col>
      </Row>
    </div>
  )
}

const matchInputPadding = '6px 12px 5px'
const style = {
  cellInput: css({
    ':not(:hover), :not(:focus)': {
      borderColor: 'transparent',
    },
  }),
  cellReadOnly: {
    display: 'inline-block',
    padding: matchInputPadding,
    width: '100%',
  },
  heatmapContainer: {
    marginTop: '24px',
  },
  impactTypeCell: (readOnly, impactTypeNull) => ({
    color: impactTypeNull ? '#c5c5c5' : 'inherit',
    cursor: readOnly ? 'default' : 'pointer',
    fontWeight: '700',
    padding: matchInputPadding,
    userSelect: 'none',
  }),
  removeHoverHighlight: {
    background: 'inherit',
  },
  totalSeverityCell: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '36px',
  },
}

export default RiskImpacts
