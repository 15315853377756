/**
 * Depth-first search traversal of a tree-like structure.
 */
export function traverseDFS<T>(
  node: T,
  children: (node: T) => readonly T[] | undefined,
  visit: (node: T, path: T[]) => void,
  algorithm: 'Pre' | 'Post' = 'Pre',
  path: T[] = []
) {
  if (algorithm === 'Pre') visit(node, path)
  children(node)?.forEach(child => traverseDFS(child, children, visit, algorithm, [...path, node]))
  if (algorithm === 'Post') visit(node, path)
}
