import React, { useMemo } from 'react'
import { Renderer, CellValue } from 'react-table'

export function EntityNameRenderer({ value, row }: CellValue): Renderer<CellValue> | null {
  const color = row?.original?.color

  const dotStyle = useMemo(
    () =>
      color
        ? {
            display: 'inline-block',
            backgroundColor: color,
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            marginRight: '8px',
          }
        : { display: 'none' },
    [color]
  )

  return (
    <span>
      <div style={dotStyle} />
      {value}
    </span>
  )
}
