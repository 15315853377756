import { z } from 'zod'

import { createId } from '../../idUtils'
import { Account, CompanyContact } from '../../types/account'
import { UserAccountRecord } from '../../types/user'

const commonIdRE = /^[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?$/i
const nonEmptyString = z.string().regex(/\S/)

export const CreateAccountType = 'Create Account'
export type CreateAccountAction = {
  module: 'System'
  type: typeof CreateAccountType
  payload: Account
  meta: {
    accountId: string
    user: UserAccountRecord
  }
}
export function createAccountPreflight(): CreateAccountAction {
  return {
    module: 'System',
    type: 'Create Account',
    payload: {
      id: 'preflight',
      name: 'preflight',
      commonId: 'preflight',
      createdBy: {
        id: 'preflight',
        email: 'preflight@preflight.com',
      },
      status: 'Pending',
      contacts: {
        billing: {
          attn: {
            name: 'preflight',
            title: 'preflight',
            email: 'preflight@preflight.com',
            phone: '503-555-5555',
          },
          company: 'preflight',
          address1: 'preflight',
          address2: '',
          city: 'preflight',
          state: 'preflight',
          zip: '99999',
        }
      },
      quotas: {
        users: { allowed: 0, used: 0 },
        projects: { allowed: 0, used: 0 },
        decisions: { allowed: 0, used: 0 },
      },
      productId: null,
      integrations: {},
      createdOn: Date.now(),
    },
    meta: {
      accountId: 'preflight',
      user: {
        accountId: 'preflight',
        userId: 'preflight',
        fullName: 'preflight',
        policyIds: [],
        email: 'preflight@preflight.com',
        eulas: {},
        createdAt: Date.now(),
        updatedAt: Date.now(),
      }
    },
  }
}
export function createAccount({
  name,
  billingContact,
  commonId,
  user,
}: {
  name: string,
  commonId: string,
  billingContact: CompanyContact,
  user: Omit<UserAccountRecord, 'accountId'>
}): CreateAccountAction {
  const id = createId()
  const account: Account = {
    id,
    name,
    commonId,
    createdBy: {
      id: user.userId,
      email: user.email,
    },
    status: 'Pending',
    contacts: {
      // billing: billingContact,
      billing: {
        attn: {
          name: billingContact.attn.name,
          email: billingContact.attn.email,
          title: billingContact.attn.title ?? null,
          phone: billingContact.attn.phone ?? null,
        },
        company: billingContact.company,
        address1: billingContact.address1,
        address2: billingContact.address2 ?? null,
        city: billingContact.city,
        state: billingContact.state,
        zip: billingContact.zip,
      },
    },
    // quotas are all zero until account is updated with a product
    quotas: {
      users: { allowed: 0, used: 0 },
      projects: { allowed: 0, used: 0 },
      decisions: { allowed: 0, used: 0 },
    },
    productId: null,
    integrations: {},
    createdOn: Date.now(),
  }

  const accountUser = {
    accountId: id,
    ...user,
  }

  z.object({
    account: z.object({
      id: nonEmptyString,
      name: nonEmptyString,
      commonId: z.string().regex(commonIdRE),
      status: z.literal('Pending'),
      createdBy: z.object({
        id: nonEmptyString,
        email: z.string().email()
      }).strict(),
      contacts: z.object({
        billing: z.object({
          attn: z.object({
            name: nonEmptyString,
            email: nonEmptyString,
            title: z.optional(z.nullable(z.string())),
            phone: z.optional(z.nullable(z.string())),
          }),
          company: nonEmptyString,
          address1: nonEmptyString,
          address2: z.optional(z.nullable(z.string())),
          city: nonEmptyString,
          state: nonEmptyString,
          zip: nonEmptyString,
        }).strict(),
      }),
      quotas: z.object({
        users: z.object({ allowed: z.literal(0), used: z.literal(0) }),
        projects: z.object({ allowed: z.literal(0), used: z.literal(0) }),
        decisions: z.object({ allowed: z.literal(0), used: z.literal(0) }),
      }),
      productId: z.null(),
      integrations: z.object({
        // empty, may contain optional stripe integration later
      }).strict(),
      createdOn: z.number(),
    }).strict(),
    // TODO: we should have an "account user validator" that we *re*use here,
    // insetad of just duplicating...that will be one of the subjects of
    // https://vms.atlassian.net/browse/VP3-2760
    accountUser: z.object({
      accountId: nonEmptyString,
      disabled: z.optional(z.boolean()),
      userId: nonEmptyString,
      email: z.string().email(),
      fullName: nonEmptyString,
      createdAt: z.number(),
      updatedAt: z.number(),
      // TODO: i believe these should all be simply optional (not nullable), but will
      // validate and fix correctly as part of VP3-2760 (see above)
      shortName: z.optional(z.nullable(nonEmptyString)),
      initials: z.optional(z.nullable(nonEmptyString)),
      phone: z.optional(z.nullable(z.string())),
      policyIds: z.array(nonEmptyString),
      eulas: z.object({}).strict()
    }).strict()
  }).parse({
    account,
    accountUser,
  })

  const action: CreateAccountAction = {
    module: 'System',
    type: CreateAccountType,
    payload: account,
    meta: {
      accountId: id,
      user: accountUser,
    },
  }
  return action
}
createAccount.toString = () => CreateAccountType

