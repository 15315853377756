/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, MenuProps } from 'antd'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import { signOut } from '../../../services/authService'
import { UserPreferencesModal } from './UserPreferencesModal'
import { testId } from '../../../test-automation'
import { useAppSelector } from '../../../redux'
import { useUserAccounts } from '../../../context'
import { selectCurrentUserName } from '../../../redux/selectors'
import { useModalState } from '../../../hooks/useModalState'

function useDropdownVisible() {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const setDropdownHidden = useCallback(() => setDropdownVisible(false), [])

  return {
    dropdownVisible,
    setDropdownHidden,
    handleVisibleChange: setDropdownVisible,
  }
}

export function UserMenu({ accountCommonId }: { accountCommonId?: string }) {
  const userName = useAppSelector(selectCurrentUserName)
  const { userAccounts } = useUserAccounts()
  const navigate = useNavigate()

  const { modal, hideModal, showModal } = useModalState()

  const { dropdownVisible, handleVisibleChange, setDropdownHidden } = useDropdownVisible()

  const items: MenuProps = {
    theme: 'dark',
    onClick: setDropdownHidden,
    items: [
      {
        label: 'Select Account',
        key: 'select-account',
        children: [
          ...userAccounts
            .filter(ua => !ua.userRecord.disabled)
            .map(({ account }) => ({
              label: account.name,
              key: account.commonId,
              onClick: account.commonId === accountCommonId ? undefined : () => navigate('/' + account.commonId),
            })),
          { type: 'divider' },
          {
            label: 'Account Management',
            key: 'account-management',
            onClick: () => navigate('/'),
          },
        ],
      },
      {
        label: 'Preferences',
        key: 'preferences',
        onClick: () => showModal(<UserPreferencesModal hideModal={hideModal} />),
      },
      {
        label: 'Sign Out',
        key: 'logout',
        onClick: signOut,
      },
    ],
  }

  return (
    <>
      {modal}
      <Dropdown
        // overlay={<DropdownOverlay setDropdownHidden={setDropdownHidden} accountCommonId={accountCommonId} />}
        menu={items}
        trigger={['click']}
        open={dropdownVisible}
        onOpenChange={handleVisibleChange}
      >
        <div css={style.icon}>
          <Avatar size="small" icon={<UserOutlined />} style={style.avatar} />
          <span {...testId('headerUsername')} style={style.avatarName}>
            {userName}
          </span>
        </div>
      </Dropdown>
    </>
  )
}

const style = {
  avatar: {
    background: Color.BLUE_LIGHT,
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '16px',
  },
  avatarName: {
    fontSize: '14px',
  },
  icon: {
    height: '100%',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'color 0.3s',
    ':hover': {
      color: Color.BLUE_LINK,
    },
  },
  menu: {
    width: '228px',
  },
}
