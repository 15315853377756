import { Policy } from '../types'
import { createId } from '../idUtils'

const CreatePolicyActionType = 'Create Policy'
export interface CreatePolicyAction {
  module: 'System',
  type: typeof CreatePolicyActionType,
  payload: Policy,
  meta: {
    authz?: {
      resources?: string[],
    },
  },
}
/**
 * Create a policy.
 */
const create = function policyCreateActionCreator(
  payload: CreatePolicyAction['payload'],
  meta: CreatePolicyAction['meta'],
): CreatePolicyAction {
  const type = CreatePolicyActionType



  if(!payload.accountId) throw new Error(`${type}: missing accountId`)
  if(!payload.policyId) payload.policyId = createId()
  if(payload.policyType === 'RESOURCE') {
    // if policyType === RESOURCE, extends, Principal, and resourceId are required
    if(!payload.extends) throw new Error(`${type}: missing extends`)
    if(!payload.Principal) throw new Error(`${type}: missing Principal`)
    if(!payload.resourceId) throw new Error(`${type}: missing resourceId`)
    if(!payload.resourceType) throw new Error(`${type}: missing resourceType`)

    if(!meta.authz) meta.authz = {}
    if(!meta.authz.resources) meta.authz.resources = []
    meta.authz.resources.push(payload.resourceId)
  } else if(!payload.Statement) {
    // if policyType !== RESOURCE, Statement is required
    throw new Error(`${type}: missing Statement`)
  }

  return {
    module: 'System',
    type,
    payload,
    meta,
  }
}
create.toString = () => CreatePolicyActionType

const UpdatePolicyActionType = 'Update Policy'
export interface UpdatePolicyAction {
  module: 'System',
  type: typeof UpdatePolicyActionType,
  payload: {
    Principal?: string[],
  },
  meta: {
    accountId: string,
    ancestry: string,
    extends: string,
    policyId: string,
    policyType: 'RESOURCE', // we don't currently allow updating any other policy type
    resourceId: string,
    resourceType: string
    authz?: {
      resources?: string[],
    },
  }
}
/**
 * Update a policy.
 *
 * @param {Object} payload - action payload
 * @param {Object} meta - action metadata
 *  @param {String} meta.policyId - the ID of the policy to update
 *  @param {String} meta.resourceId - The ID of the resource to attach this policy to,
 *    e.g. "b65G1FgkC7B7pytQMEXCpd"
 *
 * @returns {object} Action with type 'policy::update'.
 */
const update = function policyUpdateActionCreator(
  payload: UpdatePolicyAction['payload'],
  meta: UpdatePolicyAction['meta'],
): UpdatePolicyAction {
  const type = UpdatePolicyActionType

  if(!meta.accountId) throw new Error(`${type}: missing accountId`)
  if(!meta.policyId) throw new Error(`${type}: missing policyId`)
  if(meta.policyType === 'RESOURCE') {
    if(!meta.resourceId) throw new Error(`${type}: missing resourceId`)
    if(!meta.resourceType) throw new Error(`${type}: missing resourceType`)

    if(!meta.authz) meta.authz = {}
    if(!meta.authz.resources) meta.authz.resources = []
    meta.authz.resources.push(meta.resourceId)
  }

  return {
    module: 'System',
    type,
    payload,
    meta,
  }
}
update.toString = () => UpdatePolicyActionType

export default {
  create,
  update,
}
