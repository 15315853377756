/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { UpdateDecisionAction, updateDecision as updateDecisionAC } from '@vms/vmspro3-core/dist/actions/decision'
import { useAppDispatch } from '../../../../redux'
import { useDecision } from '../../../../redux/hooks'
import { ValuemetricsTable } from '../Valuemetrics/ValuemetricsTable'
import { Button, Drawer, Switch } from 'antd'
import { BarChartOutlined } from '@ant-design/icons'
import { Option } from '@vms/vmspro3-core/dist/nextgen/options'

import style from './ResultsPage.module.css'
import { ValueGraphContainer } from '../Valuemetrics/ValueGraphContainer'
import { SensitivityAnalysis } from '../Valuemetrics/SensitivityAnalysis'
import { ValuemetricsChart } from '../Valuemetrics/ValuemetricsChart'
import { PerformanceChart } from '../Valuemetrics/PerformanceChart'
import { match } from 'ts-pattern'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useTour } from '../../../../hooks/useTour'

interface OptionPodiumSpotProps {
  option?: Option
  place: 'first' | 'second' | 'third'
}
function OptionPodiumSpot(props: OptionPodiumSpotProps) {
  const { option, place } = props
  const placeStyle = match<'first' | 'second' | 'third', CSSProperties>(place)
    .with('first', () => ({}))
    .with('second', () => ({ marginTop: '5rem' }))
    .with('third', () => ({ marginTop: '8rem' }))
    .exhaustive()
  const trophyColor = match<'first' | 'second' | 'third', string>(place)
    .with('first', () => '#c9b037')
    .with('second', () => '#d7d7d7')
    .with('third', () => '#ad8a56')
    .exhaustive()
  return (
    <>
      <div style={{ padding: '2rem 4rem', background: '#eee', ...placeStyle, textAlign: 'center' }}>
        <FontAwesomeIcon icon={faTrophy as any} size="2x" color={trophyColor} />
        <div>{option ? <h3>{option.name}</h3> : <h3>(none)</h3>}</div>
      </div>
    </>
  )
}

interface OptionsPodiumProps {
  options: Option[]
}
function OptionsPodium(props: OptionsPodiumProps) {
  const options = props.options
    .filter(o => o.value !== null)
    .sort(opValueSOrter)
    .slice(0, 3)
  return (
    <>
      {options.length === 0 && (
        <p>
          <i>There isn't enough information to recommend any option at this time.</i>
        </p>
      )}
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', columnGap: '20px' }}>
        <OptionPodiumSpot place="second" option={options[1]} />
        <OptionPodiumSpot place="first" option={options[0]} />
        <OptionPodiumSpot place="third" option={options[2]} />
      </div>
    </>
  )
}

const opValueSOrter = (a: Option, b: Option) => {
  if (a.value === null && b.value === null) return 0
  if (a.value === null) return +1
  if (b.value === null) return -1
  return b.value - a.value
}

interface ResultsPageProps {
  decisionId: string
}
export function ResultsPage(props: ResultsPageProps) {
  const { decisionId } = props
  const decision = useDecision(decisionId)
  const [hasBaseline, setHasBaseline] = useState(false)

  const dispatch = useAppDispatch()
  const updateDecision = useCallback(
    (payload: UpdateDecisionAction['payload']) => dispatch(updateDecisionAC(decisionId, payload)),
    [decisionId, dispatch]
  )
  const [optionsSortOrder, setOptionsSortOrder] = useState(() => decision.options.all.map(option => option.id))
  const sortedOptions = useMemo(
    () =>
      optionsSortOrder
        .map(optionId => decision.options.byId(optionId))
        .filter((option): option is Option => Boolean(option)),
    [optionsSortOrder, decision.options]
  )
  const [valueGraphOptionId, setValueGraphOptionId] = useState<string>()
  const updateBaselineOptionId = useCallback(
    (optionId: string | undefined) => updateDecision({ baselineOptionId: optionId ?? null }),
    [updateDecision]
  )
  const [showSensitivityAnalysis, setShowSensitivityAnalysis] = useState<boolean>(false)

  const { tour } = useTour('Results Page', [
    {
      target: "[data-tour-id='Results Podium']",
      placement: 'top',
      content: (
        <>
          <p>
            Once there's enough data, this section will list the three best options based on the data provided by
            you and the participants you recruited!
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Results Details']",
      placement: 'auto',
      content: (
        <>
          <p>
            Here you can find detailed information about how OptionLab has assigned value to each of your options.
          </p>
          <p>
            In OptionLab, a <i>score</i> is always a 0-10 value, where 10 is the best. Scores sometimes move in an
            opposite direction of the thing they represent. For example, if "cost" is money you're spending, a low
            cost will result in a high cost scores. Because high scores are always better, you can quickly see how
            your options stack up.
          </p>
          <p>
            If you want to compare one option against all other options, you can toggle the "Has Baseline" switch.
            You can then set any option to be the baseline to see the performance of all other options in relation
            to that option.
          </p>
        </>
      ),
      styles: {
        tooltip: {
          width: 900,
        },
      },
    },
    {
      target: "[data-tour-id='Results Baseline Toggle']",
      placement: 'auto',
      content: (
        <>
          <p>
            If you want to compare one option against all other options, you can toggle the "Has Baseline" switch.
            You can then set any option to be the baseline to see the performance of all other options in relation
            to that option.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Sensitivity Analysis']",
      placement: 'auto',
      content: (
        <>
          <p>
            Sensitivity analysis allows you to experiment with different criteria prioritizations to see if it
            would effect the outcome of the decision.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Valuemetrics Chart']",
      placement: 'auto',
      content: (
        <>
          <p>
            This chart shows the individual scores that comprise value...it's a quick visual reference of what's in
            the table.
          </p>
        </>
      ),
    },
    {
      target: "[data-tour-id='Performance Chart']",
      placement: 'auto',
      content: (
        <>
          <p>
            This chart shows the contribution of each attribute of performance to each option. Use the controls
            below the chart to see how each criteria contributes to the total performance of each option.
          </p>
        </>
      ),
    },
  ])

  return (
    <>
      {tour}
      <div data-tour-id="Results Podium">
        <h1>Best Options</h1>
        <OptionsPodium options={decision.options.all} />
      </div>
      <div data-tour-id="Results Details">
        <h1>Details</h1>
        <div className={style.controls}>
          <label data-tour-id="Results Baseline Toggle">
            Has Baseline:&nbsp;
            <Switch checked={hasBaseline} onChange={() => setHasBaseline(hasBaseline => !hasBaseline)} />
          </label>
          <Button
            data-tour-id="Sensitivity Analysis"
            type="primary"
            icon={<BarChartOutlined />}
            onClick={() => setShowSensitivityAnalysis(true)}
          >
            Sensitivity Analysis
          </Button>
        </div>
        <ValuemetricsTable
          decisionId={decisionId}
          hasBaseline={hasBaseline}
          options={decision.options}
          setOptionsSortOrder={setOptionsSortOrder}
          setValueGraphOptionId={setValueGraphOptionId}
          updateBaselineOptionId={updateBaselineOptionId}
        />
        <div className={style.chartRow}>
          <div className={style.chartGroup} data-tour-id="Valuemetrics Chart">
            <ValuemetricsChart options={sortedOptions} valueFunctionExpr={decision.valueFunctionExpr} />
          </div>
          <div className={style.chartGroup} data-tour-id="Performance Chart">
            <PerformanceChart criteria={decision.criteria.all} options={sortedOptions} />
          </div>
        </div>
      </div>

      <Drawer onClose={() => setValueGraphOptionId(undefined)} open={!!valueGraphOptionId} width="100%">
        {valueGraphOptionId && <ValueGraphContainer decisionId={decisionId} optionId={valueGraphOptionId} />}
      </Drawer>
      <Drawer onClose={() => setShowSensitivityAnalysis(false)} open={showSensitivityAnalysis} width="100%">
        <SensitivityAnalysis decisionId={decisionId} />
      </Drawer>
    </>
  )
}
