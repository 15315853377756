import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Duration, DurationUnit } from '@vms/vmspro3-core/dist/qty'
import { createOverlayRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core/dist'

import FormModal from './FormModal'
import RiskEntityIdentityFormFields from '../components/RiskEntityIdentityFormFields'

import { getEntityTypeLabel } from '../utils/getEntityTypeLabel'
import { effectiveRiskContextFromAncestry } from '../selectors'
import { useModalData } from '../RiskModalContext'

const actionCreatorByEntityType = {
  [EntityType.PORTFOLIO]: actions.riskPortfolio.create,
  [EntityType.PROGRAM]: actions.riskProgram.create,
  [EntityType.PROJECT]: actions.riskProject.create,
}

/**
 * Create risk project details (name, description, cost, time).  Note that
 * cost and time need to be synchronized with the risk context.
 *
 * The following properties must be included in the modal data:
 *   ancestry - the ancestry of the entity being created. use joinAncestry(parent.ancestry, parent.id)
 */
const RiskEntityCreateModal = () => {
  const { ancestry, entityType } = useModalData(RiskEntityCreateModal.id)

  const effectiveRiskContext = useSelector(effectiveRiskContextFromAncestry(ancestry))
  const entityTypeLabel = getEntityTypeLabel(entityType, ancestry)

  const dispatch = useDispatch()
  const onOk = values => {
    if (entityType === EntityType.PROJECT) {
      values.commonId = values.commonId?.trim()
      values.name = values.name.trim()
      // TODO: see https://vms.atlassian.net/browse/VP3-2220
      values.time = Duration.convert(values.time, DurationUnit.Months)
      values.riskContext = createOverlayRiskContext(effectiveRiskContext, values.cost, values.time)
    } else {
      values.riskContext = createOverlayRiskContext(effectiveRiskContext)
    }

    const actionCreator = actionCreatorByEntityType[entityType]
    const entityCreateAction = actionCreator({ ...values, ancestry })
    dispatch(entityCreateAction)
  }

  return (
    <FormModal modalId={RiskEntityCreateModal.id} onOk={onOk}>
      <RiskEntityIdentityFormFields
        entityType={entityType}
        entityTypeLabel={entityTypeLabel}
        ancestry={ancestry}
      />
    </FormModal>
  )
}
RiskEntityCreateModal.id = 'RiskEntityCreateModal'

export default RiskEntityCreateModal
