import { useAuth } from '../../../context'

function ImpersonatingUser() {
  const { authUser: assumedUser } = useAuth()

  return (
    <h3 style={style.message}>
      You are impersonating user &quot;{assumedUser?.fullName || assumedUser?.email}&quot; (id: {assumedUser?.id})!
    </h3>
  )
}

const style = {
  message: {
    margin: '0',
  },
}

export default ImpersonatingUser
