import { createContext, useContext } from 'react'
import asSet from 'arraysetjs'
import { produce } from 'immer'

interface VisibleModalsState {
  stack: string[]
  byId: { [modalId: string]: Record<string, unknown> }
}
interface RiskModalContextValue {
  showModal: (modalId: string, modalData: Record<string, unknown>) => void
  hideModal: (modalId: string) => void
  byId: { [modalId: string]: Record<string, unknown> }
}
export const RiskModalContext = createContext<RiskModalContextValue | undefined>(undefined)

export interface ShowModalAction {
  type: 'ShowModal'
  modalData: Record<string, unknown>
  modalId: string
}
export interface HideModalAction {
  type: 'HideModal'
  modalId: string
}

export const visibleModalsReducer = produce(
  (state: VisibleModalsState, action: ShowModalAction | HideModalAction) => {
    switch (action.type) {
      case 'ShowModal': {
        const { modalId, modalData } = action
        state.byId[modalId] = modalData
        asSet.mutable(state.stack).add(modalId)
        break
      }
      case 'HideModal': {
        const { modalId } = action
        asSet.mutable(state.stack).remove(modalId)
        delete state.byId[modalId]
        break
      }
      default: {
        return state
      }
    }
  }
)

export const visibleModalsInitialState: VisibleModalsState = {
  stack: [],
  byId: {},
}

export function useShowModal() {
  const context = useContext(RiskModalContext)
  if (!context) {
    throw new Error('useShowModal can only be used within a child of RiskModalProvider')
  }
  return context.showModal
}

export function useHideModal() {
  const context = useContext(RiskModalContext)
  if (!context) {
    throw new Error('useHideModal can only be used within a child of RiskModalProvider')
  }
  return context.hideModal
}

export function useModalData(modalId: string) {
  const context = useContext(RiskModalContext)
  if (!context) {
    throw new Error('useModalData can only be used within a child of RiskModalProvider')
  }
  return context.byId[modalId]
}
