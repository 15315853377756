import { ReactNode } from 'react'

import { UserGlobalRecord_App } from '@vms/vmspro3-core/dist/types'

import { createTypedContext } from '../utils/createTypedContext'
import { AssumedAuthz } from '../utils/authzUtils'

interface AuthContextValue {
  authUser: UserGlobalRecord_App
  authUserId: string
  assumedAuthz: AssumedAuthz | undefined
}

const [useAuth, Provider] = createTypedContext<AuthContextValue>('AuthContext')

interface AuthContextProps extends AuthContextValue {
  children: ReactNode
}
export function AuthProvider({ authUser, authUserId, assumedAuthz, children }: AuthContextProps) {
  return <Provider value={{ authUser, authUserId, assumedAuthz }}>{children}</Provider>
}
export { useAuth }
