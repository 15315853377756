import { createSelector } from 'reselect'
import { getEffectiveRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'

import { LoadingStatus } from '../../../utils/appConsts'

export const ancestorEntitiesFromAncestry = ancestry =>
  createSelector(
    state => state.riskEntities.byId,
    byId => splitAncestry(ancestry).map(id => byId[id])
  )

export const riskContextsFromAncestry = ancestry =>
  createSelector(ancestorEntitiesFromAncestry(ancestry), ancestorEntities =>
    ancestorEntities.map(e => e.riskContext)
  )

export const entityById = entityId => state => state.riskEntities.byId[entityId]

export const effectiveRiskContextFromAncestry = ancestry =>
  createSelector(riskContextsFromAncestry(ancestry), getEffectiveRiskContext)

// TODO: this should replace all other ways of fetching risk context; it should
// be audited for performance, but it's much clearer and easier to use
export const effectiveRiskContextFromEntityId = entityId =>
  createSelector(
    state => state.riskEntities.byId,
    byId => {
      const entity = byId[entityId]
      if (entity?.loadingStatus !== LoadingStatus.Loaded) return undefined
      const ancestors = splitAncestry(entity.ancestry).map(entityId => byId[entityId])
      const riskContexts = [...ancestors, entity].map(entity => entity.riskContext).filter(Boolean) // risks will not have a risk context
      return getEffectiveRiskContext(riskContexts)
    }
  )

export const getRiskEntityLoadingStatus = entityId => state =>
  state.riskEntities.byId[entityId]?.loadingStatus ?? LoadingStatus.NotLoaded
