import _sum from 'lodash/sum'
import _keyBy from 'lodash/keyBy'
import _filter from 'lodash/filter'
import _mapValues from 'lodash/mapValues'
import _meanBy from 'lodash/meanBy'
import _uniq from 'lodash/uniq'

import { Cost, Duration, Quantity } from '../qty'
import { Criteria } from './criteria'
import { ParticipationSession } from './participationSession'
import { valueExpressionToValueGraphExpression, ValueNode } from './valueGraph/valueNode'
import { PerformanceValueNode } from './valueGraph/performanceValueNode'
import { ValueFunctionLibrary } from '../valuemetrics/valuemetrics'
import * as valueFormula from '../valuemetrics/valueFormula'
import { Html, Rating} from '../types'
import { ContextPrioritization, prioritizeContext } from './prioritization/quickPrioritization'
import { safeMult, safeSum } from './valueGraph/util'
import { augmentWithStats, NumberWithStats } from '../stats/quartiles'
import { number } from 'zod'

export function scoreQuantAttr(
  value: number | undefined,
  attrConfig: QuantAttrConfig,
  attrStats: { sum: number, min: number, max: number },
) {
  if(value === undefined) return undefined
  const range = attrStats.max - attrStats.min
  switch(attrConfig.scoreTransform) {
    case 'Normalize': return 10 * value / attrStats.sum
    case 'NormalizeComplement': return 10 - 10 * value / attrStats.sum
    case 'RangeNormalize': return 10 * (value - attrStats.min) / range
    case 'RangeNormalizeComplement': return 10 - 10 * (value - attrStats.min) / range
    default: throw new Error(`unrecognized quant attr transform: ${attrConfig.scoreTransform}`)
  }
}

/**
 * This represents the serializable data that describes an option.  This can be used to
 * type database records, JSON data, or GQL interfaces.  Ideally, it would be generated
 * by an ORM.
 *
 * Note that it is declared as a type instead of an interface so it is compatible
 * with Record<string, unknown>.
 */
export type OptionData = {
  id: string
  name: string
  abbrev: string
  description: Html
  commonId: string | null
  color: string
  cost: Cost | null
  time: Duration | null
  outcomes?: OptionOutcomeData[]
  tagsJson?: string
}

export type OptionOutcomeData = {
  id: string
  name: string
  abbrev: string
  description: Html
  color: string
}

type SubjectRatingData = {
  all: Rating[]
  byCriterionId: Record<string, {
    /**
     * The average rating for this criterion/option combination.  Note that if the option has outcomes,
     * this represents the probability-weighted average of the outcome ratings.
     */
    avg?: number
    byParticipantId: Record<string, {
      /**
       * This is the numeric value of the rating (same as valueWithStats.value).
       */
      value?: number
      /**
       * This is the direct rating for the criterion/option (if any).  Note that this will not be relevant
       * if there are outcomes (which will override the value).
       */
      rating?: Rating
      /**
       * The value of the rating (first element of the ratingVector), along with statistical markers within
       * its cohort (ratings from other participants for this option/criterion combination).  Note that if
       * this option has outcomes, this value will represent the probability-weighted combination of outcome
       * ratings.
       */
      valueWithStats?: NumberWithStats
    }>,
  }>
}

export interface Option {
  /**
   * The Options instance to which this Option belongs.
   */
  get options(): Options
  get data(): OptionData
  get id(): string
  get name(): string
  get abbrev(): string
  get description(): Html
  get commonId(): string | null
  get color(): string
  get tags(): string[]
  get criteria(): Criteria | undefined
  /**
   * Note that this replaces "cost" and "time".  An option can (and usually will)
   * still have cost and time, but they will be accessed via `quant.C` and `quant.T`.
   * This is setting us up for the next stage of evaluation where the quantitative
   * (intrinsic) attributes are configurable for a decision.
   */
  get quantAttrs(): Record<string, Quantity | null>
  /**
   * Scores for quantitative (intrinsic) attributes.
   */
  get quantScores(): Record<string, number | null>
  /**
   * Change in quantitative (intrinsic) score (if a baseline is set).
   */
  get changeInQuantScores(): Record<string, number | null>
  /**
   * Participant ratings for this option for the current participation session
   * (see Options#useParticipationSession).
   */
  get ratings(): Rating[]
  /**
   * TODO: this will eventually replace 'get ratings' above
   */
  getRatings({ ratingFractionDigits }?: { ratingFractionDigits?: number }): SubjectRatingData
  get performanceGraph(): PerformanceValueNode | undefined
  get value(): number | null
  get valueGraph(): ValueNode
  getValueGraph(perf: PerformanceValueNode | null): ValueNode
  get outcomes(): Outcomes
  readonly changeInPerf: number | null
  readonly changeInValue: number | null
}

class OptionInternal implements Option {
  _options: Options
  _criteria?: Criteria
  _data: OptionData
  _quant: Record<string, Quantity | null>
  _participationSession?: ParticipationSession
  _outcomePs?: ParticipationSession
  _tags: string[]
  get options() { return this._options }
  get data() { return this._data }
  get id() { return this._data.id }
  get name() { return this._data.name }
  get abbrev() { return this._data.abbrev || '' }
  get description() { return this._data.description }
  get commonId() { return this._data.commonId }
  get color() { return this._data.color }
  get tags() { return this._tags }
  get quantAttrs() { return this._quant }
  get criteria() { return this._criteria }
  get quantScores() {
    const { quantAttributes } = this._options
    return Object.fromEntries<number | null>(quantAttributes.map(attrConfig => {
      const qv = this._quant[attrConfig.symbol]
      if(qv === null) return [attrConfig.symbol, null]
      const stats = this._options.quantAttrStats[attrConfig.symbol]
      const score = scoreQuantAttr(this._options.normalizeQuantAttr(qv), attrConfig, stats) ?? null
      return [attrConfig.symbol, score]
    }))
  }
  get ratings() {
    return this._participationSession?.ratings.filter(r => r.subjectId === this.id) || []
  }
  getDirectRatingsForCriterion(
    criterionId: string,
    // TODO: it's increasingly clear that these statistical markers should be moved closer to the display
    // layer because of the fractional digits issue...however, for that to happen, it'll have to be easy
    // to get the raw cohort data to pass to augmentWithStats
    { ratingFractionDigits = 1 }: { ratingFractionDigits?: number } = { ratingFractionDigits: 1 }
  ): SubjectRatingData['byCriterionId'][string] {
    // note that it's important that we filter by subjectType==='Option'
    const ratings = this.ratings
      .filter(r => r.contextId === criterionId && r.subjectType === 'Option') || []
    const participantIds = _uniq(ratings.map(r => r.participantId))
    let avg: number | undefined = _meanBy(ratings.filter(r => !r.abstain), 'ratingVector.0')
    if(Number.isNaN(avg)) avg = undefined
    return {
      avg,
      byParticipantId: _keyBy(
        participantIds.map(participantId => {
          const ratingsWithStats = augmentWithStats(
            ratings,
            r => r.ratingVector?.[0] ?? undefined,
            ratingFractionDigits
          )
          const ratingWithStats = ratingsWithStats.find(r => r.datum.participantId === participantId)
          return {
            participantId,
            value: ratingWithStats?.datum.ratingVector?.[0],
            rating: ratingWithStats?.datum,
            stats: ratingWithStats?.numberWithStats,
          }
        }),
        'participantId',
      ),
    }
  }
  // TODO: oh man, this got messy fast!
  getOutcomeInformedRatingsForCriterion(
    criterionId: string,
    // TODO: it's increasingly clear that these statistical markers should be moved closer to the display
    // layer because of the fractional digits issue...however, for that to happen, it'll have to be easy
    // to get the raw cohort data to pass to augmentWithStats
    options: { ratingFractionDigits: number } = { ratingFractionDigits: 1 }
  ): SubjectRatingData['byCriterionId'][string] {
    const ratings = (this._participationSession?.ratings || [])
      .filter(r => r.contextId === criterionId && r.subjectType == 'OptionOutcome') || []
    const participantIds = _uniq(ratings.map(r => r.participantId))
    const outcomeInfo = this.outcomes.all.map(oo => ({
      id: oo.id,
      name: oo.name,
      prob: oo.prob,
      probData: oo.probData,
      ratingData: oo.getRatings(options).byCriterionId[criterionId],
    }))
    // "as number" cast below safe because of the Array#every
    const avg = outcomeInfo.every(o => o.prob !== null && o.ratingData.avg !== null)
      ? _sum(outcomeInfo.map(o => o.prob * (o.ratingData.avg as number)))
      : undefined
    const byParticipantId = _keyBy(participantIds.map(participantId => {
      // "as number" cast below safe because of the Array#every
      const avg = outcomeInfo
        .every(o => typeof o.probData?.byParticipantId[participantId] == 'number' &&
          typeof o.ratingData?.byParticipantId[participantId]?.value === 'number')
        ? _sum(outcomeInfo.map(o => o.prob * (o.ratingData.byParticipantId[participantId]?.value as number)))
        : undefined
      return {
        participantId,
        value: avg,
        rating: undefined,  // this is not really relevant here since it's the combination of two ratings
        stats: undefined as (NumberWithStats | undefined), // this will be filled in later
      }
    }), 'participantId')
    augmentWithStats(
      Object.values(byParticipantId),
      r => r.value ?? undefined,
      options.ratingFractionDigits
    ).forEach(stats => { byParticipantId[stats.datum.participantId].stats = stats.numberWithStats })
    return {
      avg,
      byParticipantId,
    }
  }
  getRatingsForCriterion(
    criterionId: string,
    // TODO: it's increasingly clear that these statistical markers should be moved closer to the display
    // layer because of the fractional digits issue...however, for that to happen, it'll have to be easy
    // to get the raw cohort data to pass to augmentWithStats
    options: { ratingFractionDigits: number } = { ratingFractionDigits: 1 }
  ): SubjectRatingData['byCriterionId'][string] {
    return this.outcomes.all.length > 0
      ? this.getOutcomeInformedRatingsForCriterion(criterionId, options)
      : this.getDirectRatingsForCriterion(criterionId, options)
  }
  /**
   * This is the swiss army knife of comprehending ratings for a specific option.  It will tell you:
   *  - All the Rating records for this option
   *  - Indexed by criterion ID with:
   *    - Average across all participants
   *    - Indexed by participant ID with:
   *      - Participant rating (if any)
   *      - Participant rating statistics
   *
   *  To construct statistics (isMin/isMax), the number of display digits is required (so that it doesn't say
   *  (1.59 is a max over 1.57 when the suers sees 1.6 and 1.6).
   */
  getRatings(options: { ratingFractionDigits: number } = { ratingFractionDigits: 1 }) {
    if(!this._criteria) return { all: this.ratings, byCriterionId: {} }
    const optionRatings = this.ratings
    return {
      all: optionRatings,
      byCriterionId: _mapValues(this._criteria.byId, c => this.getRatingsForCriterion(c.id, options))
    }
  }
  get outcomes(): Outcomes {
    return new Outcomes(this, this.data.outcomes ?? [], this._participationSession, this._outcomePs)
  }
  constructor(options: Options, data: OptionData, criteria?: Criteria, ps?: ParticipationSession) {
    this._options = options
    this._data = data
    this._quant = {
      C: this._data.cost,
      T: this._data.time,
    }
    this._criteria = criteria
    this._participationSession = ps
    this._tags = data.tagsJson ? JSON.parse(data.tagsJson) : []
  }
  useCriteria(criteria: Criteria) {
    this._criteria = criteria
  }
  get performanceGraph(): PerformanceValueNode | undefined {
    if(!this._criteria || !this._participationSession) return undefined
    let valueOverride: number | undefined = undefined
    let valueOverrideByCriterionId: Record<string, number | undefined> = {}
    if(this.outcomes.all.length) {
      valueOverride =
        safeSum(this.outcomes.all.map(oo => safeMult([oo.performanceGraph?.value ?? null, oo.prob]))) ?? undefined
      valueOverrideByCriterionId = _mapValues(this.getRatings().byCriterionId, ({ avg }) => avg)
    }
    return new PerformanceValueNode(
      this._criteria.perfRoot,
      this.id,
      this._participationSession,
      valueOverride,
      valueOverrideByCriterionId,
    )
  }
  /** Things are getting hairy now.  The value of an option with outcomes is the probability-weighted
   * value of its outcomes.  I don't really want to slot that into a ValueGraph, sooo...this.
   */
  get value(): number | null {
    return this.outcomes.all.length > 0
      ? safeSum(this.outcomes.all.map(oo => safeMult([oo.valueGraph?.value, oo.prob])))
      : this.valueGraph?.value
  }
  get valueGraph(): ValueNode {
   return this.getValueGraph(null)
  }
  getValueGraph(perf: PerformanceValueNode | null): ValueNode {
    const vars: Record<string, null | number | ValueNode> = {
      C: this.quantScores.C,
      T: this.quantScores.T,
      P: perf || this.performanceGraph || null,
    }
    const expr = valueExpressionToValueGraphExpression(this._options.valueFormula)
    const root =  new ValueNode(expr, vars)
    root.name = 'Value'
    root.descendants.forEach(n => {
      if(n.operation === 'ScoreComplement') {
        // note use of unicode U+2032 prime marker instead of single quote;
        // for some reason, the single quote causes a rendering error in React/SVG
        n.name = n.children[0].name + `′ (complement)`
      } else if(n.operation !== 'Score' && n.type === 'Intermediate') {
        // currently, the only use of intermediate nodes (other than complement) is the denominator...
        // if we expand to different types of value formulas, this may have to get more sophisticated
        n.name = '(denominator)'
      } else if(n.type === 'PerformanceRoot') {
        n.name = 'Performance'
      }
    })
    return root
  }
  get changeInValue(): number | null {
    if(!this._options.baseline) return null
    if(this.id === this._options.baseline.id) return null
    const v = this.valueGraph.value ?? null
    const vb = this._options.baseline?.valueGraph.value ?? null
    if(v === null || vb === null) return null
    return (v - vb) / vb
  }
  get changeInPerf(): number | null {
    if(!this._options.baseline) return null
    if(this.id === this._options.baseline.id) return null
    const p = this.performanceGraph?.value ?? null
    const pb = this._options.baseline?.performanceGraph?.value ?? null
    if(p === null || pb === null) return null
    return (p - pb) / pb
  }
  get changeInQuantScores(): Record<string, number | null> {
    const { quantAttributes } = this._options
    return Object.fromEntries<number | null>(quantAttributes.map(attrConfig => {
      if(!this._options.baseline) return [attrConfig.symbol, null]
      if(this.id === this._options.baseline.id) return [attrConfig.symbol, null]
      const s = this.quantScores[attrConfig.symbol]
      const sb = this._options.baseline.quantScores[attrConfig.symbol]
      if(s === null || sb === null) return [attrConfig.symbol, null]
      return [
        attrConfig.symbol,
        (s - sb) / sb,
      ]
    }))
  }
}

/**
 * Once again, I'm causing chaos by changing the value formula to use symbols instead of keys (e.g. "C" instead
 * of "cost").  Of course I could have just used matching symbols, but this is more consistent with my future
 * vision of intrinsic properties.
 */
function mapValueFormulaVars(vf: valueFormula.Expression, map: Record<string, string>): valueFormula.Expression {
  if(Array.isArray(vf)) {
    return vf.map((op, idx) => idx === 0 ? op : mapValueFormulaVars(op, map)) as valueFormula.Expression
  }
  if(typeof vf === 'string' && map[vf]) return map[vf]
  return vf
}

/**
 * The ways quantitative attributes can be transformed into a score.
 *
 * Each transform has a corresponding "complement" transform which simply takes the complement (10 - score),
 * which is used when the score appears in the denominator.
 *
 * - Normalize: the score is the value of the attribute divided by the sum of the attribute for all options.
 *     For example, if an option has a value of 20, and the sum of all options is 100, its score will be
 *     2 (10*20/100).
 * - RangeNormalize: the score is the linear ratio of the value of the attribute to the range of the
 *     attribute for all options.  For example, if the smallest attribute is 10 and the highest is 110,
 *     an option with a value of 60 would have a score of 5 (10*(60 - 10)/(110 - 10)).
 *
 */
export type QuantAttrTransform =
  | 'Normalize'
  | 'NormalizeComplement'
  | 'RangeNormalize'
  | 'RangeNormalizeComplement'

/**
 * Option quantitative attribute configuration.
 */
export interface QuantAttrConfig {
  /** Symbol to use for attribute in value formula (such as "C" for cost). */
  symbol: string
  /** Description of attribute (empty string okay). */
  description: string
  /** Base quantity (Cost, Duration, etc). */
  base: Quantity['base'],
  /** Method for converting value to score. */
  scoreTransform: QuantAttrTransform
}

/**
 * A collection of related options in a decision.  These represent options that will be compared
 * directly against one another; this class is necessary to manage the use of participation sessions
 * and to calculate intrinsic scores (the default algorithm of which normalizes the underyling value
 * among the options).
 *
 * When dealing with options, you must use the Options class (i.e. you cannot currently construct
 * a standalone option).
 */
export class Options {
  private _all: OptionInternal[]
  private _participationSession?: ParticipationSession
  private _outcomePs?: ParticipationSession
  private _criteria: Criteria
  get criteria() { return this._criteria }
  get quantAttributes(): QuantAttrConfig[] {
    // hardcoded for now
    return [
      { symbol: 'C', base: 'Cost', description: 'Cost', scoreTransform: 'NormalizeComplement' },
      { symbol: 'T', base: 'Duration', description: 'Time', scoreTransform: 'NormalizeComplement' },
    ]
  }
  baseline?: Option
  /**
   * Get the sums of all the (non-null) quantitative attributes in this collection
   * of options.  Note that this uses #normalizeQuantAttr to normalize the values.
   *
   * Note that this is used by the individual options to calculate attribute.
   * In this way, this computation can (and should) be cached.  However, we
   * still have work to do in the relationship between OptionData and
   * Options/Option, so this is, for now, a safer approach.
   */
  get quantAttrStats(): Record<string, { sum: number, min: number, max: number }> {
    const stats: Record<string, { sum: number, min: number, max: number }> = {}
    const normalizer = this.normalizeQuantAttr.bind(this)
    this.quantAttributes.forEach(({ symbol }) => {
      const quantities = this._all.map(o => o.quantAttrs[symbol])
        .filter((qty): qty is Quantity => qty !== null)
        .map(normalizer)
        .filter((n): n is number => n !== undefined)
      stats[symbol] = {
        sum: _sum(quantities),
        min: Math.min(...quantities),
        max: Math.max(...quantities),
      }
    })
    return stats
  }
  /**
   * Normalizes a quantitative attribute.  For example, the default duration
   * unit is "Months"; if this is passed a duration with unit "Years", the
   * value returned will be in months.
   */
  normalizeQuantAttr(attr: Quantity): number | undefined {
    switch(attr.base) {
      case 'Cost':
        if(attr.unit !== 'USD') throw new Error(`currently only USD id supported`)
        return attr.value
      case 'Duration':
        return Duration.convert(attr as Duration, 'Months').value
      default:
        throw new Error(`unsupported quantity base type: ${attr.base}`)
    }
  }
  private _valueFormula: valueFormula.Expression
  get valueFormula(): valueFormula.Expression { return this._valueFormula }

  /**
   * A list of all options in this collection.
   */
  get all(): Option[] { return this._all }

  /**
   * Creates a collection of Option instances from an array of option data.  You may also
   * attach associated criteria and participation session (option rating).  You can also
   * attach those later, but value graph data won't be available without them.
   */

  /**
   * Gets an option by ID.
   */
  byId(id: string): Option | undefined {
    // we may want to keep an internal index, but for now just finding it
    return this._all.find(o => o.id === id)
  }

  constructor(
    optionData: OptionData[],
    criteria: Criteria,
    ps?: ParticipationSession,
    valueFormula?: valueFormula.Expression
  ) {
    this._all = optionData.map(od => new OptionInternal(this, od, criteria))
    this._criteria = criteria
    // unnecessary assignment to convince TS that this value is getting initialized
    this._valueFormula = this.setValueFormula(valueFormula)
    if(ps) this.useParticipationContext(ps)
  }

  setValueFormula(valueFormula?: valueFormula.Expression) {
    // TODO: change to new value formulas and update database, etc.; then this map will be unnecessary
    this._valueFormula = mapValueFormulaVars(valueFormula || ValueFunctionLibrary.Standard, {
      perf: 'P',
      "perf'": "P'",
      cost: 'C',
      "cost'": "C'",
      time: 'T',
      "time'": "T'",
    })
    return this._valueFormula
  }

  /**
   * Select a set of criteria to use in determining valuemetrics for these options.  This
   * is essentially reserved for future functionality or testing; our current application has
   * only one set of criteria for each decision.
   */
  useCriteria(criteria: Criteria) {
    this._criteria = criteria
    this._all.forEach(o => o.useCriteria(criteria))
  }
  /**
   * Select the participation session (option rating) containing option ratings that will be
   * used to determine valuemetrics of the options in this collection.
   */
  useParticipationContext(ps?: ParticipationSession) {
    this._participationSession = ps
    this._all.forEach(o => o._participationSession = ps)
  }

  useOutcomeProbPs(ps?: ParticipationSession) {
    this._outcomePs = ps
    this._all.forEach(o => o._outcomePs = ps)
  }
}

// outcomes have to be here to prevent cyclic dependency

export class Outcome {
  constructor(
    readonly outcomes: Outcomes,
    readonly outcomeData: OptionOutcomeData,
    readonly probData: ContextPrioritization[number]
  ) {}
  get id() { return this.outcomeData.id }
  get name() { return this.outcomeData.name }
  get abbrev() { return this.outcomeData.abbrev }
  get color() { return this.outcomeData.color }
  get prob() { return this.probData?.aggregate ?? 0 }
  get option() { return this.outcomes.option }
  get valueGraph() {
    return this.outcomes.option.getValueGraph(this.performanceGraph)
  }
  get performanceGraph() {
    const cri = this.outcomes.option.options.criteria
    const ps = this.outcomes.ratingPs
    if(!cri || !ps) return null
    return new PerformanceValueNode(cri.perfRoot, this.id, ps)
  }
  /**
   * Valid performance ratings for this outcome.
   */
  get perfRatings() {
    return this.outcomes.ratingPs?.validRatings.filter(r => r.subjectId === this.id) || []
  }
  /**
   * Gets aggregate probability data, and probability data for each participant, with stats.
   */
  getProbDataWithStats(ratingFractionDigits = 1) {
    const withStats = augmentWithStats(
      Object.entries(this.probData.byParticipantId),
      r => r[1],
      ratingFractionDigits
    ).map(({ datum, numberWithStats }) => [datum[0], numberWithStats])
    return {
      aggregate: this.probData.aggregate,
      byParticipantId: Object.fromEntries(withStats)
    }
  }
  /**
   * This is the swiss army knife of comprehending ratings for a specific option.  It will tell you:
   *  - All the Rating records for this option
   *  - Indexed by criterion ID with:
   *    - Average across all participants
   *    - Indexed by participant ID with:
   *      - Participant rating (if any)
   *      - Participant rating statistics
   *
   *  To construct statistics (isMin/isMax), the number of display digits is required (so that it doesn't say
   *  (1.59 is a max over 1.57 when the suers sees 1.6 and 1.6).
   */
  getRatings({ ratingFractionDigits = 1 }: { ratingFractionDigits?: number } = { ratingFractionDigits: 1 }) {
    const { criteria } = this.outcomes.option
    if(!criteria) return { all: this.perfRatings, byCriterionId: {} }
    const outcomeRatings = this.perfRatings
    return {
      all: outcomeRatings,
      byCriterionId: _mapValues(criteria.byId, c => {
        const ratings = outcomeRatings.filter(r => r.contextId === c.id) || []
        const participantIds = _uniq(ratings.map(r => r.participantId))
        let avg: number | null = _meanBy(ratings, 'ratingVector.0')
        if(Number.isNaN(avg)) avg = null
        return {
          avg,
          byParticipantId: _keyBy(
            participantIds.map(participantId => {
              const ratingsWithStats = augmentWithStats(
                ratings,
                r => r.ratingVector?.[0] ?? undefined,
                ratingFractionDigits
              )
              const ratingWithStats = ratingsWithStats.find(r => r.datum.participantId === participantId)
              return {
                participantId,
                value: ratingWithStats?.datum.ratingVector?.[0],
                rating: ratingWithStats?.datum,
                stats: ratingWithStats?.numberWithStats,
              }
            }),
            'participantId',
          ),
        }
      }),
    }
  }
}

export class Outcomes {
  readonly all: Outcome[]
  readonly byId: Record<string, Outcome>
  probPs?: ParticipationSession
  ratingPs?: ParticipationSession

  probabilities: ContextPrioritization

  constructor(
    readonly option: Option,
    outcomeData: OptionOutcomeData[],
    ratingPs?: ParticipationSession,
    probPs?: ParticipationSession,
  ) {
    this.probabilities = {}
    if(probPs) {
      const ratings = _filter(probPs.validRatings, {
        contextType: 'Option',
        contextId: this.option.id,
        subjectType: 'OptionOutcome',
      })
      // TODO: there's work to be done, I think, in the prioritization algorithm...I would like to
      // include a sense of completeness (i.e. a participant's ratings will be rejected if they are not
      // complete) and either there will be at least one participant with complete ratings (in which case
      // the aggregate is used) or there will be none, in which case the probabilities will be distributed
      // equally.  Currently, if ratings are missing, the corresponding probability defaults to 0.
      this.probabilities = prioritizeContext(outcomeData, ratings, 'RecenterAndNormalize')
    }
    this.all = outcomeData.map(data => new Outcome(this, data, this.probabilities[data.id]))
    this.byId = _keyBy(this.all, 'id')
    this.probPs = probPs
    this.ratingPs = ratingPs
  }
}
