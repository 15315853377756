/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, ReactElement } from 'react'
import { Column } from 'react-table'
import { Button, Modal, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons'

import { deleteOption } from '@vms/vmspro3-core/dist/actions/decision'

import { Table, TableCellRenderer } from '../../../common/Table'
import { OptionModal } from '../Options/OptionModal'
import { OptionDuplicateModal } from '../Options/OptionDuplicateModal'

import { useAppDispatch } from '../../../../redux'
import { getRandomColorFromLeastFrequent } from '../../../../utils/getRandomColorFromLeastFrequent'
import { colorSelectOptions } from '../../../../utils/appConsts'
import { alphanumericSortByKey } from '../../../../utils/sort-utils'
import { useAccount } from '../../../../context'
import { useModalState } from '../../../../hooks/useModalState'
import {
  useAuthUserParticipant,
  useDecisionChildAncestry,
  useOptions,
  useParticipants,
  useDecision,
} from '../../../../redux/hooks'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/options'
import { extractCoefficients } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'

interface OptionsListProps {
  decisionId: string
}
export function OptionsList(props: OptionsListProps): ReactElement {
  const { decisionId } = props
  const { accountId } = useAccount()
  const decision = useDecision(decisionId)
  const options = useOptions(decisionId)
  const optionAncestry = useDecisionChildAncestry(decisionId)

  const { modal, showModal, hideModal } = useModalState()

  const onCreateOption = useCallback(
    () => showModal(<OptionModal decisionId={decisionId} hideModal={hideModal} />),
    [decisionId, showModal, hideModal]
  )

  const onEditOption = useCallback(
    (option: OptionData) =>
      showModal(<OptionModal option={option} decisionId={decisionId} hideModal={hideModal} />),
    [decisionId, showModal, hideModal]
  )

  const dispatch = useAppDispatch()
  const onDeleteOption = useCallback(
    (option: OptionData) =>
      Modal.confirm({
        title: `Deleting option "${option.name}"`,
        content: 'Are you sure you want to delete this option?',
        onOk: () => dispatch(deleteOption(optionAncestry, option.id)),
      }),
    [dispatch, optionAncestry]
  )

  const onDownloadExcel = useCallback(
    () =>
      import('../../../../utils/excelWriters').then(async ({ saveOptions }) => {
        const filename = `${decision.name} - Options`
        saveOptions(filename, options)
      }),
    [decision.name, options]
  )

  const participants = useParticipants(decisionId)
  const authUserParticipant = useAuthUserParticipant(decisionId)
  // const showOptionDuplicateModal = useShowModal<OptionDuplicateModalData>(OptionDuplicateModalId)
  /**
   * Generates duplicate option form initialValues and displays the OptionDuplicateModal.
   *
   * The default form initialValues are:
   *  - name: the source option name followed by " (copy N)" where N is the count
   *      of options with names starting with the source option name and ending with a word boundary
   *  - color: picked at random from the least used colors that are not the source option color
   *  - abbrev: source option abbrev followed by "-N" where N is the count of options starting with
   *      the source option abbrev
   */
  const onDuplicateOption = useCallback(
    (sourceOption: OptionData) => {
      const nameMatchCount = options.filter(
        opt =>
          // count matching names; a matching name is the source name (in its entirety) or names
          // that start with the source name and are followed by at least one whitespace character.
          // so if the source name was "Option 1", it would count "Option 1", "Option 1 (copy 1)", but
          // not "Option 1A" (no space after "Option 1"). Note that this is not foolproof...it will also
          // count "Option 1 A", but you can't catch 'em all.
          opt.name.startsWith(sourceOption.name) && !/^\S/.test(opt.name.substring(sourceOption.name.length))
      ).length

      const colors = colorSelectOptions.filter(o => o.value !== sourceOption.color).map(o => o.value)
      const abbrevMatchCount = options.filter(o => o.abbrev?.startsWith(sourceOption.abbrev)).length

      const initialValues = {
        name: `${sourceOption.name} (copy ${nameMatchCount})`,
        color: getRandomColorFromLeastFrequent(colors, options),
        abbrev: `${sourceOption.abbrev}-${abbrevMatchCount}`,
        assignRatings: true,
        assignRatingsToParticipantId: authUserParticipant?.id,
      }

      const participantSelectOptions = participants
        .slice()
        .sort(alphanumericSortByKey('fullName'))
        .map(participant => ({
          value: participant.id,
          label: participant.fullName,
        }))

      showModal(
        <OptionDuplicateModal
          accountId={accountId}
          decisionId={decisionId}
          sourceOptionId={sourceOption.id}
          initialValues={initialValues}
          participants={participants}
          participantSelectOptions={participantSelectOptions}
          hideModal={hideModal}
        />
      )
    },
    [showModal, hideModal, accountId, decisionId, options, participants, authUserParticipant]
  )

  const [hasCost, hasTime] = useMemo(() => {
    if (decision.type === 'VM') return [true, true]
    const coefficients = extractCoefficients(decision.valueFunctionExpr)
    return [typeof coefficients.cost === 'number', typeof coefficients.time === 'number']
  }, [decision.type, decision.valueFunctionExpr])

  const columns = useMemo<Column<OptionData>[]>(() => {
    const cols: Column<OptionData>[] = [
      {
        Header: 'Abbrev.',
        accessor: 'abbrev',
        Cell: ({ value }) => <>{value}</>,
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: TableCellRenderer.EntityName as any,
      },
      {
        Header: 'Option ID',
        accessor: 'commonId',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: TableCellRenderer.Html as any,
        // disable sort, because how is one supposed to meaningfully sort HTML object contents?
        disableSortBy: true,
      },
    ]
    if (hasCost)
      cols.push({
        Header: 'Cost',
        accessor: 'cost',
        Cell: TableCellRenderer.Cost as any,
        align: 'right',
        sortType: 'cost',
        // TODO: filter function for cost objects — range?
      })
    if (hasTime)
      cols.push({
        Header: 'Duration',
        accessor: 'time',
        Cell: TableCellRenderer.Duration as any,
        align: 'right',
        sortType: 'duration',
        // TODO: filter function for duration objects — range?
      })
    return cols
  }, [hasCost, hasTime])

  return (
    <>
      {modal}
      <Table<OptionData>
        columns={columns}
        data={options}
        rowControls={option => (
          <>
            <Tooltip title="Duplicate">
              <FontAwesomeIcon
                aria-label="Duplicate"
                icon={faClone as any}
                onClick={() => onDuplicateOption(option)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <FontAwesomeIcon aria-label="Edit" icon={faEdit as any} onClick={() => onEditOption(option)} />
            </Tooltip>
            <Tooltip title="Delete">
              <FontAwesomeIcon
                aria-label="Delete"
                icon={faTrashAlt as any}
                onClick={() => onDeleteOption(option)}
              />
            </Tooltip>
          </>
        )}
        toolbarLeft={() => (
          <Button icon={<DownloadOutlined />} onClick={onDownloadExcel}>
            Download Options Excel
          </Button>
        )}
        toolbarRight={() => (
          <Button type="primary" onClick={onCreateOption} icon={<PlusOutlined />}>
            Add Option
          </Button>
        )}
        initialState={{
          sortBy: [{ id: 'name', desc: false }],
        }}
      />
    </>
  )
}
