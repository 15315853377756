import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { Form, Input, Progress } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import FileDrop from '../common/FileDrop'
import FormModal from './FormModal'

import Server from '../../../server/VMSProServerAdapter'
import { useAccount, useAuth } from '../../../context'
import { useModalData } from '../RiskModalContext'

export default function EntityAttachmentAddModal() {
  const { entity } = useModalData(EntityAttachmentAddModal.id)

  const { accountId } = useAccount()
  const { authUser } = useAuth()

  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [progress, setProgress] = useState(0)

  const dispatch = useDispatch()
  const onOk = async ({ files, fileTitle }) => {
    setUploadInProgress(true)

    const entityId = entity.id

    const entityAttachmentInfo = await Server.getEntityAttachmentInfo(accountId, entityId, files[0])

    // prevent multiple uploads of the same file for the same project
    const { attachments = [] } = entity
    const alreadyUploaded = attachments.find(({ url }) => url === entityAttachmentInfo.url)
    if (alreadyUploaded) {
      setUploadInProgress(false)
      return {
        errors: [
          {
            message: `This file has already been uploaded as "${alreadyUploaded.fileTitle}"`,
            field: 'fileTitle',
          },
        ],
      }
    }

    return Server.uploadEntityAttachment(entityAttachmentInfo, ({ progress }) => setProgress(progress))
      .then(fileUrl => {
        const payload = {
          attachments: [
            ...(entity.attachments || []),
            {
              url: fileUrl,
              title: fileTitle,
              uploadedBy: { fullName: authUser.fullName, id: authUser.id },
              uploadedOn: Date.now(),
            },
          ],
        }
        const meta = {
          entityId,
          entityType: entity.entityType,
          ancestry: entity.ancestry,
        }
        dispatch(actions.riskEntity.update(payload, meta))
      })
      .catch(error => {
        // TODO: alert the user about errors other than file size, which is handled preemptively
        setUploadInProgress(false)
        const sentryId = error
          ? Sentry.captureException(error)
          : Sentry.captureMessage('EntityAttachmentAddModal - Failed to upload the file to the S3 bucket.')
        return {
          errors: [
            {
              message: 'There was an error processing this attachment.',
              field: 'fileTitle',
              sentryId,
            },
          ],
        }
      })
  }

  return (
    <FormModal modalId={EntityAttachmentAddModal.id} confirmLoading={uploadInProgress} onProcess={onOk}>
      {(_initialFocus, _, form) => (
        <>
          {uploadInProgress ? (
            <>
              <h3>Uploading, Please Wait...</h3>
              <Progress percent={progress} />
            </>
          ) : (
            <>
              <Form.Item
                label="Title"
                name="fileTitle"
                rules={[{ required: true, message: 'Please provide a title for the file.' }]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Files"
                name="files"
                rules={[{ required: true, message: 'Please select a file to upload.' }]}
              >
                <FileDrop form={form} />
              </Form.Item>
            </>
          )}
        </>
      )}
    </FormModal>
  )
}
EntityAttachmentAddModal.id = 'EntityAttachmentAddModal'

// for some unknown reason this default export is somehow undefined when imported into the index module
// export default EntityAttachmentAddModal
