import { useCallback, ReactElement } from 'react'
import { Form, Input, Modal } from 'antd'

import { updateDecisionFolder } from '@vms/vmspro3-core/dist/actions/decision'

import { useAppDispatch } from '../../../redux'
import { useDecisionFolder } from '../../../redux/hooks'

export type DecisionFolderEditModalProps = {
  decisionFolderId: string
  hide: VoidFunction
}
export function DecisionFolderEditModal({ decisionFolderId, hide }: DecisionFolderEditModalProps): ReactElement {
  const [form] = Form.useForm<{ name: string }>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () =>
      form.validateFields().then(({ name }) => {
        dispatch(updateDecisionFolder(decisionFolderId, { name }))
        hide()
      }),
    [form, dispatch, decisionFolderId, hide]
  )

  const decisionFolder = useDecisionFolder(decisionFolderId)

  return (
    <Modal open onOk={onOk} onCancel={hide}>
      <h3>Editing {decisionFolder.name}</h3>
      <Form form={form} layout="vertical" initialValues={decisionFolder}>
        <Form.Item label="Name" name="name">
          <Input autoFocus />
        </Form.Item>
      </Form>
    </Modal>
  )
}
