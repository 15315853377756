import { Middleware, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { RootState } from '../reducers'
import { augmentAction } from './augmentAction'

type AugmentationMiddleware = Middleware<unknown, RootState, ThunkDispatch<RootState, undefined, AnyAction>>
const augmentationMiddleware: AugmentationMiddleware = store => next => action => {
  const state = store.getState()
  const { userId: authUserId, assumedAuthz } = state.auth || {}

  next(
    augmentAction(action, {
      accountId: state.account.account?.id,
      authUserId,
      authUserName: state.user.currentUser?.fullName,
      assumedAuthz,
    })
  )
}

export default augmentationMiddleware
