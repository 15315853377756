import { TableConfig, UserGlobalRecord } from '../types'

const AddToAccountActionType = 'Add User to Account'
function addToAccount(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: AddToAccountActionType,
    payload,
    meta,
  }
}
addToAccount.toString = () => AddToAccountActionType

type UpdatableUserProperties =
  | 'fullName'
  | 'shortName'
  | 'initials'
  | 'dateFormat'
  | 'timeFormat'
  | 'dateTimeFormat'
  | 'toursViewed'
const UpdateUserActionType = 'Update User'
export interface UpdateUserAction {
  module: 'System',
  type: typeof UpdateUserActionType,
  payload: Partial<Pick<UserGlobalRecord, UpdatableUserProperties>>,
  meta: { userId: string },
}
function update(payload: UpdateUserAction['payload'], meta: UpdateUserAction['meta']): UpdateUserAction {
  return {
    module: 'System',
    type: UpdateUserActionType,
    payload,
    meta,
  }
}
update.toString = () => UpdateUserActionType

const DisableUserActionType = 'Disable User'
export interface DisableUserAction {
  module: 'System',
  type: typeof DisableUserActionType,
  payload: Record<never, never>,
  meta: { authUserId: string, userId: string },
}
function disable(payload: undefined, meta: DisableUserAction['meta']): DisableUserAction {
  return {
    module: 'System',
    type: DisableUserActionType,
    payload: {},
    meta,
  }
}
disable.toString = () => DisableUserActionType

const EnableUserActionType = 'Enable User'
export interface EnableUserAction {
  module: 'System',
  type: typeof EnableUserActionType,
  payload: Record<never, never>,
  meta: { userId: string },
}
function enable(payload: undefined, meta: EnableUserAction['meta']): EnableUserAction {
  return {
    module: 'System',
    type: EnableUserActionType,
    payload: {},
    meta,
  }
}
enable.toString = () => EnableUserActionType

const AddPolicyToUserActionType = 'Add Policy to User'
export interface AddPolicyToUserAction {
  module: 'System',
  type: typeof AddPolicyToUserActionType,
  payload: Record<never, never>,
  meta: {
    policyId: string,
    userId: string,
    authUserId?: string,
  },
}
function addPolicy(payload: undefined, meta: AddPolicyToUserAction['meta']): AddPolicyToUserAction {
  return {
    module: 'System',
    type: AddPolicyToUserActionType,
    payload: {},
    meta,
  }
}
addPolicy.toString = () => AddPolicyToUserActionType

const RemovePolicyActionType = 'Remove Policy from User'
export interface RemovePolicyAction {
  module: 'System',
  type: typeof RemovePolicyActionType,
  payload: Record<never, never>,
  meta: {
    policyId: string,
    userId: string,
    authUserId?: string,
  },
}
function removePolicy(payload: undefined, meta: RemovePolicyAction['meta']): RemovePolicyAction {
  return {
    module: 'System',
    type: RemovePolicyActionType,
    payload: {},
    meta,
  }
}
removePolicy.toString = () => RemovePolicyActionType

const SetTableConfigActionType = 'Set Table Config'
export interface SetTableConfigAction {
  module: 'System',
  type: typeof SetTableConfigActionType,
  payload: TableConfig,
  meta: {
    authUserId?: string,
    userId: string,
  },
}
function setTableConfig(
  payload: SetTableConfigAction['payload'],
  meta: SetTableConfigAction['meta']
): SetTableConfigAction {
  return {
    module: 'System',
    type: SetTableConfigActionType,
    payload,
    meta,
  }
}
setTableConfig.toString = () => SetTableConfigActionType

const AgreeToEulaActionType = 'Agree to EULA'
export interface AgreeToEulaAction {
  module: 'System',
  type: typeof AgreeToEulaActionType,
  payload: {
    eulaId: string,
    version: string,
  },
  meta: {
    userId: string,
    accountId: string,
  },
}
function agreeToEula(payload: AgreeToEulaAction['payload'], meta: AgreeToEulaAction['meta']): AgreeToEulaAction {
  return {
    module: 'System',
    type: AgreeToEulaActionType,
    payload,
    meta,
  }
}
agreeToEula.toString = () => AgreeToEulaActionType

const AssumeAuthzActionType = 'Assume AuthZ'
function assumeAuthz(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: AssumeAuthzActionType,
    payload,
    meta,
  }
}
assumeAuthz.toString = () => AssumeAuthzActionType

export default {
  addToAccount,
  update,
  disable,
  enable,
  addPolicy,
  removePolicy,
  setTableConfig,
  agreeToEula,
  assumeAuthz,
}
