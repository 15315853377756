// TODO: this can probably be replaced with data-tour-id...might be better
export default {
  tab: {
    overview: '__TOUR_tab_overview',
    criteria: '__TOUR_tab_criteria',
    options: '__TOUR_tab_options',
    participants: '__TOUR_tab_participants',
    valuemetrics: '__TOUR_tab_valuemetrics',
    results: '__TOUR_tab_results',
  },
}
