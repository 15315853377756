import { produce } from 'immer'
import _set from 'lodash/set'
import asSet from 'arraysetjs'

import { Invitation, UserAccountRecord_App, UserGlobalRecord_App } from '@vms/vmspro3-core/dist/types'

import { LoadingStatus } from '../../types'
import {
  AcceptAccountInvitationAction,
  SetAccountUserAction,
  FetchCurrentUserRequestAction,
  FetchCurrentUserSuccessAction,
  FetchUserInvitationsRequestAction,
  FetchUserInvitationsSuccessAction,
  ResetAccountStateAction,
  SignOutAction,
} from '../actions'
import {
  AddPolicyToUserAction,
  AgreeToEulaAction,
  DisableUserAction,
  EnableUserAction,
  RemovePolicyAction,
  UpdateUserAction,
  SetTableConfigAction,
} from '@vms/vmspro3-core/dist/actions/user'

interface UserState {
  currentUser?: UserGlobalRecord_App
  currentUserLoadingStatus: LoadingStatus
  accountUser?: UserAccountRecord_App
  invitations?: Invitation[]
  invitationsLoadingStatus: LoadingStatus
}
type UserReducerAction =
  | SignOutAction
  | ResetAccountStateAction
  | FetchCurrentUserRequestAction
  | FetchCurrentUserSuccessAction
  | SetAccountUserAction
  | FetchUserInvitationsRequestAction
  | FetchUserInvitationsSuccessAction
  | AcceptAccountInvitationAction
  | UpdateUserAction
  | DisableUserAction
  | EnableUserAction
  | AddPolicyToUserAction
  | AgreeToEulaAction
  | RemovePolicyAction
  | SetTableConfigAction

const initialState: UserState = {
  currentUser: undefined,
  currentUserLoadingStatus: 'NotLoaded',
  accountUser: undefined,
  invitations: undefined,
  invitationsLoadingStatus: 'NotLoaded',
}

export const userReducer = produce<UserState, [UserReducerAction?]>((state, action) => {
  if (!action) return

  // ephemeral actions always handled
  switch (action.type) {
    case 'SignOut': {
      return initialState
    }
    case 'ResetAccountState': {
      delete state.accountUser
      break
    }
    case 'FetchCurrentUserRequest': {
      state.currentUserLoadingStatus = 'Requested'
      break
    }
    case 'FetchCurrentUserSuccess': {
      state.currentUser = action.payload
      state.currentUserLoadingStatus = 'Loaded'
      break
    }
    case 'SetAccountUser': {
      state.accountUser = action.payload.accountUser
      break
    }
    case 'FetchUserInvitationsRequest': {
      state.invitationsLoadingStatus = 'Requested'
      break
    }
    case 'FetchUserInvitationsSuccess': {
      state.invitations = action.payload
      state.invitationsLoadingStatus = 'Loaded'
      break
    }
    case 'AcceptAccountInvitation': {
      const { email, accountId } = action.payload

      const idx = state.invitations?.findIndex(inv => inv.id === `${accountId}/${email}`)
      if (typeof idx === 'number' && idx > -1) {
        state.invitations?.splice(idx, 1)
      }

      break
    }

    default: {
      break
    }
  }

  if (!(action.meta && 'userId' in action.meta && action.meta.userId)) return

  // system user actions
  if (action.meta.userId === state.currentUser?.id) {
    switch (action.type) {
      case 'Update User': {
        Object.assign(state.currentUser, action.payload)
        break
      }
      default: {
        break
      }
    }
  }

  // account user actions
  if (action.meta.userId === state.accountUser?.id) {
    switch (action.type) {
      case 'Agree to EULA': {
        const { eulaId, version } = action.payload
        _set(state.accountUser, ['eulas', eulaId], version)
        break
      }
      case 'Disable User': {
        state.accountUser.disabled = true
        break
      }
      case 'Enable User': {
        state.accountUser.disabled = false
        break
      }
      case 'Add Policy to User': {
        asSet.mutable(state.accountUser.policyIds).add(action.meta.policyId)
        break
      }
      case 'Remove Policy from User': {
        asSet.mutable(state.accountUser.policyIds).remove(action.meta.policyId)
        break
      }
      case 'Set Table Config': {
        const { configId, tableId } = action.payload
        // TODO: is this an account specific configuration? It's being set on the account user record.
        // TODO: the variable configId part of this path is sketchy, the schema shape should always be the same.
        // TODO: see https://vms.atlassian.net/browse/VP3-1946
        _set(state.accountUser, `tableConfigs.${tableId}${configId ? `.${configId}` : ''}`, action.payload)
        break
      }
      default: {
        break
      }
    }
  }
}, initialState)
