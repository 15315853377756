/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, ReactElement } from 'react'
import { createSelector } from 'reselect'
import { Column } from 'react-table'

import { Rating } from '@vms/vmspro3-core/dist/types'
import { NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'

import { Table, TableCellRenderer } from '../../../common/Table'
import {
  getRatingCellRenderer,
  getRatingWithStatsCellRenderer,
} from '../../../common/Table/TableCellRenderer/RatingCellRenderer'

import { useAppSelector } from '../../../../redux'
import { useDecision } from '../../../../redux/hooks'
import { getSelectParticipants } from '../../../../redux/selectors'
import { Tooltip } from 'antd'

const ratingFractionDigits = 1
const RatingCellRenderer = getRatingCellRenderer(ratingFractionDigits)
const RatingWithStatsCellRenderer = getRatingWithStatsCellRenderer(ratingFractionDigits)

type OptionRatingTableData = {
  name: string
  color: string
  ratingsByParticipantId: Record<string, { rating?: Rating; stats?: NumberWithStats } | undefined>
  groupAverage: number | null
}
type OptionRatingsTableProps = {
  decisionId: string
  optionId: string
  outcomeId?: string
}
export function OptionRatingsTable({ decisionId, optionId, outcomeId }: OptionRatingsTableProps): ReactElement {
  const columns = useAppSelector(
    useMemo(
      () =>
        createSelector(getSelectParticipants(decisionId), (participants = []): Column<OptionRatingTableData>[] => [
          {
            Header: 'Criterion',
            accessor: 'name',
            Cell: TableCellRenderer.EntityName as any,
          },
          {
            Header: 'Avg. Rating',
            accessor: 'groupAverage',
            align: 'right',
            sortType: 'numeric',
            Cell: RatingCellRenderer as any,
          },
          {
            Header: 'Participant Ratings',
            columns: participants.map<any>(participant => ({
              id: participant.id,
              Header: () => <Tooltip title={participant.fullName}>{participant.initials}</Tooltip>,
              accessor: (row: any) => row.ratingsByParticipantId[participant.id],
              align: 'right',
              sortType: 'wrappedRating',
              Cell: RatingWithStatsCellRenderer,
            })),
          },
        ]),
      [decisionId]
    )
  )

  const decision = useDecision(decisionId)
  const data: OptionRatingTableData[] = decision.criteria.leafCriteria
    .filter(c => c.type === 'Performance' || c.type === 'Rated')
    .map(c => {
      const ratingData = (outcomeId
        ? decision.options.byId(optionId)?.outcomes.byId[outcomeId]?.getRatings().byCriterionId[c.id]
        : decision.options.byId(optionId)?.getRatings().byCriterionId[c.id]) || {
        byParticipantId: {},
        avg: undefined,
      }
      return {
        name: c.label({ skipAncestors: 2 }),
        color: c.color,
        ratingsByParticipantId: ratingData.byParticipantId,
        groupAverage: ratingData.avg ?? null,
      }
    })

  return <Table<OptionRatingTableData> columns={columns} data={data} fixFirstCol />
}
