import { Button } from 'antd'
import ReactDOM from 'react-dom'
import Joyride, { Step as JoyrideStep } from 'react-joyride'
import { QuestionCircleFilled } from '@ant-design/icons'
import { useAuth } from '../context'
import { useState } from 'react'
import { UserGlobalRecord } from '@vms/vmspro3-core/dist/types'
import { useAppDispatch } from '../redux'
import userActions from '@vms/vmspro3-core/dist/actions/user'

// TODO: we might want to just use Joyride's step...really what we're trying to do is just
// establish sensible defaults, which we don't need to mangle types to do (see 'steps.map'
// in useTour)
type Step = {
  target: JoyrideStep['target']
  placement?: JoyrideStep['placement']
  content: JoyrideStep['content']
  styles?: JoyrideStep['styles']
}

interface HelpButtonProps {
  onClick: VoidFunction
}
function HelpButton(props: HelpButtonProps) {
  const helpContainer = document.getElementById('helpButtonContainer')
  if (!helpContainer) return null
  return ReactDOM.createPortal(
    <Button
      id="tourButton"
      onClick={props.onClick}
      ghost
      icon={<QuestionCircleFilled />}
      shape="round"
      size="small"
      style={{ marginRight: '20px' }}
    >
      Help
    </Button>,
    helpContainer
  )
}

type TourKey = 'Overview Page' | 'Criteria Page' | 'Results Page'

export function useTour(tourKey: TourKey, steps: Step[]) {
  const _steps = steps.map(step => ({
    ...step,
    disableBeacon: true,
  }))
  const { authUser } = useAuth()

  const dispatch = useAppDispatch()
  const [runTour, setRunTour] = useState(!authUser.toursViewed?.[tourKey])
  const tour = (
    <>
      <HelpButton onClick={() => setRunTour(true)} />
      <Joyride
        steps={_steps}
        continuous
        run={runTour}
        hideCloseButton
        showSkipButton
        disableScrolling
        callback={state => {
          if (state.action !== 'reset') return
          // "reset" seems to be the only action we care about; it happens when the user finishes the tour,
          // and when the user skips it
          setRunTour(false)
          const toursViewed: UserGlobalRecord['toursViewed'] = { ...authUser?.toursViewed }
          if (toursViewed[tourKey]) {
            toursViewed[tourKey] = { ...toursViewed[tourKey], mostRecentlyViewed: Date.now() }
          } else {
            const firstViewed = Date.now()
            toursViewed[tourKey] = { firstViewed, mostRecentlyViewed: firstViewed }
          }
          dispatch(userActions.update({ toursViewed }, { userId: authUser.id }))
        }}
        styles={{
          tooltipContent: {
            textAlign: 'left',
          },
        }}
        locale={{ skip: 'Skip Tour', last: 'Exit Tour' }}
      />
    </>
  )
  return { tour }
}
