import React from 'react'
/** @jsxImportSource @emotion/react */

import { toggleWrap } from './controlStyles'
import { COLOR } from '../../_riskConfig/color'

export default function Toggle({ id, label, checked, onChange }) {
  return (
    <div css={toggleWrap}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      <label
        htmlFor={id}
        css={{
          '&::before,&::after': {
            position: 'absolute',
          },
          '&::before': {
            // box and bg
            content: '""',
            borderColor: 'var(--fg-color)',
            backgroundColor: COLOR.GREY_2L,
            display: 'inline-block',
            height: '20px',
            width: '20px',
            top: '10px',
            right: '10px',
            borderStyle: 'solid',
            borderWidth: '0px',
            transition: 'border-width 0.05s',
          },
          '&::after': {
            // checkmark
            content: '""',
            display: 'inline-block',
            border: 'none',
            borderLeft: `3.5px solid ${COLOR.GREY_1}`,
            borderBottom: `3.5px solid ${COLOR.GREY_1}`,
            height: '7px',
            width: '12px',
            right: '14px',
            top: '15px',
            transform: 'rotate(-45deg) scale(0.75)',
            opacity: '0',
            transformOrigin: 'center',
            transition: 'transform 0.1s',
          },
        }}
      >
        {label}
      </label>
    </div>
  )
}
