import React from 'react'
import LockOutlined from '@ant-design/icons/LockOutlined'
import { Form, Input } from 'antd'

import PasswordSuggestor from './PasswordSuggestor'

import { testId } from '../../test-automation'

const passwordRulesCopy = 'Password must be at least 12 characters long'

function PasswordField() {
  return (
    <Form.Item label="Password" name="password">
      <Input.Password prefix={<LockOutlined css={style.inputPrefix} />} placeholder="password" />
    </Form.Item>
  )
}

function SetNewPasswordFields({ showSuggestion }) {
  return (
    <>
      {showSuggestion && <PasswordSuggestor />}
      <Form.Item
        label="Password"
        name="newPassword"
        extra={<i>Password must be at least 12 characters</i>}
        rules={[
          {
            required: true,
            message: 'Please enter a password',
          },
          {
            min: 12,
            message: passwordRulesCopy,
            validateTrigger: ['onBlur'],
          },
        ]}
      >
        <Input.Password
          {...testId('newPassword')}
          prefix={<LockOutlined css={style.inputPrefix} />}
          placeholder="Password"
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        label="Verify Password"
        name="verifyNewPassword"
        rules={[
          ({ getFieldValue }) => ({
            validateTrigger: 'onChange',
            async validator(rule, verifyValue) {
              if (getFieldValue('newPassword') && !verifyValue) {
                throw new Error('Please verify your password')
              }
            },
          }),
          ({ getFieldValue }) => ({
            validateTrigger: 'onBlur',
            async validator(rule, verifyValue) {
              const passwordValue = getFieldValue('newPassword')
              if (!passwordValue || !verifyValue) return
              if (verifyValue !== passwordValue) {
                throw new Error('The passwords you entered do not match')
              }
            },
          }),
        ]}
      >
        <Input.Password
          {...testId('newPasswordVerify')}
          prefix={<LockOutlined css={style.inputPrefix} />}
          placeholder="Verify New Password"
          autoComplete="new-password"
        />
      </Form.Item>
    </>
  )
}

const style = {
  inputPrefix: { color: 'rgba(0,0,0,.25)' },
}

PasswordField.SetNewPassword = SetNewPasswordFields

export default PasswordField
