import { Button } from 'antd'
import { RollbackOutlined } from '@ant-design/icons'

import { RatingContext } from '@vms/vmspro3-core/dist/nextgen/RatingContext'

import style from './RatingOverview.module.css'

interface RatingOverviewProps {
  participationSessionTypeLabel: string
  completedRatingContexts: RatingContext[]
  inProgressRatingContexts: RatingContext[]
  setContextId: (contextId: string) => void
  decisionName: string
  participantShortName: string
  unsetParticipant?: VoidFunction
  backToDecision?: VoidFunction
}
export function RatingOverview({
  participationSessionTypeLabel,
  completedRatingContexts,
  inProgressRatingContexts,
  setContextId,
  decisionName,
  participantShortName,
  unsetParticipant,
  backToDecision,
}: RatingOverviewProps) {
  return (
    <div className={style.ratingOverview}>
      {inProgressRatingContexts.length > 0 ? (
        <>
          <div>
            <h1>Hi, {participantShortName}!</h1>
            {unsetParticipant && (
              <Button type="link" onClick={unsetParticipant}>
                Not {participantShortName}?
              </Button>
            )}
          </div>
          <div>
            <h2>{participationSessionTypeLabel}</h2>
            <p>
              Thank you for participating in {participationSessionTypeLabel} for "{decisionName}".
            </p>
          </div>
        </>
      ) : (
        <>
          <h1>Thank You, {participantShortName}!</h1>
          <p>
            You have completed {participationSessionTypeLabel} for "{decisionName}". You can close this window, or
            revisit any of your prioritizations.
          </p>
        </>
      )}
      {inProgressRatingContexts.length > 0 && (
        <Button onClick={() => setContextId(inProgressRatingContexts[0]?.contextCriterion.id)} type="primary">
          {completedRatingContexts.length === 0 ? 'Get Started' : 'Pick Up Where You Left Off'}
        </Button>
      )}
      {completedRatingContexts.length > 0 && (
        <div>
          <h3>Revisit your prioritizations for:</h3>
          <ul className={style.completedList}>
            {completedRatingContexts.map(ratingContext => (
              <li
                key={ratingContext.contextCriterion.id}
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => setContextId(ratingContext.contextCriterion.id)}
              >
                {ratingContext.contextCriterion.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {backToDecision && (
        <Button className={style.backButton} type="primary" onClick={backToDecision}>
          <RollbackOutlined /> Back to Decision
        </Button>
      )}
    </div>
  )
}
