import { gql } from '@apollo/client'
import { AccountUserFieldsFragment } from '../graphql'

import Server from '../server/VMSProServerAdapter'

const GET_ACCOUNT_USERS = gql`
  query GetAccountUsers($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      ...AccountUserFields
    }
  }
  ${AccountUserFieldsFragment}
`

export const getUsers = async accountId => {
  const response = await Server.graphql({
    query: GET_ACCOUNT_USERS,
    variables: { accountId },
  })

  return response.data.accountUsers
}
