import { deleteOption } from '@vms/vmspro3-core/dist/actions/decision'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import { Badge, Button, Modal, Space, Tooltip } from 'antd'
import { Fragment, useCallback } from 'react'
import { useModalState } from '../../../../hooks/useModalState'
import { useAppDispatch } from '../../../../redux'
import { useDecisionChildAncestry } from '../../../../redux/hooks'
import { OptionModal } from '../Options/OptionModal'
import { PlusOutlined, DeleteOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

interface SelectableProps {
  contents: JSX.Element
  selected?: boolean
  onSelect?: VoidFunction
  indent?: number
}
function Selectable(props: SelectableProps) {
  const { selected, onSelect, indent = 0 } = props
  const containerStyle = {
    cursor: onSelect ? 'pointer' : 'arrow',
    margin: '2px 0',
    padding: '4px 8px',
    background: selected ? '#bae7ff' : 'none',
    marginLeft: `${indent * 12}px`,
  }
  return (
    <div style={containerStyle} onClick={onSelect}>
      {props.contents}
    </div>
  )
}

interface OptionProps {
  decision: Decision
  optionId: string
}
function Option(props: OptionProps) {
  const { decision, optionId } = props
  const op = decision.options.byId(optionId)
  if (!op) return null
  const badgeStyle = {
    color: 'white',
    backgroundColor: '#00c',
    marginTop: '-3px',
    marginLeft: '4px',
    border: 'none',
    borderRadius: '10px',
    padding: '1px 8px',
  }
  const perf = op.performanceGraph?.value
  const tooltipContent = typeof perf === 'number' ? `${op.name} (Perf: ${perf.toFixed(1)})` : op.name
  return (
    <>
      <Tooltip title={tooltipContent}>
        {op.commonId} <b>{op.abbrev}</b>
      </Tooltip>
      {typeof op.value === 'number' ? (
        <span style={badgeStyle}>{op.value.toFixed(1)}</span>
      ) : (
        <span style={{ marginLeft: '1ex' }}>
          <Tooltip title="Value will be displayed here when all information is available; make sure your value formula is configured correctly.">
            <QuestionCircleFilled style={{ color: Color.BLUE_LINK }} />
          </Tooltip>
        </span>
      )}
    </>
  )
}

const outcomeProbFormatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'percent',
}).format

interface OptionOutcomeProps {
  decision: Decision
  optionId: string
  outcomeId: string
}
function OptionOutcome(props: OptionOutcomeProps) {
  const { decision, optionId, outcomeId } = props
  const oo = decision.options.byId(optionId)?.outcomes.byId[outcomeId]
  if (!oo) return null
  const badgeStyle = {
    color: 'black',
    backgroundColor: '#eee',
    marginTop: '-3px',
    marginLeft: '4px',
    border: 'none',
  }
  return (
    <>
      <Tooltip title={oo.name}>
        <b>{oo.abbrev}</b>
      </Tooltip>
      {oo.prob !== null && <Badge style={badgeStyle} count={outcomeProbFormatter(oo.prob)} />}
    </>
  )
}

interface OptionNavigatorProps {
  decision: Decision
  selectedOptionId?: string
  selectedOutcomeId?: string
  setSelected: (optionId?: string, outcomeId?: string) => void
}
export function OptionNavigator(props: OptionNavigatorProps) {
  const { decision, selectedOptionId, selectedOutcomeId, setSelected } = props

  const { modal, showModal, hideModal } = useModalState()

  const onCreateOption = useCallback(
    () => showModal(<OptionModal decisionId={decision.id} hideModal={hideModal} />),
    [showModal, decision.id, hideModal]
  )

  const dispatch = useAppDispatch()
  const optionAncestry = useDecisionChildAncestry(decision.id)
  const onDeleteOption = useCallback(
    (optionId?: string) => {
      if (!optionId) throw new Error(`must provide option ID`)
      const option = decision.options.byId(optionId)
      if (!option) throw new Error(`option with ID ${optionId} not found`)
      Modal.confirm({
        title: `Deleting option "${option.name}"`,
        content: 'Are you sure you want to delete this option?',
        onOk: () => dispatch(deleteOption(optionAncestry, option.id)),
      })
    },
    [decision.options, dispatch, optionAncestry]
  )

  const DeleteButton = useCallback(
    () => (
      <Button
        danger
        disabled={!selectedOptionId || !!selectedOutcomeId}
        onClick={() => onDeleteOption(selectedOptionId)}
        icon={<DeleteOutlined />}
      >
        Delete
      </Button>
    ),
    [onDeleteOption, selectedOptionId, selectedOutcomeId]
  )

  const AddButton = useCallback(
    () => (
      <Button type="default" icon={<PlusOutlined />} data-tour-id="Add Option" onClick={onCreateOption}>
        Add Option
      </Button>
    ),
    [onCreateOption]
  )

  return (
    <div data-tour-id="Option Navigator">
      {modal}
      <Selectable
        contents={<b>All Options</b>}
        selected={selectedOptionId === undefined && selectedOutcomeId === undefined}
        onSelect={() => setSelected(undefined, undefined)}
      />
      {decision.options.all.map(o => (
        <Fragment key={o.id}>
          <Selectable
            key={o.id}
            contents={<Option decision={decision} optionId={o.id} />}
            selected={selectedOptionId === o.id && !selectedOutcomeId}
            onSelect={() => setSelected(o.id, undefined)}
            indent={1}
          />
          {o.outcomes.all.map(oo => (
            <Selectable
              key={oo.id}
              contents={<OptionOutcome decision={decision} optionId={o.id} outcomeId={oo.id} />}
              selected={selectedOutcomeId === oo.id}
              onSelect={() => setSelected(o.id, oo.id)}
              indent={2}
            />
          ))}
        </Fragment>
      ))}
      <Space style={{ marginTop: '12px' }}>
        <AddButton />
        <DeleteButton />
      </Space>
    </div>
  )
}
