/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
import account from './account'
import dev from './dev'
const feature = require('./feature')
const invitation = require('./invitation')
import policy from './policy'
import risk from './risk'
const riskContext = require('./riskContext')
import riskEntity from './riskEntity'
import riskPortfolio from './riskPortfolio'
import riskProgram from './riskProgram'
import riskProject from './riskProject'
import user from './user'

export default {
  account,
  dev,
  feature,
  invitation,
  policy,
  risk,
  riskContext,
  riskEntity,
  riskPortfolio,
  riskProgram,
  riskProject,
  user,
}
