import React from 'react'

import AntSelect from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'
import { getOptionLabel } from '../utils/getOptionLabel'

import 'antd/lib/button/style/css'
import 'antd/lib/tooltip/style/css'
import { testId } from '../../../test-automation'

const { Option } = AntSelect

const Select = React.forwardRef(({ options, readOnly, style, value, ...props }: any, ref: any) => {
  const selectProps = {
    getPopupContainer: (trigger: any) => trigger.parentNode,
    ...props,
    style: {
      width: '100%',
      ...style,
    },
  }

  // TODO: Change global use of Select to pass 'options' as prop rather than rendering children!
  if (!options) return <AntSelect value={value} ref={ref} {...selectProps} />

  if (readOnly) {
    if (value === undefined || value === null) return <span style={readOnlyStyle.single} />

    return Array.isArray(value) ? (
      <ul ref={ref} style={readOnlyStyle.list}>
        {value.map(v => (
          <li key={v}>{getOptionLabel(v, options)}</li>
        ))}
      </ul>
    ) : (
      <div ref={ref} style={readOnlyStyle.single}>
        {getOptionLabel(value, options)}
      </div>
    )
  }

  return (
    <AntSelect ref={ref} value={value} {...selectProps}>
      {options.map((o: any) => (
        <Option {...testId('select-option')} key={o.value} {...o.optionProps}>
          {o.optionProps && o.optionProps.tooltip ? (
            <Tooltip title={o.optionProps.tooltip} placement="topLeft">
              {o.label}
            </Tooltip>
          ) : (
            o.label
          )}
        </Option>
      ))}
    </AntSelect>
  )
}) as any
Select.defaultProps = {
  allowClear: true,
  showSearch: true,
  filterOption(input: any, { children }: any) {
    return children && children.toString().toLowerCase().includes(input.toLowerCase())
  },
}
Select.Option = Option

const readOnlyStyle = {
  list: {
    paddingLeft: '24px',
  },
  single: {
    minHeight: '32px',
    paddingLeft: '12px',
    display: 'flex',
    alignItems: 'center',
  },
}

export { Option }
export default Select
