import { GlobalPolicy_App } from '@vms/vmspro3-core/dist/types'

import { useAppSelector } from '../store'
import { selectUserPolicies } from '../selectors/policiesSelectors'

export function useUserPoliciesLoading(): boolean {
  const currentUserLoading = useAppSelector(state => state.user.currentUserLoadingStatus !== 'Loaded')
  const policiesLoading = useAppSelector(state => state.policies.loadingStatus !== 'Loaded')

  return currentUserLoading || policiesLoading
}

export function useUserPolicies(): [userPolicies: GlobalPolicy_App[], loading: boolean] {
  const userPolicies = useAppSelector(selectUserPolicies)
  const loading = useUserPoliciesLoading()

  return [userPolicies, loading]
}
