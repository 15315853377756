import { ReactElement, useCallback } from 'react'
import { Button, Form, Input } from 'antd'

import { updateDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { Decision } from '@vms/vmspro3-core/dist/types'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import style from './Overview.module.css'
import { useAppDispatch } from '../../../../redux'
import { useDecisionData } from '../../../../redux/hooks'
import tourIds from '../../../../tourIds'
import { useTour } from '../../../../hooks/useTour'

type DecisionOverviewFormFields = Pick<Decision, 'name' | 'objective' | 'description'>
export function Overview({ decisionId }: { decisionId: string }): ReactElement {
  const decision = useDecisionData(decisionId)

  const [formInstance] = Form.useForm()

  const { tour } = useTour('Overview Page', [
    {
      target: 'body',
      placement: 'center',
      content: (
        <>
          <p>Welcome to OptionLab! This guided tour will acquaint you with the features of this screen.</p>
        </>
      ),
    },
    {
      target: '#tourButton',
      content: (
        <>
          <p>
            You can access this tour at any time by clicking this button. (Not every screen has a tour yet, though,
            so don't be alarmed if you don't see it.)
          </p>
        </>
      ),
    },
    {
      target: `#${tourIds.tab.overview}`,
      content: (
        <>
          <p>
            You are here! The overview tab contains high-level information about your decision. Make sure you
            provide an objective before you invite other people to participate in the decision...it helps them
            understand what they're doing!
          </p>
        </>
      ),
    },
    {
      target: `#${tourIds.tab.criteria}`,
      content: (
        <>
          <p>This is where you set up the criteria you'll use to make your decision.</p>
        </>
      ),
    },
    {
      target: `#${tourIds.tab.options}`,
      content: `Here's where you'll list the options in the decision.`,
    },
    {
      target: `#${tourIds.tab.participants}`,
      content: `A record of anyone who has participated in the decision will be found here.`,
    },
    {
      target: `#${tourIds.tab.valuemetrics}`,
      content: `Valuemetrics are the results that will help you analyze your decision!`,
    },
  ])

  const dispatch = useAppDispatch()
  const onFinish = useCallback(
    (formValues: DecisionOverviewFormFields) => {
      dispatch(
        updateDecision(decisionId, {
          name: formValues.name,
          description: createHtmlObject(formValues.description?.value ?? ''),
          objective: createHtmlObject(formValues.objective?.value ?? ''),
        })
      )
      formInstance.resetFields()
    },
    [dispatch, decisionId, formInstance]
  )

  return (
    <>
      {tour}
      <Form<DecisionOverviewFormFields>
        form={formInstance}
        onFinish={onFinish}
        initialValues={decision}
        layout="vertical"
        className={style.overviewForm}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={['objective', 'value']} label="Objective">
          <Input.TextArea showCount maxLength={1200} rows={4} />
        </Form.Item>
        <Form.Item name={['description', 'value']} label="Description">
          <Input.TextArea rows={10} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {form => {
            const disableFormControls = !form.isFieldsTouched()
            return (
              <div className={style.formControls}>
                <Button disabled={disableFormControls} type="primary" htmlType="submit">
                  Save
                </Button>
                <Button disabled={disableFormControls} onClick={() => form.resetFields()}>
                  Cancel
                </Button>
              </div>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}
