/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContextType, useCallback, useContext, useEffect } from 'react'
import { Blocker, History, Transition } from 'history'
import { Navigator as BaseNavigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import EditOutlined from '@ant-design/icons/EditOutlined'
import invariant from 'tiny-invariant'
import { Modal, ModalFuncProps } from 'antd'

interface Navigator extends BaseNavigator {
  block: History['block']
}
type NavigationContext_WithBlock = ContextType<any> & { navigator: Navigator }

/**
 * Reimplements useBlocker which has been temporarily removed from stable React Router v6 releases.
 *
 * See: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
function useBlocker(blocker: Blocker, when = true) {
  const routerContext = useContext(NavigationContext as any)

  invariant(routerContext, 'useBlocker() may be used only in the context of a <Router> component.')

  const { navigator } = routerContext as NavigationContext_WithBlock

  useEffect(() => {
    if (!when) return

    const unblock = navigator.block(transition => {
      const autoUnblockingTransition = {
        ...transition,
        retry: () => {
          unblock()
          transition.retry()
        },
      }

      blocker(autoUnblockingTransition)
    })

    return unblock
  }, [navigator, blocker, when])
}

type Props = {
  modalParams?: ModalFuncProps
  modalType?: NonNullable<ModalFuncProps['type']>
  when: boolean
}
/**
 * Navigation confirmation component.  Presents user with a moadl asking if them
 * if they're sure they want to navigate away from a page where they have made
 * uncomitted changes.
 *
 */
const NavConfirmation = ({ when, modalParams, modalType = 'confirm' }: Props) => {
  invariant(typeof when === 'boolean', `prop "when" is type ${typeof when}. Expected boolean.`)

  const blocker = useCallback(
    (transition: Transition) => {
      const modal = Modal[modalType]
      const params = {
        cancelText: 'Stay',
        content: 'Any unsaved changes will be discarded. Are you sure you want to leave?',
        icon: <EditOutlined />,
        okText: 'Leave',
        title: 'Unsaved Changes',
        ...modalParams,
      }

      modal({
        ...params,
        onOk: () => {
          if (params.onOk) params.onOk()
          transition.retry()
        },
      })
    },
    [modalParams, modalType]
  )
  useBlocker(blocker, when)

  return null
}

export default NavConfirmation
