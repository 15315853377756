/* eslint-disable @typescript-eslint/no-explicit-any */
import { Html } from '@vms/vmspro3-core/dist/types'
import { useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import { useDecision } from '../../../../redux/hooks'
import { Table, TableCellRenderer } from '../../../common/Table'
import { EntityNameRenderer } from '../../../common/Table/TableCellRenderer/EntityNameRenderer'
import { ContextRatingNotes } from '../CriteriaRatings/ContextRatingNotes'

type OptionRatingNotesTableData = {
  type: 'Criterion' | 'Participant'
  criterionOrParticipantName: string
  color?: string
  notes?: Html
  subRows?: OptionRatingNotesTableData[]
}

export interface OptionRatingNotesProps {
  decisionId: string
}
export function OptionRatingNotes(props: OptionRatingNotesProps) {
  const { decisionId } = props

  const decision = useDecision(decisionId)

  const columns = useMemo(() => {
    const columns: Column<OptionRatingNotesTableData>[] = [
      {
        Header: 'Criterion',
        accessor: 'criterionOrParticipantName',
        Cell: (cell: CellProps<OptionRatingNotesTableData>) => {
          const { row } = cell
          const text =
            row.original.type === 'Criterion' ? EntityNameRenderer(cell) : row.original.criterionOrParticipantName
          return row.canExpand ? (
            <span {...row.getToggleRowExpandedProps({ style: { paddingLeft: `${row.depth * 20}px` } })}>
              {text} <span style={{ userSelect: 'none' }}>{row.isExpanded ? '▼' : '▶︎'}</span>
            </span>
          ) : (
            <span style={{ paddingLeft: `${row.depth * 20}px` }}>{text}</span>
          )
        },
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: TableCellRenderer.Html as any,
      },
    ]

    return columns
  }, [])
  const data: OptionRatingNotesTableData[] = decision.criteria.leafCriteria
    .filter(c => ['Performance', 'Rated'].includes(c.type))
    .map(c => ({
      type: 'Criterion',
      color: c.color,
      criterionOrParticipantName: c.label({ skipAncestors: 2 }),
      expanded: true,
      subRows: decision
        .getParticipationSession('OptionRating')
        .ratingNotes.filter(rn => rn.participantId !== '*' && rn.contextId === c.id && rn.subjectType === 'Option')
        .map(rn => ({
          type: 'Participant',
          criterionOrParticipantName: decision.participantsById[rn.participantId]?.fullName,
          notes: rn.notes,
        })),
    }))
  return (
    <>
      <Table<OptionRatingNotesTableData> columns={columns} data={data} stripe={false} />
      {decision.criteria.leafCriteria
        .filter(c => ['Performance', 'Rated'].includes(c.type))
        .map(c => (
          <div key={c.id} style={{ marginTop: '8px' }}>
            <ContextRatingNotes
              decisionId={decisionId}
              participationSessionId={decision.getParticipationSession('OptionRating').id}
              participantId="*"
              contextId={c.id}
              contextLabel={`Summary notes for "${c.label({ skipAncestors: 2 })}"`}
              subjectType="Option"
            />
          </div>
        ))}
    </>
  )
}
