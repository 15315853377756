import React from 'react'
import { connect } from 'react-redux'

import { formatTimestamp } from '../../../utils/formatUtils'
import { selectDateTimeFormat } from '../../../redux/selectors'

const ProjectDateDisplay = ({ dateFormat, time }) => <span>{formatTimestamp(time, dateFormat, '-')}</span>
const state2props = state => ({ dateFormat: selectDateTimeFormat(state) })

export default connect(state2props)(ProjectDateDisplay)
