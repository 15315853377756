import React from 'react'
import { Link } from 'react-router-dom'
import mime from 'mime'
import FileExcelOutlined from '@ant-design/icons/FileExcelOutlined'
import FileImageOutlined from '@ant-design/icons/FileImageOutlined'
import FileOutlined from '@ant-design/icons/FileOutlined'
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined'
import FilePptOutlined from '@ant-design/icons/FilePptOutlined'
import FileWordOutlined from '@ant-design/icons/FileWordOutlined'
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined'

import FormAvatar from './FormAvatar'

import { getFileIcon } from '../../../utils/inputUtils'

const browserMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf']

const icons = {
  excel: FileExcelOutlined,
  file: FileOutlined,
  pdf: FilePdfOutlined,
  picture: FileImageOutlined,
  ppt: FilePptOutlined,
  video: VideoCameraOutlined,
  word: FileWordOutlined,
}

const AttachmentLink = ({ url, title, noCache }) => {
  const mimeType = mime.getType(url)
  const extension = mime.getExtension(mimeType)
  const isDownload = !browserMimeTypes.includes(mimeType)
  const download = isDownload ? `${title}.${extension}` : null
  const responseCacheControl = noCache ? '?response-cache-control=No-cache' : ''
  const Icon = icons[getFileIcon(extension)]

  return (
    <Link
      download={download}
      to={{ pathname: url + responseCacheControl }}
      style={style.attachmentLink}
      target="_blank"
    >
      <FormAvatar color="white" icon={<Icon />} />
      {title}
    </Link>
  )
}

const style = {
  attachmentLink: {
    alignItems: 'center',
    display: 'flex',
  },
}

export default AttachmentLink
