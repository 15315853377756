import mime from 'mime'

import md5 from '../utils/md5'
import { API_NAME as apiName } from '../utils/appConsts'

import config from '../config.json'

import { generateClient } from 'aws-amplify/api'
import { get, post } from 'aws-amplify/api'
import { uploadData } from 'aws-amplify/storage'
import { getHeaders } from './AmplifyProxy'

const client = generateClient()

const { stage } = config.instance

const actionResultListeners = {}

const VMSProServerAdapter = {
  async graphql(config) {
    return client.graphql(config, await getHeaders())
  },

  async get(path) {
    return get({
      apiName,
      path: `/${stage}/api${path}`,
      options: { headers: await getHeaders() },
    })
      .response.then(res => res.body.json())
      .catch(this._errorHandler)
  },

  async post(path, body) {
    return post({
      apiName,
      path: `/${stage}/api${path}`,
      options: { headers: await getHeaders(), body },
    })
      .response.then(res => res.body.json())
      .catch(this._errorHandler)
  },

  async fetchInitialState(userId) {
    return this.get(`/initial-state?type=auth&userId=${userId}`)
  },

  _errorHandler: undefined,
  errorHandler(err) {
    if (typeof this._errorHandler !== 'function') {
      console.error('>> warning: error handler not configured properly')
      console.error(err)
    }
    this._errorHandler(err)
  },

  registerErrorHandler(handler) {
    this._errorHandler = handler
  },

  /**
   * Posts one or more Redux actions to the server.
   */
  async postAction(action) {
    const body = Array.isArray(action) ? action : [action]
    try {
      const res = await this.post(`/action`, body)
      action.forEach(action_1 => {
        const listeners = actionResultListeners[action_1.meta.seq] || []
        listeners.forEach(l => l(null, res))
      })
      return res
    } catch (err) {
      action.forEach(action_2 => {
        const listeners_1 = actionResultListeners[action_2.meta.seq] || []
        listeners_1.forEach(l_1 => l_1(err))
      })
      throw err
    }
  },

  /**
   * Posts a single action to the server.  IMPORTANT NOTE: this method bypasses
   * the usual error-handling method, so it's up to the client to handle errors
   * (promise rejections) from this.  If the action succeeds, the action is handled
   * on the server side, and broadcast to other clients (just as in postAction).
   */
  async tryAction(action) {
    if (Array.isArray(action)) throw new Error('this method only supports single actions')
    return this.post(`/action`, [action])
  },

  stripeQuery(action) {
    const qs = '?action=' + encodeURIComponent(JSON.stringify(action))
    return this.get(`/stripe${qs}`)
  },

  resendPassword(email) {
    return this.post(`/resend-password`, { email })
  },

  getItem(id) {
    return this.get(`/get-item/${id}`)
  },

  /**
   * @param {string} ancestry
   * @param {string|void} entityType
   * @param {Array.<string|Array.<string|number>>} [projection]
   */
  getItemsByAncestry(ancestry, entityType, projection) {
    const params = {}
    if (entityType) params.entityType = entityType
    if (projection) params.projection = JSON.stringify(projection)
    // note that underscores and parentheses do not need to be URI-encoded
    const path = `/get-items/${encodeURIComponent(ancestry)}?${new URLSearchParams(params).toString()}`
    return this.get(path)
  },

  /**
   * @param {string} ancestryBeginsWith
   * @param {string} entityType
   * @param {Array.<string|Array.<string|number>>} [projection]
   */
  getItemsByAncestryBeginsWith(ancestryBeginsWith, entityType, projection) {
    // ancestry is encoded in getItemsByAncestry
    return this.getItemsByAncestry(`begins_with(${ancestryBeginsWith})`, entityType, projection)
  },

  getRiskContextReport(entityId) {
    return this.get(`/risk-context-report/${entityId}`)
  },

  getReport(body) {
    return this.post(`/reports`, body)
  },

  authorizeImpersonation(authUserId) {
    return this.get(`/impersonate/${authUserId}`)
  },

  getGlobalPolicies() {
    return this.get(`/policies`)
  },

  /**
   * @typedef {Object} ProjectFileInfo
   * @property {string} projectId - Project ID
   * @property {string} hash - MD5 hash of file contents
   * @property {string} key - S3 key for project file
   * @property {string} ext - File extension (including period)
   * @property {string} url - URL path to file
   * @property {string} contentType - Detected IANA Media Type
   * @property {File} file - File contents
   */

  /**
   * Get S3 key for entity attachment.  The key is constructed from the account ID, entity ID and the
   * MD5 hash of the file contents, with its extension preserved.
   *
   * @param {string} accountId
   * @param {string} entityId
   * @param {File} file - the file to upload (https://developer.mozilla.org/en-US/docs/Web/API/File)
   * @return {ProjectFileInfo} Identifying information about this project file
   */
  async getEntityAttachmentInfo(accountId, entityId, file) {
    const hash = await md5(file)
    const ext = file.name.trim().replace(/.*(\.\w+)$/, '$1')
    const contentType = mime.getType(ext)
    const key = `${accountId}/${entityId}/${hash}${ext}`
    return {
      accountId,
      entityId,
      hash,
      key,
      ext,
      url: '/entity-resources/' + key,
      contentType,
      file,
    }
  },

  /**
   * Uploads an entity attachment to the server.
   *
   * @param {EntityAttachmentInfo} entityAttachmentInfo - Identifying information about file.
   * @param {function(int, int)} uploadProgress - Callback to report progres; receives loaded and total as args.
   */
  async uploadEntityAttachment(entityAttachmentInfo, uploadProgress) {
    const { key, url, file, contentType } = entityAttachmentInfo
    await uploadData({
      key,
      data: file,
      options: {
        contentType,
        customPrefix: { public: 'entity-resources/' },
        progressCallback: ({ loaded, total }) => {
          uploadProgress({ url, progress: Math.round((loaded / total) * 100), loaded, total })
        },
      },
    }).result
    return url
  },
}

export default VMSProServerAdapter
