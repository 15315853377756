import { css } from 'glamor'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

const { Color, RiskColor } = systemConsts

export const riskTableStyle = css({
  '#root & th': {
    borderColor: Color.GREY_2,
    // Filter Icons
    ' i': {
      mixBlendMode: 'multiply',
    },
    ' i:not(.ant-table-filter-selected)': {
      color: Color.GREY_2,
    },
    // Managed Top Header Styling
    '.risk-impact-response-managed': {
      backgroundColor: RiskColor.MANAGED_BACKGROUND,
      color: '#fff',
      ':hover': { backgroundColor: RiskColor.MANAGED_BACKGROUND_HOVER },
    },
    // Unmanaged Top Header Styling
    '.risk-impact-response-unmanaged': {
      backgroundColor: Color.GREY_1,
      ':hover': { backgroundColor: Color.GREY_1_DARK },
    },
    // Unmanaged Header Child Styling
    '.risk-impact-measure-unmanaged': {
      backgroundColor: Color.GREY_1,
      ':hover': { backgroundColor: Color.GREY_1_DARK },
    },
    // Managed Header Child Styling
    '.risk-impact-measure-managed': {
      backgroundColor: Color.GREY_1_DARK,
      ':hover': { backgroundColor: Color.GREY_1_DARKER },
    },
    // Cost Header Styling
    '.risk-impact-measure-cost': {
      backgroundColor: RiskColor.COST_IMPACT,
      ':hover': { backgroundColor: RiskColor.COST_IMPACT_HOVER },
    },
    // Schedule Header Styling
    '.risk-impact-measure-time': {
      backgroundColor: RiskColor.TIME_IMPACT,
      ':hover': { backgroundColor: RiskColor.TIME_IMPACT_HOVER },
    },
    // Performance Header Styling
    '.risk-impact-measure-perf': {
      backgroundColor: RiskColor.PERF_IMPACT,
      ':hover': { backgroundColor: RiskColor.PERF_IMPACT_HOVER },
    },
  },
})
