import { ReactElement, useCallback, useMemo, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { parseFullName } from 'parse-full-name'

import { Participant } from '@vms/vmspro3-core/dist/types'
import { EmailRegex } from '@vms/vmspro3-core/dist/systemConsts'

import style from './AdHocParticipantSelection.module.css'

export function getShortNameAndInitials(fullName: string) {
  // parseFullName, when confronted with a single name, assumes it's the last name...which
  // is probably not what we're looking for here
  if (!/\s/.test(fullName.trim())) {
    return {
      shortName: fullName.trim(),
      initials: fullName.trim()[0],
    }
  }
  const result = parseFullName(fullName)
  const firstInitial = result.first?.[0] ?? ''
  const lastInitial = result.last?.[0] ?? ''
  return {
    shortName: result.first || result.last || fullName.trim(),
    initials: firstInitial + lastInitial,
  }
}

interface AdHocParticipantNameFields {
  shortName: string
  initials: string
}
interface AdHocParticipantNameFormProps {
  fullName: string
  onSubmit: (fields: AdHocParticipantNameFields) => void
  onCancel: VoidFunction
}
function AdHocParticipantNameForm({ fullName, onSubmit, onCancel }: AdHocParticipantNameFormProps) {
  const initialValues = useMemo(() => getShortNameAndInitials(fullName), [fullName])

  return (
    <>
      {fullName && <h3>Welcome {fullName}!</h3>}
      <p>How would you like to be referred to?</p>
      <Form<AdHocParticipantNameFields>
        layout="vertical"
        requiredMark="optional"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Form.Item label="Preferred name" name="shortName" rules={[{ required: true, whitespace: true }]}>
          <Input autoFocus size="large" />
        </Form.Item>
        <Form.Item label="Initials" name="initials" rules={[{ required: true, whitespace: true }]}>
          <Input size="large" />
        </Form.Item>
        <div className={style.formControls}>
          <Button type="link" onClick={onCancel}>
            <LeftOutlined /> Back
          </Button>
          <Button type="primary" htmlType="submit">
            Next <RightOutlined />
          </Button>
        </div>
      </Form>
    </>
  )
}

interface AdHocParticipantContactFields {
  phone?: string
  email?: string
}
interface AdHocParticipantContactFormProps {
  onSubmit: (fields: AdHocParticipantContactFields) => void
  onCancel: VoidFunction
  loading: boolean
}
function AdHocParticipantContactForm({ onSubmit, onCancel, loading }: AdHocParticipantContactFormProps) {
  return (
    <>
      <p>
        You can optionally provide some contact information; we will only use it to contact you about this
        decision, and we&apos;ll never sell your info!
      </p>
      <Form<AdHocParticipantContactFields> layout="vertical" requiredMark="optional" onFinish={onSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              validateTrigger: 'onBlur',
              pattern: EmailRegex,
              message: 'Please enter a valid email address',
            },
          ]}
        >
          <Input autoFocus size="large" type="email" placeholder="example@domain.com" />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input type="tel" size="large" />
        </Form.Item>
        <div className={style.formControls}>
          <Button type="link" onClick={onCancel}>
            <LeftOutlined /> Back
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Next <RightOutlined />
          </Button>
        </div>
      </Form>
    </>
  )
}

interface ParticipantRegistrationData {
  fullName: string
  shortName: string
  initials: string
  phone?: string
  email?: string
}

type ParticipantSelectionStep = 'selection' | 'registration-name' | 'registration-contact' | 'name-not-found'

interface AdHocParticipantSelectionProps {
  participants: Participant[]
  setParticipantId: (participantId: string) => void
  createAdHocParticipant: (params: {
    fullName: string
    shortName: string
    initials: string
    phone?: string
    email?: string
  }) => Promise<void>
  decisionName: string
}
export function AdHocParticipantSelection({
  participants,
  setParticipantId,
  createAdHocParticipant,
  decisionName,
}: AdHocParticipantSelectionProps) {
  const [step, setStep] = useState<ParticipantSelectionStep>('selection')
  const [matchingParticipant, setMatchingParticipant] = useState<Participant | undefined>()
  const [participantData, setParticipantData] = useState<Partial<ParticipantRegistrationData>>({})
  const [loading, setLoading] = useState(false)

  const setRegisterParticipant = useCallback(() => {
    const matchingParticipant = participants.find(
      participant => participant.fullName.toLowerCase().trim() === participantData.fullName?.toLowerCase().trim()
    )
    if (matchingParticipant) {
      setMatchingParticipant(matchingParticipant)
    } else {
      setStep('registration-name')
    }
  }, [participants, participantData.fullName])

  const fullNameInput = (
    <Form.Item
      {...(matchingParticipant && {
        hasFeedback: true,
        validateStatus: 'error',
        help: (
          <span>
            There is already a participant with the name "{matchingParticipant.fullName}". If you are a{' '}
            <i>different</i> "{matchingParticipant.fullName}" please add an initial or suffix to your name.
            Otherwise, select "This is Me" below.
          </span>
        ),
      })}
    >
      <Input
        autoFocus
        size="large"
        onChange={event => {
          setMatchingParticipant(undefined)
          setParticipantData({ fullName: event.currentTarget.value })
        }}
        aria-label="Full name (e.g. first, last)"
        placeholder="Full name (e.g. first, last)"
        className={style.input}
      />
    </Form.Item>
  )

  const participantsListItems = useMemo(
    () =>
      participants
        .filter(participant =>
          participant.fullName.toLowerCase().startsWith(participantData.fullName?.toLowerCase().trim() ?? '')
        )
        .map(participant => (
          <li
            tabIndex={0}
            key={participant.id}
            onClick={() => setParticipantId(participant.id)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                setParticipantId(participant.id)
              }
            }}
          >
            {participant.fullName}
          </li>
        )),
    [participants, participantData.fullName, setParticipantId]
  )
  const participantsList = <ul className={style.participantsList}>{participantsListItems}</ul>

  const showRegistrationButton = (
    <Button disabled={!participantData.fullName} type="primary" onClick={setRegisterParticipant}>
      Next <RightOutlined />
    </Button>
  )

  const NameInputLabel = () => (
    <>
      <p>Thank you for participating!</p>
      <p>What's your full name (e.g. first, last)?</p>
    </>
  )

  const contentByStep: Record<ParticipantSelectionStep, ReactElement> = {
    selection:
      participants.length >= 8 || participants.length === 0 ? (
        <>
          <NameInputLabel />
          {fullNameInput}
          {!!participantData.fullName &&
            (participantsListItems.length > 0 ? (
              participantsList
            ) : (
              <div className={style.formControls}>{showRegistrationButton}</div>
            ))}
        </>
      ) : (
        <>
          <p>
            Thank you for participating!
            <br />
            Please choose your name:
          </p>
          <br />
          {participantsList}
          <Button type="link" onClick={() => setStep('name-not-found')}>
            Don't see your name?
          </Button>
        </>
      ),
    'name-not-found': (
      <>
        <NameInputLabel />
        {fullNameInput}
        <div className={style.formControls}>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setParticipantData({})
              setMatchingParticipant(undefined)
              setStep('selection')
            }}
          >
            <LeftOutlined /> Back
          </Button>
          {matchingParticipant ? (
            <Button type="primary" onClick={() => setParticipantId(matchingParticipant.id)}>
              This is Me <RightOutlined />
            </Button>
          ) : (
            showRegistrationButton
          )}
        </div>
      </>
    ),
    'registration-name': (
      <AdHocParticipantNameForm
        fullName={participantData.fullName ?? ''}
        onSubmit={nameFields => {
          setParticipantData(data => ({ ...data, ...nameFields }))
          setStep('registration-contact')
        }}
        onCancel={() => {
          setParticipantData({})
          setMatchingParticipant(undefined)
          setStep('selection')
        }}
      />
    ),
    'registration-contact': (
      <AdHocParticipantContactForm
        onSubmit={contactFields => {
          setLoading(true)
          const { fullName, shortName, initials } = participantData
          if (!(fullName && shortName && initials)) {
            return
          }

          createAdHocParticipant({
            fullName,
            shortName,
            initials,
            ...contactFields,
          })
        }}
        onCancel={() => {
          setParticipantData({})
          setMatchingParticipant(undefined)
          setStep('selection')
        }}
        loading={loading}
      />
    ),
  }

  return (
    <div className={style.container}>
      <h2>{decisionName}</h2>
      <div className={style.registrationForm}>{contentByStep[step]}</div>
    </div>
  )
}
