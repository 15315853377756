/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  endParticipationSession,
  startParticipationSession,
  updateParticipationSession,
} from '@vms/vmspro3-core/dist/actions/decision'
import { Decision } from '@vms/vmspro3-core/dist/nextgen/decision'
import {
  ParticipationSession,
  ParticipationSessionType,
} from '@vms/vmspro3-core/dist/nextgen/participationSession'
import { message, Button, ButtonProps, Drawer, Modal, Row, Col, Space, Checkbox } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDice, faUser, faUsers, faUsersSlash } from '@fortawesome/free-solid-svg-icons'
import QRCode from 'react-qr-code'
import { CopyOutlined, FundProjectionScreenOutlined, PauseOutlined, CloseOutlined } from '@ant-design/icons'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount } from '../../../../context'
import { useAugmentAction } from '../../../../hooks/useAugmentAction'
import { useAppDispatch } from '../../../../redux'
import Server from '../../../../server/VMSProServerAdapter'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useModalState } from '../../../../hooks/useModalState'
import { ParticipationDemoModal } from './ParticipationDemoModal'
import useAuthz from '../../../../hooks/useAuthz'
import { match } from 'ts-pattern'
import logo from '../../../../assets/OptionLab_blackText.svg'
dayjs.extend(duration)

interface CopyParticipationButtonProps {
  url: string
  type?: ButtonProps['type']
}
function CopyLinkButton(props: CopyParticipationButtonProps) {
  const onClick = useCallback(() => {
    navigator.clipboard.writeText(props.url)
    message.success('Link copied to clipboard.')
  }, [props.url])
  return (
    <Button icon={<CopyOutlined />} onClick={onClick} type={props.type}>
      Copy Link
    </Button>
  )
}

interface ParticipationControlHeaderProps {
  openFor?: string
  title: string
}
function ParticipationControlHeader(props: ParticipationControlHeaderProps) {
  const { openFor, title } = props
  return (
    <Row gutter={16} style={{ marginBottom: 12 }}>
      <Col flex="55px">
        {openFor ? (
          <FontAwesomeIcon icon={faUsers as any} size="3x" color="#bbb" />
        ) : (
          <FontAwesomeIcon icon={faUsersSlash as any} size="3x" color="#bbb" />
        )}
      </Col>
      <Col flex="auto">
        <h3 style={{ marginBottom: 0 }}>{title}</h3>
        {openFor ? (
          <p style={{ maxWidth: '120px', marginBottom: 0 }}>Open{openFor && <span> for {openFor}</span>}</p>
        ) : (
          <p style={{ maxWidth: '120px', marginBottom: 0 }}>Closed</p>
        )}
      </Col>
    </Row>
  )
}

interface InactiveParticipationSessionControlProps {
  onOpen: () => void
  decision: Decision
  participationSession: ParticipationSession
  title: string
  loading: boolean
}
function InactiveParticipationSessionControl(props: InactiveParticipationSessionControlProps) {
  const { decision, participationSession: ps, title } = props
  const dispatch = useAppDispatch()
  const changeIncludeObjCri = useCallback(() => {
    dispatch(updateParticipationSession(decision.id, ps.id, { includeIntrinsic: !ps.includeIntrinsic }))
  }, [dispatch, decision.id, ps.id, ps.includeIntrinsic])
  return (
    <div style={{ margin: '32px 0' }}>
      <ParticipationControlHeader title={title} />
      <Space direction="vertical">
        {decision.type !== 'Regular' && ps.type === 'CriteriaPrioritization' && (
          <Checkbox checked={ps.includeIntrinsic} onChange={changeIncludeObjCri}>
            Include objective criteria
          </Checkbox>
        )}
        <Button type="primary" onClick={props.onOpen} loading={props.loading}>
          Open {title}
        </Button>
      </Space>
    </div>
  )
}

interface ActiveParticipationSessionControlProps {
  decision: Decision
  participationSession: ParticipationSession
  title: string
  sessionUrl: string
  openFor?: string
  onClose: () => void
  onPresent: () => void
  loading: boolean
}
function ActiveParticipationSessionControl(props: ActiveParticipationSessionControlProps) {
  const { decision, participationSession: ps, title, sessionUrl, openFor, onClose, onPresent } = props

  const { modal, showModal, hideModal } = useModalState()
  const onDemo = useCallback(
    () =>
      showModal(
        <ParticipationDemoModal decisionId={decision.id} participationSessionId={ps.id} hideModal={hideModal} />
      ),
    [showModal, decision, ps.id, hideModal]
  )

  const authz = useAuthz()
  const showDemoButton = authz({ module: 'Decision', type: 'Generate Demo Data' })

  const participateButtonText = match(ps.type)
    .with('CriteriaPrioritization', () => 'Prioritize')
    .with('OptionRating', () => 'Rate Options')
    .with('OutcomeProbability', () => 'Assess Probability')
    .exhaustive()

  return (
    <div style={{ margin: '32px 0' }}>
      {modal}
      <ParticipationControlHeader title={title} openFor={openFor} />
      <Space direction="vertical">
        <Button type="primary" onClick={onClose} icon={<PauseOutlined />} loading={props.loading}>
          Close {title}
        </Button>
        <Button
          href={sessionUrl}
          target="blank"
          type="link"
          icon={<FontAwesomeIcon icon={faUser as any} style={{ marginRight: 8 }} />}
        >
          {participateButtonText}
        </Button>
        <CopyLinkButton type="link" url={sessionUrl} />
        <Button type="link" onClick={onPresent} icon={<FundProjectionScreenOutlined />}>
          Presentation Mode
        </Button>
        {/* Currently, demo is only configured to work for prioritization */}
        {showDemoButton && ps.type === 'CriteriaPrioritization' && (
          <Button
            icon={<FontAwesomeIcon icon={faDice as any} style={{ marginRight: 8 }} />}
            type="link"
            onClick={onDemo}
          >
            Demo
          </Button>
        )}
      </Space>
    </div>
  )
}

interface ParticipationPresentationProps {
  decision: Decision
  sessionUrl: string
  title: string
  onClose: () => void
}
function ParticipationPresentation(props: ParticipationPresentationProps) {
  const { decision, sessionUrl, title } = props
  const { account } = useAccount()
  const containerStyle: CSSProperties = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
  return (
    <Drawer onClose={props.onClose} open width="100%">
      <div style={containerStyle}>
        <Row gutter={36}>
          <Col>
            <img src={logo} width={400} alt="OptionLab Logo" style={{ marginBottom: '30px' }} />
            <h1>
              {account.name}: {decision.name}
            </h1>
            <h2>{title}</h2>
            <h3 style={{ marginBottom: 0 }}>Decision objective:</h3>
            <p>{decision.objective?.value}</p>
          </Col>
          <Col>
            <div style={{ marginTop: 12, marginBottom: 6 }}>
              <QRCode value={sessionUrl} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col>
            <Button onClick={props.onClose} icon={<CloseOutlined />}>
              Close
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '12px' }}>
          <Col>
            <a href={sessionUrl} style={{ marginBottom: 36 }}>
              {sessionUrl}
            </a>
          </Col>
        </Row>
      </div>
    </Drawer>
  )
}

function useParticipationControl(decision: Decision, type: ParticipationSessionType, title: string) {
  const { accountId } = useAccount()
  const ratingContexts = useMemo(() => decision.getRatingContexts(type, '*'), [decision, type])
  const hasIntrinsicRoot = useMemo(() => !!decision.criteria.all.find(c => c.type === 'IntrinsicRoot'), [decision])
  const ps = decision.getParticipationSession(type)
  const canStart = ratingContexts.length > 0
  const dispatch = useAppDispatch()
  const augmentAction = useAugmentAction(accountId)
  const [loadingParticipation, setLoadingParticipation] = useState(false)
  const openParticipation = useCallback(() => {
    if (ps.type === 'CriteriaPrioritization' && !canStart) {
      Modal.error({
        title: `Cannot Start Participation Session`,
        content: (
          <>
            {decision.type === 'Regular' ? (
              <p>
                To start a criteria prioritization session, you must either have two or more subjective criteria,
                or have the "include objective criteria" option checked.
              </p>
            ) : (
              <p>To start a criteria prioritization session, you must have two or more subjective criteria.</p>
            )}
          </>
        ),
      })
      return
    }
    const action = augmentAction(startParticipationSession(decision.id, ps.id))
    setLoadingParticipation(true)
    Server.tryAction(action).then(() => {
      action.meta.ephemeral = true
      dispatch(action)
      setLoadingParticipation(false)
    })
  }, [ps.type, ps.id, canStart, augmentAction, decision.id, decision.type, dispatch])
  const closeParticipation = useCallback(
    () =>
      Modal.confirm({
        title: 'Close Participation',
        content: `Are you sure you want to close ${title.toLowerCase()}?`,
        onOk: () => {
          dispatch(endParticipationSession(decision.id, ps.id))
        },
      }),
    [title, dispatch, decision.id, ps.id]
  )
  return {
    hasIntrinsicRoot,
    loadingParticipation,
    openParticipation,
    closeParticipation,
  }
}

// TODO: we should probably have a better pattern for this
export function ParticipationSessionControl(props: ParticipationSessionControlProps) {
  try {
    // this is just here to ensure getParticipationSession doesn't throw an error
    props.decision.getParticipationSession(props.type)
    return <_ParticipationSessionControl {...props} />
  } catch (err) {
    return (
      <div>
        <h3>Participation session missing</h3>
        <p>TODO: provide a way to create it</p>
      </div>
    )
  }
}

interface ParticipationSessionControlProps {
  decision: Decision
  type: ParticipationSessionType
}
export function _ParticipationSessionControl(props: ParticipationSessionControlProps) {
  const { accountCommonId } = useAccount()
  const [openFor, setOpenFor] = useState<undefined | string>()
  const [presMode, setPresMode] = useState(false)
  const ps = props.decision.getParticipationSession(props.type)
  const title = match(ps.type)
    .with('CriteriaPrioritization', () => 'Prioritization')
    .with('OptionRating', () => 'Option Rating')
    .with('OutcomeProbability', () => 'Probability Assessment')
    .exhaustive()
  useEffect(() => {
    if (!ps) return
    if (ps.status === 'Active' && ps.history && ps.history.length > 0) {
      const mostRecentEvent = ps.history[ps.history.length - 1]
      const update = () => {
        const duration = dayjs.duration(dayjs().diff(mostRecentEvent.timestamp))
        if (duration.asHours() < 24) {
          setOpenFor(duration.format('H:mm:ss'))
        } else {
          setOpenFor(Math.floor(duration.asDays()) + ' day(s), ' + duration.format('H:mm:ss') + ' hours')
        }
      }
      update()
      const intervalId = window.setInterval(update, 500)
      return () => window.clearInterval(intervalId)
    }
    setOpenFor(undefined)
  }, [ps])

  const { openParticipation, closeParticipation, loadingParticipation } = useParticipationControl(
    props.decision,
    props.type,
    title
  )

  const onPresent = useCallback(() => {
    setPresMode(true)
  }, [])

  const sessionUrl =
    window.location.origin + `/${accountCommonId}/decision/${props.decision.id}/participation/${ps.id}`

  if (presMode) {
    return (
      <ParticipationPresentation
        sessionUrl={sessionUrl}
        title={title}
        decision={props.decision}
        onClose={() => setPresMode(false)}
      />
    )
  }

  return (
    <div data-tour-id="Participation Session Control">
      {ps.status === 'Active' ? (
        <ActiveParticipationSessionControl
          title={title}
          decision={props.decision}
          participationSession={ps}
          sessionUrl={sessionUrl}
          openFor={openFor}
          onClose={closeParticipation}
          onPresent={onPresent}
          loading={loadingParticipation}
        />
      ) : (
        <InactiveParticipationSessionControl
          title={title}
          onOpen={openParticipation}
          decision={props.decision}
          participationSession={ps}
          loading={loadingParticipation}
        />
      )}
    </div>
  )
}
