import { useCallback, useMemo, ReactElement, useEffect } from 'react'
import cx from 'classnames'
import { UnreachableCaseError } from 'ts-essentials'
import { Empty } from 'antd'
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Hooks,
  IdType,
  Meta,
  Row,
  RowSelectPosition,
  TableCellProps,
  TableHeaderProps,
  TableOptions,
  useTable,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect,
  TableInstance,
} from 'react-table'

import { TableFilters } from './TableFilters'
import { Pagination } from './Pagination'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'

import style from './Table.module.css'
import * as sortTypes from './sortTypes'

const defaultColumn = {
  Filter: TableFilters.Text,
}

function getHeaderProps<T extends Record<string, unknown>>(
  props: Partial<TableHeaderProps>,
  meta: Meta<T, { column: HeaderGroup<T> }>
): Partial<TableHeaderProps> | Partial<TableHeaderProps>[] {
  return [
    props,
    {
      style: {
        textAlign: meta.column.align,
        width: meta.column.width,
        maxWidth: meta.column.maxWidth,
      },
    },
    meta.column.getSortByToggleProps(),
  ]
}

function getCellProps<T extends Record<string, unknown>>(
  props: Partial<TableCellProps>,
  meta: Meta<T, { cell: Cell<T> }>
): Partial<TableCellProps> | Partial<TableCellProps>[] {
  return [
    props,
    {
      style: {
        textAlign: meta.cell.column.align,
        ...meta.cell.column.style,
      },
    },
  ]
}

function renderRows<T extends Record<string, unknown>>(
  rows: Row<T>[],
  prepareRow: (row: Row<T>) => void
): JSX.Element[] {
  return rows
    .map((row, rowIndex) => {
      prepareRow(row)

      const className = cx(style.tableRow, {
        [style.expanded]: row.isExpanded,
        [style.nested]: row.depth > 0,
        [style.first]: rowIndex === 0,
        [style.last]: rowIndex === rows.length - 1,
      })

      const rowElements = [
        <tr {...row.getRowProps({ className })}>
          {row.cells.map(cell => (
            <td {...cell.getCellProps(getCellProps)}>{cell.render('Cell')}</td>
          ))}
        </tr>,
      ]

      if (row.isExpanded && row.subRows.length > 0) {
        return rowElements.concat(...renderRows(row.subRows, prepareRow))
      }

      return rowElements
    })
    .flat()
}

interface SortIcons extends Record<'asc' | 'desc', string | ReactElement> {}
const defaultSortIcons: SortIcons = {
  asc: '▲',
  desc: '▼',
}

type TableOptionProperties =
  | 'data'
  | 'columns'
  | 'disableFilters'
  | 'disableSortBy'
  | 'autoResetSortBy'
  | 'autoResetSelectedRows'
  | 'initialState'
interface TableProps<T extends Record<string, unknown>> extends Pick<TableOptions<T>, TableOptionProperties> {
  pagination?: boolean
  stripe?: boolean
  fixFirstCol?: boolean
  sortIcons?: SortIcons
  rowSelectPosition?: RowSelectPosition
  hiddenColumns?: IdType<T>[]
  /**
   * Function to declare row control elements. More than one element
   * should be wrapped in a React Fragment
   */
  rowControls?: (row: T) => ReactElement | ReactElement[]
  /**
   * Function to declare left-side toolbar elements. More than one element
   * should be wrapped in a React Fragment.
   */
  toolbarLeft?: (instance: TableInstance<T>) => ReactElement
  /**
   * Function to declare right-side toolbar elements. More than one element
   * should be wrapped in a React Fragment.
   */
  toolbarRight?: (instance: TableInstance<T>) => ReactElement
  /**
   * onSortByChanged is a callback to allow the table to manage data
   * sorting elsewhere and should not be used to externally manage
   * the order of table row data
   */
  onSortByChanged?: (sortedData: T[]) => void
}
export function Table<T extends Record<string, unknown>>({
  data,
  columns,
  disableFilters = false,
  disableSortBy = false,
  autoResetSortBy = false,
  pagination = false,
  stripe = true,
  fixFirstCol = false,
  sortIcons = defaultSortIcons,
  autoResetSelectedRows,
  hiddenColumns,
  rowSelectPosition,
  rowControls,
  toolbarLeft,
  toolbarRight,
  onSortByChanged,
  initialState,
}: TableProps<T>): ReactElement {
  const _columns = useMemo<readonly Column<T>[]>(() => {
    if (!rowControls) return columns

    return [
      ...columns,
      {
        id: 'action',
        align: 'right',
        Cell: ({ row }: { row: Row<T> }) => <div className={style.actionIcons}>{rowControls?.(row.original)}</div>,
      },
    ]
  }, [columns, rowControls])

  const rowSelectColumnHook = useCallback(
    (hooks: Hooks<T>) => {
      if (rowSelectPosition) {
        const selectCol: Pick<ColumnInstance<T>, 'id' | 'Header' | 'Cell' | 'align'> = {
          id: 'select',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row: { getToggleRowSelectedProps } }) => (
            <div>
              <IndeterminateCheckbox {...getToggleRowSelectedProps()} />
            </div>
          ),
        }

        switch (rowSelectPosition) {
          case 'first': {
            hooks.visibleColumns.push(columns => [selectCol, ...columns])
            break
          }
          case 'last': {
            selectCol.align = 'right'
            hooks.visibleColumns.push(columns => [...columns, selectCol])
            break
          }
          default: {
            throw new UnreachableCaseError(rowSelectPosition)
          }
        }
      }
    },
    [rowSelectPosition]
  )

  const tableHooks = [useFilters, useSortBy, useExpanded, usePagination, useRowSelect, rowSelectColumnHook]

  const instance = useTable<T>(
    {
      data,
      columns: _columns,
      initialState,
      defaultColumn,
      disableFilters,
      disableSortBy,
      autoResetSortBy,
      sortTypes,
      expandSubRows: false,
      autoResetSelectedRows,
      useControlledState: useCallback(
        state => (hiddenColumns ? { ...state, hiddenColumns } : state),
        [hiddenColumns]
      ),
    },
    ...tableHooks
  )

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // post-filtering
    page,
  } = instance

  const displayRows = pagination ? page : rows

  useEffect(() => {
    if (onSortByChanged) {
      const sortedData = rows.map(row => row.original)
      onSortByChanged(sortedData)
    }
  }, [onSortByChanged, rows])

  const renderColumnSortIcon = useCallback(
    (column: HeaderGroup<T>) => {
      if (!column.isSorted) return null

      return <span> {column.isSortedDesc ? sortIcons.desc : sortIcons.asc}</span>
    },
    [sortIcons]
  )

  const containerClasses = [style.table, fixFirstCol ? style.tableFixFirstCol : undefined]
    .filter(Boolean)
    .join(' ')

  return (
    <div className={containerClasses}>
      {(toolbarLeft || toolbarRight) && (
        <div className={style.toolbar}>
          <div>{toolbarLeft?.(instance)}</div>
          <div>{toolbarRight?.(instance)}</div>
        </div>
      )}
      <table {...getTableProps()} className={stripe ? 'stripe' : ''}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(getHeaderProps)}>
                  {column.render('Header')}
                  {renderColumnSortIcon(column)}
                  {/*
                    TODO: filter styling - should we hide in a dropdown or always show filter?
                    if hiding, should show a list of tags with applied filters and alter icon
                    to show that a filter is applied on the column.
                  */}
                  {column.canFilter && column.filter ? <div>{column.render('Filter')}</div> : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {data.length > 0 ? (
          <tbody {...getTableBodyProps()} data-stripe={stripe}>
            {renderRows(displayRows, prepareRow)}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={allColumns.length} style={{ textAlign: 'center' }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {pagination && <Pagination tableInstance={instance} />}
    </div>
  )
}
