import React from 'react'
/** @jsxImportSource @emotion/react */
import Chunk from './Chunk'

export default function Cluster({
  changeCurrentTooltip,
  chunkMargin,
  columnWidth,
  content,
  displayRange,
  greatestMagnitude,
  hasRelativeScale,
  pinnedTooltip,
  pinTooltip,
  statSelections,
}) {
  return (
    <div
      css={{
        ...content.style,
        display: 'flex',
        flexWrap: 'wrap-reverse',
        alignContent: 'flex-start',
        transitionProperty: 'height, width, flex',
        transitionDuration: '0.8s',
      }}
    >
      {content.articles.map((article, idx) => (
        <Chunk
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          article={article}
          changeCurrentTooltip={changeCurrentTooltip}
          chunkMargin={chunkMargin}
          columnWidth={columnWidth}
          displayRange={displayRange}
          greatestMagnitude={greatestMagnitude}
          hasRelativeScale={hasRelativeScale}
          statSelections={statSelections}
          pinnedTooltip={pinnedTooltip}
          pinTooltip={pinTooltip}
        />
      ))}
    </div>
  )
}
