import React from 'react'
import { css } from 'glamor'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

const { RiskColor, RISK_COLOR_MATRIX } = systemConsts

const Heatmap = ({
  activeCells,
  customMatrix,
  probability,
  impactType,
  costImpact,
  timeImpact,
  perfImpact,
  costAbbreviation,
  timeAbbreviation,
  perfAbbreviation,
  height,
  width,
  onClick,
}) => {
  // scales are hardcoded for now, though we may use different scales in the future. note that the
  // probability scale is reversed for a bottom-to-top y-axis scale built from top-to-bottom HTML.
  const probScale = ['VH', 'H', 'M', 'L', 'VL']
  const attrScale = ['VL', 'L', 'M', 'H', 'VH']

  const matrix = customMatrix || probScale.map(() => attrScale.map(() => ''))

  const probIdx = probScale.indexOf(probability)
  if (probIdx >= 0) {
    const costIdx = attrScale.indexOf(costImpact)
    const timeIdx = attrScale.indexOf(timeImpact)
    const perfIdx = attrScale.indexOf(perfImpact)

    if (costIdx >= 0) matrix[probIdx][costIdx] += costAbbreviation
    if (timeIdx >= 0) matrix[probIdx][timeIdx] += timeAbbreviation
    if (perfIdx >= 0) matrix[probIdx][perfIdx] += perfAbbreviation
  }

  return (
    <div style={style.container(height, width)}>
      <div style={style.yAxisTitle}>Probability</div>
      <div style={style.plot}>
        <div style={style.yAxisLegend}>
          {probScale.map(k => (
            <span key={k}>{k}</span>
          ))}
        </div>
        {matrix.map((row, rIdx) => (
          <div key={probScale[rIdx]} {...style.row}>
            {row.map((totalRisks, cIdx) => (
              <div
                key={attrScale[cIdx]}
                onClick={() => onClick && onClick(rIdx, cIdx)}
                {...style.cell({
                  impactType,
                  rIdx,
                  cIdx,
                  isActive: activeCells && activeCells.some(([r, c]) => r === rIdx && c === cIdx),
                  group: activeCells,
                })}
              >
                {totalRisks || ''}
              </div>
            ))}
          </div>
        ))}
        <div style={style.xAxisLegend}>
          {attrScale.map(k => (
            <span key={k}>{k}</span>
          ))}
        </div>
      </div>
      <div style={style.xAxisTitle}>Impact</div>
    </div>
  )
}
Heatmap.defaultProps = {
  activeCells: false,
  costAbbreviation: '$',
  timeAbbreviation: 'S', // "S" for "schedule"
  perfAbbreviation: 'P',
  height: '220px',
  width: '300px',
  impactType: 'THREAT',
}

const palette = {
  THREAT: {
    H: RiskColor.THREAT_HIGH,
    M: RiskColor.THREAT_MED,
    L: RiskColor.THREAT_LOW,
  },
  OPPORTUNITY: {
    H: RiskColor.OPPORTUNITY_HIGH,
    M: RiskColor.OPPORTUNITY_MED,
    L: RiskColor.OPPORTUNITY_LOW,
  },
}

const blackBorder = '2px solid black'

/**
 * Function to get black borders when one or many cells are selected.
 *
 * @param group - Active cell group (list of [r, c] coordinates)
 * @param rIdx - Row of cell in question
 * @param cIdx - Col of cell in question
 */
const getSelectedBorder = (group, rIdx, cIdx) => {
  // assume all borders...
  const borders = {
    borderTop: blackBorder,
    borderRight: blackBorder,
    borderBottom: blackBorder,
    borderLeft: blackBorder,
  }
  // then turn off any borders that are shared with another cell in this group
  if (group.some(([r, c]) => r === rIdx - 1 && c === cIdx)) delete borders.borderTop
  if (group.some(([r, c]) => r === rIdx && c === cIdx + 1)) delete borders.borderRight
  if (group.some(([r, c]) => r === rIdx + 1 && c === cIdx)) delete borders.borderBottom
  if (group.some(([r, c]) => r === rIdx && c === cIdx - 1)) delete borders.borderLeft
  return borders
}
const getBgColor = (impactType, r, c) => palette[impactType][RISK_COLOR_MATRIX[r][c]]

const xAxisTitleHeight = '26px'
const xAxisLegendHeight = '26px'
const yAxisTitleWidth = '26px'
const yAxisLegendWidth = '26px'

const style = {
  container: (height, width) => ({
    height,
    width,
    margin: `0 0 ${xAxisTitleHeight} ${yAxisTitleWidth}`,
    position: 'relative',
  }),
  plot: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    padding: `0 0 ${xAxisLegendHeight} ${yAxisLegendWidth}`,
  },
  yAxisTitle: {
    position: 'absolute',
    top: `calc(50% - ${xAxisTitleHeight} / 2)`,
    transform: 'translate(-50%, -100%) rotate(-90deg)',
    transformOrigin: 'bottom',
    fontWeight: 700,
  },
  yAxisLegend: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${xAxisLegendHeight})`,
    justifyContent: 'space-around',
    textAlign: 'right',
    marginRight: '8px',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  xAxisTitle: {
    textAlign: 'center',
    width: `calc(100% - ${yAxisTitleWidth})`,
    fontWeight: 700,
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translateY(100%)',
  },
  xAxisLegend: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    width: `calc(100% - ${yAxisLegendWidth})`,
    bottom: 0,
    right: 0,
  },
  row: css({
    display: 'flex',
    flex: '1',
  }),

  cell: ({ impactType, rIdx, cIdx, isActive, group }) =>
    css({
      cursor: 'pointer',
      alignItems: 'center',
      backgroundColor: getBgColor(impactType, rIdx, cIdx),
      color: 'white',
      display: 'flex',
      flex: '1',
      fontSize: 'large',
      fontWeight: 'bold',
      justifyContent: 'center',
      textShadow: '2px 2px rgba(0, 0, 0, 0.4)',
      border: '.5px solid white',
      ...(isActive ? getSelectedBorder(group, rIdx, cIdx) : {}),
    }),
}

export default Heatmap
