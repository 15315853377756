import { Link, To } from 'react-router-dom'

import { ReactComponent as Logo } from '../../../assets/OptionLab_whiteText.svg'

import style from './LogoNav.module.css'

export function LogoNav({ linkTo }: { linkTo: To }) {
  return (
    <Link className={style.logoNav} to={linkTo}>
      <Logo title="OptionLab Logo" />
    </Link>
  )
}
