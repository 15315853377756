import { FormItemProps } from 'antd'

import { EmailRegex } from '@vms/vmspro3-core/dist/systemConsts'

export function getEmailFieldProps(additionalRules: FormItemProps['rules'] = []): FormItemProps {
  return {
    label: 'Email',
    name: 'email',
    normalize: (email: string) => email.trim(),
    rules: [
      {
        required: true,
        message: 'Please enter an email address',
      },
      {
        validateTrigger: 'onBlur',
        pattern: EmailRegex,
        message: 'Please enter a valid email address',
      },
      ...additionalRules,
    ],
  }
}
