import { ReactElement } from 'react'
import { css, keyframes } from 'glamor'
import { Button } from 'antd'

import AssumingRole from './AssumingRole'
import ImpersonatingUser from './ImpersonatingUser'

import { restoreDefaultAuthz } from '../../../utils/authzUtils'
import { useAppSelector } from '../../../redux'

export function AssumedAuthorizationHeader(): ReactElement | null {
  const assumedAuthz = useAppSelector(state => state.auth.assumedAuthz)

  if (!assumedAuthz) return null

  return (
    <div {...style.container}>
      {(() => {
        switch (assumedAuthz.type) {
          case 'ASSUME_ROLE':
            return <AssumingRole />
          case 'IMPERSONATE_USER':
            return <ImpersonatingUser />
          default:
            return <h3 style={style.message}>AuthZ modification type not recognized!</h3>
        }
      })()}
      <Button onClick={restoreDefaultAuthz}>Restore Default AuthZ</Button>
    </div>
  )
}

const blink = keyframes('blink', {
  '0%': { backgroundColor: 'red' },
  '100%': { backgroundColor: 'yellow' },
})

const style = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 30px',
    animation: `${blink} 1s alternate infinite`,
  }),
  message: {
    margin: '0',
  },
}
