/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, ReactElement } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { UnreachableCaseError } from 'ts-essentials'
import { createSelector } from 'reselect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faClone } from '@fortawesome/free-regular-svg-icons'
import { FolderFilled, FolderAddOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'

import { DecisionFolderPolicyKey, Html } from '@vms/vmspro3-core/dist/types'
import { deleteDecision } from '@vms/vmspro3-core/dist/actions/decision'

import { DecisionNodeCreateModal } from './DecisionNodeCreateModal'
import { DecisionEditModal } from './DecisionEditModal'
import { DecisionFolderDeleteModal } from './DecisionFolderDeleteModal'
import { DecisionFolderEditModal } from './DecisionFolderEditModal'
import { Table, TableCellRenderer } from '../../common/Table'

import { useAppDispatch, useAppSelector } from '../../../redux'
import {
  useDecisionAncestry,
  useDecisionFolder,
  useDecisionFolderAncestry,
  useDecisionFolderChildAncestry,
} from '../../../redux/hooks'
import {
  getSelectFolderAncestors,
  getSelectLoadableChildDecisionFolders,
  getSelectLoadableChildDecisions,
} from '../../../redux/selectors'
import { useModalState } from '../../../hooks/useModalState'
import { testId } from '../../../test-automation'
import { CreateDecisionModal } from './CreateDecisionModal'
import { DuplicateDecisionModal } from './DuplicateDecisionModal'
import { useAccount } from '../../../context'
import { SystemPolicyId } from '@vms/vmspro3-core/dist/systemConsts'

type DecisionFolderChildNode = {
  id: string
  entityType: 'Decision' | 'DecisionFolder'
  typeLabel: 'Decision' | 'VM Decision' | 'Decision Folder'
  name: string
  optionsCount?: number
  description?: Html
}

function useDecisionAccessLevel(folderId: string): { accessLevel: DecisionFolderPolicyKey } {
  const { accountUserRecord } = useAccount()
  const ancestors = useAppSelector(getSelectFolderAncestors(folderId, true))
  if (accountUserRecord.policyIds.includes(SystemPolicyId.DECISION_ADMIN)) return { accessLevel: 'Administrator' }
  for (const a of ancestors.reverse()) {
    const p = a.policies?.find(p => p.userId === accountUserRecord.id)
    if (p && p.policyKey !== 'Inherit') return { accessLevel: p.policyKey }
  }
  return { accessLevel: 'None' }
}

type DecisionFolderProps = {
  accountCommonId: string
  decisionFolderId: string
}
export function DecisionFolderChildren({ accountCommonId, decisionFolderId }: DecisionFolderProps): ReactElement {
  const childAncestry = useDecisionFolderChildAncestry(decisionFolderId)

  const { accessLevel } = useDecisionAccessLevel(decisionFolderId)

  const columns = useMemo<Column<DecisionFolderChildNode>[]>(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: cell => {
          const entity = cell.row.original
          switch (entity.entityType) {
            case 'Decision': {
              return <Link to={`/${accountCommonId}/decision/${entity.id}`}>{entity.name}</Link>
            }
            case 'DecisionFolder': {
              return (
                <Link to={`/${accountCommonId}/decision?folder=${entity.id}`}>
                  <FolderFilled /> {entity.name}
                </Link>
              )
            }
            default: {
              throw new UnreachableCaseError(entity.entityType)
            }
          }
        },
      },
      {
        Header: 'Type',
        accessor: 'typeLabel',
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
        Cell: TableCellRenderer.Html as any,
      },
      {
        Header: 'Options',
        accessor: 'optionsCount',
        sortType: 'numeric',
        align: 'right',
      },
    ],
    [accountCommonId]
  )

  const decisionFolderChildNodes = useAppSelector(
    useMemo(() => {
      return createSelector(
        getSelectLoadableChildDecisionFolders(decisionFolderId),
        getSelectLoadableChildDecisions(decisionFolderId),
        (decisionFolders, decisions) => {
          const childNodes: DecisionFolderChildNode[] = []
          decisionFolders.forEach(decisionFolder => {
            childNodes.push({
              id: decisionFolder.data.id,
              entityType: decisionFolder.data.entityType,
              name: decisionFolder.data.name,
              typeLabel: 'Decision Folder',
            })
          })
          decisions.forEach(decision => {
            // TODO: this isn't a great user experience...as it's loading, the decision/folder disappears
            // from the list, only to reappear when it's loaded...it would be better to have a loading
            // indicator, or even better, in the case of a decision reload, just keep the old decision
            // until the new one's loaded, but that'll be a much deeper change....
            if (decision?.status !== 'Success') return
            childNodes.push({
              id: decision.data.id,
              entityType: decision.data.entityType,
              name: decision.data.name,
              description: decision.data.description,
              typeLabel: decision.data.type === 'Regular' ? 'Decision' : 'VM Decision',
              optionsCount: decision.children && (decision.children?.options?.length ?? 0),
            })
          })
          return childNodes
        }
      )
    }, [decisionFolderId])
  )

  const dispatch = useAppDispatch()

  const { modal, showModal, hideModal } = useModalState()

  const showDecisionNodeCreateModal = useCallback(
    (decisionNodeType: 'Decision' | 'DecisionFolder') =>
      showModal(
        <DecisionNodeCreateModal hide={hideModal} ancestry={childAncestry} decisionNodeType={decisionNodeType} />
      ),
    [showModal, hideModal, childAncestry]
  )

  const createDecisionModal = useModalState()

  const showCreateDecisionModal = useCallback(
    () =>
      createDecisionModal.showModal(
        <CreateDecisionModal ancestry={childAncestry} hide={createDecisionModal.hideModal} />
      ),
    [childAncestry, createDecisionModal]
  )

  const duplicateDecisionModal = useModalState()

  const showDuplicateDecisionModal = useCallback(
    (decisionId: string) =>
      duplicateDecisionModal.showModal(
        <DuplicateDecisionModal decisionId={decisionId} hideModal={duplicateDecisionModal.hideModal} />
      ),
    [duplicateDecisionModal]
  )

  return (
    <>
      {modal}
      {createDecisionModal.modal}
      {duplicateDecisionModal.modal}
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '12px',
          justifyContent: 'flex-end',
        }}
      >
        {accessLevel === 'Administrator' && (
          <>
            <Button
              {...testId('folder')}
              key="DecisionFolder"
              icon={<FolderAddOutlined />}
              onClick={() => showDecisionNodeCreateModal('DecisionFolder')}
              type="primary"
            >
              Create Folder
            </Button>
            <Button
              {...testId('decision')}
              key="Decision"
              icon={<PlusOutlined />}
              onClick={showCreateDecisionModal}
              type="primary"
            >
              Create Decision
            </Button>
          </>
        )}
      </div>
      <Table<DecisionFolderChildNode>
        columns={columns}
        data={decisionFolderChildNodes}
        rowControls={decisionNode =>
          accessLevel === 'Administrator' ? (
            <>
              {decisionNode.entityType === 'Decision' && (
                <Tooltip title="Duplicate">
                  <FontAwesomeIcon
                    aria-label="Duplicate"
                    icon={faClone as any}
                    onClick={() => showDuplicateDecisionModal(decisionNode.id)}
                  />
                </Tooltip>
              )}
              <Tooltip title="Edit">
                <FontAwesomeIcon
                  aria-label="Edit"
                  icon={faEdit as any}
                  onClick={() =>
                    decisionNode.entityType === 'DecisionFolder'
                      ? showModal(<DecisionFolderEditModal decisionFolderId={decisionNode.id} hide={hideModal} />)
                      : showModal(<DecisionEditModal decisionId={decisionNode.id} hide={hideModal} />)
                  }
                />
              </Tooltip>
              <Tooltip title="Delete">
                <FontAwesomeIcon
                  aria-label="Delete"
                  icon={faTrashAlt as any}
                  onClick={() =>
                    decisionNode.entityType === 'DecisionFolder'
                      ? showModal(
                          <DecisionFolderDeleteModal decisionFolderId={decisionNode.id} hide={hideModal} />
                        )
                      : Modal.confirm({
                          title: `Deleting ${decisionNode.name}`,
                          content: `Are you sure you want to delete ${decisionNode.name}?`,
                          onOk: () => {
                            dispatch(deleteDecision(decisionNode.id, decisionFolderId))
                          },
                        })
                  }
                />
              </Tooltip>
            </>
          ) : (
            <></>
          )
        }
        initialState={{
          sortBy: [{ id: 'name', desc: false }],
        }}
      />
    </>
  )
}
