import { duplicateDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { ROOT_DECISION_NODE_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { joinAncestry, splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { Input, message, Modal } from 'antd'
import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../redux'
import { useDecisionAncestry, useDecisionData, useDecisionFolderAncestry } from '../../../redux/hooks'
import { DecisionFolderTreeSelect } from '../../common/DecisionFolderTreeSelect'
import { useCheckboxTree } from '../../common/CheckboxTree'

interface DuplicateParticipantModalProps {
  decisionId: string
  hideModal: VoidFunction
}
export function DuplicateDecisionModal(props: DuplicateParticipantModalProps) {
  const { decisionId, hideModal } = props

  const { name } = useDecisionData(decisionId)

  const [folderId, setFolderId] = useState(
    splitAncestry(useDecisionAncestry(decisionId)).pop() || ROOT_DECISION_NODE_ID
  )
  const [dupName, setDupName] = useState(name + ' Copy')

  const dispatch = useAppDispatch()

  const dstPath = joinAncestry(useDecisionFolderAncestry(folderId), folderId)

  const { CheckboxTree, getValues } = useCheckboxTree(
    [
      {
        key: 'participants',
        label: 'Participant list',
      },
      {
        key: 'criteria',
        label: 'Criteria',
        value: true,
        children: [
          { key: 'criteriaFacilitatorNotes', label: 'Include facilitator notes' },
          { key: 'criteriaRatings', label: 'Include participant ratings' },
          { key: 'criteriaRatingNotes', label: 'Include participant notes' },
        ],
      },
      {
        key: 'options',
        label: 'Options',
        value: true,
        children: [
          { key: 'optionFacilitatorNotes', label: 'Include facilitator notes' },
          { key: 'optionRatings', label: 'Include participant ratings' },
          { key: 'optionRatingNotes', label: 'Include participant notes' },
        ],
      },
    ],
    (key, value, keys, itemsByKey) => {
      const item = itemsByKey[key]
      // if unchecking parent, uncheck & disable all children
      if (!value && item.children?.length) item.children.forEach(c => ((c.value = false), (c.disabled = true)))
      // if checking parent, enable all children
      if (value && item.children?.length) item.children.forEach(c => (c.disabled = false))
      // if any ratings or notes field is checked, force "participants" to be checked
      if (['criteriaRatings', 'criteriaRatingNotes', 'optionRatings', 'optionRatingNotes'].includes(key)) {
        if (value) itemsByKey.participants.value = true
      }
      // if "participants" is unchecked, uncheck any checked ratings or notes fields
      if (!value && key === 'participants') {
        ;(['criteriaRatings', 'criteriaRatingNotes', 'optionRatings', 'optionRatingNotes'] as const).forEach(
          key => (itemsByKey[key].value = false)
        )
      }
      // if criteria are not included, disable all option ratings & notes
      if (!value && key === 'criteria') {
        ;(['optionFacilitatorNotes', 'optionRatings', 'optionRatingNotes'] as const).forEach(
          key => (itemsByKey[key].value = false)
        )
      }
      // if any option ratings or notes are checked, check criteria
      if (value && ['optionFacilitatorNotes', 'optionRatings', 'optionRatingNotes'].includes(key)) {
        itemsByKey.criteria.value = true
      }
    }
  )

  const onOk = useCallback(() => {
    const action = duplicateDecision({
      srcDecisionId: decisionId,
      dstDecisionFolder: dstPath,
      dstName: dupName,
      ...getValues(),
    })
    dispatch(action)
    hideModal()
    message.info('Duplication in progress...this may take up to 30 seconds to complete.')
  }, [decisionId, dispatch, dstPath, dupName, getValues, hideModal])

  return (
    <Modal open onOk={onOk} onCancel={hideModal} title="Duplicate Decision">
      <p>What data from the decision would you like to duplicate?</p>
      <CheckboxTree />
      <div style={{ marginTop: '12px' }}>
        <label>
          What folder should the duplicate go in?
          <DecisionFolderTreeSelect initialSelectedFolderPath={dstPath} onSelect={setFolderId} />
        </label>
      </div>
      <div style={{ marginTop: '12px' }}>
        <label>
          Duplicate name:
          <Input value={dupName} onChange={e => setDupName(e.target.value)} />
        </label>
      </div>
    </Modal>
  )
}
