import style from './CriterionLabel.module.css'
import { match } from 'ts-pattern'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { useMemo } from 'react'
import { Badge, Tooltip } from 'antd'

interface CriterionLabelProps {
  criterion: Criterion
}

const priFormatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'percent',
})

function createLabel(criterion: Criterion, useAbbrev = true) {
  const { name, abbrev } = criterion
  const dispName = useAbbrev ? (
    <Tooltip title={name} placement="right">
      {abbrev}
    </Tooltip>
  ) : (
    name
  )
  const badgeStyle =
    criterion.children.length === 0 || criterion.children.some(c => c.pri.global === null)
      ? { color: 'white', backgroundColor: '#00c', marginTop: -3, border: 'none' }
      : { color: 'black', backgroundColor: '#eee', marginTop: -3, border: 'none' }
  const pri =
    ['Rated', 'Intrinsic'].includes(criterion.type) &&
    criterion.pri.global !== null &&
    priFormatter.format(criterion.pri.global)
  return (
    <span>
      {dispName}&nbsp;{pri && <Badge style={badgeStyle} count={pri} />}
    </span>
  )
}

export function CriterionLabel({ criterion }: CriterionLabelProps) {
  const { type } = criterion
  // add colored dot only to performance (rated) and intrinsic nodes
  const color = ['Rated', 'Intrinsic'].includes(criterion.type) && criterion.color
  const [label, labelStyle] = useMemo(
    () =>
      match(type)
        .with('Value', () => ['All Criteria', {}])
        .with('Performance', () => [createLabel(criterion, false), { fontWeight: 'bold' }])
        .with('Rated', () => [createLabel(criterion), {}])
        .with('IntrinsicRoot', () => [createLabel(criterion, false), { fontWeight: 'bold' }])
        .with('Intrinsic', () => [createLabel(criterion), {}])
        .exhaustive(),
    [type, criterion]
  )
  return (
    <span>
      {color && <div className={style.criterionColor} style={{ backgroundColor: color }} />}
      <span style={labelStyle}>{label}</span>
    </span>
  )
}
